import AdminUserUpdateRequestDto from "../dto/user/AdminUserUpdateRequestDto";
import PresignedRequestDto from "../dto/common/PresignedRequestDto";
import StoreInstance from "../store/StoreInstance";
import UploadApi from "./UploadApi";
import UserAddressUpdateRequestDto from "../dto/user/UserAddressUpdateRequestDto";
import UserDto from "../dto/user/UserDto";
import UserForgotPasswordRequestDto from "../dto/user/auth/UserForgotPasswordRequestDto";
import UserLoginRequestDto from "../dto/user/auth/UserLoginRequestDto";
import UserRegisterRequestDto from "../dto/user/auth/UserRegisterRequestDto";
import UserResetPasswordRequestDto from "../dto/user/auth/UserResetPasswordRequestDto";
import UserSubscribeRequestDto from "../dto/user/auth/UserSubscribeRequestDto";
import UserUpdateRequestDto from "@paperdateco/common/dto/user/UserUpdateRequestDto";
import UserVerifyEmailRequestDto from "../dto/user/auth/UserVerifyEmailRequestDto";
import { handleApiError } from "../utils/ApiUtils";

export default class UserApi {
  public static getAll = async () => {
    try {
      const users = await StoreInstance.api().get<UserDto[]>("/auth/users");
      return users.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get all users");
    }
  };

  public static getOne = async (userId: string) => {
    try {
      const user = await StoreInstance.api().get<UserDto>(
        `/auth/users/${userId}`
      );
      return user.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get user detail");
    }
  };

  public static updateOne = async (
    userId: string,
    data: AdminUserUpdateRequestDto
  ) => {
    try {
      const user = await StoreInstance.api().put<UserDto>(
        `/auth/users/${userId}`,
        data
      );
      return user.data;
    } catch (e) {
      throw handleApiError(e, "Failed to update user detail");
    }
  };

  public static me = async () => {
    try {
      const user = await StoreInstance.api().get<UserDto>("/auth/me");
      return user.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get logged in user");
    }
  };

  public static register = async (userDto: UserRegisterRequestDto) => {
    try {
      const user = await StoreInstance.api().post<UserDto>("/auth", userDto);
      return user.data;
    } catch (e) {
      throw handleApiError(e, "Failed to create new user");
    }
  };

  public static subscribe = async (userDto: UserSubscribeRequestDto) => {
    try {
      const user = await StoreInstance.api().post<UserDto>(
        "/auth/subscribe",
        userDto
      );
      return user.data;
    } catch (e) {
      throw handleApiError(e, "Failed to create new user");
    }
  };

  public static login = async (userDto: UserLoginRequestDto) => {
    try {
      const user = await StoreInstance.api().post<UserDto>(
        "/auth/login",
        userDto
      );
      return user.data;
    } catch (e) {
      throw handleApiError(e, "Failed to login user");
    }
  };

  public static forgotPassword = async (
    userDto: UserForgotPasswordRequestDto
  ) => {
    try {
      const user = await StoreInstance.api().post<UserDto>(
        "/auth/forgot-password",
        userDto
      );
      return user.data;
    } catch (e) {
      throw handleApiError(e, "Failed to send reset mail");
    }
  };

  public static resetPassword = async (
    userDto: UserResetPasswordRequestDto
  ) => {
    try {
      const user = await StoreInstance.api().post<UserDto>(
        "/auth/reset-password",
        userDto
      );
      return user.data;
    } catch (e) {
      throw handleApiError(e, "Failed to reset user password");
    }
  };

  public static verifyEmail = async (userDto: UserVerifyEmailRequestDto) => {
    try {
      const user = await StoreInstance.api().post<UserDto>(
        "/auth/verify-email",
        userDto
      );
      return user.data;
    } catch (e) {
      throw handleApiError(e, "Failed to Verify Email");
    }
  };

  public static logoutUser = async () => {
    try {
      const user = await StoreInstance.api().post("/auth/logout");
      return user.data;
    } catch (e) {
      throw handleApiError(e, "Failed to logout user");
    }
  };

  public static updateUser = async (updateUser: UserUpdateRequestDto) => {
    try {
      const user = await StoreInstance.api().put<UserDto>(
        "/auth/me",
        updateUser
      );
      return user.data;
    } catch (e) {
      throw handleApiError(e, "Failed to Update User");
    }
  };

  public static updateUserAddress = async (
    updateUser: UserAddressUpdateRequestDto
  ) => {
    try {
      const user = await StoreInstance.api().put<UserDto>(
        "/auth/me/address",
        updateUser
      );
      return user.data;
    } catch (e) {
      throw handleApiError(e, "Failed to Update User Address");
    }
  };

  public static async uploadMedia(userId: string, file: File) {
    const presignedRequest = await this.getPresignedUrlForNewMedia(
      userId,
      file.name
    );
    const url = await UploadApi.uploadFile(presignedRequest, file);
    return url;
  }

  private static async getPresignedUrlForNewMedia(
    userId: string,
    name: string
  ) {
    try {
      const products = await StoreInstance.api().get<PresignedRequestDto>(
        `/auth/users/${userId}/media/presigned-url?name=${name}`
      );
      return products.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get valid url for uploading file");
    }
  }
}
