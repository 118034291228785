import { Container, Typography } from "@mui/material";

import CollaboratorList from "../../products/collaborator/CollaboratorList";
import Layout from "../../layout/Layout";

export default function CollaboratorsPage() {
  return (
    <Layout>
      <Container maxWidth="xl" disableGutters>
        <Typography variant="h5" marginTop={5} textAlign="center">
          ARTISTS &amp; CREATORS
        </Typography>
        <CollaboratorList />
      </Container>
    </Layout>
  );
}
