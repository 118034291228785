import { BoxProps } from "@mui/system";
import { Button } from "@mui/material";
import CategoryProductItemWithoutLink from "@paperdateco/customer/components/products/category/CategoryProductItemWithoutLink";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import useCustomiseTemplate from "@paperdateco/customer/components/products/detail/useCustomiseTemplate";

const CustomButton = CustomStyled(Button)({
  padding: 0,
  flexShrink: 0,
});

interface InviteProductListItemProps {
  product: ProductDto;
}

export default function InviteProductListItem({
  product,
  ...props
}: InviteProductListItemProps & BoxProps) {
  const onCustomiseTemplate = useCustomiseTemplate(product);

  return (
    <CustomButton variant="text" onClick={onCustomiseTemplate}>
      <CategoryProductItemWithoutLink
        key={product.id}
        product={product}
        showName={false}
        {...props}
      />
    </CustomButton>
  );
}
