import CommonActionTypes, { ON_LOGIN, ON_LOGOUT } from "./CommonTypes";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { RootState } from "..";
import { getAllCartItems } from "../cart/CartAction";

export const onLoginInternal = (): CommonActionTypes => ({
  type: ON_LOGIN,
});

export const onLogout = (): CommonActionTypes => ({
  type: ON_LOGOUT,
});

export const onLogin =
  (): ThunkAction<void, RootState, unknown, CommonActionTypes> =>
  async (dispatch) => {
    await dispatch(getAllCartItems());
    dispatch(onLoginInternal());
  };

export type CommonStateDispatchType = ThunkDispatch<
  RootState,
  unknown,
  CommonActionTypes
>;
