import { Box, BoxProps } from "@mui/system";

import { AuthContext } from "@paperdateco/shared-frontend/components/auth/AuthProvider";
import { Typography } from "@mui/material";
import { useContext } from "react";

export default function AccountDashboard(props: BoxProps) {
  const { user } = useContext(AuthContext);

  if (!user) {
    return null;
  }

  return (
    <Box padding={2} color="primary.600" {...props}>
      <Typography variant="body1">
        Hey{" "}
        <Typography component="span" fontWeight="bold">
          {user.email}
        </Typography>{" "}
      </Typography>
      <Typography variant="body1" marginY={3}>
        Welcome to Paperdate Co Dashboard.
      </Typography>
    </Box>
  );
}
