import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import CustomDesignApi from "@paperdateco/common/api/CustomDesignApi";
import CustomDesignDto from "@paperdateco/common/dto/design/custom/CustomDesignDto";
import InvitePreviewAnimation from "./InvitePreviewAnimation";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import PreviewNavigationButton from "./PreviewNavigationButton";

interface CustomDesignPreviewProps {
  customDesignId: string;
  cartItemId?: string;
  shouldDownload?: boolean;
}

export default function CustomDesignPreview({
  customDesignId,
  cartItemId,
  shouldDownload = false,
}: CustomDesignPreviewProps) {
  const [customDesign, setCustomDesign] = useState<CustomDesignDto>();

  useEffect(() => {
    CustomDesignApi.getById(customDesignId)
      .then(setCustomDesign)
      .catch(NotificationUtils.showGenericError);
  }, [customDesignId]);

  if (!customDesign) {
    return null;
  }

  return (
    <Box flexGrow={1} position="relative" display="flex">
      <Box
        position="absolute"
        zIndex={2}
        top={35}
        left={0}
        display="flex"
        justifyContent="center"
        width="100%"
      >
        <PreviewNavigationButton
          customDesignId={customDesignId}
          cartItemId={cartItemId}
        />
      </Box>
      <InvitePreviewAnimation
        customDesign={customDesign}
        showDownload={shouldDownload}
      />
    </Box>
  );
}
