import CustomDesignDto from "@paperdateco/common/dto/design/custom/CustomDesignDto";
import InvitationJpeg from "./InvitationJpeg";

interface InvitationJpegDownloadProps {
  customDesign: CustomDesignDto;
  onComplete?: () => void;
}

export default function InvitationJpegDownload({
  customDesign,
  onComplete,
}: InvitationJpegDownloadProps) {
  // Invitation Back is not officially supported yet
  // if (customDesign.invitationBack) {
  //   return (
  //     <GreetingJpeg
  //       invitation={customDesign.invitationFront}
  //       invitationBack={customDesign.invitationBack}
  //       onComplete={onComplete}
  //     />
  //   );
  // }

  return (
    <InvitationJpeg invitation={customDesign.preview} onComplete={onComplete} />
  );
}
