import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

import { Box } from "@mui/system";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import { Link } from "@mui/material";

const HomePageLine = CustomStyled(Box)(({ theme }) => ({
  height: theme.spacing(20),
  borderLeft: `1px solid ${Colors.BLACK}`,
}));

interface IconItem {
  link: string;
  icon: FontAwesomeIconProps["icon"];
}

const iconsList: IconItem[] = [
  { link: "https://www.facebook.com/paperdateco/", icon: faFacebookF },
  { link: "https://www.instagram.com/paperdateco/", icon: faInstagram },
  {
    link: "https://www.linkedin.com/company/paper-date-co/",
    icon: faLinkedinIn,
  },
];

export default function HomePageBannerIcons() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginTop={6}
    >
      <HomePageLine />
      {iconsList.map((icon) => (
        <Link
          key={icon.link}
          href={icon.link}
          color={Colors.BLACK}
          marginTop={2}
          target="_blank"
          rel="noopener noreferer noreferrer"
        >
          <FontAwesomeIcon icon={icon.icon} />
        </Link>
      ))}
    </Box>
  );
}
