import { Box } from "@mui/system";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import GtmUtils from "@paperdateco/customer/utils/GtmUtils";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import { Link } from "@mui/material";
import WhatsappIcon from "@paperdateco/customer/assets/images/logo/whatsapp_logo.jpeg";
import { useCallback } from "react";

const WhatsappImage = CustomStyled(HiddenImage)({
  transform: "scale(1.8)",
});

export default function FooterMessageIcons() {
  const trackWhatsappContact = useCallback(() => {
    GtmUtils.trackEvent("whatsapp chat icon", "click", window.location.href);
  }, []);

  return (
    <Box position="fixed" bottom={70} right={0} padding={3} zIndex={5}>
      <Box borderRadius="50%" width={60} height={60} overflow="hidden">
        <Link
          href="https://wa.me/+917093300007"
          target="_blank"
          onClick={trackWhatsappContact}
          display="block"
        >
          <WhatsappImage width={60} src={WhatsappIcon} aspectRatio={1} />
        </Link>
      </Box>
    </Box>
  );
}
