const Fonts = {
  Futurist: "Futurist Fixed-width",
  Carmorant: "Cormorant Garamond",
  Marcellus: "Marcellus",
  Amertha: "amertha",
  Futura: "Futura",
  Modesty: "Modesty",
  Suther: "suther",
  AleoBold: "aleobold",
  AleoLight: "aleolight",
  Titillium: "Titillium Web",
  Roboto: "Roboto",
  Playfair: "PlayfairDisplay-Regular",
};

export default Fonts;
