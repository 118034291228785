import { Container, Typography } from "@mui/material";

import Layout from "../../layout/Layout";

export default function PrivacyPolicy() {
  return (
    <Layout>
      <Container maxWidth="lg">
        <Typography variant="h3" margin={2} textAlign={"center"}>
          Privacy Policy
        </Typography>
        <Typography variant="h6" marginY={10} marginX={2}>
          <strong>This Privacy Policy applies to the paperdateco.com</strong>
          <br />
          <br />
          MESH DESIGNS recognizes the importance of maintaining your privacy. We
          value your privacy and appreciate your trust in us. This Policy
          describes how we treat user information we collect on
          www.paperdateco.com and other offline sources. This Privacy Policy
          applies to current and former visitors to our website and our online
          customers. By visiting and/or using our website, you agree to this
          Privacy Policy.
          <br />
          <br />
          Mesh Designs is a property of Mesh Designs having its registered
          office at 8-2-293/82/A/896, Road No.46, Jubilee Hills, Hyderabad,
          Telangana, 500033.
          <br />
          <br />
          <strong>Information we collect:</strong>
          <br />
          <strong>Contact information.</strong> We might collect your name,
          email, mobile number, phone number, street, city, state, Pincode,
          country, and IP address.
          <br />
          <br />
          <strong>Payment and billing information.</strong> We might collect
          your billing name, address, and payment method when you buy a product.
          We NEVER collect your credit card number or credit card expiry date or
          other details of your credit card on our website. Credit card
          information will be obtained and processed by our online payment
          partner Razor Pay.
          <br />
          <br />
          <strong>Information you post.</strong> We might collect your billing
          name, address, and payment method when you buy a product. We NEVER
          collect your credit card number or credit card expiry date or other
          details of your credit card on our website. Credit card information
          will be obtained and processed by our online payment partner Razor
          Pay.
          <br />
          <br />
          <strong>Demographic information.</strong> We may collect demographic
          information about you, products you like, products you intend to buy,
          or any other information provided by you during the use of our
          website. We might collect this as a part of a survey also.
          <br />
          <br />
          <strong>Other information.</strong> If you use our website, we may
          collect information about your IP address and the browser you're
          using. We might look at what site you came from, the duration of time
          spent on our website, pages accessed, or what site you visit when you
          leave us. We might also collect the type of mobile device you are
          using, or the version of the operating system your computer or device
          is running.
          <br />
          <br />
          <strong>We collect information in different ways.</strong>
          <br />
          <br />
          <strong>We collect information directly from you.</strong> We collect
          information directly from you when you buy products. We also collect
          information if you post a comment on our websites or ask us a question
          through phone or email.
          <br />
          <br />
          <strong>We collect information from you passively. </strong> We use
          tracking tools like Google Analytics, Google Webmaster, browser
          cookies, and web beacons for collecting information about your usage
          of our website.
          <br />
          <br />
          <strong>We get information about you from third parties.</strong> For
          example, if you use an integrated social media feature on our
          websites. The third-party social media site will give us certain
          information about you. This could include your name and email address.
          <br />
          <br />
          <strong>Use of your personal information</strong>
          <br />
          <br />
          <strong>We use the information to contact you:</strong> We might use
          the information you provide to contact you for confirmation of a
          purchase on our website or other promotional purposes.
          <br />
          <br />
          <strong>
            We use the information to respond to your requests or questions.
          </strong>{" "}
          we might use your information to confirm your registration for an
          event or contest.
          <br />
          <br />
          <strong>
            We use the information to improve our products and services.{" "}
          </strong>{" "}
          We might use your information to customize your experience with us.
          This could include displaying content based on your preferences.
          <br />
          <br />
          <strong>
            We use the information to look at site trends and customer
            interests.
          </strong>{" "}
          We may use your information to make our website and products better.
          We may combine information we get from you with information about you
          we get from third parties.
          <br />
          <br />
          <strong>We use the information for security purposes.</strong> We may
          use the information to protect our company, our customers, or our
          websites.
          <br />
          <br />
          <strong>We use the information for marketing purposes.</strong> We
          might send you information about special promotions or offers. We
          might also tell you about new features or products. These might be our
          offers or products, or third-party offers or products we think you
          might find interesting. Or, for example, if you buy products from us
          we'll enroll you in our newsletter.
          <br />
          <br />
          <strong>
            We use the information to send you transactional communications.
          </strong>{" "}
          We might send you emails or SMS about your account or a product
          purchase.
          <br />
          <br />
          We use information as otherwise permitted by law.
          <br />
          <br />
          <strong>Sharing of information with third-parties</strong>
          <br />
          <br />
          <strong>
            We will share information with third parties who perform services on
            our behalf.
          </strong>{" "}
          We share information with vendors who help us manage our online
          registration process or payment processors or transactional message
          processors. Some vendors may be located outside of India.
          <br />
          <br />
          <strong>
            We will share information with the logistic providers.
          </strong>{" "}
          We share your information with logistic providers and other parties
          responsible for fulfilling the purchase obligation. The logistic
          providers and other parties may use the information we give them as
          described in their privacy policies.
          <br />
          <br />
          <strong>
            We may share information if we think we have to comply with the law
            or to protect ourselves.
          </strong>{" "}
          We will share information to respond to a court order or subpoena. We
          may also share it if a government agency or investigatory body
          requests it. Or, we might also share information when we are
          investigating potential fraud.
          <br />
          <br />
          <strong>
            We may share information with any successor to all or part of our
            business.
          </strong>{" "}
          For example, if part of our business is sold we may give our customer
          list as part of that transaction.
          <br />
          <br />
          <strong>
            We may share your information for reasons not described in this
            policy.
          </strong>{" "}
          We will tell you before we do this.
          <br />
          <br />
          <strong>Email Opt-Out</strong>
          <br />
          <br />
          <strong>You can opt-out of receiving our marketing emails.</strong> To
          stop receiving our promotional emails, please email
          unsubscriber@paperdateco.com. It may take about ten days to process
          your request. Even if you opt-out of getting marketing messages, we
          will still be sending you transactional messages through email and SMS
          about your purchases.
          <br />
          <br />
          <strong>Third-party sites</strong>
          <br />
          <br />
          If you click on one of the links to third-party websites, you may be
          taken to websites we do not control. This policy does not apply to the
          privacy practices of those websites. Read the privacy policy of other
          websites carefully. We are not responsible for these third-party
          sites.
          <br />
          <br />
          <strong>Grievance Officer</strong>
          <br />
          <br />
          Under Information Technology Act 2000 and rules made thereunder, the
          name and contact details of the Grievance Officer are provided below:
          sites.
          <br />
          <br />
          Mrs. Meenakshi Rao <br />
          8-2-293/82/A/896, Road No.46, Jubilee Hills, Hyderabad, Telangana,
          500033 <br />
          Phone: 7093300007 Email: admin@paperdateco.com <br />
          <br />
          If you have any questions about this Policy or other privacy concerns,
          you can also email us at Info@paperdateco.com
          <br />
          <br />
          <strong>Updates to this policy</strong>
          <br />
          <br />
          This Privacy Policy was last updated on 05 Jan 2022. From time to time
          we may change our privacy practices. We will notify you of any
          material changes to this policy as required by law. We will also post
          an updated copy on our website. Please check our site periodically for
          updates. sites.
          <br />
          <br />
          <strong>Jurisdiction</strong>
          <br />
          <br />
          If you choose to visit the website, your visit and any dispute over
          privacy are subject to this Policy and the website's terms of use. In
          addition to the foregoing, any disputes arising under this Policy
          shall be governed by the laws of India
          <br />
          <br />
        </Typography>
        <br />
      </Container>
    </Layout>
  );
}
