import AnimationValue from "./AnimationValue";
import AnimationValueUtil from "./AnimationValueUtil";
import { fabric } from "fabric";

const duration = 8000;
const easing = AnimationValueUtil.easeLinear;

export default function DriftAnimation(elem: fabric.Object): AnimationValue[] {
  return [
    {
      property: "left",
      value: {
        from: (elem.left ?? 0) - 30,
        to: elem.left ?? 0,
        easing,
        duration,
      },
    },
    {
      property: "opacity",
      value: {
        from: 0,
        to: elem.opacity ?? 1,
        easing,
        duration: 10,
      },
    },
  ];
}
