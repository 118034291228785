import { CATEGORY_GET_ITEMS_NAME } from "./CategoryTypes";
import CategoryApi from "@paperdateco/common/api/product/CategoryApi";
import CategoryDto from "@paperdateco/common/dto/category/CategoryDto";
import GetItemsAction from "@paperdateco/shared-frontend/store/getItems/GetItemsAction";
import GetItemsActionTypes from "@paperdateco/shared-frontend/store/getItems/GetItemsTypes";
import { RootState } from "..";
import { ThunkDispatch } from "redux-thunk";

export const getAllCategories = GetItemsAction(
  CATEGORY_GET_ITEMS_NAME,
  CategoryApi.getAll,
  (state: RootState) => state.category
);

export type CategoryStateDispatchType = ThunkDispatch<
  RootState,
  unknown,
  GetItemsActionTypes<CategoryDto>
>;
