import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import DesignUtils from "@paperdateco/common/utils/DesignUtils";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import HiddenImageFit from "@paperdateco/shared-frontend/components/common/image/HiddenImageFit";
import { Link } from "react-router-dom";
import OrderDetailedDto from "@paperdateco/common/dto/order/OrderDetailedDto";
import StringUtils from "@paperdateco/common/utils/StringUtils";
import classNames from "classnames";
import routes from "@paperdateco/customer/routes/routes";
import { useState } from "react";

interface AccountOrderRowProps {
  order: OrderDetailedDto;
}

export default function AccountOrderRow({ order }: AccountOrderRowProps) {
  const [open, setOpen] = useState(false);

  const toggleDetails = () => {
    setOpen(!open);
  };

  return (
    <>
      <TableRow
        key={order.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell>{StringUtils.shortenObjectId(order.id)}</TableCell>
        <TableCell>{StringUtils.formatDate(order.orderTime)}</TableCell>
        <TableCell>{order.price.total}</TableCell>
        <TableCell>{order.items.length}</TableCell>
        <TableCell>
          <IconButton size="small" onClick={toggleDetails}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} className={classNames({ "d-none": !open })}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6">Products</Typography>
              <Table aria-label="purchased">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Event Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.items.map((item) => (
                    <TableRow key={item.product.id}>
                      <TableCell>{item.product.name}</TableCell>
                      <TableCell>
                        <HiddenImage
                          src={DesignUtils.getImageFromOrderItem(item)}
                          width={100}
                          height={150}
                          fit={HiddenImageFit.COVER}
                        />
                      </TableCell>
                      <TableCell>{item.product.price}</TableCell>
                      <TableCell>
                        <Link to={routes.ACCOUNT_MANAGE_INVITES(item.id)}>
                          <Button variant="contained">View</Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
