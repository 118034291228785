import { Button, Stack, Typography } from "@mui/material";

import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import WarningPopup from "./common/WarningPopup";
import { useCallback } from "react";

interface PageTransitionWarningPopupProps {
  message: string;
  onResult: (result: boolean) => void;
}

export default function PageTransitionWarningPopup({
  message,
  onResult,
}: PageTransitionWarningPopupProps) {
  const onCancel = useCallback(() => {
    onResult(false);
  }, [onResult]);

  const onContinue = useCallback(() => {
    onResult(true);
  }, [onResult]);

  return (
    <WarningPopup>
      <Typography
        variant="body2"
        textAlign="center"
        fontFamily={Fonts.Playfair}
        fontWeight="bold"
      >
        {message}
      </Typography>

      <Stack
        direction="row"
        spacing={2}
        fontFamily={Fonts.Roboto}
        marginTop={2}
      >
        <Button variant="contained" onClick={onCancel} size="small">
          CANCEL
        </Button>
        <Button
          variant="contained"
          onClick={onContinue}
          size="small"
          color="error"
        >
          OK
        </Button>
      </Stack>
    </WarningPopup>
  );
}
