import { Box, SliderProps } from "@mui/material";

import CanvasElement from "@paperdateco/shared-frontend/canvas/objects/CanvasElement";
import CustomSliderInput from "@paperdateco/shared-frontend/components/common/form/CustomSliderInput";
import { useCallback } from "react";
import useOptionRerender from "@paperdateco/shared-frontend/canvas/hooks/useOptionRerender";

type KeysMatching<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

export type NumberKeys<T> = KeysMatching<T, number | undefined>;

interface CanvasNumberPropControlProps<T extends fabric.Object> {
  elements: CanvasElement<T>[];
  prop: NumberKeys<T>;
  defaultValue: number;
  label: string;
  valueFormat?: (value: number) => number;
  valueLabelFormat?: (value: number) => string;
  scale?: (value?: number) => number | undefined;
  inverseScale?: (value?: number) => number | undefined;
  sliderProps?: SliderProps;
}

export default function CanvasNumberPropControl<T extends fabric.Object>({
  elements,
  prop,
  defaultValue,
  label,
  scale = (v) => v,
  inverseScale = (v) => v,
  valueFormat = (v) => v,
  valueLabelFormat = (v) => v.toString(),
  sliderProps,
}: CanvasNumberPropControlProps<T>) {
  useOptionRerender(elements, prop);

  const onValueChange = useCallback(
    (value: number) => {
      // Typing explicitly as comiler doesn't understand T[NumberKeys<T>] is number
      elements.forEach((elem) =>
        elem.set(prop, value as unknown as T[NumberKeys<T>])
      );
    },
    [elements, prop]
  );

  const value =
    (elements[0]?.nativeElement[prop] as unknown as number) ?? defaultValue;

  return (
    <Box
      paddingX={2}
      paddingY={1}
      width="100%"
      display="flex"
      flexDirection="column"
    >
      <CustomSliderInput
        value={value}
        onValueChange={onValueChange}
        label={label}
        valueFormat={valueFormat}
        valueLabelFormat={valueLabelFormat}
        scale={scale}
        inverseScale={inverseScale}
        sliderProps={sliderProps}
      />
    </Box>
  );
}
