import { Stack, StackProps } from "@mui/material";
import { useCallback, useContext, useState } from "react";

import CustomDesignDto from "@paperdateco/common/dto/design/custom/CustomDesignDto";
import CustomShadowButton from "@paperdateco/customer/components/account/common/CustomShadowButton";
import DownloadPreparationMessage from "./DownloadPreparationMessage";
import InvitationAnimationDownload from "../animation/InvitationAnimationDownload";
import InvitationJpegDownload from "../jpeg/InvitationJpegDownload";
import { ModalContext } from "@paperdateco/shared-frontend/components/providers/ModalProvider";

interface InviteDownloadButtonsProps {
  customDesign: CustomDesignDto;
}

export default function InviteDownloadButtons({
  customDesign,
  ...props
}: InviteDownloadButtonsProps & StackProps) {
  const { open, close } = useContext(ModalContext);
  const [isDownloadingAnimation, setIsDownloadingAnimation] = useState(false);
  const [isDownloadingJpeg, setIsDownloadingJpeg] = useState(false);

  const onDownloadAnimation = useCallback(() => {
    open(<DownloadPreparationMessage message="Packing your invitation..." />);
    setIsDownloadingAnimation(true);
  }, [open]);

  const onDownloadJpeg = useCallback(() => {
    open(<DownloadPreparationMessage message="Printing your invitation..." />);
    setIsDownloadingJpeg(true);
  }, [open]);

  const onRecordComplete = useCallback(() => {
    close();
    setIsDownloadingAnimation(false);
  }, [close]);

  const onPrintComplete = useCallback(() => {
    close();
    setIsDownloadingJpeg(false);
  }, [close]);

  return (
    <Stack direction="column" spacing={2} width="100%" {...props}>
      <CustomShadowButton fullWidth onClick={onDownloadAnimation}>
        Download animation
      </CustomShadowButton>

      {isDownloadingAnimation && (
        <InvitationAnimationDownload
          customDesign={customDesign}
          onComplete={onRecordComplete}
        />
      )}

      <CustomShadowButton fullWidth onClick={onDownloadJpeg}>
        Download JPEG
      </CustomShadowButton>

      {isDownloadingJpeg && (
        <InvitationJpegDownload
          customDesign={customDesign}
          onComplete={onPrintComplete}
        />
      )}
    </Stack>
  );
}
