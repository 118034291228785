import { BoxProps } from "@mui/system";
import FullWidthPage from "@paperdateco/shared-frontend/components/layout/FullWidthPage";
import { ReactNode } from "react";
import SimpleHeader from "./Header/SimpleHeader";

interface SimpleLayoutProps {
  title: ReactNode;
  headerNav?: ReactNode;
}

export default function SimpleLayout({
  title,
  headerNav,
  children,
  ...props
}: SimpleLayoutProps & Omit<BoxProps, "title">) {
  return (
    <FullWidthPage {...props}>
      <SimpleHeader navSection={headerNav}>{title}</SimpleHeader>
      {children}
    </FullWidthPage>
  );
}
