import { Box, BoxProps } from "@mui/system";

import HiddenImage from "../../image/HiddenImage";
import PopupContainer from "./PopupContainer";
import { Stack } from "@mui/material";
import WarningIcon from "@paperdateco/shared-frontend/assets/icons/warning.svg";

export default function WarningPopup(props: BoxProps) {
  return (
    <PopupContainer maxWidth={300} padding={2} {...props}>
      <Stack alignItems="center">
        <Box width={50}>
          <HiddenImage
            src={WarningIcon}
            display="block"
            width="100%"
            aspectRatio={1}
          />
        </Box>
        {props.children}
      </Stack>
    </PopupContainer>
  );
}
