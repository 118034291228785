import { Box, Container } from "@mui/material";

import ContactUsDetails from "./ContactUsDetails";
import ContactUsForm from "./ContactUsForm";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import SimpleLayoutWithFooter from "@paperdateco/customer/components/layout/SimpleLayoutWithFooter";

const FormContainer = CustomStyled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.primary}`,
}));

export default function Contact_Us() {
  return (
    <SimpleLayoutWithFooter title="CONTACT US">
      <Container maxWidth="md">
        <ContactUsDetails />
        <FormContainer
          margin={{ sm: 4, xs: 1 }}
          padding={{ md: 4, xs: 2 }}
          borderRadius={3}
        >
          <ContactUsForm />
        </FormContainer>
      </Container>
    </SimpleLayoutWithFooter>
  );
}
