import { SliderProps, Tooltip } from "@mui/material";

import { Box } from "@mui/system";
import CanvasElement from "@paperdateco/shared-frontend/canvas/objects/CanvasElement";
import CanvasNumberPropControl from "@paperdateco/shared-frontend/canvas/controls/common/CanvasNumberPropControl";
import NumberUtils from "@paperdateco/common/utils/NumberUtils";
import OpacityIcon from "@mui/icons-material/Opacity";
import PopupButton from "@paperdateco/shared-frontend/components/common/form/button/PopupButton";

const OpacitySliderProps: SliderProps = {
  step: 0.01,
  min: 0,
  max: 1,
  valueLabelDisplay: "off",
};

const OpacityScale = (v?: number) =>
  v !== undefined ? NumberUtils.round(v * 100, 0) : undefined;

const OpacityInverseScale = (v?: number) =>
  v !== undefined ? NumberUtils.round(v / 100, 2) : undefined;

interface CanvasElementOpacityControlProps<T extends fabric.Object> {
  elements: CanvasElement<T>[];
  disabled?: boolean;
  iconColor: string;
}

export default function CanvasElementOpacityControl<T extends fabric.Object>({
  elements,
  disabled = false,
  iconColor,
}: CanvasElementOpacityControlProps<T>) {
  return (
    <PopupButton
      disabled={disabled}
      button={
        <Tooltip title="Opacity" placement="left">
          <OpacityIcon sx={{ width: "100%" }} htmlColor={iconColor} />
        </Tooltip>
      }
      buttonProps={{ sx: { maxWidth: 64 } }}
      popup={
        <Box paddingX={1} paddingY={0.5}>
          <CanvasNumberPropControl
            elements={elements}
            prop={"opacity" as any}
            defaultValue={1}
            label="TRANSPARENCY"
            sliderProps={OpacitySliderProps}
            scale={OpacityScale}
            inverseScale={OpacityInverseScale}
          />
        </Box>
      }
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "center",
      }}
    />
  );
}
