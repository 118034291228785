import {
  BannerStateDispatchType,
  GetAllBanners,
} from "@paperdateco/customer/store/banner/BannerActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";

import BannerWithTextLayout from "./BannerWithTextLayout";
import { Box } from "@mui/system";
import CustomSwiper from "../../../common/ui/swiper/CustomSwiper";
import HomePageBannerIcons from "./HomePageBannerIcons";
import { selectBanners } from "@paperdateco/customer/store/banner/BannerSelector";

export default function HomePageBanner() {
  const banners = useSelector(selectBanners);
  const slides = useMemo(() => banners.map(BannerWithTextLayout), [banners]);
  const dispatch = useDispatch<BannerStateDispatchType>();

  useEffect(() => {
    dispatch(GetAllBanners());
  }, [dispatch]);

  if (slides.length === 0) {
    return null;
  }

  return (
    <Box position="relative">
      <Box position="absolute" right={20} zIndex={10}>
        <HomePageBannerIcons />
      </Box>
      <CustomSwiper slides={slides} />;
    </Box>
  );
}
