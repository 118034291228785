import GtmUtils from "./GtmUtils";
import SentryUtils from "./SentryUtils";
import UserDto from "@paperdateco/common/dto/user/UserDto";

export default class UserUtils {
  static onUser(user: UserDto) {
    SentryUtils.setUser(user);
    GtmUtils.trackUser(user);
  }

  static onAnonymous() {
    SentryUtils.setUser();
    GtmUtils.trackUser();
  }
}
