import {
  Box,
  Button,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import CustomSwiper from "../../../common/ui/swiper/CustomSwiper";
import FeedItemDto from "@paperdateco/common/dto/instagramFeed/FeedItemDto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FooterCard from "./FooterCard";
import InstagramFeedApi from "@paperdateco/common/api/instagramFeedApi";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

const FooterCardCarousel = CustomStyled(CustomSwiper)(({ theme }) => ({
  paddingBottom: theme.spacing(5),
  ".swiper-wrapper": {
    alignItems: "stretch",
    ".swiper-slide": {
      height: "unset",
    },
  },
  ".swiper-pagination.swiper-pagination-bullets": {
    bottom: 0,
    ".swiper-pagination-bullet": {
      width: 5,
      height: 5,
      "&.swiper-pagination-bullet-active": {
        width: 8,
        height: 8,
      },
    },
  },
}));

export default function FooterCardSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const [instaFeeds, setInstaFeeds] = useState<FeedItemDto[]>([]);
  useEffect(() => {
    InstagramFeedApi.getAllInstagramFeeds().then(setInstaFeeds);
  }, []);

  return (
    <Box paddingX={2} marginBottom={2} textAlign="center">
      <Typography variant="body3" component="p" marginBottom={1}>
        Follow the @paperdateco official instagram profile
      </Typography>
      <FooterCardCarousel
        slides={instaFeeds.map((card, i) => (
          <FooterCard key={i} card={card} />
        ))}
        slidesPerView={isMobile ? 2 : isLargeScreen ? 4 : 3}
        loop={false}
        spaceBetween={5}
        speed={1000}
        effect="slide"
      />
      <Link
        href="https://www.instagram.com/paperdateco/"
        target="_blank"
        className="text-decoration-none"
      >
        <Button
          variant="outlined"
          size="small"
          startIcon={
            <FontAwesomeIcon color={Colors.BLACK} icon={faInstagram} />
          }
        >
          <Typography variant="body2">Follow us on Instagram</Typography>
        </Button>
      </Link>
    </Box>
  );
}
