import AnimationValue from "./AnimationValue";
import { fabric } from "fabric";

export default function SlideLeftAnimation(
  elem: fabric.Object
): AnimationValue[] {
  return [
    {
      property: "left",
      value: {
        from: (elem.left ?? 0) + 100,
        to: elem.left ?? 0,
        easing: fabric.util.ease.easeOutQuad,
        duration: 1000,
      },
    },
    {
      property: "opacity",
      value: {
        from: 0,
        to: elem.opacity ?? 1,
        easing: fabric.util.ease.easeOutQuad,
        duration: 1000,
      },
    },
  ];
}
