import GetItemsReducer from "@paperdateco/shared-frontend/store/getItems/GetItemsReducer";
import { ON_LOGOUT } from "../common/CommonTypes";
import { ORDER_GET_ITEMS_NAME } from "./OrderTypes";
import OrderDetailedDto from "@paperdateco/common/dto/order/OrderDetailedDto";

const OrderReducer = GetItemsReducer<OrderDetailedDto[]>(
  ORDER_GET_ITEMS_NAME,
  [],
  ON_LOGOUT
);

export default OrderReducer;
