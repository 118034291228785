import { Box, BoxProps } from "@mui/system";

import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";

const GrayContainer = CustomStyled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export default function AuthFormContainer({ children, ...props }: BoxProps) {
  return (
    <Box display="flex" justifyContent="center">
      <GrayContainer
        width="100%"
        maxWidth={800}
        margin={4}
        borderRadius={2}
        paddingY={4}
        {...props}
      >
        {children}
      </GrayContainer>
    </Box>
  );
}
