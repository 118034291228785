import { useEffect, useState } from "react";

import { BoxProps } from "@mui/system";
import CanvasPageProvider from "@paperdateco/shared-frontend/canvas/provider/CanvasPageProvider";
import CustomDesignApi from "@paperdateco/common/api/CustomDesignApi";
import CustomDesignDto from "@paperdateco/common/dto/design/custom/CustomDesignDto";
import InviteCustomiseLayout from "./InviteCustomiseLayout";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import { useDispatch } from "react-redux";

interface InviteCustomisationProps {
  customDesignId: string;
  onViewPreview?: () => void;
}

export default function InviteCustomisation({
  customDesignId,
  onViewPreview,
  ...props
}: InviteCustomisationProps & BoxProps) {
  const [design, setDesign] = useState<CustomDesignDto>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (customDesignId) {
      CustomDesignApi.getById(customDesignId)
        .then(setDesign)
        .catch(NotificationUtils.showGenericError);
    }
  }, [customDesignId, dispatch]);

  if (!design) {
    return null;
  }

  return (
    <CanvasPageProvider pages={design.pages}>
      <InviteCustomiseLayout
        design={design}
        onViewPreview={onViewPreview}
        {...props}
      />
    </CanvasPageProvider>
  );
}
