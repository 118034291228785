import AnimationType from "@paperdateco/common/canvas/animation/AnimationType";

export interface CanvasAnimationSection {
  name: string;
  options: AnimationType[];
}

const CanvasAnimationSections: CanvasAnimationSection[] = [
  {
    name: "SLIDE",
    options: [
      AnimationType.SLIDE_HORIZONTAL,
      AnimationType.SLIDE_VERTICAL,
      AnimationType.SLIDE_LEFT,
      AnimationType.SLIDE_RIGHT,
      AnimationType.DRIFT,
      AnimationType.TECTONIC,
    ],
  },
  {
    name: "EXAGGERATE",
    options: [
      AnimationType.DISSOLVE,
      AnimationType.WIPE,
      AnimationType.BASELINE,
      AnimationType.TUMBLE,
    ],
  },
  {
    name: "ZOOM",
    options: [
      AnimationType.ZOOM_IN,
      AnimationType.ZOOM_OUT,
      AnimationType.POP,
      AnimationType.STOMP,
    ],
  },
  {
    name: "FADE",
    options: [
      AnimationType.FADE_IN,
      AnimationType.FADE_OUT,
      AnimationType.NEON,
    ],
  },
];

export default CanvasAnimationSections;
