import { Button, TableCell, TableRow, Typography } from "@mui/material";

import { Box } from "@mui/system";
import CartItemDto from "@paperdateco/common/dto/order/cart/CartItemDto";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import DesignUtils from "@paperdateco/common/utils/DesignUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import { Link } from "react-router-dom";
import NumberUtils from "@paperdateco/common/utils/NumberUtils";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import routes from "@paperdateco/customer/routes/routes";
import { useCallback } from "react";

interface PriceItemProps {
  title: string;
  price: number;
}

function PriceItem({ title, price }: PriceItemProps) {
  return (
    <Box width="100%" display="flex" justifyContent="space-between">
      <Typography variant="h6">{title}:</Typography>
      <Typography variant="h6">₹ {NumberUtils.roundString(price)}</Typography>
    </Box>
  );
}

interface CartItemRowProps {
  cartItem: CartItemDto;
  onDelete: (cartItemId: string) => void;
}

export default function CartItemRow({ cartItem, onDelete }: CartItemRowProps) {
  const onDeleteItem = useCallback(() => {
    onDelete(cartItem.id);
  }, [cartItem, onDelete]);
  return (
    <TableRow key={cartItem.id}>
      <TableCell size="small">
        <Box textAlign="center">
          <Link to={routes.PRODUCT(cartItem.product.slug)} target="_blank">
            <HiddenImage
              width={100}
              src={DesignUtils.getImageFromCartItem(cartItem)}
              aspectRatio={
                cartItem.customDesign.width / cartItem.customDesign.height
              }
              margin="auto"
            />
            <Typography variant="h6" mt={1}>
              {cartItem.product.name}
            </Typography>
          </Link>
        </Box>
      </TableCell>
      <TableCell size="small">
        <PriceItem title="Product Price" price={cartItem.price.productPrice} />
        <PriceItem
          title="Additional Text Price"
          price={cartItem.price.textPrice}
        />
        <PriceItem
          title="Additional Envelop Price"
          price={cartItem.price.envelopePrice}
        />
        <PriceItem
          title="Premium Elements Price"
          price={cartItem.price.premiumElementsPrice}
        />
      </TableCell>
      <TableCell size="small">
        <Link to={routes.CART_ITEM_EDIT(cartItem.id)}>
          <Button>Edit</Button>
        </Link>
      </TableCell>
      <TableCell size="small">
        <Link to={routes.CART_ITEM_PREVIEW(cartItem.id)}>
          <Button>View Invite</Button>
        </Link>
      </TableCell>
      <TableCell>
        <Button onClick={onDeleteItem}>
          <FontAwesomeIcon color={Colors.RED} icon={faTimes} />
        </Button>
      </TableCell>
    </TableRow>
  );
}
