import { useContext, useEffect } from "react";

import { AuthContext } from "../../auth/AuthProvider";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import UserApi from "@paperdateco/common/api/UserApi";
import { useNavigate } from "react-router-dom";

export default function useLogout(loggedInRoute: string) {
  const { updateAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    UserApi.logoutUser()
      .then(() => {
        updateAuth();
        window.localStorage.clear();
        navigate(loggedInRoute);
      })
      .catch(() => {
        NotificationUtils.showError("Failed to logout");
        if (history.length > 0) {
          navigate(-1);
        }
      });
  }, [updateAuth, history, loggedInRoute]);
}
