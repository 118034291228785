import { Box, Container, Grid, Typography } from "@mui/material";

import AppLogo from "@paperdateco/shared-frontend/assets/images/logo/small_logo.png";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import SimpleLayoutWithFooter from "@paperdateco/customer/components/layout/SimpleLayoutWithFooter";

const StylizedText = CustomStyled("span")(({ theme }) => ({
  ...theme.typography.h4,
  fontFamily: Fonts.Amertha,
  fontWeight: "normal",
  lineHeight: "2rem",
}));

export default function AboutUs() {
  return (
    <SimpleLayoutWithFooter title="About Us">
      <Container maxWidth="lg">
        <Grid container spacing={4} color="primary.600">
          <Grid item md={6} xs={12}>
            <Box padding={2}>
              <Typography variant="body1" textAlign="justify">
                In a time when the world has gone virtual, Paper Date Co.
                presents a range of{" "}
                <StylizedText>online invitations</StylizedText> and greetings
                with the same old-world charm and nostalgia you experience from
                a hand written invite,{" "}
                <StylizedText>postmarked with love</StylizedText>
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box
              display="flex"
              justifyContent={{ md: "flex-end", xs: "center" }}
            >
              <HiddenImage src={AppLogo} width="350px" aspectRatio={2.5} />
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box padding={2}>
              <Typography variant="h6">
                Customise, create and plan for a special event with invitations
                and greetings from a design marketplace of independent artists
                and graphic designers.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box padding={2}>
              <Typography variant="h6">
                There's something special about receiving a letter with your
                name on it. Every Paper Date Co. card you send captures that
                emotion
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </SimpleLayoutWithFooter>
  );
}
