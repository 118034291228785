import { RootState } from "..";

export const selectWishLists = (state: RootState) => state.wishList.items;

export const selectWishListsCount = (state: RootState) =>
  selectWishLists(state).length;

export const selectProductWishListStatus =
  (productId: string) => (state: RootState) =>
    selectWishLists(state).some((item) => item.product.id === productId);
