import { useEffect, useState } from "react";

import { Box } from "@mui/system";
import CategoryProductItem from "../category/CategoryProductItem";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import ProductApi from "@paperdateco/common/api/product/ProductApi";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import { Typography } from "@mui/material";

const Container = CustomStyled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border.secondary}`,
}));

interface ProductRelatedSectionProps {
  product: ProductDto;
}

export default function ProductRelatedSection({
  product,
}: ProductRelatedSectionProps) {
  const [products, setProducts] = useState<ProductDto[]>([]);

  useEffect(() => {
    ProductApi.findRelatedProducts(product.slug)
      .then((data) => setProducts(data))
      .catch((err) => {
        NotificationUtils.showGenericError(err);
      });
  }, [product]);

  return (
    <Container marginTop={6} marginX={5} padding={1}>
      <Typography variant="h5" padding={1}>
        Related Products
      </Typography>
      <Box display="flex" overflow="auto" padding={2}>
        {products.map((product) => (
          <Box marginX={3} key={product.id}>
            <CategoryProductItem product={product} width={200} />
          </Box>
        ))}
      </Box>
    </Container>
  );
}
