import TextPaths from "@paperdateco/common/dto/design/layer/paths/TextPaths";

export default function PathValue(textPath: TextPaths, params: number[]) {
  const p1 = params[0] ?? 100;
  switch (textPath) {
    case TextPaths.CIRCLE:
      return `m 0 0 a ${Math.abs(p1)} ${Math.abs(p1)} 0 0 ${p1 > 0 ? 1 : 0} ${
        Math.abs(p1) * 2
      } 0 A ${Math.abs(p1)} ${Math.abs(p1)} 0 0 ${p1 > 0 ? 1 : 0} 0 0`;
    case TextPaths.TRIANGLE:
      return `m 0 100 L ${Math.abs(p1) * 2} ${100 - p1} L ${
        Math.abs(p1) * 4
      } 100 L 0 100`;
    default:
      return undefined;
  }
}
