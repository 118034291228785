import { Box, BoxProps, Button, ToggleButtonGroup } from "@mui/material";

import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import CustomCanvasObjectProps from "@paperdateco/shared-frontend/canvas/types/CustomCanvasObjectProps";
import EditableTextElement from "@paperdateco/shared-frontend/canvas/objects/EditableTextElement";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TextPathOptionIcon from "./TextPathOptionIcon";
import TextPaths from "@paperdateco/common/dto/design/layer/paths/TextPaths";
import useOptionRerender from "@paperdateco/shared-frontend/canvas/hooks/useOptionRerender";

const options = [
  { option: TextPaths.CIRCLE, icon: <RadioButtonUncheckedIcon /> },
  { option: TextPaths.TRIANGLE, icon: <ChangeHistoryIcon /> },
];

interface TextPathOptionsControlProps {
  textElements: EditableTextElement[];
}

export default function TextPathOptionsControl({
  textElements,
  ...props
}: TextPathOptionsControlProps & BoxProps) {
  useOptionRerender(textElements, CustomCanvasObjectProps.TEXT_PATH);

  const textPath = textElements[0]?.textPath;

  const onChangeTextPath = (_?: any, textPath?: TextPaths) =>
    textElements.forEach((element) => element.setTextPath(textPath));

  return (
    <Box textAlign="center" {...props}>
      <ToggleButtonGroup
        value={textPath}
        exclusive
        onChange={onChangeTextPath}
        sx={{ flexWrap: "wrap", width: "100%", marginBottom: 1 }}
      >
        {options.map((option) => (
          <TextPathOptionIcon key={option.option} value={option.option}>
            {option.icon}
          </TextPathOptionIcon>
        ))}
      </ToggleButtonGroup>
      {textPath && (
        <Button variant="contained" onClick={() => onChangeTextPath()}>
          Remove
        </Button>
      )}
    </Box>
  );
}
