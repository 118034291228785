import { useCallback, useEffect, useState } from "react";

import throttle from "lodash.throttle";

export default function useScrollPosition() {
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const setScrollPosition = useCallback(() => {
    setX(window.scrollX);
    setY(window.scrollY);
  }, []);

  useEffect(() => {
    setScrollPosition();
    const throttledSetScrollPosition = throttle(setScrollPosition, 100);
    window.addEventListener("scroll", throttledSetScrollPosition);
    return () => {
      window.removeEventListener("scroll", throttledSetScrollPosition);
    };
  }, [setScrollPosition]);

  return { x, y };
}
