import InviteInstantPreview from "@paperdateco/shared-frontend/components/invite/InviteInstantPreview";
import { useEffect, useReducer } from "react";

export default function useInviteLayersChange(
  instantPreview?: InviteInstantPreview
) {
  const [, rerender] = useReducer((x) => x + 1, 0);

  const layers = instantPreview?.layers ?? [];

  useEffect(() => {
    instantPreview?.onLayersChange(rerender);
    return () => instantPreview?.removeLayersChangeListener(rerender);
  }, [instantPreview]);

  return layers;
}
