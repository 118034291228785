import CanvasUtils from "@paperdateco/shared-frontend/canvas/CanvasUtils";
import DesignPageDto from "@paperdateco/common/dto/design/pages/DesignPageDto";
import DesignPageRequestDto from "@paperdateco/common/dto/design/pages/DesignPageRequestDto";
import DesignUtils from "@paperdateco/common/utils/DesignUtils";
import InviteInstantPreview from "./InviteInstantPreview";
import StringUtils from "@paperdateco/common/utils/StringUtils";

export type SaveImageCallback = (image: string) => Promise<string>;

export default class InvitePageContents {
  id: string;
  instantPreview?: InviteInstantPreview;

  constructor(public page: DesignPageDto) {
    this.id = page.id ?? StringUtils.getRandomId();
  }

  setInstantPreview(instantPreview: InviteInstantPreview) {
    this.instantPreview = instantPreview;
  }

  async getRequestDto(
    savePreviewImage: SaveImageCallback
  ): Promise<DesignPageRequestDto> {
    if (this.instantPreview) {
      const preview = await this.getPreviewImage(savePreviewImage);
      return {
        id: this.page.id,
        layers: DesignUtils.getRequestLayers(
          this.instantPreview.getDesignLayers()
        ),
        background: this.instantPreview.getComponents().background,
        preview,
      };
    } else {
      return {
        id: this.page.id,
        layers: DesignUtils.getRequestLayers(this.page.layers ?? []),
        background:
          this.page.background?.image &&
          DesignUtils.getImageRequest(this.page.background.image),
        preview: this.page.preview,
      };
    }
  }

  async getPreviewImage(saveImage: SaveImageCallback) {
    if (!this.instantPreview) {
      return this.page.preview;
    }
    const invitationPageImage = CanvasUtils.takePicture(
      this.instantPreview.canvas,
      this.instantPreview.nativeCanvas
    );
    return saveImage(invitationPageImage);
  }
}
