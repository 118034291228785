import AuthFormContainer from "@paperdateco/customer/components/auth/AuthFormContainer";
import { Box } from "@mui/system";
import ForgotPasswordForm from "@paperdateco/customer/components/auth/ForgotPasswordForm";
import Layout from "../../../layout/Layout";
import { Typography } from "@mui/material";

export default function ForgotPasswordPage() {
  return (
    <Layout>
      <AuthFormContainer>
        <Box textAlign="center">
          <Typography variant="h4">Forgot Password</Typography>
          <Typography variant="body1" color="primary.light" marginY={2}>
            Great to have you !
          </Typography>
        </Box>
        <ForgotPasswordForm />
      </AuthFormContainer>
    </Layout>
  );
}
