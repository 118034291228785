import { useCallback, useEffect, useState } from "react";

import { Box } from "@mui/system";
import CategoryProductItem from "../category/CategoryProductItem";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import ProductApi from "@paperdateco/common/api/product/ProductApi";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import { Typography } from "@mui/material";

interface ProductSearchListProps {
  query: string | null;
  category: string | null;
}

export default function ProductSearchList({
  query,
  category,
}: ProductSearchListProps) {
  const [productList, setProductList] = useState<ProductDto[]>([]);
  const [fetching, setFetching] = useState(true);

  const getSearchedProducts = useCallback(async () => {
    try {
      setFetching(true);
      const products = await ProductApi.getSearchedProducts(query, category);
      setProductList(products);
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
    setFetching(false);
  }, [query, category]);

  useEffect(() => {
    getSearchedProducts();
  }, [getSearchedProducts]);

  if (fetching) {
    return (
      <Box padding={2}>
        <Typography variant="h6" textAlign="center">
          We're looking for your query...
        </Typography>
      </Box>
    );
  }

  if (productList.length === 0) {
    return (
      <Box padding={2}>
        <Typography variant="h6" textAlign="center">
          We're sorry! Your search returned 0 results
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      {productList.map((product) => (
        <Box width={150} margin={2} key={product.id}>
          <CategoryProductItem product={product} />
        </Box>
      ))}
    </Box>
  );
}
