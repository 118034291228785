import InviteRsvpSave from "@paperdateco/customer/components/invite/rsvp/InviteRsvpSave";
import Layout from "@paperdateco/customer/components/layout/Layout";
import RsvpStatus from "@paperdateco/common/dto/order/rsvp/RsvpStatus";
import { useParams } from "react-router-dom";
import useQueryParam from "@paperdateco/customer/utils/useQueryParam";

export default function RsvpPage() {
  const { orderItemId } = useParams<{ orderItemId: string }>();
  const email = useQueryParam("email");
  const status = useQueryParam("response") as RsvpStatus;

  if (!orderItemId) {
    return null;
  }

  return (
    <Layout>
      <InviteRsvpSave orderItemId={orderItemId} email={email} status={status} />
    </Layout>
  );
}
