import { ChangeEvent, useCallback } from "react";
import { TextField, TextFieldProps } from "@mui/material";

interface CustomTextFieldProps {
  onChange?: (value: string) => void;
}

export default function CustomTextField({
  onChange,
  ...props
}: Omit<TextFieldProps, "onChange"> & CustomTextFieldProps) {
  const onCustomChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value);
    },
    [onChange]
  );

  return (
    <TextField
      fullWidth
      required
      type="text"
      variant={props.variant ?? "outlined"}
      {...props}
      value={props.value}
      onChange={onCustomChange}
    />
  );
}
