import HomeWarningPopupModal from "./HomeWarningPopupModal";
import HomeWelcomePopupModal from "./HomeWelcomePopupModal";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/system";

export default function HomePopupModal() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return matches ? <HomeWarningPopupModal /> : <HomeWelcomePopupModal />;
}
