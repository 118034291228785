import { Box, BoxProps } from "@mui/system";
import { Ref, forwardRef } from "react";

import ReactPlayer from "react-player";

interface BackgroundVideoProps {
  video: string;
}

function BackgroundVideo(
  { video, children, ...props }: BackgroundVideoProps & BoxProps,
  ref?: Ref<unknown>
) {
  return (
    <Box {...props} ref={ref}>
      <ReactPlayer
        config={{
          file: {
            attributes: {
              onContextMenu: (e: { preventDefault: () => any }) =>
                e.preventDefault(),
              controlsList: "nodownload nofullscreen noremoteplayback",
            },
          },
        }}
        url={[{ src: video, type: "video/mp4" }]}
        playing
        playsinline
        loop
        muted
        width="100%"
        height="100%"
      />
    </Box>
  );
}

export default forwardRef(BackgroundVideo);
