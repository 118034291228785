import { Slider, Stack, Typography, styled } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";

const MINIMUM_ZOOM = 10;
const MAXIMUM_ZOOM = 500;

const WhiteSlider = styled(Slider)({
  color: Colors.WHITE,
});

interface CanvasZoomControlProps {
  onZoom?: (zoom: number) => void;
}

export default function CanvasZoomControl({ onZoom }: CanvasZoomControlProps) {
  const [zoom, setZoom] = useState(100);

  const onSliderValueChange = useCallback(
    (_: any, newValue: number | number[]) => {
      if (Array.isArray(newValue)) {
        if (newValue.length > 0) {
          setZoom(newValue[0]);
        }
      } else {
        setZoom(newValue);
      }
    },
    []
  );

  useEffect(() => {
    onZoom?.(zoom);
  }, [zoom, onZoom]);

  return (
    <Stack
      direction="row"
      maxWidth={225}
      width="100%"
      color={Colors.WHITE}
      spacing={2}
      alignItems="center"
    >
      <WhiteSlider
        aria-label="Zoom"
        value={zoom}
        onChange={onSliderValueChange}
        valueLabelDisplay="auto"
        marks={false}
        step={1}
        min={MINIMUM_ZOOM}
        max={MAXIMUM_ZOOM}
      />
      <Typography color="inherit" fontWeight="bold">
        {zoom}%
      </Typography>
    </Stack>
  );
}
