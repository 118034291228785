import { Button, Stack, StackProps, Typography } from "@mui/material";

import CanvasElement from "../../objects/CanvasElement";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import CustomCanvasObjectProps from "../../types/CustomCanvasObjectProps";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import HiddenImageFit from "@paperdateco/shared-frontend/components/common/image/HiddenImageFit";
import InviteInstantPreview from "@paperdateco/shared-frontend/components/invite/InviteInstantPreview";
import LibraryAnimationDto from "@paperdateco/common/dto/design/library/animation/LibraryAnimationDto";
import PremiumItemIcon from "@paperdateco/shared-frontend/components/common/PremiumItemIcon";
import { useCallback } from "react";
import useOptionRerender from "../../hooks/useOptionRerender";

interface CanvasAnimationOptionControlProps<T extends fabric.Object> {
  instantPreview?: InviteInstantPreview;
  elements: CanvasElement<T>[];
  option: LibraryAnimationDto;
}

export default function CanvasAnimationOptionControl<T extends fabric.Object>({
  instantPreview,
  elements,
  option,
  ...props
}: CanvasAnimationOptionControlProps<T> & StackProps) {
  useOptionRerender(elements, CustomCanvasObjectProps.ANIMATION);

  const isSelected =
    elements[0]?.animation?.animationType === option.animationType;

  const onChooseOption = useCallback(() => {
    elements.forEach((elem) => elem.setAnimation(option, true));
    instantPreview?.onPriceUpdate();
  }, [elements, option, instantPreview]);

  return (
    <Button
      onClick={onChooseOption}
      sx={{
        margin: 1,
        width: 90,
        alignItems: "flex-start",
        paddingX: 0,
        paddingY: 1,
        border: "1px solid transparent",
        borderColor: isSelected ? Colors.PRIMARY : "transparent",
      }}
    >
      <Stack
        direction="column"
        spacing={1}
        alignItems="center"
        flexGrow={1}
        {...props}
        position="relative"
      >
        {option.price > 0 && <PremiumItemIcon />}
        <HiddenImage
          src={option.url}
          width={80}
          aspectRatio={1}
          fit={HiddenImageFit.CONTAIN}
        />
        <Typography
          variant="body3"
          textAlign="center"
          sx={{ lineBreak: "anywhere" }}
        >
          {option.name}
        </Typography>
      </Stack>
    </Button>
  );
}
