import { Box, Button, Stack } from "@mui/material";

import { Link } from "react-router-dom";
import WishListProductList from "./WishListProductList";
import routes from "@paperdateco/customer/routes/routes";

export default function WishListBody() {
  return (
    <Box
      padding={4}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <WishListProductList />
      <Stack
        direction={{ sm: "row", xs: "column" }}
        spacing={2}
        marginY={2}
        textAlign="center"
      >
        <Link to={routes.HOME}>
          <Button color="inherit" variant="contained" size="large">
            CONTINUE SHOPPING
          </Button>
        </Link>
        <Link to={routes.CART}>
          <Button color="primary" variant="contained" size="large">
            VIEW CART
          </Button>
        </Link>
      </Stack>
    </Box>
  );
}
