import InviteCustomisationSection, {
  InviteEnvelopeInnerSection,
} from "@paperdateco/customer/components/common/types/InviteCustomisationSection";
import InviteCustomiseActionTypes, {
  SET_CUSTOMISE_SECTION,
  SET_ENVELOPE_CUSTOMISE_SUBSECTION,
} from "./InviteCustomiseTypes";

export const setInviteCustomiseSection = (
  section?: InviteCustomisationSection
): InviteCustomiseActionTypes => ({
  type: SET_CUSTOMISE_SECTION,
  payload: section,
});

export const setInviteEnvelopeCustomiseSubSection = (
  subSection?: InviteEnvelopeInnerSection
): InviteCustomiseActionTypes => ({
  type: SET_ENVELOPE_CUSTOMISE_SUBSECTION,
  payload: subSection,
});
