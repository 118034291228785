import { Box, Link as ExternalLink, Stack } from "@mui/material";
import { FormEvent, useCallback, useEffect, useState } from "react";

import CustomShadowButton from "../../common/CustomShadowButton";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import DefaultEvent from "../../common/DefaultEvent";
import DoneIcon from "@mui/icons-material/Done";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import EventDto from "@paperdateco/common/dto/order/event/EventDto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MailerRecipients from "./MailerRecipients";
import MailerWithChooseDto from "../common/MailerWithChooseDto";
import OrderItemDto from "@paperdateco/common/dto/order/details/OrderItemDto";
import ShareMessageUtils from "@paperdateco/customer/utils/ShareMessageUtils";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

interface EventMailerFormProps {
  orderItem: OrderItemDto;
  onUpdateEvent?: (event: EventDto) => Promise<void>;
  sendEmailInvite?: (event: EventDto, emails: string[]) => Promise<void>;
}

export default function EventMailerForm({
  orderItem,
  onUpdateEvent,
  sendEmailInvite,
}: EventMailerFormProps) {
  const [event, setEvent] = useState(DefaultEvent);
  const [mailerText, setMailerText] = useState("");
  const [mailerList, setMailerList] = useState<MailerWithChooseDto[]>([]);

  const getUpdatedEvent = useCallback(() => {
    const mailers = mailerList.map((value) => {
      const { rsvpStatus, choosen, ...mailer } = value;
      return mailer;
    });
    return { ...event, mailerText, mailerList: mailers };
  }, [event, mailerList, mailerText]);

  useEffect(() => {
    setEvent(orderItem.event ?? DefaultEvent);
    setMailerList(
      orderItem.event?.mailerList.map((mailer) => ({
        ...mailer,
        choosen: false,
      })) ?? []
    );
    setMailerText(orderItem.event?.mailerText ?? "");
  }, [orderItem]);

  const handleEmailInvite = () => {
    const emails = mailerList
      .filter((mailer) => mailer.choosen)
      .map((mailer) => mailer.email);
    sendEmailInvite?.(getUpdatedEvent(), emails);
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    onUpdateEvent?.(getUpdatedEvent());
  };

  return (
    <form onSubmit={onSubmit}>
      <MailerRecipients
        orderItemId={orderItem.id}
        mailerList={mailerList}
        setMailerList={setMailerList}
      />
      <Box marginX={4} marginY={1}>
        <CustomTextField
          label="CUSTOMISE YOUR INVITE WITH A MESSAGE"
          multiline
          minRows={4}
          maxRows={6}
          required={event.mailerList.length > 0}
          value={mailerText}
          onChange={setMailerText}
        />
      </Box>
      <Stack direction="row" justifyContent="center" spacing={4} marginTop={2}>
        <CustomShadowButton type="submit" startIcon={<DoneIcon />}>
          SAVE
        </CustomShadowButton>
        <CustomShadowButton
          type="button"
          startIcon={<EmailOutlinedIcon />}
          onClick={handleEmailInvite}
        >
          EMAIL INVITE
        </CustomShadowButton>
        <ExternalLink
          href={ShareMessageUtils.whatsppMessage(orderItem.id)}
          target="_blank"
          rel="noopener"
          underline="none"
        >
          <CustomShadowButton
            type="button"
            startIcon={<FontAwesomeIcon icon={faWhatsapp} />}
          >
            WHATSAPP INVITE
          </CustomShadowButton>
        </ExternalLink>
      </Stack>
    </form>
  );
}
