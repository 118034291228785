import { Button, styled } from "@mui/material";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";

const DesignEditPreviousButton = styled(Button)({
  position: "relative",
  borderRadius: 0,
  height: 32,
  "&:before": {
    content: "''",
    position: "absolute",
    right: "100%",
    top: 2.5,
    width: 0,
    height: 0,
    zIndex: 3,
    borderTop: "13px solid transparent",
    borderBottom: "13px solid transparent",
    borderRight: `9px solid ${Colors.WHITE}`,
  },
  "&:after": {
    content: "''",
    position: "absolute",
    right: "100%",
    top: 0,
    width: 0,
    height: 0,
    borderTop: "16px solid transparent",
    borderBottom: "16px solid transparent",
    borderRight: `12px solid ${Colors.PRIMARY}`,
  },
});

export default DesignEditPreviousButton;
