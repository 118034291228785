import { Box, BoxProps } from "@mui/system";

import EditableTextElement from "../../objects/EditableTextElement";
import TextAlignControl from "./TextAlignControl";
import TextBoldControl from "./TextBoldControl";
import TextColorControl from "./TextColorControl";
import TextItalicControl from "./TextItalicControl";
import TextSizeControl from "./TextSizeControl";
import TextSpacingControl from "./TextSpacingControl";
import TextUnderlineControl from "./TextUnderlineControl";

interface CanvasTextCustomisationOptionsProps {
  textElements: EditableTextElement[];
}

export default function CanvasTextCustomisationOptions({
  textElements,
  ...props
}: CanvasTextCustomisationOptionsProps & BoxProps) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" {...props}>
      <TextSizeControl textElements={textElements} />
      <TextColorControl textElements={textElements} />
      <TextBoldControl textElements={textElements} />
      <TextItalicControl textElements={textElements} />
      <TextUnderlineControl textElements={textElements} />
      <TextAlignControl textElements={textElements} />
      <TextSpacingControl textElements={textElements} />
    </Box>
  );
}
