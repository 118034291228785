import { Box, Button, Collapse, Theme, styled } from "@mui/material";
import { PropsWithChildren, useCallback, useEffect } from "react";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import KeyUtils from "@paperdateco/shared-frontend/utils/KeyUtils";
import { SxProps } from "@mui/system";

const LeftPopup: SxProps<Theme> = {
  left: "100%",
};

const RightPopup: SxProps<Theme> = {
  right: "100%",
};

const LeftCloseButton: SxProps<Theme> = {
  left: "99%", // To avoid spacing due to number rouding issues
  clipPath: "polygon( 0 0, 100% 25px, 100% calc(100% - 25px), 0 100% )",
};

const RightCloseButton: SxProps<Theme> = {
  right: "99%", // To avoid spacing due to number rouding issues
  clipPath: "polygon( 0 25px , 100% 0, 100% 100%, 0 calc(100% - 25px) )",
};

const OptionsPopup = styled(Box)({
  background: Colors.GREY_BACKGROUND,
});

const CloseContainer = styled(OptionsPopup)({
  position: "absolute",
  top: "calc(50% - 75px)",
  width: 35,
  height: 150,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& > *": {
    width: "100%",
    height: "100%",
  },
});

interface DesignEditPopoverSectionProps {
  position: "left" | "right";
  open: boolean;
  onClose: () => void;
}

export default function DesignEditPopoverSection({
  position,
  children,
  open,
  onClose,
}: PropsWithChildren<DesignEditPopoverSectionProps>) {
  const isLeftPopup = position === "left";

  const escapeKeyListener = useCallback(
    (e: KeyboardEvent) => {
      if (KeyUtils.isEscape(e)) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escapeKeyListener, false);
    return () =>
      document.removeEventListener("keydown", escapeKeyListener, false);
  }, [onClose]);

  return (
    <OptionsPopup
      position="absolute"
      height="100%"
      zIndex={3}
      sx={isLeftPopup ? LeftPopup : RightPopup}
    >
      <Collapse orientation="horizontal" in={open} sx={{ height: "100%" }}>
        <Box minWidth={250} height="100%">
          {children}
          <CloseContainer sx={isLeftPopup ? LeftCloseButton : RightCloseButton}>
            <Button onClick={onClose}>
              {isLeftPopup ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </Button>
          </CloseContainer>
        </Box>
      </Collapse>
    </OptionsPopup>
  );
}
