import { FormEvent, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import CustomDatePicker from "@paperdateco/shared-frontend/components/common/form/date/CustomDatePicker";
import CustomShadowButton from "../common/CustomShadowButton";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import CustomTimePicker from "@paperdateco/shared-frontend/components/common/form/date/CustomTimePicker";
import DateUtils from "@paperdateco/common/utils/DateUtils";
import DoneIcon from "@mui/icons-material/Done";
import EventDto from "@paperdateco/common/dto/order/event/EventDto";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import { Link } from "react-router-dom";
import { Stack } from "@mui/material";
import routes from "@paperdateco/customer/routes/routes";

interface EditEventDetailsProps {
  event: EventDto;
  onSubmit?: (event: EventDto) => void;
}

export default function EditEventDetails({
  event,
  onSubmit,
}: EditEventDetailsProps) {
  const [hostName, setHostName] = useState("");
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState<Date>();
  const [eventTime, setEventTime] = useState<Date>();
  const [location, setLocation] = useState("");

  useEffect(() => {
    setHostName(event.hostName);
    setEventName(event.eventName);
    setEventDate(new Date(event.eventDate));
    setEventTime(new Date(event.eventDate));
    setLocation(event.location);
  }, [event]);

  const onSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    onSubmit?.({
      ...event,
      hostName,
      eventName,
      eventDate: DateUtils.mergeDateTime(eventDate, eventTime) ?? new Date(),
      location,
    });
  };

  return (
    <form onSubmit={onSubmitForm}>
      <Stack spacing={2}>
        <CustomTextField
          label="Host Name"
          value={hostName}
          onChange={setHostName}
        />
        <CustomTextField
          label="Event Name"
          value={eventName}
          onChange={setEventName}
        />
        <CustomDatePicker
          label="Date"
          inputFormat="dd/MM/yyyy"
          value={eventDate}
          onChange={setEventDate}
          PopperProps={{ style: { fontFamily: Fonts.Roboto } }}
        />
        <CustomTimePicker
          label="Time"
          inputFormat="h:mm a"
          value={eventTime}
          onChange={setEventTime}
          PopperProps={{ style: { fontFamily: Fonts.Roboto } }}
        />
        <CustomTextField
          label="Location"
          value={location}
          onChange={setLocation}
        />

        <Stack direction="row" paddingTop={3} spacing={2}>
          <CustomShadowButton endIcon={<DoneIcon />} type="submit">
            Save
          </CustomShadowButton>

          <Link to={routes.ACCOUNT_MANAGE_INVITES()}>
            <CustomShadowButton endIcon={<CloseIcon />} type="button">
              Cancel
            </CustomShadowButton>
          </Link>
        </Stack>
      </Stack>
    </form>
  );
}
