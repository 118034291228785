enum LibraryImageType {
  BACKGROUND = "background",
  STICKER = "sticker",
  GIF = "gif",
  MASK = "mask",
  PHOTO = "photo",
  FRONT_ENVELOPE = "front_envelope",
  BACK_ENVELOPE = "back_envelope",
  COVER_ENVELOPE = "cover_envelope",
  INNER_ENVELOPE = "inner_envelope",
}

export default LibraryImageType;
