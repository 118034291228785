import { Box, BoxProps } from "@mui/system";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { styled } from "@mui/material";

const DarkBox = styled(Box)({
  background: Colors.DARK_BACKGROUND,
  width: "100%",
});

const DesignEditDarkBox = (props: BoxProps) => (
  <DarkBox
    minHeight={55}
    display="flex"
    justifyContent="center"
    alignItems="center"
    {...props}
  >
    {props.children}
  </DarkBox>
);

export default DesignEditDarkBox;
