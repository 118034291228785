import { useCallback, useContext } from "react";

import { CanvasPageContext } from "../../provider/CanvasPageProvider";
import CanvasToggleButton from "@paperdateco/shared-frontend/canvas/controls/common/CanvasToggleButton";
import EditableTextElement from "@paperdateco/shared-frontend/canvas/objects/EditableTextElement";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import { ToggleButtonGroup } from "@mui/material";
import { fabric } from "fabric";
import useOptionRerender from "@paperdateco/shared-frontend/canvas/hooks/useOptionRerender";

type AlignValues = "left" | "center" | "right" | "justify";

const alignGroupItems = (
  alignment: AlignValues,
  items: fabric.Object[],
  groupWidth: number
) => {
  items.forEach((element) => {
    switch (alignment) {
      case "left":
        element.set("originX", "left");
        element.set("left", -groupWidth / 2);
        break;
      case "right":
        element.set("originX", "right");
        element.set("left", groupWidth / 2);
        break;
      default:
        element.set("originX", "center");
        element.set("left", 0);
    }
  });
};

interface TextAlignControlProps {
  textElements: EditableTextElement[];
}

export default function TextAlignControl({
  textElements,
}: TextAlignControlProps) {
  const { instantPreview } = useContext(CanvasPageContext);
  useOptionRerender(textElements, "textAlign");

  const textAlign = textElements[0].nativeElement.textAlign;

  const onChangeAlignment = useCallback(
    (_: any, alignment: AlignValues | null) => {
      if (alignment === null || instantPreview === undefined) {
        return;
      }
      textElements.forEach((element) => element.set("textAlign", alignment));

      const selectedObjects = instantPreview.canvas.getActiveObjects();
      if (selectedObjects.length <= 1) {
        return;
      }
      const groupWidth = instantPreview.canvas.getActiveObject()?.width ?? 0;
      alignGroupItems(alignment, selectedObjects, groupWidth);
    },
    [textElements, instantPreview]
  );

  return (
    <ToggleButtonGroup
      value={textAlign}
      exclusive
      onChange={onChangeAlignment}
      aria-label="text alignment"
    >
      <CanvasToggleButton value="left" aria-label="left aligned">
        <FormatAlignLeftIcon />
      </CanvasToggleButton>
      <CanvasToggleButton value="center" aria-label="centered">
        <FormatAlignCenterIcon />
      </CanvasToggleButton>
      <CanvasToggleButton value="right" aria-label="right aligned">
        <FormatAlignRightIcon />
      </CanvasToggleButton>
      <CanvasToggleButton value="justify" aria-label="justify aligned">
        <FormatAlignJustifyIcon />
      </CanvasToggleButton>
    </ToggleButtonGroup>
  );
}
