import { FileUtils } from "@paperdateco/common/utils/FileUtils";
import ModalContainer from "../../layout/ModalContainer";
import { ModalContext } from "../../providers/ModalProvider";
import { Typography } from "@mui/material";
import { useContext } from "react";

interface UploadingMessageProps {
  message: string;
}

function UploadingMessage({ message }: UploadingMessageProps) {
  return (
    <ModalContainer padding={3}>
      <Typography variant="h6" component="h2">
        {message}
      </Typography>
    </ModalContainer>
  );
}

interface useFileUploadProps {
  uploadingMessage?: string;
  onUpload?: (file: File) => Promise<void>;
  fileTypes?: string;
}

export default function useFileUpload({
  uploadingMessage,
  onUpload,
  fileTypes = FileUtils.ImageExtensions,
}: useFileUploadProps) {
  const { open, close } = useContext(ModalContext);

  const onAddImage = async () => {
    FileUtils.openFileDialog(async (e) => {
      const files = (e.target as HTMLInputElement).files;
      if (files && (files?.length ?? 0) > 0) {
        const file = files[0];
        const popupId = open(
          <UploadingMessage
            message={
              uploadingMessage ??
              `Uploading ${FileUtils.getUploadType(file.type)}...`
            }
          />
        );
        await onUpload?.(file);
        close(popupId);
      }
    }, fileTypes);
  };

  return onAddImage;
}
