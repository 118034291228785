import BlogSection from "./blog/BlogSection";
import CategoriesSection from "./categories/CategoriesSection";
import CollaborateSection from "./CollaborateSection";
import HomePageBanner from "./banner/HomePageBanner";
import HomePopupModal from "./popup/HomePopupModal";
import Layout from "../../layout/Layout";
import NewsLetterSection from "./NewsLetterSection";
import ProcessSection from "./ProcessSection";

export default function HomePage() {
  return (
    <Layout>
      <HomePopupModal />
      <HomePageBanner />
      <ProcessSection />
      <CollaborateSection />
      <CategoriesSection />
      <BlogSection />
      <NewsLetterSection />
    </Layout>
  );
}
