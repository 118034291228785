import { Button, Typography } from "@mui/material";

import { Box } from "@mui/system";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import WarningPopup from "@paperdateco/shared-frontend/components/common/popups/common/WarningPopup";

interface ApiErrorPopupProps {
  message: string;
  onClose?: () => void;
}

export default function ApiErrorPopup({
  message,
  onClose,
}: ApiErrorPopupProps) {
  return (
    <WarningPopup>
      <Typography
        variant="body2"
        textAlign="center"
        fontFamily={Fonts.Playfair}
        fontWeight="bold"
      >
        {message}
      </Typography>

      <Box fontFamily={Fonts.Roboto} marginTop={2}>
        <Button
          variant="contained"
          onClick={onClose}
          size="small"
          color="error"
        >
          CANCEL
        </Button>
      </Box>
    </WarningPopup>
  );
}
