enum InviteCustomisationSection {
  TEMPLATE,
  TEXT_LAYOUT,
  ENVELOPE,
  PREVIEW,
}

export enum InviteEnvelopeInnerSection {
  COVER_ENVELOPE,
  FRONT_ENVELOPE,
  INNER_ENVELOPE,
}

export default InviteCustomisationSection;
