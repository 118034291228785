import { Box, Grid, Stack, Typography } from "@mui/material";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import FooterCompanyDetails from "./FooterCompanyDetails";
import { Link } from "react-router-dom";
import routes from "@paperdateco/customer/routes/routes";
import useCategoryMenuItems from "@paperdateco/customer/components/products/category/useCategoryMenuItems";

const Container = CustomStyled(Box)({
  borderTop: `1px solid ${Colors.BLACK}`,
});

const CustomLink = CustomStyled(Link)({
  lineHeight: 1,
});

export default function HomepageFooterMenu() {
  const menuItems = useCategoryMenuItems();

  return (
    <Container
      paddingX={{ xs: 2, sm: 6 }}
      paddingY={2}
      fontFamily={Fonts.Carmorant}
      marginTop={2}
    >
      <Grid container spacing={2} marginY={2} justifyContent="center">
        {menuItems.map((menu) => (
          <Grid item xs={6} sm={4} md={2} key={menu.name}>
            <Stack>
              <CustomLink to={routes.CATEGORY(menu.slug)}>
                <Typography
                  className="text-uppercase"
                  variant="body3"
                  color="primary"
                >
                  {menu.name}
                </Typography>
              </CustomLink>
              {menu.children.map((category) => (
                <CustomLink
                  key={category.name}
                  to={routes.CATEGORY(category.slug)}
                >
                  <Typography
                    key={category.name}
                    variant="body3"
                    color="primary"
                  >
                    {category.name}
                  </Typography>
                </CustomLink>
              ))}
            </Stack>
          </Grid>
        ))}
      </Grid>
      <FooterCompanyDetails />
    </Container>
  );
}
