import Box from "@mui/material/Box";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import Link from "@mui/material/Link";
import { Typography } from "@mui/material";

const CopyrightBox = CustomStyled(Box)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  padding: 2,
}));

const CopyrightText = CustomStyled(Typography)(() => ({
  fontFamily: Fonts.Carmorant,
}));

export default function Copyright() {
  return (
    <CopyrightBox>
      <CopyrightText variant="h6">
        © {new Date().getFullYear()}{" "}
        <Link href="https://paperdateco.com/" underline="hover">
          Paper Date Co
        </Link>
        . All Rights Reserved.
      </CopyrightText>
    </CopyrightBox>
  );
}
