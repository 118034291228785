import DefaultEvent from "../common/DefaultEvent";
import EditEventDetails from "./EditEventDetails";
import EventDetailImage from "./EventDetailImage";
import EventDetailTabContent from "./common/EventDetailTabContent";
import EventDto from "@paperdateco/common/dto/order/event/EventDto";
import { Grid } from "@mui/material";
import OrderItemDto from "@paperdateco/common/dto/order/details/OrderItemDto";

interface AccountEventDetailsProps {
  orderItem: OrderItemDto;
  onUpdateEvent?: (event: EventDto) => void;
}

export default function AccountEventDetails({
  orderItem,
  onUpdateEvent,
}: AccountEventDetailsProps) {
  const event = orderItem.event ?? DefaultEvent;

  return (
    <EventDetailTabContent>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <EventDetailImage orderItem={orderItem} />
        </Grid>
        <Grid item xs={12} md={8}>
          <EditEventDetails event={event} onSubmit={onUpdateEvent} />
        </Grid>
      </Grid>
    </EventDetailTabContent>
  );
}
