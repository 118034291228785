import { useCallback, useContext } from "react";

import ApiErrorPopup from "../ApiErrorPopup";
import { ModalContext } from "@paperdateco/shared-frontend/components/providers/ModalProvider";

export default function useApiErrorPopup(message: string) {
  const { open, close } = useContext(ModalContext);

  const showPopup = useCallback(() => {
    open(<ApiErrorPopup message={message} onClose={() => close()} />);
  }, [open, close, message]);

  return showPopup;
}
