import { IconButton, Stack } from "@mui/material";

import CanvasZoomControl from "@paperdateco/shared-frontend/canvas/controls/zoom/CanvasZoomControl";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import DesignEditDarkBox from "../common/DesignEditDarkBox";
import FullScreenUtils from "@paperdateco/shared-frontend/utils/FullScreenUtils";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";

interface DesignEditFooterSectionProps {
  onZoom?: (zoom: number) => void;
}

export default function DesignEditFooterSection({
  onZoom,
}: DesignEditFooterSectionProps) {
  return (
    <DesignEditDarkBox>
      <Stack
        direction="row"
        spacing={2}
        flexGrow={1}
        alignItems="center"
        justifyContent="flex-end"
        marginLeft={1}
      >
        <CanvasZoomControl onZoom={onZoom} />
        <IconButton
          color="inherit"
          component="button"
          onClick={FullScreenUtils.toggle}
        >
          <OpenInFullIcon htmlColor={Colors.WHITE} />
        </IconButton>
      </Stack>
    </DesignEditDarkBox>
  );
}
