export default class DateUtils {
  public static getDate(date?: number | string | Date) {
    return date === undefined ? undefined : new Date(date);
  }

  public static mergeDateTime(date?: Date, time?: Date) {
    if (!date && !time) {
      return undefined;
    }
    if (!date) {
      return time;
    }
    if (!time) {
      return date;
    }
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
      time.getSeconds(),
      time.getMilliseconds()
    );
  }
}
