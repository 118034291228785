import { useContext, useEffect } from "react";

import { ModalContext } from "../../providers/ModalProvider";
import PageTransitionWarningPopup from "../popups/PageTransitionWarning";
import useConfirmExit from "./useConfirmExit";

const beforeUnloadListener =
  (message: string) => (event: BeforeUnloadEvent) => {
    event.preventDefault();
    return (event.returnValue = message);
  };

interface usePageLeaveListenerOptions {
  allowedRoutes?: string[];
  allow?: boolean;
  windowBlock?: boolean;
}

export default function usePageLeaveListener(
  message: string,
  { allowedRoutes, allow, windowBlock = true }: usePageLeaveListenerOptions
) {
  const { open, close } = useContext(ModalContext);
  const when = (to: string) =>
    !allowedRoutes?.some((route) => to.toString().endsWith(route)) ?? !allow;

  useEffect(() => {
    const unloadListener = beforeUnloadListener(message);
    if (!allow && windowBlock) {
      window.addEventListener("beforeunload", unloadListener, {
        capture: true,
      });
    } else {
      window.removeEventListener("beforeunload", unloadListener, {
        capture: true,
      });
    }
    return () => {
      window.removeEventListener("beforeunload", unloadListener, {
        capture: true,
      });
    };
  }, [allow, windowBlock, message]);

  useConfirmExit(async () => {
    return new Promise((callback) =>
      open(
        <PageTransitionWarningPopup
          message={message}
          onResult={(result) => {
            close();
            callback(result);
          }}
        />
      )
    );
  }, when);
}
