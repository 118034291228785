import { Box } from "@mui/material";
import DesignUtils from "@paperdateco/common/utils/DesignUtils";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import OrderItemDto from "@paperdateco/common/dto/order/details/OrderItemDto";

interface EventDetailImageProps {
  orderItem: OrderItemDto;
}

export default function EventDetailImage({ orderItem }: EventDetailImageProps) {
  const image = DesignUtils.getImageFromOrderItem(orderItem);
  return (
    <Box margin="auto" maxWidth={200}>
      <HiddenImage
        display="block"
        width="100%"
        minWidth={150}
        aspectRatio={
          orderItem.customDesign.width / orderItem.customDesign.height
        }
        marginBottom={2}
        src={image}
      />
    </Box>
  );
}
