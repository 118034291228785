import AnimationValue from "./AnimationValue";
import AnimationValueUtil from "./AnimationValueUtil";
import { fabric } from "fabric";

const duration = 1000;
const easing = AnimationValueUtil.easeLinear;

export default function BaselineAnimation(
  elem: fabric.Object
): AnimationValue[] {
  const clipPath = new fabric.Rect({
    width: elem.width ?? 0,
    height: elem.height ?? 0,
    top: -(elem.height ?? 0),
    left: 0,
    originX: "center",
    originY: "center",
  });
  elem.clipPath = clipPath;
  const angle = AnimationValueUtil.degreesToRadians(elem.angle ?? 0);
  const onComplete = () => (elem.clipPath = undefined);
  const elemHeight = elem.getScaledHeight();
  return [
    {
      property: "top",
      value: {
        from: (elem.top ?? 0) + elemHeight * Math.cos(angle),
        to: elem.top,
        duration,
        easing,
        onComplete,
      },
    },
    {
      property: "left",
      value: {
        from: (elem.left ?? 0) - elemHeight * Math.sin(angle),
        to: elem.left,
        duration,
        easing,
        onComplete,
      },
    },
    {
      property: "clipPath",
      value: {
        from: -(elem.height ?? 0),
        to: 0,
        duration,
        easing,
        onChange: (value) => {
          clipPath.set("top", value);
          elem.dirty = true;
        },
        onComplete,
      },
    },
  ];
}
