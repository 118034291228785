import { ButtonBase, Link, Typography, styled } from "@mui/material";

import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import HiddenImageFit from "@paperdateco/shared-frontend/components/common/image/HiddenImageFit";
import LibraryImageAspectRatio from "../LibraryImageAspectRatio";
import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";

const PhotoButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  marginTop: 6,
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiPhotoBackdrop-root": {
      backgroundColor: theme.palette.common.black,
    },
    "& .MuiPhotoMarked-root": {
      opacity: 0,
    },
    "& .MuiPhotoText-root": {
      opacity: 1,
    },
  },
}));

const PhotoText = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: 0,
  color: theme.palette.common.white,
}));

const PhotoBackdrop = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  opacity: 0.4,
});

interface PhotoItemProps {
  photo: any;
  photoPreview: string;
  photoAppName: string;
  photoAppLink: string;
  photoUserName: string;
  photoUserNameLink: string;
  index: number;
  type: LibraryImageType;
  onChoose: (photo: any) => void;
}

export default function PhotoItem({
  photo,
  photoPreview,
  photoAppName,
  photoAppLink,
  photoUserName,
  photoUserNameLink,
  index,
  type,
  onChoose,
}: PhotoItemProps) {
  return (
    <PhotoButton onClick={() => onChoose(photo)} focusRipple key={index}>
      <HiddenImage
        src={photoPreview}
        width={145}
        aspectRatio={LibraryImageAspectRatio[type]}
        fit={HiddenImageFit.CONTAIN}
      />
      <PhotoBackdrop className="MuiPhotoBackdrop-root" />
      <PhotoText className="MuiPhotoText-root">
        <Typography
          gutterBottom
          component="span"
          variant="body1"
          fontSize={10}
          sx={{
            position: "relative",
            p: 4,
            pt: 2,
            pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
          }}
        >
          Photo by{" "}
          <Link
            color="inherit"
            target="_blank"
            rel="noopener"
            href={photoUserNameLink}
          >
            {photoUserName}
          </Link>{" "}
          on{" "}
          <Link
            color="inherit"
            target="_blank"
            rel="noopener"
            href={photoAppLink}
          >
            {photoAppName}
          </Link>
        </Typography>
      </PhotoText>
    </PhotoButton>
  );
}
