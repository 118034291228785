import moment from "moment";
import slugify from "slugify";
const Buffer = require("buffer/").Buffer;

export enum StringDateFormat {
  FULL_DATE = "MMMM Do YYYY, h:mm:ss a",
  ONLY_DATE = "Do MMM YYYY",
  ONLY_TIME = "h:mm a",
  DATE_TIME = "Do MMM YYYY, h:mm a",
}

export default class StringUtils {
  static shortenObjectId(value: string) {
    return Buffer.from(value, "hex").toString("base64");
  }

  static trim(value: string) {
    value = value.trim();
    return value !== "" ? value : undefined;
  }

  static formatDate(
    date?: Date | string,
    format: StringDateFormat = StringDateFormat.FULL_DATE
  ) {
    if (!date) {
      return "";
    }
    return moment(date).format(format);
  }

  public static encodeAsUri(name: string) {
    return slugify(name, {
      remove: /[*+~,()'"!:@?]/g,
      lower: true,
    });
  }

  public static useWhenEmpty(defaultValue: string, value?: string) {
    return !value ? defaultValue : value;
  }

  public static getRandomId() {
    const str = (Math.random() + 1).toString(36).substring(7);
    return str + new Date().toDateString();
  }
}
