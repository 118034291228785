import InviteCustomisationSection, {
  InviteEnvelopeInnerSection,
} from "@paperdateco/customer/components/common/types/InviteCustomisationSection";

import InviteInstantPreview from "@paperdateco/shared-frontend/components/invite/InviteInstantPreview";
import InvitePageContents from "@paperdateco/shared-frontend/components/invite/InvitePageContents";

export const SET_CUSTOMISE_SECTION = "SET_CUSTOMISE_SECTION";
export const SET_ENVELOPE_CUSTOMISE_SUBSECTION =
  "SET_ENVELOPE_CUSTOMISE_SUBSECTION";

export const SET_INSTANT_PREVIEW = "SET_INSTANT_PREVIEW";

export const SET_PREVIEW_ZOOM = "SET_PREVIEW_ZOOM";

export const SET_INVITE_PAGES = "SET_INVITE_PAGES";

export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";

interface SetCustomiseSectionAction {
  type: typeof SET_CUSTOMISE_SECTION;
  payload?: InviteCustomisationSection;
}

interface SetEnvelopeCustomiseSubSectionAction {
  type: typeof SET_ENVELOPE_CUSTOMISE_SUBSECTION;
  payload?: InviteEnvelopeInnerSection;
}

interface SetInstantPreviewAction {
  type: typeof SET_INSTANT_PREVIEW;
  payload: InviteInstantPreview;
  page: InvitePageContents;
}

interface SetInstantPreviewZoomAction {
  type: typeof SET_PREVIEW_ZOOM;
  payload: number;
}

interface SetInvitePagesAction {
  type: typeof SET_INVITE_PAGES;
  payload: InvitePageContents[];
}

interface SetActivePageAction {
  type: typeof SET_ACTIVE_PAGE;
  payload: InvitePageContents;
}

type InviteCustomiseActionTypes =
  | SetCustomiseSectionAction
  | SetEnvelopeCustomiseSubSectionAction
  | SetInstantPreviewAction
  | SetInstantPreviewZoomAction
  | SetInvitePagesAction
  | SetActivePageAction;

export default InviteCustomiseActionTypes;
