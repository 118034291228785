import AdminCategoryUpdateRequestDto from "../../dto/category/AdminCategoryUpdateRequestDto";
import CategoryDto from "../../dto/category/CategoryDto";
import { FileUtils } from "@paperdateco/common/utils/FileUtils";
import PresignedRequestDto from "../../dto/common/PresignedRequestDto";
import StoreInstance from "../../store/StoreInstance";
import UploadApi from "../UploadApi";
import UploadTypes from "@paperdateco/common/dto/common/UploadTypes";
import { handleApiError } from "../../utils/ApiUtils";

export default class CategoryApi {
  public static getAll = async () => {
    try {
      const categories = await StoreInstance.api().get<CategoryDto[]>(
        "/categories"
      );
      return categories.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get invitation categories");
    }
  };

  public static getAllAdminCategories = async () => {
    try {
      const categories = await StoreInstance.api().get<CategoryDto[]>(
        "/categories/all"
      );
      return categories.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get invitation categories");
    }
  };

  public static getCategory = async (categoryId: string) => {
    try {
      const category = await StoreInstance.api().get<CategoryDto>(
        `/categories/${categoryId}`
      );
      return category.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get Category");
    }
  };

  public static updateCategory = async (
    categoryId: string,
    data: AdminCategoryUpdateRequestDto
  ) => {
    try {
      const category = await StoreInstance.api().put<CategoryDto>(
        `/categories/${categoryId}`,
        data
      );
      return category.data;
    } catch (e) {
      throw handleApiError(e, "Failed to update category detail");
    }
  };

  public static addCategory = async (data: AdminCategoryUpdateRequestDto) => {
    try {
      const category = await StoreInstance.api().post<CategoryDto>(
        `/categories`,
        data
      );
      return category.data;
    } catch (e) {
      throw handleApiError(e, "Failed to add category detail");
    }
  };

  public static hideCategory = async (categoryId: string) => {
    try {
      const category = await StoreInstance.api().delete<CategoryDto>(
        `/categories/${categoryId}`
      );
      return category.data;
    } catch (e) {
      throw handleApiError(e, "Failed to hide category");
    }
  };

  public static async uploadCategoryImage(file: File) {
    const uploadType = FileUtils.getUploadType(file.type);
    const presignedRequest = await this.getPresignedUrlForNewMedia(
      file.name,
      uploadType
    );
    const url = await UploadApi.uploadFile(presignedRequest, file);
    return { url, type: uploadType };
  }

  private static async getPresignedUrlForNewMedia(
    fileName: string,
    type: UploadTypes
  ) {
    try {
      const products = await StoreInstance.api().get<PresignedRequestDto>(
        `/categories/media/presigned-url?name=${fileName}&type=${type}`
      );
      return products.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get valid url for uploading file");
    }
  }
}
