import { useEffect, useState } from "react";

import { Link as EmptyLink } from "@mui/material";
import Grid from "@mui/material/Grid";
import ProductApi from "@paperdateco/common/api/product/ProductApi";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import routes from "@paperdateco/customer/routes/routes";
import { Link } from "react-router-dom";
import useCustomiseTemplate from "../../../products/detail/useCustomiseTemplate";
import CategoryCard from "./CategoryCard";

interface GridCategoryItem {
  image: string;
  imageText: string;
  link: string;
}

const gridCategories: GridCategoryItem[] = [
  {
    image:
      "https://asset.paperdateco.com/cloudinary/Home%20Page%20Banners-20220110T114935Z-001/Home%20Page%20Banners/3.%20Category%20Tiles/ver-2%20optimised/s0m5n5tegbmgrovx8jm6.webp",
    imageText: "Shop",
    link: routes.CATEGORY("birthdays"),
  },
  {
    image:
      "https://asset.paperdateco.com/cloudinary/Home%20Page%20Banners-20220110T114935Z-001/Home%20Page%20Banners/3.%20Category%20Tiles/ver-2%20optimised/wpyxbs3q7c1xqrkr2iho.webp",
    imageText: "Create",
    link: routes.CATEGORY("festive"),
  },
  {
    image:
      "https://asset.paperdateco.com/cloudinary/Home%20Page%20Banners-20220110T114935Z-001/Home%20Page%20Banners/3.%20Category%20Tiles/ver-2%20optimised/zjknepar40imkchmvhyu.webp",
    imageText: "Explore",
    link: routes.CATEGORY("milestones"),
  },
];

const WeddingCard = () => {
  return (
    <Link to={routes.CATEGORY("weddings")}>
      <CategoryCard
        image="https://asset.paperdateco.com/cloudinary/Home%20Page%20Banners-20220110T114935Z-001/Home%20Page%20Banners/3.%20Category%20Tiles/ver-2%20optimised/bv7sr9vcljgqafjgnhch.webp"
        imageText="Shop Now"
      />
    </Link>
  );
};

interface DesignCardProps {
  product: ProductDto;
}

const DesignCard = ({ product }: DesignCardProps) => {
  const onCustomiseTemplate = useCustomiseTemplate(product);

  return (
    <EmptyLink href="#" onClick={onCustomiseTemplate}>
      <CategoryCard
        image="https://asset.paperdateco.com/cloudinary/Home%20Page%20Banners-20220110T114935Z-001/Home%20Page%20Banners/3.%20Category%20Tiles/ver-2%20optimised/pxxgfmq4hqyjpctgeqzt.webp"
        imageText="Design"
      />
    </EmptyLink>
  );
};

export default function CategoriesSection() {
  const [product, setProduct] = useState<ProductDto>();

  useEffect(() => {
    ProductApi.getCustomTemplateProduct()
      .then(setProduct)
      .catch(NotificationUtils.showGenericError);
  }, []);

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item display={{ xs: "none", md: "block" }} md={6}>
        <WeddingCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} display={{ md: "none" }}>
            <WeddingCard />
          </Grid>
          {gridCategories.map((category, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Link to={category.link}>
                <CategoryCard
                  image={category.image}
                  imageText={category.imageText}
                />
              </Link>
            </Grid>
          ))}
          {product && (
            <Grid item xs={12} sm={6}>
              {<DesignCard product={product} />}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
