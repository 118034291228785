import GetItemsAction from "@paperdateco/shared-frontend/store/getItems/GetItemsAction";
import GetItemsActionTypes from "@paperdateco/shared-frontend/store/getItems/GetItemsTypes";
import { RootState } from "..";
import { ThunkDispatch } from "redux-thunk";
import { WISHLIST_GET_ITEMS_NAME } from "./WishListTypes";
import WishListApi from "@paperdateco/common/api/WishListApi";
import WishListDto from "@paperdateco/common/dto/user/wishList/WishListDto";

export const getAllWishLists = GetItemsAction(
  WISHLIST_GET_ITEMS_NAME,
  WishListApi.getAll,
  (state: RootState) => state.wishList
);

export type WishlistStateDispatchType = ThunkDispatch<
  RootState,
  unknown,
  GetItemsActionTypes<WishListDto>
>;
