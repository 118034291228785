import CustomDesignAddRequestDto from "../dto/design/custom/CustomDesignAddRequestDto";
import CustomDesignDto from "../dto/design/custom/CustomDesignDto";
import CustomDesignRequestDto from "../dto/design/custom/CustomDesignRequestDto";
import { FileUtils } from "../utils/FileUtils";
import PresignedRequestDto from "../dto/common/PresignedRequestDto";
import StoreInstance from "../store/StoreInstance";
import UploadApi from "./UploadApi";
import { handleApiError } from "../utils/ApiUtils";

export default class CustomDesignApi {
  public static async getById(customDesignId: string) {
    try {
      const customDesign = await StoreInstance.api().get<CustomDesignDto>(
        `/custom-designs/${customDesignId}`
      );
      return customDesign.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get custom design");
    }
  }

  public static async createNewCustomDesign(
    request: CustomDesignAddRequestDto
  ) {
    try {
      const customDesign = await StoreInstance.api().post<CustomDesignDto>(
        `/custom-designs`,
        request
      );
      return customDesign.data;
    } catch (e) {
      throw handleApiError(e, "Failed to create new custom design");
    }
  }

  public static async updateCustomDesign(
    customDesignId: string,
    request: CustomDesignRequestDto
  ) {
    try {
      const customDesign = await StoreInstance.api().put<CustomDesignDto>(
        `/custom-designs/${customDesignId}`,
        request
      );
      return customDesign.data;
    } catch (e) {
      throw handleApiError(e, "Failed to update custom design");
    }
  }

  public static async getAllCustomDesigns() {
    try {
      const customDesigns = await StoreInstance.api().get<CustomDesignDto[]>(
        "/custom-designs"
      );
      return customDesigns.data;
    } catch (error) {
      throw handleApiError(error, "Failed to get all custom designs");
    }
  }

  public static async uploadInvitationImage(
    customDesignId: string,
    dataUri: string,
    name = "invitation.png"
  ) {
    const file = await FileUtils.getBlobFromDataUri(dataUri);
    const presignedRequest = await this.getPresignedUrlForNewMedia(
      customDesignId,
      name
    );
    const url = await UploadApi.uploadFile(presignedRequest, file);
    return url;
  }

  private static async getPresignedUrlForNewMedia(
    customDesignId: string,
    name: string
  ) {
    try {
      const products = await StoreInstance.api().get<PresignedRequestDto>(
        `/custom-designs/${customDesignId}/invitation/presigned-url?name=${name}`
      );
      return products.data;
    } catch (e) {
      throw handleApiError(
        e,
        "Failed to get valid url for uploading invitation"
      );
    }
  }
}
