import { Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import ProductApi from "@paperdateco/common/api/product/ProductApi";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import ProductDescriptionSection from "./ProductDescriptionSection";
import ProductDescriptionWithPrint from "./ProductDescriptionWithPrint";
import ProductImageSection from "./ProductImageSection";
import ProductImageSectionWithPrint from "./ProductImageSectionWithPrint";
import ProductRelatedSection from "./ProductRelatedSection";

interface ProductDetailProps {
  productSlug: string;
}

export default function ProductDetail({ productSlug }: ProductDetailProps) {
  const [product, setProduct] = useState<ProductDto>();
  const [error, setError] = useState(false);
  const [print, setprint] = useState(false);

  useEffect(() => {
    setError(false);
    ProductApi.findOneBySlug(productSlug)
      .then((data) => {
        setProduct(data);
        setError(false);
        if (data.designedBy?.id === "62380fb904e373acf2dc8538") {
          setprint(true);
        }
      })
      .catch((e) => {
        setError(true);
        NotificationUtils.showGenericError(e);
      });
  }, [productSlug]);

  if (!product) {
    if (error) {
      return <Typography>We could not find the product</Typography>;
    } else {
      return null;
    }
  }

  return (
    <Container maxWidth="xl" disableGutters>
      {print && (
        <HiddenImage
          src={
            "https://asset.paperdateco.com/cloudinary/Home%20Page%20Banners-20220110T114935Z-001/Home%20Page%20Banners/JR/jrmmlg3sxnaqmz34zhna.jpg"
          }
          width="100%"
          display="block"
          aspectRatio={1337 / 200}
        />
      )}

      <Grid container spacing={2} marginTop={2} justifyContent="center">
        <Grid item xs={12} md={5}>
          {print ? (
            <ProductImageSectionWithPrint product={product} />
          ) : (
            <ProductImageSection product={product} />
          )}
        </Grid>
        <Grid item xs={12} md={5}>
          {print ? (
            <ProductDescriptionWithPrint product={product} />
          ) : (
            <ProductDescriptionSection product={product} />
          )}
        </Grid>
      </Grid>
      <ProductRelatedSection product={product} />
    </Container>
  );
}
