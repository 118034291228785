import CouponDto from "../dto/coupon/CouponDto";
import CouponRequestDto from "../dto/coupon/CouponRequestDto";
import CouponResponseDto from "../dto/coupon/CouponResponseDto";
import StoreInstance from "../store/StoreInstance";
import { handleApiError } from "../utils/ApiUtils";

export default class CouponApi {
  public static getAllCoupons = async () => {
    try {
      const coupons = await StoreInstance.api().get<CouponDto[]>(
        "/coupons/all"
      );
      return coupons.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get all coupons");
    }
  };

  public static getCouponDetail = async (couponId: string) => {
    try {
      const coupon = await StoreInstance.api().get<CouponDto>(
        `/coupons/${couponId}`
      );
      return coupon.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get coupon detail");
    }
  };

  public static async addNewCoupon(couponDto: CouponRequestDto) {
    try {
      const coupon = await StoreInstance.api().post<CouponDto>(
        "/coupons",
        couponDto
      );
      return coupon.data;
    } catch (e) {
      throw handleApiError(e, "Failed to add new coupon");
    }
  }

  public static async editCoupon(
    couponId: string,
    couponDto: CouponRequestDto
  ) {
    try {
      const result = await StoreInstance.api().put<CouponDto>(
        `/coupons/${couponId}`,
        couponDto
      );
      return result.data;
    } catch (e) {
      throw handleApiError(e, "Failed to edit coupon");
    }
  }

  public static async deleteCoupon(couponId: string) {
    try {
      const result = await StoreInstance.api().delete<string>(
        `/coupons/${couponId}`
      );
      return result.data;
    } catch (e) {
      throw handleApiError(e, "Failed to delete coupon");
    }
  }

  public static async applyCoupon(couponName: string) {
    try {
      const cartItems = await StoreInstance.api().put<CouponResponseDto>(
        `/coupons/me/${couponName}`
      );
      return cartItems.data;
    } catch (e) {
      throw handleApiError(e, "Failed to apply coupon");
    }
  }

  public static async removeCoupon() {
    try {
      await StoreInstance.api().delete("/coupons/me");
    } catch (e) {
      throw handleApiError(e, "Failed to remove coupon");
    }
  }
}
