import { ToggleButton, styled } from "@mui/material";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";

const CanvasToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: Colors.WHITE,
  padding: theme.spacing(0.5),
  "&:hover, &.Mui-selected, &.Mui-selected:hover": {
    color: theme.palette.primary.main,
    background: Colors.WHITE,
  },
}));

export default CanvasToggleButton;
