import { Container, Grid } from "@mui/material";

import AccountSectionsNav from "./AccountSectionsNav";
import Layout from "../layout/Layout";
import { PropsWithChildren } from "react";

export default function AccountLayout({ children }: PropsWithChildren<{}>) {
  return (
    <Layout>
      <Container maxWidth="lg">
        <Grid container padding={2} marginTop={5} spacing={2}>
          <Grid item xs={12} md={5}>
            <AccountSectionsNav />
          </Grid>
          <Grid item xs={12} md={7}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}
