import OrderPaymentInfoDto from "@paperdateco/common/dto/order/OrderPaymentInfoDto";
import UserDto from "@paperdateco/common/dto/user/UserDto";

export default class RazorPayUtils {
  static createOrderOptions(
    order: OrderPaymentInfoDto,
    user: UserDto,
    onSuccess: (response: any) => void
  ) {
    return {
      key: order.razorPayKey,
      amount: order.amount.toString(),
      currency: order.currency,
      name: "Paper Date Co.",
      description: "Virtual Invitation and Greetings",
      image:
        "https://asset.paperdateco.com/cloudinary/paper-date-Logo-01_sfpvpm.png",
      order_id: order.paymentId,
      handler: onSuccess,
      prefill: {
        name: user.firstName,
        email: user.email,
      },
      theme: {
        color: "#3399cc",
      },
    };
  }

  static createOrder(options: any, onError: (error: any) => void) {
    var razorPayInstance = new (window as any).Razorpay(options);
    razorPayInstance.on("payment.failed", onError);
    return razorPayInstance;
  }
}
