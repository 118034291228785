import { CUSTOM_DESIGN_GET_ITEMS_NAME } from "./CustomDesignTypes";
import CustomDesignApi from "@paperdateco/common/api/CustomDesignApi";
import CustomDesignDto from "@paperdateco/common/dto/design/custom/CustomDesignDto";
import GetItemsAction from "@paperdateco/shared-frontend/store/getItems/GetItemsAction";
import GetItemsActionTypes from "@paperdateco/shared-frontend/store/getItems/GetItemsTypes";
import { RootState } from "..";
import { ThunkDispatch } from "redux-thunk";

export const getAllCustomDesigns = GetItemsAction(
  CUSTOM_DESIGN_GET_ITEMS_NAME,
  CustomDesignApi.getAllCustomDesigns,
  (state: RootState) => state.customDesign
);

export type CustomDesignStateDispatchType = ThunkDispatch<
  RootState,
  unknown,
  GetItemsActionTypes<CustomDesignDto>
>;
