import { useCallback, useContext, useEffect, useState } from "react";

import { CanvasPageContext } from "@paperdateco/shared-frontend/canvas/provider/CanvasPageProvider";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import CustomDesignDto from "@paperdateco/common/dto/design/custom/CustomDesignDto";
import DesignEditLeftSection from "@paperdateco/shared-frontend/components/design/common/types/DesignEditLeftSection";
import DesignEditLeftSidebar from "@paperdateco/shared-frontend/components/design/section/DesignEditLeftSidebar";
import DesignEditLibrarySection from "@paperdateco/shared-frontend/components/design/section/library/DesignEditLibrarySection";
import DesignEditTabType from "@paperdateco/shared-frontend/components/design/common/types/DesignEditTabType";
import DesignEditTextSection from "@paperdateco/shared-frontend/components/design/section/DesignEditTextSection";
import DesignEditUploadSection from "@paperdateco/shared-frontend/components/design/section/DesignEditUploadSection";
import InviteEnvelopeSection from "./envelope/InviteEnvelopeSection";
import InviteTemplateSection from "./template/InviteTemplateSection";
import InviteUtils from "@paperdateco/shared-frontend/utils/InviteUtils";
import IosShareIcon from "@mui/icons-material/IosShare";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import ViewCompactOutlinedIcon from "@mui/icons-material/ViewCompactOutlined";
import { setInviteEnvelopeCustomiseSubSection } from "@paperdateco/customer/store/inviteCustomise/InviteCustomiseAction";
import { useDispatch } from "react-redux";
import useInviteSelectedLayers from "@paperdateco/shared-frontend/canvas/hooks/useInviteSelectedLayers";

const LeftTabs: DesignEditTabType[] = [
  {
    name: "Template",
    icon: <ViewCompactOutlinedIcon htmlColor={Colors.WHITE} />,
    section: DesignEditLeftSection.TEMPLATE,
  },
  {
    name: "Library",
    icon: <CategoryOutlinedIcon htmlColor={Colors.WHITE} />,
    section: DesignEditLeftSection.LIBRARY,
  },
  {
    name: "Text",
    icon: <TextFieldsIcon htmlColor={Colors.WHITE} />,
    section: DesignEditLeftSection.TEXT,
  },
  {
    name: "Upload",
    icon: <IosShareIcon htmlColor={Colors.WHITE} />,
    section: DesignEditLeftSection.UPLOAD,
  },
];

interface InvitePopupSecitonProps {
  section?: DesignEditLeftSection;
  design: CustomDesignDto;
}

function InvitePopupSeciton({ section, design }: InvitePopupSecitonProps) {
  const { instantPreview } = useContext(CanvasPageContext);
  const selectedLayers = useInviteSelectedLayers(instantPreview);
  switch (section) {
    case DesignEditLeftSection.TEMPLATE:
      return <InviteTemplateSection />;
    case DesignEditLeftSection.TEXT:
      return (
        <DesignEditTextSection
          instantPreview={instantPreview}
          selectedLayers={selectedLayers}
        />
      );
    case DesignEditLeftSection.LIBRARY:
      return (
        <DesignEditLibrarySection
          instantPreview={instantPreview}
          design={design.design}
        />
      );
    case DesignEditLeftSection.ENVELOPE:
      return <InviteEnvelopeSection design={design} />;
    case DesignEditLeftSection.UPLOAD:
      return <DesignEditUploadSection instantPreview={instantPreview} />;
    default:
      return null;
  }
}

interface InviteCustomisationLeftSidebarProps {
  design: CustomDesignDto;
}

export default function InviteCustomisationLeftSidebar({
  design,
}: InviteCustomisationLeftSidebarProps) {
  const [choosenSection, setChoosenSection] = useState<DesignEditLeftSection>();
  const { instantPreview } = useContext(CanvasPageContext);
  const selectedLayers = useInviteSelectedLayers(instantPreview);
  const dispatch = useDispatch();

  const openSection = useCallback(
    (section?: DesignEditLeftSection) => {
      setChoosenSection(section);
      dispatch(setInviteEnvelopeCustomiseSubSection());
      if (section === DesignEditLeftSection.ENVELOPE) {
        instantPreview?.setDefaultEnvelopeDesign(design.design);
      }
    },
    [dispatch, instantPreview, design]
  );

  useEffect(() => {
    const textLayers = InviteUtils.getTextLayers(selectedLayers);
    if (textLayers.length > 0) {
      openSection(DesignEditLeftSection.TEXT);
    } else if (selectedLayers.length === 0) {
      openSection();
    }
  }, [selectedLayers, openSection]);

  return (
    <DesignEditLeftSidebar
      section={choosenSection}
      setSection={openSection}
      tabs={LeftTabs}
    >
      <InvitePopupSeciton section={choosenSection} design={design} />
    </DesignEditLeftSidebar>
  );
}
