import { Button, Typography } from "@mui/material";

import { Box } from "@mui/system";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import { Link } from "react-router-dom";
import routes from "@paperdateco/customer/routes/routes";

const Spacer = () => (
  <Box
    marginX={{ xs: 0.5, sm: 2 }}
    flexGrow={1}
    maxWidth={100}
    height="1px"
    borderBottom={`1px solid ${Colors.PRIMARY_500}`}
  />
);

interface InviteCustomisationHeaderProps {
  step: "design" | "preview" | "checkout";
}

export default function InviteCustomisationHeader({
  step,
}: InviteCustomisationHeaderProps) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      paddingY={1}
      paddingX={{ sm: 5, xs: 1 }}
      fontFamily={Fonts.Titillium}
    >
      <Link to={routes.HOME}>
        <Button startIcon={<ChevronLeftIcon />}>
          <Typography variant="body1" component="span" fontWeight="bold">
            HOME
          </Typography>
        </Button>
      </Link>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        margin={1}
      >
        <Typography color={step === "design" ? Colors.BLACK : "primary.800"}>
          DESIGN
        </Typography>
        <Spacer />
        <Typography color={step === "preview" ? Colors.BLACK : "primary.800"}>
          PREVIEW
        </Typography>
        <Spacer />
        <Typography color={step === "checkout" ? Colors.BLACK : "primary.800"}>
          CHECKOUT
        </Typography>
      </Box>
    </Box>
  );
}
