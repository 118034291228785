import Box from "@mui/material/Box";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import routes from "@paperdateco/customer/routes/routes";

const FooterList = CustomStyled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 2),
  padding: 0,
  "& > *": {
    padding: theme.spacing(1, 1.5),
  },
}));

const LinkText = CustomStyled(Typography)({
  fontFamily: Fonts.Futurist,
  fontWeight: "bold",
});

interface FooterItem {
  route: string;
  name: string;
}

const footerItems: FooterItem[] = [
  { route: routes.CONTACT_US, name: "CONTACT US" },
  { route: routes.ABOUT_US, name: "ABOUT US" },
  { route: routes.CANCELLATION_POLICY, name: "CANCELLATION" },
  { route: routes.REFUNDS, name: "REFUNDS" },
  { route: routes.RETURNS, name: "RETURNS" },
  { route: routes.TERMS_AND_CONDITIONS, name: "TERMS AND CONDITIONS" },
  { route: routes.PRIVACY_POLICY, name: "PRIVACY POLICY" },
];

export default function FooterLinks() {
  return (
    <FooterList
      display="flex"
      alignItems="center"
      justifyContent="space-around"
      flexWrap="wrap"
    >
      {footerItems.map((item) => (
        <Link key={item.name} to={item.route}>
          <LinkText variant="body3">{item.name}</LinkText>
        </Link>
      ))}
    </FooterList>
  );
}
