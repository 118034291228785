import TagManager from "react-gtm-module";
import UserDto from "@paperdateco/common/dto/user/UserDto";

export default class GtmUtils {
  static init() {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID as string,
    };

    TagManager.initialize(tagManagerArgs);
  }

  static trackPageView() {
    GtmUtils.initDataLayer();
    window["dataLayer"].push({
      event: "pageview",
    });
  }

  static trackUser(user?: UserDto) {
    GtmUtils.initDataLayer();
    window["dataLayer"].push({
      event: "user",
      user: user
        ? {
            id: user.id,
            name: user.name,
            email: user.email,
            created: user.createdAt,
          }
        : undefined,
    });
  }

  static trackEvent(
    category: string,
    action: string,
    label?: string,
    value?: string
  ) {
    GtmUtils.initDataLayer();
    window["dataLayer"].push({
      event: "event",
      eventProps: {
        category: category,
        action: action,
        label: label,
        value: value,
      },
    });
  }

  private static initDataLayer() {
    window["dataLayer"] = window["dataLayer"] || [];
  }
}
