import {
  UNSAFE_NavigationContext as NavigationContext,
  To,
} from "react-router-dom";
import { useContext, useEffect } from "react";

import { isString } from "lodash";

const getPath = (to: To) => {
  if (isString(to)) {
    return to;
  } else {
    return to.pathname ?? "";
  }
};

// This code taken from https://gist.github.com/MarksCode/64e438c82b0b2a1161e01c88ca0d0355
// Because V6 React router doesn't support "getUserConfirmation" prop of v5
export default function useConfirmExit(
  confirmExit: () => Promise<boolean>,
  when: (location: string) => boolean
) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    const push = navigator.push;

    navigator.push = (...args: Parameters<typeof push>) => {
      if (!when(getPath(args[0]))) {
        push(...args);
        return;
      }
      confirmExit().then((result) => {
        if (result !== false) {
          push(...args);
        }
      });
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
}
