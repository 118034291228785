import { Box, IconButton, Tooltip, styled } from "@mui/material";

import { PropsWithChildren } from "react";

const SidebarIconButton = styled(IconButton)({ width: "100%" });

interface DesignSidebarIconProps {
  title: string;
  onClick?: () => void;
  disabled: boolean;
}

export default function DesignSidebarIcon({
  title,
  onClick,
  disabled,
  children,
}: PropsWithChildren<DesignSidebarIconProps>) {
  return (
    <Tooltip title={title} placement="left">
      <Box>
        <SidebarIconButton onClick={onClick} disabled={disabled}>
          {children}
        </SidebarIconButton>
      </Box>
    </Tooltip>
  );
}
