import { Box, Typography } from "@mui/material";
import {
  OrderStateDispatchType,
  getAllOrders,
} from "@paperdateco/customer/store/order/OrderAction";
import { useCallback, useState } from "react";

import AccountEventDetails from "./AccountEventDetails";
import AccountEventReminders from "./AccountEventReminders";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import EventDetailTabPanel from "./common/EventDetailTabPanel";
import EventDetailTabs from "./common/EventDetailTabs";
import EventDto from "@paperdateco/common/dto/order/event/EventDto";
import EventMailerForm from "./mailer/EventMailerForm";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import OrderApi from "@paperdateco/common/api/OrderApi";
import OrderItemDto from "@paperdateco/common/dto/order/details/OrderItemDto";
import { useDispatch } from "react-redux";

const Container = CustomStyled(Box)(({ theme }) => ({
  background: Colors.WHITE,
  maxWidth: 800,
  maxHeight: "80vh",
  width: "90vw",
  minHeight: 300,
  padding: theme.spacing(2),
  border: `1px solid ${Colors.BLACK}`,
  borderRadius: theme.spacing(0.5),
}));

interface AccountEventProps {
  orderId: string;
  orderItem: OrderItemDto;
}

export default function AccountEventDetailsModal({
  orderId,
  orderItem,
}: AccountEventProps) {
  const [currentTab, setCurrentTab] = useState(0);
  const dispatch = useDispatch<OrderStateDispatchType>();

  const refreshData = useCallback(() => {
    dispatch(getAllOrders(true));
  }, [dispatch]);

  const onUpdateEventApi = async ({ id, ...event }: EventDto) => {
    // Remove RSVP status from event
    event.mailerList = event.mailerList.map(
      ({ rsvpStatus, ...mailer }) => mailer
    );
    await OrderApi.updateEvent(orderId, orderItem.id, event);
  };

  const onUpdateEvent = async (event: EventDto) => {
    try {
      await onUpdateEventApi(event);
      refreshData();
      NotificationUtils.showSuccess("Event details saved");
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  const sendEmailInvite = async (event: EventDto, emails: string[]) => {
    try {
      await onUpdateEventApi(event);
      await OrderApi.emailInvite(orderId, orderItem.id, { emails });
      refreshData();
      NotificationUtils.showSuccess("Email has been sent");
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  return (
    <Container display="flex" flexDirection="column" fontFamily={Fonts.Roboto}>
      <Typography variant="h5" textAlign="center" letterSpacing="0.2em">
        MANAGE YOUR EVENT
      </Typography>
      <Box paddingY={2}>
        <EventDetailTabs value={currentTab} setValue={setCurrentTab} />
      </Box>

      <Box overflow="auto">
        <EventDetailTabPanel value={currentTab} index={0}>
          <AccountEventDetails
            orderItem={orderItem}
            onUpdateEvent={onUpdateEvent}
          />
        </EventDetailTabPanel>
        <EventDetailTabPanel value={currentTab} index={1}>
          <EventMailerForm
            orderItem={orderItem}
            onUpdateEvent={onUpdateEvent}
            sendEmailInvite={sendEmailInvite}
          />
        </EventDetailTabPanel>
        <EventDetailTabPanel value={currentTab} index={2}>
          <AccountEventReminders
            orderItem={orderItem}
            onUpdateEvent={onUpdateEvent}
          />
        </EventDetailTabPanel>
      </Box>
    </Container>
  );
}
