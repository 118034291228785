import { Container, Typography } from "@mui/material";

import Layout from "../../layout/Layout";

export default function TermsAndConditions() {
  return (
    <Layout>
      <Container maxWidth="lg">
        <Typography variant="h3" margin={2} textAlign={"center"}>
          Terms and Conditions
        </Typography>
        <Typography variant="body1">
          {<br />}
          <strong>LAST REVISION: 05-Jan-2022 </strong>
          {<br />}
          {<br />}
          PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY USING THIS
          WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU AGREE TO BE BOUND
          BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
          {<br />}
          {<br />}
          This Terms of Service Agreement (the "Agreement") governs your use of
          this website, www.paperdateco.com (the "Website"), [MESH DESIGNS]
          ("MESH DESIGNS") offer of products for purchase on this Website, or
          your purchase of products available on this Website. This Agreement
          includes, and incorporates by this reference, the policies and
          guidelines referenced below. [MESH DESIGNS] reserves the right to
          change or revise the terms and conditions of this Agreement at any
          time by posting any changes or a revised Agreement on this Website.
          [MESH DESIGNS] will alert you that changes or revisions have been made
          by indicating on the top of this Agreement the date it was last
          revised. The changed or revised Agreement will be effective
          immediately after it is posted on this Website. Your use of the
          Website following the posting of any such changes or a revised
          Agreement will constitute your acceptance of any such changes or
          revisions. [MESH DESIGNS] encourages you to review this Agreement
          whenever you visit the Website to make sure that you understand the
          terms and conditions governing the use of the Website. This Agreement
          does not alter in any way the terms or conditions of any other written
          agreement you may have with [MESH DESIGNS] for other products or
          services. If you do not agree to this Agreement (including any
          referenced policies or guidelines), please immediately terminate your
          use of the Website. If you would like to print this Agreement, please
          click the print button on your browser toolbar.
          {<br />}
          {<br />}
        </Typography>
        <Typography variant="h5">PRODUCTS</Typography>
        <Typography variant="body1">
          <strong>Terms of Offer:</strong> This Website offers for sale certain
          products (the "Products"). By placing an order for Products through
          this Website, you agree to the terms outlined in this Agreement.
          {<br />}
          {<br />}
          <strong>Customer Solicitation:</strong> Unless you notify our third
          party call center reps or direct [MESH DESIGNS] sales reps, while they
          are calling you, of your desire to opt-out from further direct company
          communications and solicitations, you are agreeing to continue to
          receive further emails and call solicitations [MESH DESIGNS] and it is
          designated in house or third party call team(s).
          {<br />}
          {<br />}
          <strong>Opt-Out Procedure:</strong> We provide 3 easy ways to opt-out
          of future solicitations.
          <ol>
            <li>
              You may use the opt out link found in any email solicitation that
              you may receive.
            </li>
            <li>
              You may also choose to opt-out, via sending your email address to
              [info@paperdateco.com].
            </li>
            <li>
              You may send a written remove request to MESH DESIGNS having its
              registered office at 8-2-293/82/A/896.Road No.46, Jubilee Hills,
              Hyderabad, Telangana, 500033
            </li>
          </ol>
          <strong>Proprietary Rights:</strong> [MESH DESIGNS] has proprietary
          rights and trade secrets in the Products. You may not copy, reproduce,
          resell or redistribute any Product manufactured and/or distributed by
          [MESH DESIGNS]. [MESH DESIGNS] also has rights to all trademarks and
          trade dress and specific layouts of this webpage, including calls to
          action, text placement, images, and other information.
          {<br />}
          {<br />}
          <strong>Sales Tax:</strong> If you purchase any Products, you will be
          responsible for paying any applicable sales tax.
          {<br />}
          {<br />}
        </Typography>
        <Typography variant="h5">WEBSITE</Typography>
        <Typography variant="body1">
          Content; Intellectual Property; Third-Party Links. In addition to
          making Products available, this Website also offers information and
          marketing materials. This Website also offers information, both
          directly and through indirect links to third-party websites, about
          nutritional and dietary supplements. [MESH DESIGNS] does not always
          create the information offered on this Website; instead, the
          information is often gathered from other sources. To the extent that
          [MESH DESIGNS] does create the content on this Website, such content
          is protected by the intellectual property laws of India, foreign
          nations, and international bodies. Unauthorized use of the material
          may violate copyright, trademark, and/or other laws. You acknowledge
          that your use of the content on this Website is for personal,
          noncommercial use. Any links to MESH DESIGNS, MESH DESIGNS third-party
          websites are provided solely as a convenience to you does not endorse
          the contents on any such third-party websites, is not responsible for
          the content of or any damage that may result from your access to or
          reliance on these third-party websites. If you link to third-party
          websites, you do so at your own risk.
          {<br />}
          {<br />}
          <strong>Use of Website:</strong> [MESH DESIGNS] is not responsible for
          any damages resulting from the use of this website by anyone. You will
          not use the Website for illegal purposes. You will
          <ol>
            <li>
              abide by all applicable local, state, national, and international
              laws and regulations in your use of the Website (including laws
              regarding intellectual property),
            </li>
            <li>
              not interfere with or disrupt the use and enjoyment of the Website
              by other users,
            </li>
            <li>not resell material on the Website,</li>
            <li>
              not engage, directly or indirectly, in the transmission of "spam",
              chain letters, junk mail or any other type of unsolicited
              communication, and{" "}
            </li>
            <li>
              not defame, harass, abuse, or disrupt other users of the Website
            </li>
          </ol>
          <strong>License:</strong> By using this Website, you are granted a
          limited, non-exclusive, non-transferable right to use the content and
          materials on the Website in connection with your normal,
          noncommercial, use of the Website. You may not copy, reproduce,
          transmit, distribute, or create derivative works of such content or
          information without express written authorization from [MESH DESIGNS]
          or the applicable third party (if third party content is at issue).
          {<br />}
          {<br />}
          <strong>Posting:</strong> By posting, storing, or transmitting any
          content on the Website, you hereby grant [MESH DESIGNS] a perpetual,
          worldwide, non-exclusive, royalty-free, assignable, right and license
          to use, copy, display, perform, create derivative works from,
          distribute, have distributed, transmit and assign such content in any
          form, in all media now known or hereinafter created, anywhere in the
          world. [MESH DESIGNS] cannot control the nature of the user-generated
          content offered through the Website. You are solely responsible for
          your interactions with other users of the Website and any content you
          post. [MESH DESIGNS] is not liable for any damage or harm resulting
          from any posts by or interactions between users. [MESH DESIGNS]
          reserves the right, but has no obligation, to monitor interactions
          between and among users of the Website and to remove any content [MESH
          DESIGNS] deems objectionable, in [MESH DESIGNS] sole discretion.
          {<br />}
          {<br />}
        </Typography>
        <Typography variant="h5">DISCLAIMER OF WARRANTIES</Typography>
        <Typography variant="body1">
          YOUR USE OF THIS WEBSITE AND/OR PRODUCTS ARE AT YOUR SOLE RISK. THE
          WEBSITE AND PRODUCTS ARE OFFERED ON AN "AS IS" AND "AS AVAILABLE"
          BASIS. [MESH DESIGNS] EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND,
          WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT CONCERNING THE PRODUCTS OR WEBSITE CONTENT, OR ANY
          RELIANCE UPON OR USE OF THE WEBSITE CONTENT OR PRODUCTS. ("PRODUCTS"
          INCLUDE TRIAL PRODUCTS.)
          {<br />}
          {<br />}
          WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, [MESH DESIGNS] MAKES
          NO WARRANTY:
          {<br />}
          {<br />}
          THAT THE INFORMATION PROVIDED ON THIS WEBSITE IS ACCURATE, RELIABLE,
          COMPLETE, OR TIMELY.
          {<br />}
          {<br />}
          THAT THE LINKS TO THIRD-PARTY WEBSITES ARE TO INFORMATION THAT IS
          ACCURATE, RELIABLE, COMPLETE, OR TIMELY.
          {<br />}
          {<br />}
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
          FROM THIS WEBSITE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED
          HEREIN.
          {<br />}
          {<br />}
          AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE PRODUCTS OR
          THAT DEFECTS IN PRODUCTS WILL BE CORRECTED.
          {<br />}
          {<br />}
          REGARDING ANY PRODUCTS PURCHASED OR OBTAINED THROUGH THE WEBSITE.
          {<br />}
          {<br />}
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES,
          SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
          {<br />}
          {<br />}
        </Typography>
        <Typography variant="h5">LIMITATION OF LIABILITY</Typography>
        <Typography variant="body1">
          [MESH DESIGNS] ENTIRE LIABILITY, AND YOUR EXCLUSIVE REMEDY, IN LAW,
          INEQUITY, OR OTHERWISE, CONCERNING THE WEBSITE CONTENT AND PRODUCTS
          AND/OR FOR ANY BREACH OF THIS AGREEMENT IS SOLELY LIMITED TO THE
          AMOUNT YOU PAID, LESS SHIPPING AND HANDLING, FOR PRODUCTS PURCHASED
          VIA THE WEBSITE.
          {<br />}
          {<br />}
          [MESH DESIGNS] WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
          INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH THIS
          AGREEMENT OR THE PRODUCTS IN ANY MANNER, INCLUDING LIABILITIES
          RESULTING FROM
          <ol>
            <li>
              THE USE OR THE INABILITY TO USE THE WEBSITE CONTENT OR PRODUCTS;
            </li>
            <li>THE COST OF PROCURING SUBSTITUTE PRODUCTS OR CONTENT;</li>
            <li>
              ANY PRODUCTS PURCHASED OR OBTAINED OR TRANSACTIONS ENTERED INTO
              THROUGH THE WEBSITE; OR{" "}
            </li>
            <li>ANY LOST PROFITS YOU ALLEGE.</li>
          </ol>
          SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
          LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES SO SOME OF THE ABOVE
          LIMITATIONS MAY NOT APPLY TO YOU.
          {<br />}
          {<br />}
        </Typography>
        <Typography variant="h5">INDEMNIFICATION</Typography>
        <Typography variant="body1">
          You will release, indemnify, defend and hold harmless [MESH DESIGNS],
          and any of its contractors, agents, employees, officers, directors,
          shareholders, affiliates and assigns from all liabilities, claims,
          damages, costs, and expenses, including reasonable attorneys' fees and
          expenses, of third parties relating to or arising out of
          <ol>
            <li>
              this Agreement or the breach of your warranties, representations,
              and obligations under this Agreement;
            </li>
            <li>the Website content or your use of the Website content;</li>
            <li>
              the Products or your use of the Products (including Trial
              Products);
            </li>
            <li>
              any intellectual property or other proprietary rights of any
              person or entity;
            </li>
            <li>your violation of any provision of this Agreement; or </li>
            <li>any information or data you supplied to [MESH DESIGNS]. </li>
          </ol>
          When [MESH DESIGNS] is threatened with suit or sued by a third party,
          [MESH DESIGNS] may seek written assurances from you concerning your
          promise to indemnify [MESH DESIGNS]; your failure to provide such
          assurances may be considered by [MESH DESIGNS] to be a material breach
          of this Agreement. [MESH DESIGNS] will have the right to participate
          in any defense by you of a third-party claim related to your use of
          any of the Website content or Products, with counsel of [MESH DESIGNS]
          choice at its expense. [MESH DESIGNS] will reasonably cooperate in any
          defense by you of a third-party claim at your request and expense. You
          will have sole responsibility to defend [MESH DESIGNS] against any
          claim, but you must receive [MESH DESIGNS] prior written consent
          regarding any related settlement. The terms of this provision will
          survive any termination or cancellation of this Agreement or your use
          of the Website or Products.
          {<br />}
          {<br />}
        </Typography>
        <Typography variant="h5">PRIVACY</Typography>
        <Typography variant="body1">
          [MESH DESIGNS] believes strongly in protecting user privacy and
          providing you with notice of [MESH DESIGNS] use of data. Please refer
          to [MESH DESIGNS] privacy policy, incorporated by reference herein,
          that is posted on the Website.
          {<br />}
          {<br />}
        </Typography>
        <Typography variant="h5">AGREEMENT TO BE BOUND</Typography>
        <Typography variant="body1">
          By using this Website or ordering Products, you acknowledge that you
          have read and agree to be bound by this Agreement and all terms and
          conditions on this Website.
          {<br />}
          {<br />}
        </Typography>
        <Typography variant="h5">GENERAL</Typography>
        <Typography variant="body1">
          Force Majeure. [MESH DESIGNS] will not be deemed in default hereunder
          or held responsible for any cessation, interruption, or delay in the
          performance of its obligations hereunder due to earthquake, flood,
          fire, storm, natural disaster, the act of God, war, terrorism, armed
          conflict, labor strike, lockout, or boycott.
          {<br />}
          {<br />}
          Cessation of Operation. [MESH DESIGNS] may at any time, in its sole
          discretion and without advance notice to you, cease operation of the
          Website and distribution of the Products.
          {<br />}
          {<br />}
          Entire Agreement. This Agreement comprises the entire agreement
          between you and [MESH DESIGNS] and supersedes any prior agreements
          about the subject matter contained herein.
          {<br />}
          {<br />}
          Effect of Waiver. The failure of [MESH DESIGNS] to exercise or enforce
          any right or provision of this Agreement will not constitute a waiver
          of such right or provision. If any provision of this Agreement is
          found by a court of competent jurisdiction to be invalid, the parties
          nevertheless agree that the court should endeavor to give effect to
          the parties intentions as reflected in the provision, and the other
          provisions of this Agreement remain in full force and effect.
          {<br />}
          {<br />}
          Governing Law; Jurisdiction. This Website originates from [Hyderabad,
          Telangana]. This Agreement will be governed by the laws of the State
          of [Law Telangana] without regard to its conflict of law principles to
          the contrary. Neither you nor [MESH DESIGNS] will commence or
          prosecute any suit, proceeding, or claim to enforce the provisions of
          this Agreement, to recover damages for breach of or default of this
          Agreement, or otherwise arising under or because of this Agreement,
          other than in courts located in State of [Telangana]. By using this
          Website or ordering Products, you consent to the jurisdiction and
          venue of such courts in connection with any action, suit, proceeding,
          or claim arising under or because of this Agreement. You hereby waive
          any right to trial by jury arising out of this Agreement and any
          related documents.
          {<br />}
          {<br />}
          Statute of Limitation. You agree that regardless of any statute or law
          to the contrary, any claim or cause of action arising out of or
          related to use of the Website or Products or this Agreement must be
          filed within one (1) year after such claim or cause of action arose or
          be forever barred.
          {<br />}
          {<br />}
          Waiver of Class Action Rights. BY ENTERING INTO THIS AGREEMENT, YOU
          HEREBY IRREVOCABLY WAIVE ANY RIGHT YOU MAY HAVE TO JOIN CLAIMS WITH
          THOSE OF OTHERS IN THE FORM OF A CLASS ACTION OR SIMILAR PROCEDURAL
          DEVICE. ANY CLAIMS ARISING OUT OF, RELATING TO, OR CONNECTION WITH
          THIS AGREEMENT MUST BE ASSERTED INDIVIDUALLY.
          {<br />}
          {<br />}
          Termination. [MESH DESIGNS] reserves the right to terminate your
          access to the Website if it reasonably believes, in its sole
          discretion, that you have breached any of the terms and conditions of
          this Agreement. Following termination, you will not be permitted to
          use the Website, and [MESH DESIGNS] may, in its sole discretion and
          without advance notice to you, cancel any outstanding orders for
          Products. If your access to the Website is terminated, [MESH DESIGNS]
          reserves the right to exercise whatever means it deems necessary to
          prevent unauthorized access to the Website. This Agreement will
          survive indefinitely unless and until [MESH DESIGNS] chooses, in its
          sole discretion and without advance to you, to terminate it.
          {<br />}
          {<br />}
          Domestic Use. [MESH DESIGNS] makes no representation that the Website
          or Products are appropriate or available for use in locations outside
          India. Users who access the Website from outside India do so at their
          own risk and initiative and must bear all responsibility for
          compliance with any applicable local laws. Assignment. You may not
          assign your rights and obligations under this Agreement to anyone.
          [MESH DESIGNS] may assign its rights and obligations under this
          Agreement in its sole discretion and without advance notice to you.
          {<br />}
          {<br />}
          BY USING THIS WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU AGREE
          TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
          {<br />}
          {<br />}
        </Typography>
        <br />
      </Container>
    </Layout>
  );
}
