import CanvasToggleButton from "@paperdateco/shared-frontend/canvas/controls/common/CanvasToggleButton";
import EditableTextElement from "@paperdateco/shared-frontend/canvas/objects/EditableTextElement";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import useOptionRerender from "@paperdateco/shared-frontend/canvas/hooks/useOptionRerender";

interface TextItalicControlProps {
  textElements: EditableTextElement[];
}

export default function TextItalicControl({
  textElements,
}: TextItalicControlProps) {
  useOptionRerender(textElements, "fontStyle");

  const isItalic = textElements[0]?.nativeElement.fontStyle === "italic";

  const onChangeItalics = () =>
    textElements.forEach((element) =>
      element.set("fontStyle", isItalic ? "normal" : "italic")
    );

  return (
    <CanvasToggleButton
      value="italic"
      aria-label="italic"
      selected={isItalic}
      onChange={onChangeItalics}
    >
      <FormatItalicIcon />
    </CanvasToggleButton>
  );
}
