import { useEffect, useState } from "react";

import CategoryDto from "@paperdateco/common/dto/category/CategoryDto";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import ProductApi from "@paperdateco/common/api/product/ProductApi";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import ProductList from "../detail/ProductList";

interface CategoryProductListProps {
  category: CategoryDto;
}

export default function CategoryProductList({
  category,
}: CategoryProductListProps) {
  const [products, setProducts] = useState<ProductDto[]>([]);

  useEffect(() => {
    ProductApi.getByCategory(category.slug)
      .then((data) => setProducts(data))
      .catch((e) => NotificationUtils.showGenericError(e));
  }, [category]);

  return <ProductList products={products} />;
}
