import Layout from "../../layout/Layout";
import ProductDetail from "../../products/detail/ProductDetail";
import { useParams } from "react-router";

export default function ProductDetailPage() {
  const { productSlug } = useParams<{ productSlug: string }>();
  if (!productSlug) {
    return null;
  }
  return (
    <Layout>
      <ProductDetail productSlug={productSlug} />
    </Layout>
  );
}
