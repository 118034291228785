import { Box } from "@mui/system";
import CanvasUtils from "@paperdateco/shared-frontend/canvas/CanvasUtils";
import DownloadUtils from "@paperdateco/common/utils/DownloadUtils";
import StaticInvitation from "./StaticInvitation";
import { useCallback } from "react";
import { useFabric } from "@paperdateco/shared-frontend/canvas/useFabric";

interface InvitationJpegProps {
  width?: number;
  height?: number;
  invitation?: string;
  onComplete?: () => void;
}

export default function InvitationJpeg({
  width = 500,
  height = 600,
  invitation,
  onComplete,
}: InvitationJpegProps) {
  const handleCanvas = useCallback(
    async (fabricCanvas: fabric.Canvas, canvas: HTMLCanvasElement) => {
      const invite = new StaticInvitation(fabricCanvas, width, height);
      const invitationBlob = invitation
        ? await DownloadUtils.get(invitation)
        : "";
      await invite.addBackgroundImage(invitationBlob);
      const imageUrl = CanvasUtils.takePicture(fabricCanvas, canvas);
      await DownloadUtils.save(imageUrl, "download.jpg");
      onComplete?.();
    },
    [width, height, invitation, onComplete]
  );

  const ref = useFabric(width, height, handleCanvas);

  return (
    <Box display="none">
      <canvas ref={ref} width={width} height={height} />
    </Box>
  );
}
