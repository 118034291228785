import { FormEvent, useState } from "react";

import AuthForm from "./AuthForm";
import { Button } from "@mui/material";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import FormRow from "@paperdateco/shared-frontend/components/common/form/FormRow";
import LoginLink from "./LoginLink";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import UserApi from "@paperdateco/common/api/UserApi";
import routes from "@paperdateco/customer/routes/routes";
import { useNavigate } from "react-router-dom";

export default function ForgotPasswordForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await UserApi.forgotPassword({ email: email.toLowerCase() });
      NotificationUtils.showSuccess(
        "Reset mail is sent, Please check your email"
      );
      navigate(routes.LOGIN);
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  return (
    <AuthForm>
      <form onSubmit={handleSubmit}>
        <FormRow>
          <CustomTextField
            variant="standard"
            type="email"
            label="Email Address"
            autoComplete="email"
            value={email}
            onChange={setEmail}
          />
        </FormRow>
        <FormRow textAlign="center">
          <Button variant="contained" type="submit" size="large">
            Submit
          </Button>
        </FormRow>
      </form>
      <LoginLink />
    </AuthForm>
  );
}
