import { Box, BoxProps, Tooltip, styled } from "@mui/material";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import HiddenImage from "./image/HiddenImage";
import PremiumIcon from "@paperdateco/shared-frontend/assets/images/invite/premium.svg";

const PremiumIconContainer = styled(Box)({
  filter: `drop-shadow(3px 3px 4px ${Colors.LIGHT_SHADOW})`,
});

export default function PremiumItemIcon(props: BoxProps) {
  return (
    <PremiumIconContainer position="absolute" top={10} right={10} {...props}>
      <Tooltip title="Premium">
        <HiddenImage src={PremiumIcon} width={30} aspectRatio={1} />
      </Tooltip>
    </PremiumIconContainer>
  );
}
