import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";

import { Box } from "@mui/system";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import JRDesigns from "@paperdateco/customer/utils/JRDesignUtils";
import { ModalContext } from "@paperdateco/shared-frontend/components/providers/ModalProvider";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import ProductPrintRequestForm from "./ProductPrintRequestForm";
import useCustomiseTemplate from "./useCustomiseTemplate";

const OtherDetailsSection = CustomStyled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border.secondary}`,
}));
interface ProductDescriptionWithPrintProps {
  product: ProductDto;
}

export default function ProductDescriptionWithPrint({
  product,
}: ProductDescriptionWithPrintProps) {
  const onCustomiseTemplate = useCustomiseTemplate(product);
  const { open, close } = useContext(ModalContext);
  const [cost, setCost] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setCost(event.target.value as string);
  };

  function onPrintRequestForm(product: ProductDto) {
    open(<ProductPrintRequestForm product={product} onClose={close} />);
  }

  return (
    <Box paddingX={4} paddingY={2}>
      <Typography variant="h4">{product.name}</Typography>
      <Typography variant="body1" marginY={2}>
        {product.description}
      </Typography>

      <OtherDetailsSection marginTop={2} paddingY={2}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={1}
        >
          <Typography variant="h6" fontWeight="bold">
            DIGITAL
          </Typography>
          <Typography variant="body1" fontWeight="bold">
            ₹ {product.price} *
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={onCustomiseTemplate}
          >
            CUSTOMISE NOW
          </Button>
          <Typography variant="body1">*This is your base cost</Typography>
        </Stack>
      </OtherDetailsSection>
      <OtherDetailsSection marginTop={2} paddingY={2}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Typography variant="h6" fontWeight="bold">
            PRINT
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            PRINT DETAILS :
          </Typography>

          {JRDesigns.map((design) => (
            <>
              {design.NAME === product.name && (
                <>
                  <Typography variant="body2">
                    ENVELOPE - {design.DETAILS.ENVELOPE}.
                  </Typography>
                  <Typography variant="body2">
                    FOLDER - {design.DETAILS.FOLDER}.
                  </Typography>
                  <Typography variant="body2">
                    INSERTS - {design.DETAILS.INSERTS}.
                  </Typography>
                  <Typography variant="body2">
                    DIMENSIONS - {design.DETAILS.DIMENSIONS}.
                  </Typography>
                  <Typography variant="body2" fontWeight="bold">
                    SELECT YOUR QUANTITY
                  </Typography>
                  <FormControl sx={{ minWidth: 150 }}>
                    <InputLabel id="select-label">Quantity</InputLabel>
                    <Select
                      labelId="select-label"
                      value={cost}
                      label="Quantity"
                      onChange={handleChange}
                    >
                      <MenuItem value={design.QUANTITY.Q1}>50-150 </MenuItem>
                      <MenuItem value={design.QUANTITY.Q2}>150-300</MenuItem>
                    </Select>
                  </FormControl>
                </>
              )}
            </>
          ))}

          <Typography variant="body2" fontWeight="bold">
            COST PER CARD
          </Typography>
          <Typography variant="body1" fontWeight="bold">
            ₹ {cost} *
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={() => onPrintRequestForm(product)}
          >
            REQUEST PRINT
          </Button>
          <Typography variant="body1">*This is your base cost</Typography>
        </Stack>
      </OtherDetailsSection>
    </Box>
  );
}
