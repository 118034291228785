import CanvasToggleButton from "@paperdateco/shared-frontend/canvas/controls/common/CanvasToggleButton";
import EditableTextElement from "@paperdateco/shared-frontend/canvas/objects/EditableTextElement";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import useOptionRerender from "@paperdateco/shared-frontend/canvas/hooks/useOptionRerender";

interface TextBoldControlProps {
  textElements: EditableTextElement[];
}

export default function TextBoldControl({
  textElements,
}: TextBoldControlProps) {
  useOptionRerender(textElements, "fontWeight");

  const isBold = textElements[0]?.nativeElement.fontWeight === "bold";

  const onChangeBold = () =>
    textElements.forEach((element) =>
      element.set("fontWeight", isBold ? "normal" : "bold")
    );

  return (
    <CanvasToggleButton
      value="bold"
      aria-label="bold"
      selected={isBold}
      onChange={onChangeBold}
    >
      <FormatBoldIcon />
    </CanvasToggleButton>
  );
}
