import { useEffect, useState } from "react";

export default function useLongPress(
  callback = () => {},
  ms = 300,
  initialDelay = 500
) {
  const [startLongPress, setStartLongPress] = useState(false);

  useEffect(() => {
    let timerId: NodeJS.Timeout | undefined;
    let initialDelayId: NodeJS.Timeout | undefined;

    const clearTimers = () => {
      if (initialDelayId) {
        clearTimeout(initialDelayId);
      }
      if (timerId) {
        clearInterval(timerId);
      }
    };

    if (startLongPress) {
      initialDelayId = setTimeout(
        () => (timerId = setInterval(callback, ms)),
        initialDelay
      );
    } else {
      clearTimers();
    }

    return clearTimers;
  }, [callback, ms, initialDelay, startLongPress]);

  return {
    onClick: callback,
    onMouseDown: () => setStartLongPress(true),
    onMouseUp: () => setStartLongPress(false),
    onMouseLeave: () => setStartLongPress(false),
    onTouchStart: () => setStartLongPress(true),
    onTouchEnd: () => setStartLongPress(false),
  };
}
