import ProductDto from "../../dto/product/ProductDto";
import ProductRequestDto from "../../dto/product/ProductRequestDto";
import StoreInstance from "../../store/StoreInstance";
import { handleApiError } from "../../utils/ApiUtils";

export default class ProductApi {
  public static async getAllAdminProducts() {
    try {
      const products = await StoreInstance.api().get<ProductDto[]>("/products");
      return products.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get all products");
    }
  }

  public static async getProductById(productId: string) {
    try {
      const product = await StoreInstance.api().get<ProductDto>(
        `/products/${productId}`
      );
      return product.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get product detail");
    }
  }

  public static async addNewProduct(request: ProductRequestDto) {
    try {
      const product = await StoreInstance.api().post<ProductDto>(
        "/products/",
        request
      );
      return product.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get product detail");
    }
  }

  public static async updateProductById(
    productId: string,
    request: ProductRequestDto
  ) {
    try {
      const product = await StoreInstance.api().put<ProductDto>(
        `/products/${productId}`,
        request
      );
      return product.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get product detail");
    }
  }

  public static async getByCategory(categorySlug: string) {
    try {
      const products = await StoreInstance.api().get<ProductDto[]>(
        `/categories/${categorySlug}/products`
      );
      return products.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get products for category");
    }
  }

  public static async getCustomTemplateProduct() {
    try {
      const product = await StoreInstance.api().get<ProductDto | undefined>(
        `/products/custom-template`
      );
      return product.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get product details");
    }
  }

  public static async findOneBySlug(productSlug: string) {
    try {
      const product = await StoreInstance.api().get<ProductDto>(
        `/products/slug/${productSlug}`
      );
      return product.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get product details");
    }
  }

  public static async findRelatedProducts(productSlug: string) {
    try {
      const product = await StoreInstance.api().get<ProductDto[]>(
        `/products/slug/${productSlug}/related`
      );
      return product.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get related products");
    }
  }

  public static async getSearchedProducts(
    query: string | null,
    categorySlug?: string | null
  ) {
    try {
      const products = await StoreInstance.api().get<ProductDto[]>(
        `/products/search/?query=${query ?? ""}&category=${categorySlug ?? ""}`
      );
      return products.data;
    } catch (error) {
      throw handleApiError(error, "Failed to query products");
    }
  }
}
