import DesignLayerProperties from "../common/DesignLayerProperties";
import TextPaths from "../paths/TextPaths";

export enum FontWeightTypes {
  BOLD = "bold",
  NORMAL = "normal",
}

export enum FontStyleTypes {
  ITALIC = "italic",
  NORMAL = "normal",
}

export default interface DesignTextProperties extends DesignLayerProperties {
  text: string;
  fontColor?: string;
  fontSize?: number;
  fontFamily?: string;
  fontWeight?: FontWeightTypes;
  fontStyle?: FontStyleTypes;
  underline?: boolean;
  letterSpacing?: number;
  lineSpacing?: number;
  textAlign?: string;
  textPath?: TextPaths;
  textPathParams?: number[];
}
