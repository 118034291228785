enum UserError {
  USER_ALREADY_REGISTERED = "A user with the given email already exists",
  USER_NOT_FOUND = "Cannot find a user with the given email",
  AUTHENTICATION_FAILED = "Failed to login user",
  USER_EMAIL_NOT_FOUND = "User not found for the given email",
  EMAIL_NOT_VERIFIED = "Please verify your email to access the website",
  PASSWORD_EMPTY = "New Password or Old Password is Empty",
  OLD_PASSWORD_INCORRECT = "Current password provided is incorrect",
  PASSWORD_NOT_SAVED = "Please use Google to log in or reset password to use account",
  COLLABORATOR_NOT_FOUND = "We don't have the request collaborator",
}

export default UserError;
