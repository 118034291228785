import { Button, ButtonProps, Popover, PopoverProps } from "@mui/material";
import { MouseEvent, ReactNode, useState } from "react";

interface PopupButtonProps {
  button: ReactNode;
  disabled?: boolean;
  popup: ReactNode;
  buttonProps?: ButtonProps;
  onClosePopup?: () => void;
}

export default function PopupButton({
  button,
  disabled = false,
  popup,
  buttonProps,
  onClosePopup,
  ...props
}: PopupButtonProps & Omit<PopoverProps, "open">) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClosePopup?.();
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button disabled={disabled} onClick={handleClick} {...buttonProps}>
        {button}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        {...props}
      >
        {popup}
      </Popover>
    </>
  );
}
