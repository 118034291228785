import { Box } from "@mui/system";
import CanvasColorPropControl from "../color/CanvasColorPropControl";
import EditableTextElement from "@paperdateco/shared-frontend/canvas/objects/EditableTextElement";
import PopupButton from "@paperdateco/shared-frontend/components/common/form/button/PopupButton";
import TextColorIcon from "./TextColorIcon";
import { useCallback } from "react";
import useOptionRerender from "@paperdateco/shared-frontend/canvas/hooks/useOptionRerender";
import usePersistedState from "@paperdateco/shared-frontend/components/common/hooks/usePersistedState";

const DEFAULT_TEXT_COLOR = "#000";

const Default_Colors = [
  "#D0021B",
  "#F5A623",
  "#F8E71C",
  "#8B572A",
  "#7ED321",
  "#417505",
  "#BD10E0",
  "#9013FE",
  "#4A90E2",
  "#50E3C2",
  "#B8E986",
  "#000000",
  "#4A4A4A",
  "#9B9B9B",
  "#FFFFFF",
];

interface IconProps {
  textElements: EditableTextElement[];
}

function Icon({ textElements }: IconProps) {
  useOptionRerender(textElements, "fill");
  const color =
    textElements[0]?.nativeElement.fill?.toString() ?? DEFAULT_TEXT_COLOR;
  return (
    <Box width={25} height={25} display="flex">
      <TextColorIcon color={color} />
    </Box>
  );
}

interface TextColorControlProps {
  textElements: EditableTextElement[];
}

export default function TextColorControl({
  textElements,
}: TextColorControlProps) {
  const { state: colors, setState: setColors } = usePersistedState<string[]>(
    "invite-customise-color",
    Default_Colors
  );

  const onChooseColor = useCallback(() => {
    const hexString = textElements[0]?.nativeElement.fill?.toString();
    if (!hexString) {
      return;
    }
    const newColors = [...colors].filter((c) => c !== hexString).slice(0, 15);
    setColors([hexString, ...newColors]);
  }, [colors, setColors, textElements]);

  return (
    <PopupButton
      button={<Icon textElements={textElements} />}
      popup={
        <Box maxWidth={300}>
          <CanvasColorPropControl
            elements={textElements}
            prop="fill"
            defaultValue={DEFAULT_TEXT_COLOR}
            presetColor={colors}
          />
        </Box>
      }
      buttonProps={{ sx: { padding: 1, minWidth: "unset" } }}
      onClosePopup={onChooseColor}
    />
  );
}
