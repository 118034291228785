const HOME = "/";
const REGISTER = "/register";
const LOGIN = "/login";
const LOGOUT = "/logout";
const ABOUT_US = "/about-us";
const CONTACT_US = "/contact-us";
const CANCELLATION_POLICY = "/cancellation-policy";
const REFUNDS = "/refunds";
const RETURNS = "/returns";
const PRIVACY_POLICY = "/privacy-policy";
const TERMS_AND_CONDITIONS = "/terms-and-conditions";
const FORGOT_PASSWORD = "/forgot-password";
const RESET_PASSWORD = "/reset-password";
const VERIFICATION_EMAIL = "/verification-email";
const BLOG = "/category/blog";

const CATEGORIES = "/category";
const CATEGORY = (categorySlug = ":categorySlug") =>
  CATEGORIES + "/" + categorySlug;

const COLLABORATORS = "/collaborators";
const COLLABORATOR = (collaboratorId = ":collaboratorId") =>
  COLLABORATORS + "/" + collaboratorId;

const WISHLIST = "/wishlist";

const PRODUCTS = "/product";
const PRODUCT = (productSlug = ":productSlug") => PRODUCTS + "/" + productSlug;

const PRODUCT_SEARCH = "/search";

const CUSTOM_DESIGNS = "/custom-design";
const CUSTOM_DESIGN_EDIT = (customDesignId = ":customDesignId") =>
  CUSTOM_DESIGNS + "/" + customDesignId + "/edit";

const CUSTOM_DESIGN_PREVIEW = (customDesignId = ":customDesignId") =>
  CUSTOM_DESIGNS + "/" + customDesignId + "/preview";

const CART = "/cart";
const CART_ITEM = (cartItemId = ":cartItemId") => CART + "/item/" + cartItemId;
const CART_ITEM_EDIT = (cartItemId = ":cartItemId") =>
  CART_ITEM(cartItemId) + "/edit";
const CART_ITEM_PREVIEW = (cartItemId = ":cartItemId") =>
  CART_ITEM(cartItemId) + "/preview";

const ACCOUNT = "/account";
const ACCOUNT_DASHBOARD = ACCOUNT + "/dashboard";
const ACCOUNT_DETAILS = ACCOUNT + "/details";
const ACCOUNT_DRAFTS = ACCOUNT + "/drafts";
const ACCOUNT_MANAGE_INVITES = (orderItemId?: string) =>
  ACCOUNT +
  "/manage-invites" +
  (orderItemId ? "?orderItemId=" + orderItemId : "");
const ACCOUNT_ORDERS = ACCOUNT + "/orders";

const INVITE_SHARE_LINK = (orderItemId = ":orderItemId") =>
  "/invitelink/" + orderItemId;

const RSVP = (orderItemId = ":orderItemId") => "/rsvp/" + orderItemId;

const routes = {
  HOME,
  REGISTER,
  LOGIN,
  LOGOUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  VERIFICATION_EMAIL,
  ABOUT_US,
  CONTACT_US,
  CANCELLATION_POLICY,
  REFUNDS,
  PRIVACY_POLICY,
  RETURNS,
  TERMS_AND_CONDITIONS,
  WISHLIST,
  BLOG,
  CATEGORY,
  COLLABORATORS,
  COLLABORATOR,
  PRODUCT,
  PRODUCT_SEARCH,
  CUSTOM_DESIGNS,
  CUSTOM_DESIGN_EDIT,
  CUSTOM_DESIGN_PREVIEW,
  CART,
  CART_ITEM_EDIT,
  CART_ITEM_PREVIEW,
  ACCOUNT,
  ACCOUNT_DASHBOARD,
  ACCOUNT_DETAILS,
  ACCOUNT_DRAFTS,
  ACCOUNT_MANAGE_INVITES,
  ACCOUNT_ORDERS,
  INVITE_SHARE_LINK,
  RSVP,
};

export default routes;
