import { useNavigate, useParams } from "react-router";

import FullWidthPage from "@paperdateco/shared-frontend/components/layout/FullWidthPage";
import InviteCustomisation from "@paperdateco/customer/components/invite/customisation/InviteCustomisation";
import InviteCustomisationHeader from "@paperdateco/customer/components/invite/customisation/layout/InviteCustomisationHeader";
import routes from "@paperdateco/customer/routes/routes";
import { useCallback } from "react";
import usePageLeaveListener from "@paperdateco/shared-frontend/components/common/hooks/usePageLeaveListener";

export default function InviteCustomisationPage() {
  const { customDesignId } = useParams<{ customDesignId: string }>();
  usePageLeaveListener("Are you sure? Your design edits will not be saved", {
    allowedRoutes: ["preview"],
  });
  const navigate = useNavigate();

  const onViewPreview = useCallback(() => {
    navigate(routes.CUSTOM_DESIGN_PREVIEW(customDesignId));
  }, [navigate, customDesignId]);

  if (!customDesignId) {
    return null;
  }

  return (
    <FullWidthPage height="100vh">
      <InviteCustomisationHeader step="design" />
      <InviteCustomisation
        customDesignId={customDesignId}
        onViewPreview={onViewPreview}
      />
    </FullWidthPage>
  );
}
