import {
  CategoryStateDispatchType,
  getAllCategories,
} from "@paperdateco/customer/store/category/CategoryAction";
import { useDispatch, useSelector } from "react-redux";

import CategoryUtils from "@paperdateco/common/utils/CategoryUtils";
import { selectAllCategories } from "@paperdateco/customer/store/category/CategorySelector";
import { useEffect } from "react";

export default function useCategoryMenuItems() {
  const categories = useSelector(selectAllCategories);

  const dispatch = useDispatch<CategoryStateDispatchType>();

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  return CategoryUtils.groupCategories(categories);
}
