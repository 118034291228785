import { Box, Typography, styled } from "@mui/material";

import BlackButton from "@paperdateco/shared-frontend/components/common/form/button/BlackButton";
import CanvasElement from "@paperdateco/shared-frontend/canvas/objects/CanvasElement";
import CanvasTextCustomisationOptions from "@paperdateco/shared-frontend/canvas/controls/text/CanvasTextCustomisationOptions";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import DesignEditDarkBox from "@paperdateco/shared-frontend/components/design/common/DesignEditDarkBox";
import InviteUtils from "@paperdateco/shared-frontend/utils/InviteUtils";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface DesignEditHeaderSectionProps {
  selectedLayers: CanvasElement[];
  defaultTitle: string;
}

const BackBox = styled(Box)({
  position: "absolute",
  left: "3%",
});

export default function DesignEditHeaderSection({
  selectedLayers,
  defaultTitle,
}: DesignEditHeaderSectionProps) {
  const navigate = useNavigate();

  const goBack = useCallback(() => navigate(-1), [navigate]);
  const textLayers = InviteUtils.getTextLayers(selectedLayers);

  if (textLayers.length > 0) {
    return (
      <DesignEditDarkBox height={65} padding={1}>
        <BackBox>
          <BlackButton onClick={goBack} startIcon={<ChevronLeftIcon />}>
            BACK
          </BlackButton>
        </BackBox>
        <CanvasTextCustomisationOptions textElements={textLayers} />
      </DesignEditDarkBox>
    );
  }

  return (
    <>
      <DesignEditDarkBox
        height={65}
        paddingY={{ xs: 1, sm: 1.9 }}
        paddingX={{ xs: 1, sm: 3 }}
      >
        <BackBox>
          <BlackButton onClick={goBack} startIcon={<ChevronLeftIcon />}>
            BACK
          </BlackButton>
        </BackBox>
        <Typography
          className="text-uppercase"
          color={Colors.WHITE}
          textAlign="center"
          paddingY={{ xs: 0, sm: 5 }}
        >
          {defaultTitle}
        </Typography>
      </DesignEditDarkBox>
    </>
  );
}
