import { Box, BoxProps } from "@mui/material";
import { useEffect, useState } from "react";

import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import DesignLibraryApi from "@paperdateco/common/api/design/DesignLibraryApi";
import LibraryImageAspectRatio from "../LibraryImageAspectRatio";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import LibraryImageListItem from "../LibraryImageListItem";
import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import debounce from "lodash.debounce";

const searchImages = (
  type: LibraryImageType,
  query: string,
  setImages: (images: LibraryImageDto[]) => void,
  design?: DesignDto
) =>
  DesignLibraryApi.searchLibraryImages(type, {
    query,
    design: design?.id,
  })
    .then(setImages)
    .catch(NotificationUtils.showGenericError);

const debouncedSearch = debounce(searchImages, 1000);

interface LibraryImagesProps {
  query: string;
  type: LibraryImageType;
  design?: DesignDto;
  onChoose?: (type: LibraryImageType, item: LibraryImageDto) => void;
}

export default function LibraryImages({
  query,
  type,
  design,
  onChoose,
  ...props
}: LibraryImagesProps & BoxProps) {
  const [images, setImages] = useState<LibraryImageDto[]>([]);

  useEffect(() => {
    debouncedSearch(type, query, setImages, design);
  }, [query, type, design]);

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
      {...props}
    >
      {images.map((option, index) => (
        <Box key={index} margin={1}>
          <LibraryImageListItem
            width={135}
            item={option}
            type={type}
            aspectRatio={LibraryImageAspectRatio[type]}
            onChoose={onChoose}
          />
        </Box>
      ))}
    </Box>
  );
}
