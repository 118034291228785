import { Button, Stack, Typography } from "@mui/material";
import { LibrarySectionLabels, SectionLabelType } from "./LibrarySection";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import GiphyImages from "./plugins/giphy/GiphyImages";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";
import LibraryImages from "./plugins/LibraryImages";
import PixabayPhotos from "./plugins/pixabay/PixabayPhotos";
import { useState } from "react";

interface LibraryImageSearchableListProps {
  type: LibraryImageType & SectionLabelType;
  design?: DesignDto;
  onClose?: () => void;
  onChoose?: (type: LibraryImageType, item: LibraryImageDto) => void;
}

export default function LibraryImageSearchableList({
  type,
  design,
  onClose,
  onChoose,
}: LibraryImageSearchableListProps) {
  const [search, setSearch] = useState("");

  return (
    <Stack direction="column" alignItems="flex-start" height="100%">
      <Button
        variant="contained"
        startIcon={<ArrowBackIcon />}
        onClick={onClose}
      >
        BACK
      </Button>
      <Typography variant="h6" marginY={1}>
        {LibrarySectionLabels[type]}
      </Typography>
      <CustomTextField value={search} onChange={setSearch} label="Search" />

      <Stack direction="column" overflow="auto" flexGrow={1} width="100%">
        <LibraryImages
          query={search}
          type={type}
          design={design}
          onChoose={onChoose}
          paddingY={2}
          marginTop={2}
        />
        <GiphyImages
          query={search}
          type={type}
          design={design}
          onChoose={onChoose}
        />
        <PixabayPhotos
          query={search}
          type={type}
          design={design}
          onChoose={onChoose}
        />
      </Stack>
    </Stack>
  );
}
