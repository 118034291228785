import { Box, Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import DesignApi from "@paperdateco/common/api/DesignApi";
import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import DesignLibraryApi from "@paperdateco/common/api/design/DesignLibraryApi";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import HiddenImageFit from "@paperdateco/shared-frontend/components/common/image/HiddenImageFit";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import PhotoItem from "../PhotoItem";
import axios from "axios";
import debounce from "lodash.debounce";
import pixabayLogo from "@paperdateco/shared-frontend/assets/images/logo/pixabay_logo_horizontal.svg";

const PIXABAY_API_KEY = process.env.REACT_APP_PIXABAY_KEY;
const PIXABAY_API_URL = "https://pixabay.com/api";

const pixabayAxiosInstance = axios.create({
  baseURL: PIXABAY_API_URL,
  params: {
    key: PIXABAY_API_KEY,
  },
});

const fetchPhotos = (
  page: number,
  query: string,
  setPhotos: (photos: any[]) => void,
  setTotal: (total: number) => void
) =>
  query === ""
    ? pixabayAxiosInstance
        .get<any>(`/?q=india&page=${page}&safesearch=true&per_page=200`)
        .then((res) => {
          setTotal(res.data.total), setPhotos(res.data.hits);
        })
        .catch(NotificationUtils.showGenericError)
    : pixabayAxiosInstance
        .get<any>(`/?q=${query}&page=${page}&safesearch=true&per_page=200`)
        .then((res) => {
          setTotal(res.data.total), setPhotos(res.data.hits);
        })
        .catch(NotificationUtils.showGenericError);

const debouncedSearch = debounce(fetchPhotos, 1000);

interface PixabayPhotosProps {
  query: string;
  type: LibraryImageType;
  design?: DesignDto;
  onChoose?: (type: LibraryImageType, item: LibraryImageDto) => void;
}

export default function PixabayPhotos({
  query,
  type,
  design,
  onChoose,
  ...props
}: PixabayPhotosProps) {
  const [photos, setPhotos] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(1);
  const [page, setPage] = useState<number>(1);

  const onChoosePixabayPhoto = useCallback(
    async (photo: any) => {
      await fetch(photo.largeImageURL).then(async (response) => {
        const contentType = response.headers.get("content-type");
        const blob = await response.blob();
        const file = new File([blob], "pixabayPhoto.jpg", {
          type: contentType ?? "",
        });
        const pixabayUrl = await DesignApi.uploadMedia(file);
        const libraryPhoto = await DesignLibraryApi.addUserImage({
          types: [type],
          url: pixabayUrl,
          design: design?.id,
        });
        onChoose?.(LibraryImageType.PHOTO, libraryPhoto);
      });
    },
    [onChoose]
  );

  useEffect(() => {
    setPhotos([]);
    setPage(1);
    debouncedSearch(page, query, setPhotos, setTotal);
  }, [query]);

  if (!fetchPhotos || type !== LibraryImageType.PHOTO) {
    return null;
  }

  const handleGetMore = (page: number) => {
    setPage(page);
    query === ""
      ? pixabayAxiosInstance
          .get<any>(`/?q=india&page=${page}&per_page=200&safesearch=true`)
          .then((res) => {
            setPhotos([...photos, ...res.data.hits]);
          })
          .catch(NotificationUtils.showGenericError)
      : pixabayAxiosInstance
          .get<any>(`/?q=${query}&page=${page}&safesearch=true&per_page=200`)
          .then((res) => {
            setPhotos([...photos, ...res.data.hits]);
          })
          .catch(NotificationUtils.showGenericError);
  };

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
      {...props}
    >
      <HiddenImage
        src={pixabayLogo}
        width={"100%"}
        aspectRatio={8}
        fit={HiddenImageFit.CONTAIN}
        marginBottom={1.5}
      />
      {photos.map((photo, index) => (
        <PhotoItem
          photo={photo}
          photoPreview={photo.previewURL}
          photoAppName={"Pixabay"}
          photoAppLink={photo.pageURL}
          photoUserName={photo.user}
          photoUserNameLink={`https://pixabay.com/users/${photo.user}/`}
          index={index}
          type={type}
          onChoose={onChoosePixabayPhoto}
        />
      ))}
      {photos.length === total ? (
        <>
          <br />
          You have reached the End
        </>
      ) : (
        <Button
          onClick={() => handleGetMore(page + 1)}
          size="small"
          variant="outlined"
          sx={{ margin: 2 }}
        >
          Get Photos...
        </Button>
      )}
    </Box>
  );
}
