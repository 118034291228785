import {
  CategoryStateDispatchType,
  getAllCategories,
} from "@paperdateco/customer/store/category/CategoryAction";
import { Container, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import CategoryHeader from "./CategoryHeader";
import CategoryPopupModal from "./CategoryPopupModal";
import CategoryProductList from "./CategoryProductList";
import CategorySiblingList from "./CategorySiblingList";
import { selectCategoryBySlug } from "@paperdateco/customer/store/category/CategorySelector";
import { useEffect } from "react";

interface CategoryDetailProps {
  categorySlug: string;
}

export default function CategoryDetail({ categorySlug }: CategoryDetailProps) {
  const category = useSelector(selectCategoryBySlug(categorySlug));
  const dispatch = useDispatch<CategoryStateDispatchType>();

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  if (!category) {
    return null;
  }

  return (
    <Container maxWidth="xl" disableGutters>
      <CategoryPopupModal />
      <CategoryHeader category={category} />
      <Grid container spacing={4} paddingX={6} paddingY={3}>
        <Grid item xs={12} sm={6} md={3}>
          <CategorySiblingList category={category} />
        </Grid>
        <Grid item xs={12} sm={6} md={9}>
          <CategoryProductList category={category} />
        </Grid>
      </Grid>
    </Container>
  );
}
