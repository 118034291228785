import CategoryDetail from "../../products/category/CategoryDetail";
import Layout from "../../layout/Layout";
import { useParams } from "react-router";

export default function CategoryPage() {
  const { categorySlug } = useParams<{ categorySlug: string }>();

  if (!categorySlug) {
    return null;
  }

  return (
    <Layout>
      <CategoryDetail categorySlug={categorySlug} />
    </Layout>
  );
}
