import { Container, Typography } from "@mui/material";

import Layout from "../../layout/Layout";

export default function Returns() {
  return (
    <Layout>
      <Container maxWidth="lg">
        <Typography variant="h3" margin={2} textAlign={"center"}>
          Return Policy
        </Typography>
        <br />
        <Typography variant="body1" component="div">
          <ol>
            <li>
              The Mesh Designs does not ordinarily in the course of business
              accept returns/replacements of products that are bought through
              the website.
            </li>
            <br />
            <li>
              However, in case You believe that there is a relevant requirement
              to return the items purchased by You through the Website such as
              for damaged products or otherwise, you may raise a request for
              Returns on the Website or by writing to us at info@paperdateco.com
              with all relevant details as indicated herein. Any request for
              return or replacement will only be entertained if received by Us
              within 3 hours after the delivery has been made.
            </li>
            <br />
            <li>
              In case the Mesh Designs accepts the Returns Request following the
              policies as set out hereunder, depending on the nature of the
              Products or the Request raised by You, You are entitled to
              Replacement of the products or Refund which shall be completed at
              the discretion of the Mesh Designs.
            </li>
            <br />
            <li>
              Replacement: In case of Mesh Designs agrees to Replace the
              Product, the Mesh Designs shall ensure that the Product which you
              are returning is replaced with a product that is of the same
              description as the original product ordered by you, subject to the
              availability of the Stocks.
            </li>
            <br />
            <li>
              Refunds: In the case of Mesh Designs agrees to Refund the Purchase
              Price paid for the products, any amount paid for the purchase of
              the Products by You will be refunded to You following the Refunds
              Policy.
            </li>
            <br />
            <li>
              For the processing of Returns & Replacements, the following
              details will be checked by Us or our agents :
              <ol type="a">
                <li>A Photograph of the products you wish to Return to Us.</li>
                <li>
                  The invoice which was billed to you on the sales of the
                  Products.
                </li>
                <li>
                  The Original Products sold to You are returned unused,
                  unaltered, undented, unscratched, or otherwise damaged.
                </li>
                <li>Original Packaging/ Box of the Products.</li>
                <li>
                  Products tags, MRP labels, and any other product ancillaries
                  are undetached from the products and are returned as-is.
                </li>
              </ol>
            </li>
            <br />
            <li>
              In case we deem that there are quality issues with the products
              that are requested to be returned by you, we or our agents reserve
              the right to reject the return request and not accept Returns of
              the Products.
            </li>
            <br />
            <li>
              The Mesh Designs may not be able to take back such products which
              shall be indicated in the Terms and Conditions itself. Return
              Requests for such products will not be accepted by the Mesh
              Designs under any circumstances due to the very nature of such
              products.
            </li>
            <br />
          </ol>
        </Typography>
      </Container>
    </Layout>
  );
}
