import CanvasToggleButton from "@paperdateco/shared-frontend/canvas/controls/common/CanvasToggleButton";
import EditableTextElement from "@paperdateco/shared-frontend/canvas/objects/EditableTextElement";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import useOptionRerender from "@paperdateco/shared-frontend/canvas/hooks/useOptionRerender";

interface TextUnderlineControlProps {
  textElements: EditableTextElement[];
}

export default function TextUnderlineControl({
  textElements,
}: TextUnderlineControlProps) {
  useOptionRerender(textElements, "underline");

  const isUnderline = textElements[0]?.nativeElement.underline ?? false;

  const onChangeUnderline = () =>
    textElements.forEach((element) => element.set("underline", !isUnderline));

  return (
    <CanvasToggleButton
      value="underlined"
      aria-label="underlined"
      selected={isUnderline}
      onChange={onChangeUnderline}
    >
      <FormatUnderlinedIcon />
    </CanvasToggleButton>
  );
}
