const Colors = {
  PRIMARY: "#333333",
  PRIMARY_LIGHT: "#7e7e7e",
  PRIMARY_500: "#565656",
  PRIMARY_600: "#696969",
  PRIMARY_800: "#a8a8a8",
  PRIMARY_900: "#999",
  PRIMARY_A100: "#F5F5F5",
  SECONDARY: "#94b794",
  ERROR: "#EE404C",
  WHITE: "#FFFFFF",
  SNOW_WHITE: "#f8f9fa",
  DARK_GREY: "#404040",
  BLACK: "#000000",
  RED: "#FF3737",
  BRIGHT_RED: "#F17560",
  LIGHT_RED: "#fa8787",
  ORANGE: "#FF725E",
  GREEN: "#6AA244",
  YELLOW: "#e0c06b",
  GREY_900: "#F5F5F5",
  LIGHT_GREY: "#dee2e6",
  LIGHT_BACKGROUND: "#FAFAFA",
  DARK_BACKGROUND: "#1d252c",
  DARK_BLUE_BACKGROUND: "#2B4963",
  GREY_BACKGROUND: "#edeae3",
  PRIMARY_BACKGROUND_COLOR: "#afbdac",
  TRANSPARENT_GREY_BACKGROUND: "rgb(6 6 6/74%)",
  LIGHT_BORDER: "#dee2e6",
  SHADOW: "#a3a1a1",
  LIGHT_SHADOW: "rgba(0, 0, 0, 0.15)",
  BADGE: "#f8796c",
  BLUE: "#1565c0",
  PINK: "#FA9685",
  LIGHT_YELLOW: "#FFFDF4",
};

export default Colors;
