import { useEffect, useReducer } from "react";

import InviteInstantPreview from "@paperdateco/shared-frontend/components/invite/InviteInstantPreview";

export default function useInviteSelectedLayers(
  instantPreview?: InviteInstantPreview
) {
  const [, rerender] = useReducer((x) => x + 1, 0);

  const selectedLayers = instantPreview?.selectedLayers ?? [];

  useEffect(() => {
    instantPreview?.onSelectionChange(rerender);
    return () => instantPreview?.removeSelectionChangeListener(rerender);
  }, [instantPreview]);

  return selectedLayers;
}
