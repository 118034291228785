import {
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { MouseEvent, useCallback, useState } from "react";

import CategoryWithChildren from "@paperdateco/common/dto/category/CategoryWithChildrenDto";
import { Link } from "react-router-dom";
import routes from "@paperdateco/customer/routes/routes";

interface VerticalMenuItemProps {
  category: CategoryWithChildren;
}

export default function VerticalMenuItem({ category }: VerticalMenuItemProps) {
  const [open, setOpen] = useState(false);

  const toggle = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setOpen(!open);
    },
    [open]
  );

  return (
    <>
      <Link to={routes.CATEGORY(category.slug)}>
        <ListItemButton>
          <ListItemText primary={category.name} />
          <IconButton onClick={toggle}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemButton>
      </Link>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {category.children.map((child) => (
            <Link to={routes.CATEGORY(child.slug)} key={child.id}>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary={child.name} />
              </ListItemButton>
            </Link>
          ))}
        </List>
      </Collapse>
    </>
  );
}
