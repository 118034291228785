import { IconButton, SvgIconProps, Tooltip } from "@mui/material";

import { SvgIconComponent } from "@mui/icons-material";

interface CanvasPageControlProps {
  title: string;
  disabled?: boolean;
  onClick?: () => void;
  icon: SvgIconComponent;
  iconProps?: SvgIconProps;
}

export default function CanvasPageControl({
  title,
  disabled = false,
  onClick,
  icon: Icon,
  iconProps,
}: CanvasPageControlProps) {
  return (
    <Tooltip title={title}>
      <span>
        <IconButton size="small" disabled={disabled} onClick={onClick}>
          <Icon fontSize="inherit" {...iconProps} />
        </IconButton>
      </span>
    </Tooltip>
  );
}
