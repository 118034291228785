import { FormEvent, useEffect, useState } from "react";

import AuthForm from "./AuthForm";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import FormRow from "@paperdateco/shared-frontend/components/common/form/FormRow";
import LoginLink from "./LoginLink";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import UserApi from "@paperdateco/common/api/UserApi";
import routes from "@paperdateco/customer/routes/routes";
import { useNavigate } from "react-router";
import useQueryParam from "@paperdateco/customer/utils/useQueryParam";

export default function ResetPasswordForm() {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const verificationCode = useQueryParam("code");
  const email = useQueryParam("email");

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!verificationCode) {
      return;
    }

    if (password !== confirmPassword) {
      NotificationUtils.showError("Please check if the password match");
      return;
    }

    try {
      await UserApi.resetPassword({ password, verificationCode });
      navigate(routes.LOGIN);
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  useEffect(() => {
    if (!verificationCode) {
      NotificationUtils.showError(
        "No verification code received, check the email"
      );
    }
  }, [verificationCode]);

  return (
    <AuthForm>
      <form onSubmit={handleSubmit}>
        <Box display="none">
          <CustomTextField
            variant="standard"
            label="Email"
            type="email"
            value={email}
          />
        </Box>
        <FormRow>
          <CustomTextField
            variant="standard"
            label="Password"
            type="password"
            autoComplete="new-password"
            value={password}
            onChange={setPassword}
          />
        </FormRow>
        <FormRow>
          <CustomTextField
            variant="standard"
            label="Confirm Password"
            type="password"
            autoComplete="new-password"
            value={confirmPassword}
            onChange={setConfirmPassword}
          />
        </FormRow>
        <FormRow textAlign="center">
          <Button variant="contained" type="submit" size="large">
            Submit
          </Button>
        </FormRow>
      </form>
      <LoginLink />
    </AuthForm>
  );
}
