import { IconButton, Typography } from "@mui/material";
import { Box, BoxProps } from "@mui/system";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";

interface AlignmentButtonControlProps {
  name: string;
  icon: string;
  onClick?: () => void;
  imageProps?: BoxProps;
}

export default function AlignmentButtonControl({
  name,
  onClick,
  icon,
  imageProps,
  ...props
}: AlignmentButtonControlProps & Omit<BoxProps, "onClick">) {
  return (
    <Box
      minHeight={80}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Typography variant="body3">{name.toUpperCase()}</Typography>
      <IconButton onClick={onClick}>
        <HiddenImage src={icon} aspectRatio={1} width={30} {...imageProps} />
      </IconButton>
    </Box>
  );
}
