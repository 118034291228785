import { Box, BoxProps } from "@mui/system";

export default function EventDetailTabContent(props: BoxProps) {
  return (
    <Box
      textAlign="center"
      width="80%"
      margin="auto"
      paddingY={3}
      paddingX={2}
      {...props}
    >
      {props.children}
    </Box>
  );
}
