import EventDto from "../dto/order/event/EventDto";
import EventRequestDto from "../dto/order/event/EventRequestDto";
import OrderDetailedDto from "../dto/order/OrderDetailedDto";
import OrderItemDto from "../dto/order/details/OrderItemDto";
import OrderPaymentInfoDto from "../dto/order/OrderPaymentInfoDto";
import OrderVerifyRequestDto from "../dto/order/OrderVerifyRequestDto";
import SendMailRequestDto from "../dto/order/event/mailer/SendMailRequestDto";
import StoreInstance from "../store/StoreInstance";
import { handleApiError } from "../utils/ApiUtils";

export default class OrderApi {
  public static async getAllAdminOrders() {
    try {
      const orders = await StoreInstance.api().get<OrderDetailedDto[]>(
        "/orders/all"
      );
      return orders.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get all orders");
    }
  }

  public static async getAllUserOrders() {
    try {
      const orders = await StoreInstance.api().get<OrderDetailedDto[]>(
        "/orders"
      );
      return orders.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get all orders");
    }
  }

  public static async getOrderItemDetails(orderItemId: string) {
    try {
      const event = await StoreInstance.api().get<OrderItemDto>(
        `/orders/items/${orderItemId}`
      );
      return event.data;
    } catch (error) {
      throw handleApiError(error, "Failed to get event");
    }
  }

  public static async getEvent(orderId: string, orderItemId: string) {
    try {
      const event = await StoreInstance.api().get<EventDto>(
        `/orders/${orderId}/items/${orderItemId}/event`
      );
      return event.data;
    } catch (error) {
      throw handleApiError(error, "Failed to get event");
    }
  }

  public static async updateEvent(
    orderId: string,
    orderItemId: string,
    event: EventRequestDto
  ) {
    try {
      await StoreInstance.api().put<OrderDetailedDto>(
        `/orders/${orderId}/items/${orderItemId}/event`,
        event
      );
    } catch (error) {
      throw handleApiError(error, "Failed to update event");
    }
  }

  public static async emailInvite(
    orderId: string,
    orderItemId: string,
    request: SendMailRequestDto
  ) {
    try {
      await StoreInstance.api().put<OrderDetailedDto>(
        `/orders/${orderId}/items/${orderItemId}/event/send-mail`,
        request
      );
    } catch (error) {
      throw handleApiError(error, "Failed to send email invite");
    }
  }

  public static async createOrder() {
    try {
      const order = await StoreInstance.api().post<OrderPaymentInfoDto>(
        "/orders"
      );
      return order.data;
    } catch (e) {
      throw handleApiError(e, "Failed to create order");
    }
  }

  public static async veriryOrder(
    orderId: string,
    orderDetails: OrderVerifyRequestDto
  ) {
    try {
      const order = await StoreInstance.api().post<OrderPaymentInfoDto>(
        `/orders/${orderId}/validate`,
        orderDetails
      );
      return order.data;
    } catch (e) {
      throw handleApiError(e, "Failed to verify order with razorpay");
    }
  }
}
