import { Container, Typography } from "@mui/material";

import Layout from "../../layout/Layout";

export default function Refunds() {
  return (
    <Layout>
      <Container maxWidth="lg">
        <Typography variant="h3" margin={2} textAlign={"center"}>
          Refund Policy
        </Typography>
        <Typography variant="h6" marginY={10} marginX={2}>
          If Mesh Designs agrees to the Refund Option on Returns and
          Cancellations, once we receive your return and it is accepted
          following the Returns Policy, we will refund the original Purchase
          Price of the Products paid by you. The refunds will be processed to
          your bank account through approved methods of transfer within 7
          business days. In case you are not in receipt of your Refund amount
          within the above specified time, you can get in touch with your
          Banking services provider or with Mesh Designs at{" "}
          <a href="mailto:info@paperdateco.com">info@paperdateco.com</a>
        </Typography>
      </Container>
    </Layout>
  );
}
