import {
  OrderStateDispatchType,
  getAllOrders,
} from "@paperdateco/customer/store/order/OrderAction";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import AccountOrderRow from "./AccountOrderRow";
import { selectOrders } from "@paperdateco/customer/store/order/OrderSelector";
import { useEffect } from "react";

export default function AccountOrders() {
  const orders = useSelector(selectOrders);
  const dispatch = useDispatch<OrderStateDispatchType>();

  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Order Id</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Total</TableCell>
          <TableCell>Items</TableCell>
          <TableCell>Order Details</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {orders.map((order) => (
          <AccountOrderRow key={order.id} order={order} />
        ))}
      </TableBody>
    </Table>
  );
}
