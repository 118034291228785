import { useEffect, useState } from "react";

import CartApi from "@paperdateco/common/api/CartApi";
import CartItemDto from "@paperdateco/common/dto/order/cart/CartItemDto";
import CustomDesignPreview from "../../invite/preview/CustomDesignPreview";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import FullWidthPage from "@paperdateco/shared-frontend/components/layout/FullWidthPage";
import InviteCustomisationHeader from "../../invite/customisation/layout/InviteCustomisationHeader";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import { useParams } from "react-router-dom";

export default function CartPreviewPage() {
  const { cartItemId } = useParams<{ cartItemId: string }>();
  const [cartItem, setCartItem] = useState<CartItemDto>();

  useEffect(() => {
    if (!cartItemId) {
      return;
    }
    CartApi.getCartItem(cartItemId)
      .then(setCartItem)
      .catch(NotificationUtils.showGenericError);
  }, [cartItemId]);

  return (
    <FullWidthPage height="100vh" fontFamily={Fonts.Titillium}>
      <InviteCustomisationHeader step="preview" />
      {cartItem && (
        <CustomDesignPreview
          customDesignId={cartItem.customDesign.id}
          cartItemId={cartItem.id}
        />
      )}
    </FullWidthPage>
  );
}
