import { Navigate, Outlet, useLocation } from "react-router-dom";

import { AuthContext } from "@paperdateco/shared-frontend/components/auth/AuthProvider";
import UserRole from "@paperdateco/common/dto/auth/UserRole";
import routes from "../routes";
import { useContext } from "react";

export default function CustomerRoute() {
  const { user } = useContext(AuthContext);
  const location = useLocation();

  if (!(user && user.roles.includes(UserRole.CUSTOMER))) {
    return <Navigate to={routes.LOGIN} state={{ from: location.pathname }} />;
  }

  return <Outlet />;
}
