import InvitePreviewAnimation from "@paperdateco/customer/components/invite/preview/InvitePreviewAnimation";
import SimpleLayout from "@paperdateco/customer/components/layout/SimpleLayout";
import { Typography } from "@mui/material";
import useGetOrderItemDetails from "../../../invite/common/useGetOrderItemDetails";
import { useParams } from "react-router-dom";

export default function InviteSharePage() {
  const { orderItemId } = useParams<{ orderItemId: string }>();
  const orderItem = useGetOrderItemDetails(orderItemId);

  return (
    <SimpleLayout
      title={
        <Typography variant="h5">
          {orderItem?.event?.eventName ?? "Invitation"}
        </Typography>
      }
    >
      {orderItem && (
        <InvitePreviewAnimation customDesign={orderItem.customDesign} />
      )}
    </SimpleLayout>
  );
}
