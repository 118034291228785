import { AuthContext } from "@paperdateco/shared-frontend/components/auth/AuthProvider";
import { Box } from "@mui/system";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeaderCartIcon from "./HeaderCartIcon";
import HeaderWishListIcon from "./HeaderWishListIcon";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import SearchField from "@paperdateco/customer/components/common/search/SearchField";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import routes from "@paperdateco/customer/routes/routes";
import { useContext } from "react";

const AuthItemContainer = CustomStyled(Box)(({ theme }) => ({
  "& > *": {
    margin: theme.spacing(0, 0.5),
    display: "inline-block",
  },
}));

export default function HeaderAuthContainer() {
  const { user } = useContext(AuthContext);

  return (
    <AuthItemContainer margin={1} display="flex" alignItems="center">
      <SearchField />
      <Link to={routes.WISHLIST}>
        <HeaderWishListIcon />
      </Link>
      <Link to={routes.CART}>
        <HeaderCartIcon />
      </Link>
      <Link to={user ? routes.ACCOUNT : routes.LOGIN}>
        <IconButton size="small" color="inherit">
          <FontAwesomeIcon icon={faUser} color="inherit" />
        </IconButton>
      </Link>
    </AuthItemContainer>
  );
}
