import AnimationValue from "./AnimationValue";
import AnimationValueUtil from "./AnimationValueUtil";
import { fabric } from "fabric";

export default function ZoomOutAnimation(
  elem: fabric.Object
): AnimationValue[] {
  AnimationValueUtil.setCenteredOriginForZoom(elem);
  const scaleXValue = elem.scaleX ?? 1;
  const scaleYValue = elem.scaleY ?? 1;
  return [
    {
      property: "opacity",
      value: {
        from: 0,
        to: 1,
        easing: fabric.util.ease.easeOutQuad,
        duration: 600,
      },
    },
    {
      property: "scaleX",
      value: {
        from: scaleXValue / AnimationValueUtil.SCALE_FRACTION,
        to: scaleXValue,
        easing: fabric.util.ease.easeInQuad,
        duration: 600,
      },
    },
    {
      property: "scaleY",
      value: {
        from: scaleYValue / AnimationValueUtil.SCALE_FRACTION,
        to: scaleYValue,
        easing: fabric.util.ease.easeInQuad,
        duration: 600,
      },
    },
  ];
}
