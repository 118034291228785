import { Button, InputAdornment, styled } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import EditableTextElement from "@paperdateco/shared-frontend/canvas/objects/EditableTextElement";
import RemoveIcon from "@mui/icons-material/Remove";
import { useCallback } from "react";
import useLongPress from "@paperdateco/shared-frontend/components/common/hooks/useLongPress";
import useOptionRerender from "@paperdateco/shared-frontend/canvas/hooks/useOptionRerender";

const TextSizeTextField = styled(CustomTextField)({
  ".MuiInputBase-root": {
    padding: 0,
    background: Colors.WHITE,
    maxWidth: 150,
  },
  ".MuiInputBase-input": {
    textAlign: "center",
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  ".MuiInputAdornment-root": {
    height: "unset",
  },
  ".MuiInputAdornment-positionStart": {
    borderRight: `1px solid ${Colors.LIGHT_BORDER}`,
  },
  ".MuiInputAdornment-positionEnd": {
    borderLeft: `1px solid ${Colors.LIGHT_BORDER}`,
  },
});

const TextSizeButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  minWidth: "unset",
}));

interface TextSizeControlProps {
  textElements: EditableTextElement[];
}

export default function TextSizeControl({
  textElements,
}: TextSizeControlProps) {
  useOptionRerender(textElements, "fontSize");

  const fontSize = textElements[0]?.nativeElement.fontSize ?? 0;

  const onChange = useCallback(
    (value: string | number) => {
      textElements.forEach((element) => element.set("fontSize", +value));
    },
    [textElements]
  );

  const reduceFontSize = useCallback(
    () => onChange(fontSize - 1),
    [fontSize, onChange]
  );

  const increaseFontSize = useCallback(
    () => onChange(fontSize + 1),
    [onChange, fontSize]
  );

  const minusLongPressEvents = useLongPress(reduceFontSize, 100);
  const plusLongPressEvents = useLongPress(increaseFontSize, 100);

  return (
    <TextSizeTextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <TextSizeButton {...minusLongPressEvents}>
              <RemoveIcon />
            </TextSizeButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <TextSizeButton {...plusLongPressEvents}>
              <AddIcon />
            </TextSizeButton>
          </InputAdornment>
        ),
      }}
      value={fontSize}
      onChange={onChange}
    />
  );
}
