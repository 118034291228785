import LibraryAnimationDto from "@paperdateco/common/dto/design/library/animation/LibraryAnimationDto";
import LibraryAnimationRequestDto from "@paperdateco/common/dto/design/library/animation/LibraryAnimationRequestDto";
import StoreInstance from "@paperdateco/common/store/StoreInstance";
import { handleApiError } from "@paperdateco/common/utils/ApiUtils";

export default class DesignLibraryAnimationApi {
  public static async getLibraryAnimations() {
    try {
      const animations = await StoreInstance.api().get<LibraryAnimationDto[]>(
        "/design-library/animations"
      );
      return animations.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get library animations");
    }
  }

  public static async updateLibraryAnimation(
    animationId: string,
    data: LibraryAnimationRequestDto
  ) {
    try {
      const animation = await StoreInstance.api().put<LibraryAnimationDto>(
        `/design-library/animations/${animationId}`,
        data
      );
      return animation.data;
    } catch (e) {
      throw handleApiError(e, "Failed to update library animation");
    }
  }

  public static async hideLibraryAnimation(animationId: string) {
    try {
      const animation = await StoreInstance.api().delete<LibraryAnimationDto>(
        `/design-library/animations/${animationId}`
      );
      return animation.data;
    } catch (e) {
      throw handleApiError(e, "Failed to hide library animation");
    }
  }
}
