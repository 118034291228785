import AccountDrafts from "../../account/AccountDrafts";
import AccountLayout from "../../account/AccountLayout";

export default function AccountDraftsPage() {
  return (
    <AccountLayout>
      <AccountDrafts />
    </AccountLayout>
  );
}
