import {
  FontStyleTypes,
  FontWeightTypes,
} from "@paperdateco/common/dto/design/layer/text/DesignTextProperties";

import CanvasElement from "./CanvasElement";
import CustomCanvasObjectProps from "../types/CustomCanvasObjectProps";
import DesignTextPropertiesDto from "@paperdateco/common/dto/design/layer/text/DesignTextPropertiesDto";
import EditableTextOptions from "@paperdateco/common/canvas/objects/EditableTextOptions";
import PathValue from "./paths/PathValue";
import TextPaths from "@paperdateco/common/dto/design/layer/paths/TextPaths";
import { fabric } from "fabric";

export default class EditableTextElement extends CanvasElement<fabric.IText> {
  public textPath?: TextPaths;
  public textPathParams: number[] = [];
  private cloneText?: fabric.IText;
  private cachedOpacity = 1;

  constructor(
    canvas: fabric.Canvas,
    { animation, textPath, textPathParams, ...options }: EditableTextOptions
  ) {
    super(canvas, new fabric.IText(options.text, options));
    this.setAnimation(animation?.animation);
    this.setTextPath(textPath, textPathParams);
    this.listenForTextEdit();
  }

  static convertDesignPropertyToOptions(
    designProperty: DesignTextPropertiesDto
  ): EditableTextOptions {
    return {
      ...super.convertDesignPropertyToOptions(designProperty),
      text: designProperty.text ?? "",
      fill: designProperty.fontColor ?? "#000",
      fontSize: designProperty.fontSize ?? 18,
      fontFamily: designProperty.fontFamily ?? "Roboto",
      fontWeight: designProperty.fontWeight ?? "normal",
      fontStyle: designProperty.fontStyle ?? "normal",
      underline: designProperty.underline ?? false,
      charSpacing: designProperty.letterSpacing ?? 100,
      lineHeight: designProperty.lineSpacing ?? 1,
      textAlign: designProperty.textAlign ?? "left",
      path: EditableTextElement.getTextPath(
        designProperty.textPath,
        designProperty.textPathParams
      ),
      textPath: designProperty.textPath,
      textPathParams: designProperty.textPathParams,
    };
  }

  static getTextPath(textPath?: TextPaths, textPathParams?: number[]) {
    if (!textPath || !textPathParams) {
      return undefined;
    }
    return new fabric.Path(PathValue(textPath, textPathParams), {
      visible: false,
    }) as unknown as string; // Converting to string as fabric js type for path is incorrect
  }

  getDesignLayerProperties = (): DesignTextPropertiesDto => {
    return {
      ...super.getDesignLayerProperties(),
      text: this.nativeElement.text ?? "",
      fontColor: this.nativeElement.fill?.toString(),
      fontSize: this.nativeElement.fontSize,
      fontFamily: this.nativeElement.fontFamily,
      fontWeight:
        this.nativeElement.fontWeight === "bold"
          ? FontWeightTypes.BOLD
          : FontWeightTypes.NORMAL,
      fontStyle:
        this.nativeElement.fontStyle === "italic"
          ? FontStyleTypes.ITALIC
          : FontStyleTypes.NORMAL,
      underline: this.nativeElement.underline,
      letterSpacing: this.nativeElement.charSpacing,
      lineSpacing: this.nativeElement.lineHeight,
      textAlign: this.nativeElement.textAlign,
      textPath: this.textPath,
      textPathParams: this.textPath ? this.textPathParams : undefined,
    };
  };

  setTextPath = (textPath?: TextPaths, pathParams: number[] = [100]) => {
    this.textPath = textPath;
    this.setPathParams(pathParams);
    this.notifyOptionsChange([CustomCanvasObjectProps.TEXT_PATH]);
  };

  setPathParams = (value: number[]) => {
    if (!this.textPath) {
      this.textPathParams = [];
      this.nativeElement.set("path", undefined);
    } else {
      this.textPathParams = value;
      this.nativeElement.set(
        "path",
        EditableTextElement.getTextPath(this.textPath, this.textPathParams)
      );
    }
    this.notifyOptionsChange([CustomCanvasObjectProps.CURVE]);
  };

  private listenForTextEdit() {
    this.nativeElement.on("editing:entered", () => {
      if (this.textPath && !this.cloneText) {
        const textbox = this.getClonedTextbox();
        this.canvas.add(textbox);
        this.canvas.discardActiveObject();
        this.canvas.setActiveObject(textbox);
        this.setPartialVisiblity();

        textbox.onDeselect = () => {
          this.restoreVisibility();
          textbox.exitEditing();
          this.canvas.remove(textbox);
          this.cloneText = undefined;
          return false;
        };
        textbox.enterEditing();
        this.cloneText = textbox;
      }
    });
  }

  private getClonedTextbox() {
    const textbox = new fabric.Textbox(
      this.nativeElement.text ?? "",
      this.nativeElement.toJSON()
    );
    textbox.set("path", undefined);
    textbox.set("opacity", 1);
    textbox.set("width", 300);

    textbox.on("changed", () => {
      this.nativeElement.set("text", textbox.text);
    });
    return textbox;
  }

  private setPartialVisiblity() {
    this.cachedOpacity = this.nativeElement.opacity ?? 1;
    this.nativeElement.set("opacity", 0.3);
  }

  private restoreVisibility() {
    this.nativeElement.set("opacity", this.cachedOpacity);
  }
}
