import { Button, ButtonProps } from "@mui/material";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";

const CustomButton = CustomStyled(Button)(({ theme }) => ({
  padding: theme.spacing(0.5, 2),
  boxShadow: `2px 2px 0px ${Colors.LIGHT_SHADOW}`,
  border: `0.2px solid ${Colors.BLACK}`,
  borderRadius: theme.spacing(0.5),
  background: Colors.WHITE,
  cursor: "pointer",
}));

export default function CustomShadowButton(props: ButtonProps) {
  return <CustomButton {...props}>{props.children}</CustomButton>;
}
