import { Button } from "@mui/material";
import CanvasChooseAnimationSection from "./CanvasChooseAnimationSection";
import CanvasElement from "@paperdateco/shared-frontend/canvas/objects/CanvasElement";
import InviteInstantPreview from "@paperdateco/shared-frontend/components/invite/InviteInstantPreview";
import { useCallback } from "react";
import useMappedAnimationOption from "../useMappedAnimationOptions";

interface CanvasChooseAnimationFormProps<T extends fabric.Object> {
  instantPreview?: InviteInstantPreview;
  elements: CanvasElement<T>[];
}

export default function CanvasChooseAnimationForm<T extends fabric.Object>({
  instantPreview,
  elements,
}: CanvasChooseAnimationFormProps<T>) {
  const animationSections = useMappedAnimationOption();

  const removeAnimation = useCallback(() => {
    elements.forEach((elem) => elem.setAnimation(undefined, true));
    instantPreview?.onPriceUpdate();
  }, [elements, instantPreview]);

  return (
    <>
      {animationSections.map((section) => (
        <CanvasChooseAnimationSection
          key={section.name}
          elements={elements}
          instantPreview={instantPreview}
          section={section}
        />
      ))}
      <Button variant="contained" fullWidth onClick={removeAnimation}>
        Remove Animation
      </Button>
    </>
  );
}
