import BackgroundSelect from "@paperdateco/shared-frontend/components/common/image/BackgroundSelect";
import BannerDto from "@paperdateco/common/dto/banner/BannerDto";
import BannerLayout from "./BannerLayout";
import { Box } from "@mui/material";
import SwiperSlideProps from "@paperdateco/customer/components/common/ui/swiper/SwiperSlideProps";
import WhiteButton from "@paperdateco/customer/components/common/ui/WhiteButton";

export default function BannerWithTextLayout(banner: BannerDto) {
  return ({ isActive }: SwiperSlideProps) => (
    <BannerLayout
      src={banner.bannerImage}
      type={banner.bannerType}
      isActive={isActive}
      width={banner.bannerWidth}
      height={banner.bannerHeight}
    >
      <Box
        width="80%"
        height="100%"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        padding="0 8%"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="32%"
          height="32%"
        >
          <BackgroundSelect
            src={banner.textImage}
            aspectRatio={banner.textWidth / banner.textHeight}
            type={banner.textType}
          />

          <WhiteButton
            variant="contained"
            size="large"
            href={banner.buttonLink}
          >
            {banner.buttonText}
          </WhiteButton>
        </Box>
      </Box>
    </BannerLayout>
  );
}
