import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import CustomShadowButton from "../../common/CustomShadowButton";
import MailerItem from "./MailerItem";
import MailerItemHeader from "./MailerItemHeader";
import MailerWithChooseDto from "../common/MailerWithChooseDto";
import { useCallback } from "react";

const DEFAULT_MAILER: MailerWithChooseDto = {
  name: "",
  email: "",
  phoneNumber: "",
  mailSent: false,
  choosen: false,
};

interface MailerRecipientsProps {
  orderItemId: string;
  mailerList: MailerWithChooseDto[];
  setMailerList: (mailers: MailerWithChooseDto[]) => void;
}

export default function MailerRecipients({
  orderItemId,
  mailerList,
  setMailerList,
}: MailerRecipientsProps) {
  const onAddMailer = useCallback(() => {
    setMailerList([...mailerList, { ...DEFAULT_MAILER }]);
  }, [mailerList, setMailerList]);

  const onUpdateMailer = useCallback(
    (index: number, mailer: MailerWithChooseDto) => {
      const newMailers = [...mailerList];
      if (index < newMailers.length && index >= 0) {
        newMailers[index] = mailer;
      }
      setMailerList(newMailers);
    },
    [mailerList, setMailerList]
  );

  const onRemoveMailer = useCallback(
    (index: number) => {
      const newMailers = [...mailerList];
      newMailers.splice(index, 1);
      setMailerList(newMailers);
    },
    [mailerList, setMailerList]
  );

  const onSelectAllMailer = useCallback(
    (value: boolean) => {
      const newMailers = mailerList.map((mailer) => ({
        ...mailer,
        choosen: value,
      }));
      setMailerList(newMailers);
    },
    [mailerList, setMailerList]
  );

  return (
    <Box marginBottom={3}>
      <Box
        paddingX={1.5}
        marginBottom={2}
        maxHeight={150}
        sx={{ overflowY: "scroll" }}
      >
        <MailerItemHeader
          allSelected={
            mailerList.length > 0 &&
            !mailerList.some((mailer) => !mailer.choosen)
          }
          onSelectAll={onSelectAllMailer}
        />
        {mailerList.map((mailer, index) => (
          <MailerItem
            key={index}
            mailer={mailer}
            index={index}
            onChange={onUpdateMailer}
            onRemove={onRemoveMailer}
            orderItemId={orderItemId}
          />
        ))}
      </Box>
      <Box textAlign="center">
        <CustomShadowButton
          type="button"
          startIcon={<AddIcon />}
          onClick={onAddMailer}
        >
          Add Recipient
        </CustomShadowButton>
      </Box>
    </Box>
  );
}
