import { Box, Stack, Typography } from "@mui/material";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import GreenButton from "../../common/ui/GreenButton";
import useOneTimePopup from "@paperdateco/shared-frontend/components/common/hooks/useOneTimePopup";

const PopupContainer = CustomStyled(Box)({
  backgroundColor: Colors.LIGHT_YELLOW,
});

interface CategoryPopupProps {
  onClose?: () => void;
}

function CategoryPopup({ onClose }: CategoryPopupProps) {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <PopupContainer padding={3} margin={5} width={250} borderRadius={4}>
        <Stack spacing={3} alignItems="center">
          <Typography
            variant="h6"
            component="h2"
            fontWeight="bold"
            textAlign="center"
            fontFamily={Fonts.Playfair}
          >
            PICK A TEMPLATE AND {<br />} START DESIGNING!
          </Typography>
          <Box fontFamily={Fonts.Roboto}>
            <GreenButton size="large" onClick={onClose}>
              LET'S GO!
            </GreenButton>
          </Box>
        </Stack>
      </PopupContainer>
    </Box>
  );
}

export default function CategoryPopupModal() {
  useOneTimePopup("CategoryPopup", CategoryPopup);
  return null;
}
