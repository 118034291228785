import {
  CommonStateDispatchType,
  onLogin,
  onLogout,
} from "@paperdateco/customer/store/common/CommonAction";
import { PropsWithChildren, useCallback } from "react";

import AuthProvider from "@paperdateco/shared-frontend/components/auth/AuthProvider";
import UserDto from "@paperdateco/common/dto/user/UserDto";
import UserUtils from "@paperdateco/customer/utils/UserUtils";
import { useDispatch } from "react-redux";

export default function CustomerAuthProvider({
  children,
}: PropsWithChildren<{}>) {
  const dispatch = useDispatch<CommonStateDispatchType>();

  const onUser = useCallback(
    (user: UserDto) => {
      dispatch(onLogin());
      UserUtils.onUser(user);
    },
    [dispatch]
  );

  const onFail = useCallback(() => {
    dispatch(onLogout());
    UserUtils.onAnonymous();
  }, [dispatch]);

  return (
    <AuthProvider successCallback={onUser} failureCallback={onFail}>
      {children}
    </AuthProvider>
  );
}
