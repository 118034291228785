import CategoryDto from "../dto/category/CategoryDto";
import CategoryWithChildren from "../dto/category/CategoryWithChildrenDto";

type CategoryMap = { [key: string]: CategoryWithChildren };

export default class CategoryUtils {
  public static groupCategories(categories: CategoryDto[]) {
    const parentCategories = categories
      .filter((category) => !category.parent)
      .reduce((ans, category) => {
        ans[category.id] = { ...category, children: [] };
        return ans;
      }, {} as CategoryMap);

    categories
      .filter((category) => category.parent)
      .forEach((category) => {
        parentCategories[category.parent ?? ""]?.children.push(category);
      });

    return Object.values(parentCategories);
  }
}
