import { Button, Typography } from "@mui/material";

interface TextFontButtonProps {
  id: string;
  font: string;
  selected: boolean;
  onClick?: (font: string) => void;
}

export default function TextFontButton({
  id,
  font,
  selected,
  onClick,
}: TextFontButtonProps) {
  return (
    <Button
      id={id}
      key={font}
      variant={selected ? "contained" : "text"}
      onClick={() => onClick?.(font)}
    >
      <Typography key={font} fontFamily={font}>
        {font}
      </Typography>
    </Button>
  );
}
