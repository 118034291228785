import { Box, BoxProps } from "@mui/system";
import { useContext, useEffect, useReducer } from "react";

import AspectRatio from "@paperdateco/shared-frontend/utils/styles/AspectRatio";
import { CanvasPageContext } from "@paperdateco/shared-frontend/canvas/provider/CanvasPageProvider";
import CustomDesignDto from "@paperdateco/common/dto/design/custom/CustomDesignDto";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import { InviteEnvelopeInnerSection } from "@paperdateco/customer/components/common/types/InviteCustomisationSection";
import InvitePaginatedInstantPreview from "@paperdateco/shared-frontend/canvas/controls/page/InvitePaginatedInstantPreview";
import { selectEnvelopeSubSection } from "@paperdateco/customer/store/inviteCustomise/InviteCustomiseSelector";
import { useSelector } from "react-redux";

interface ConditionalBoxProps {
  show: boolean;
}

const ConditionalBox = ({
  show,
  children,
  ...props
}: ConditionalBoxProps & BoxProps) => {
  return (
    <Box {...props} display={show ? props.display : "none"}>
      {children}
    </Box>
  );
};

interface InviteCustomisationInstantPreviewProps {
  design: CustomDesignDto;
}

export default function InviteCustomisationInstantPreview({
  design,
}: InviteCustomisationInstantPreviewProps) {
  const [, rerender] = useReducer((x) => x + 1, 0);
  const envelopeSection = useSelector(selectEnvelopeSubSection);
  const { instantPreview } = useContext(CanvasPageContext);

  useEffect(() => {
    if (!instantPreview) {
      return;
    }

    instantPreview.onEnvelopeChange(rerender);
    return () => instantPreview.removeEnvelopeChangeListener(rerender);
  }, [instantPreview]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflow="auto"
    >
      <ConditionalBox
        show={envelopeSection === undefined}
        width="100%"
        height="100%"
      >
        <InvitePaginatedInstantPreview
          width={design.width}
          height={design.height}
        />
      </ConditionalBox>
      <ConditionalBox
        show={envelopeSection === InviteEnvelopeInnerSection.COVER_ENVELOPE}
        display="block"
      >
        <HiddenImage
          width="100%"
          src={instantPreview?.coverEnvelope?.url}
          aspectRatio={AspectRatio.ENVELOPE}
        />
      </ConditionalBox>
      <ConditionalBox
        show={envelopeSection === InviteEnvelopeInnerSection.FRONT_ENVELOPE}
        display="block"
      >
        <HiddenImage
          width="100%"
          src={instantPreview?.frontEnvelope?.url}
          aspectRatio={AspectRatio.ENVELOPE}
        />
      </ConditionalBox>
      <ConditionalBox
        show={envelopeSection === InviteEnvelopeInnerSection.INNER_ENVELOPE}
        display="block"
      >
        <HiddenImage
          width="100%"
          src={instantPreview?.innerEnvelope?.url}
          aspectRatio={AspectRatio.ENVELOPE}
        />
      </ConditionalBox>
    </Box>
  );
}
