import { Ref, forwardRef } from "react";

import BackgroundImage from "./BackgroundImage";
import { BoxProps } from "@mui/material";
import HiddenImageFit from "./HiddenImageFit";

interface HiddenImageProps {
  src?: string;
  aspectRatio?: number;
  fit?: HiddenImageFit;
}

const getBackgroundSize = (fit: HiddenImageFit): string => {
  switch (fit) {
    case HiddenImageFit.COVER:
      return "cover";
    case HiddenImageFit.STRETCH:
      return "100% 100%";
    case HiddenImageFit.CONTAIN:
      return "contain";
  }
};

function HiddenImage(
  {
    src,
    aspectRatio,
    fit = HiddenImageFit.STRETCH,
    ...props
  }: HiddenImageProps & BoxProps,
  ref?: Ref<unknown>
) {
  const padding = aspectRatio ? 100 / (aspectRatio > 0 ? aspectRatio : 1) : 0;
  if (!src) {
    return null;
  }
  return (
    <BackgroundImage
      display="table-cell"
      image={src}
      paddingBottom={padding + "%"}
      {...props}
      sx={{ backgroundSize: getBackgroundSize(fit), ...props.sx }}
      ref={ref}
    />
  );
}

export default forwardRef(HiddenImage);
