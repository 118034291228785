import { Button, Grid } from "@mui/material";

import { Box } from "@mui/system";
import DesignUtils from "@paperdateco/common/utils/DesignUtils";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import JRDesigns from "@paperdateco/customer/utils/JRDesignUtils";
import { ModalContext } from "@paperdateco/shared-frontend/components/providers/ModalProvider";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import ProductSamplePreview from "./ProductSamplePreview";
import { useContext } from "react";

interface ProductImageSectionWithPrintProps {
  product: ProductDto;
}

export default function ProductImageSectionWithPrint({
  product,
}: ProductImageSectionWithPrintProps) {
  const { open, close } = useContext(ModalContext);

  function onSamplePreview(sample: string) {
    open(<ProductSamplePreview sample={sample} onClose={close} />);
  }
  return (
    <Box width="100%" maxWidth={500} padding={1}>
      <HiddenImage
        src={DesignUtils.getImageFromProduct(product)}
        aspectRatio={product.design.width / product.design.height}
        width="100%"
        display="block"
      />
      <Grid container spacing={2} marginTop={2}>
        {JRDesigns.map((design, index) => (
          <>
            {design.NAME === product.name && (
              <>
                <Grid item xs={4} key={index}>
                  <Button
                    fullWidth
                    onClick={() => onSamplePreview(design.SAMPLE.S1)}
                  >
                    <HiddenImage
                      src={design.SAMPLE.S1}
                      aspectRatio={1200 / 800}
                      width="100%"
                      display="block"
                    />
                  </Button>
                </Grid>
                <Grid item xs={4} key={index}>
                  <Button
                    fullWidth
                    onClick={() => onSamplePreview(design.SAMPLE.S2)}
                  >
                    <HiddenImage
                      src={design.SAMPLE.S2}
                      aspectRatio={1200 / 800}
                      width="100%"
                      display="block"
                    />
                  </Button>
                </Grid>
                <Grid item xs={4} key={index}>
                  <Button
                    fullWidth
                    onClick={() => onSamplePreview(design.SAMPLE.S3)}
                  >
                    <HiddenImage
                      src={design.SAMPLE.S3}
                      aspectRatio={1200 / 800}
                      width="100%"
                      display="block"
                    />
                  </Button>
                </Grid>
                <Grid item xs={4} key={index}>
                  <Button
                    fullWidth
                    onClick={() => onSamplePreview(design.SAMPLE.S4)}
                  >
                    <HiddenImage
                      src={design.SAMPLE.S4}
                      aspectRatio={1200 / 800}
                      width="100%"
                      display="block"
                    />
                  </Button>
                </Grid>
              </>
            )}
          </>
        ))}
      </Grid>
    </Box>
  );
}
