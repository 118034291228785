import CustomDesignPreview from "@paperdateco/customer/components/invite/preview/CustomDesignPreview";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import FullWidthPage from "@paperdateco/shared-frontend/components/layout/FullWidthPage";
import InviteCustomisationHeader from "@paperdateco/customer/components/invite/customisation/layout/InviteCustomisationHeader";
import { useParams } from "react-router";

export default function InvitePreviewPage() {
  const { customDesignId } = useParams<{ customDesignId: string }>();

  if (!customDesignId) {
    return null;
  }

  return (
    <FullWidthPage height="100vh" fontFamily={Fonts.Titillium}>
      <InviteCustomisationHeader step="preview" />
      <CustomDesignPreview customDesignId={customDesignId} />
    </FullWidthPage>
  );
}
