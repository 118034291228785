import { Box, Button, Stack, Typography } from "@mui/material";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import routes from "@paperdateco/customer/routes/routes";

const SaveMessageContainer = CustomStyled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.secondary}`,
}));

export default function InviteRsvpSaveSuccess() {
  return (
    <SaveMessageContainer
      width="90%"
      maxWidth={500}
      marginX="auto"
      marginY={5}
      padding={2}
      borderRadius={2}
    >
      <Stack alignItems="center" spacing={2}>
        <FontAwesomeIcon size="4x" icon={faCheckCircle} color={Colors.GREEN} />
        <Typography variant="h3">Success</Typography>
        <Typography variant="h6">
          Thanks, your response is registered
        </Typography>

        <Link to={routes.HOME}>
          <Button variant="contained">Ok</Button>
        </Link>
      </Stack>
    </SaveMessageContainer>
  );
}
