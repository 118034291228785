import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import CartApi from "@paperdateco/common/api/CartApi";
import CartItemDto from "@paperdateco/common/dto/order/cart/CartItemDto";
import FullWidthPage from "@paperdateco/shared-frontend/components/layout/FullWidthPage";
import InviteCustomisation from "../../invite/customisation/InviteCustomisation";
import InviteCustomisationHeader from "../../invite/customisation/layout/InviteCustomisationHeader";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import routes from "@paperdateco/customer/routes/routes";

export default function CartCustomisePage() {
  const { cartItemId } = useParams<{ cartItemId: string }>();
  const [cartItem, setCartItem] = useState<CartItemDto>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!cartItemId) {
      return;
    }
    CartApi.getCartItem(cartItemId)
      .then(setCartItem)
      .catch(NotificationUtils.showGenericError);
  }, [cartItemId]);

  const onViewPreview = useCallback(() => {
    navigate(routes.CART_ITEM_PREVIEW(cartItemId));
  }, [navigate, cartItemId]);

  return (
    <FullWidthPage height="100vh">
      <InviteCustomisationHeader step="design" />
      {cartItem && (
        <InviteCustomisation
          customDesignId={cartItem.customDesign.id}
          onViewPreview={onViewPreview}
        />
      )}
    </FullWidthPage>
  );
}
