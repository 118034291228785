import { useEffect, useReducer } from "react";

import CanvasElement from "@paperdateco/shared-frontend/canvas/objects/CanvasElement";
import CustomCanvasObjectProps from "../types/CustomCanvasObjectProps";

export default function useOptionRerender<T extends fabric.Object>(
  elements: CanvasElement<T>[],
  prop: keyof T | CustomCanvasObjectProps
) {
  const [, rerender] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    const optionsChangeListener = (keys: string[]) => {
      if (keys.includes(prop as string)) {
        rerender();
      }
    };
    elements.forEach((elem) => elem.addChangeListener(optionsChangeListener));
    return () =>
      elements.forEach((elem) =>
        elem.removeChangeListener(optionsChangeListener)
      );
  }, [elements, prop]);

  return rerender;
}
