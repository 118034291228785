import DesignDto from "../dto/design/DesignDto";
import DesignLibraryApi from "./design/DesignLibraryApi";
import DesignRequestDto from "../dto/design/DesignRequestDto";
import { FileUtils } from "../utils/FileUtils";
import LibraryImageType from "../dto/design/library/image/LibraryImageType";
import PresignedRequestDto from "../dto/common/PresignedRequestDto";
import StoreInstance from "../store/StoreInstance";
import UploadApi from "./UploadApi";
import { handleApiError } from "../utils/ApiUtils";

export default class DesignApi {
  public static async getAll() {
    try {
      const products = await StoreInstance.api().get<DesignDto[]>("/designs");
      return products.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get all designs");
    }
  }

  public static async getMyDesigns() {
    try {
      const products = await StoreInstance.api().get<DesignDto[]>(
        "/designs/me"
      );
      return products.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get my designs");
    }
  }

  public static async addNewDesigns(design: DesignRequestDto) {
    try {
      const products = await StoreInstance.api().post<DesignDto>(
        "/designs",
        design
      );
      return products.data;
    } catch (e) {
      throw handleApiError(e, "Failed to add new design");
    }
  }

  public static async getById(designId: string) {
    try {
      const products = await StoreInstance.api().get<DesignDto>(
        `/designs/${designId}`
      );
      return products.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get design for the product");
    }
  }

  public static async updateById(
    designId: string,
    newDesign: DesignRequestDto
  ) {
    try {
      const products = await StoreInstance.api().put<DesignDto>(
        `/designs/${designId}`,
        newDesign
      );
      return products.data;
    } catch (e) {
      throw handleApiError(e, "Failed to update design for product");
    }
  }

  public static async uploadLibraryImage(
    file: File,
    libraryImageType: LibraryImageType
  ) {
    const url = await DesignApi.uploadMedia(file);
    const libraryImage = await DesignLibraryApi.addUserImage({
      url,
      types: [libraryImageType],
    });
    return libraryImage;
  }

  public static async uploadMedia(file: File) {
    const presignedRequest = await this.getPresignedUrlForNewMedia(
      file.name,
      file.type
    );
    const url = await UploadApi.uploadFile(presignedRequest, file);
    return url;
  }

  private static async getPresignedUrlForNewMedia(name: string, type: string) {
    try {
      const products = await StoreInstance.api().get<PresignedRequestDto>(
        `/designs/media/presigned-url?name=${name}&type=${FileUtils.getUploadType(
          type
        )}`
      );
      return products.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get valid url for uploading file");
    }
  }
}
