import { Box, BoxProps } from "@mui/system";
import { Button, styled } from "@mui/material";

import AspectRatio from "@paperdateco/shared-frontend/utils/styles/AspectRatio";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import { useCallback } from "react";

const ImageContainer = styled(Box)(({ theme }) => ({
  width: 120,
  background: theme.palette.customBackground.light,
  "& button": {
    width: "100%",
    padding: theme.spacing(0.5),
  },
}));

interface InviteImageOptionProps {
  image: LibraryImageDto;
  onChoose?: (image: LibraryImageDto) => void;
}

function InviteImageOption({
  image,
  onChoose,
  ...props
}: InviteImageOptionProps & BoxProps) {
  const onChooseImage = useCallback(() => onChoose?.(image), [onChoose, image]);
  return (
    <ImageContainer
      display="flex"
      flexShrink={0}
      alignItems="center"
      justifyContent="center"
      margin={1}
      {...props}
    >
      <Button onClick={onChooseImage}>
        <HiddenImage
          src={image.url}
          width="100%"
          aspectRatio={AspectRatio.INVITE}
        />
      </Button>
    </ImageContainer>
  );
}

interface InviteImageOptionsPickerProps {
  images: LibraryImageDto[];
  onPickImage?: (image: LibraryImageDto) => void;
}

export default function InviteImageOptionsPicker({
  images,
  onPickImage,
  ...props
}: InviteImageOptionsPickerProps & BoxProps) {
  return (
    <Box display="flex" overflow="auto" alignItems="center" {...props}>
      {images.map((image) => (
        <InviteImageOption
          key={image.id}
          image={image}
          onChoose={onPickImage}
        />
      ))}
    </Box>
  );
}
