import { Box, IconButton, Link, Typography } from "@mui/material";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Icon = CustomStyled(IconButton)({
  background: Colors.BLACK,
  width: 25,
  height: 25,
  borderRadius: "50%",
  "& svg": {
    fontSize: "15px",
  },
  "&:hover": {
    background: Colors.BLACK,
  },
});

export default function FooterSocial() {
  return (
    <Box marginTop={3} textAlign={{ xs: "center", sm: "left" }}>
      <Typography variant="body2">FIND US ONLINE</Typography>
      <Link
        href="https://www.facebook.com/paperdateco/"
        target="_blank"
        marginRight={1}
      >
        <Icon>
          <FontAwesomeIcon color="white" icon={faFacebookF} />
        </Icon>
      </Link>
      <Link href="https://www.instagram.com/paperdateco/" target="_blank">
        <Icon>
          <FontAwesomeIcon color="white" icon={faInstagram} />
        </Icon>
      </Link>
    </Box>
  );
}
