import { Box, BoxProps } from "@mui/system";
import { Container, useMediaQuery, useTheme } from "@mui/material";

import AppFullLogo from "@paperdateco/customer/assets/images/logo/SVG_paper_date_co_logo.svg";
import AppTextLogo from "@paperdateco/customer/assets/images/logo/SVG_paper_date_co_Text_logo.svg";
import AspectRatio from "@paperdateco/shared-frontend/utils/styles/AspectRatio";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import HeaderAuthContainer from "./HeaderAuthContainer/HeaderAuthContainer";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import HorizontalHeaderMenu from "./HorizontalMenu/HorizontalHeaderMenu";
import { Link } from "react-router-dom";
import VerticalHeaderMenu from "./VerticalMenu/VerticalHeaderMenu";
import routes from "@paperdateco/customer/routes/routes";
import useScrollExceed from "@paperdateco/shared-frontend/components/common/hooks/useScrollExceed";

const NavContainer = CustomStyled(Box)({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundColor: Colors.WHITE,
});

interface HeaderProps {
  backgroundImage?: string;
}

function AppLogo() {
  const hasScrolled = useScrollExceed(300);
  const logo = hasScrolled ? AppTextLogo : AppFullLogo;
  const aspectRatio = hasScrolled
    ? AspectRatio.TEXT_LOGO
    : AspectRatio.FULL_LOGO;
  return (
    <HiddenImage
      src={logo}
      width={100}
      aspectRatio={aspectRatio}
      display="block"
    />
  );
}

export default function Header({
  backgroundImage,
  ...props
}: HeaderProps & BoxProps) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <NavContainer
      {...props}
      sx={{
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : "none",
        ...props.sx,
      }}
    >
      <Container maxWidth="xl">
        <Box
          display="flex"
          alignItems="flex-end"
          paddingTop={1}
          paddingBottom={3}
        >
          <Box>
            <Link to={routes.HOME}>
              <AppLogo />
            </Link>
          </Box>
          <Box
            flexGrow={1}
            display="flex"
            flexDirection={{ xs: "row", md: "column" }}
            alignItems="center"
          >
            <Box
              marginX={2}
              display="flex"
              flexGrow={1}
              justifyContent="flex-end"
              alignSelf="flex-end"
            >
              <HeaderAuthContainer />
            </Box>
            {isLargeScreen ? <HorizontalHeaderMenu /> : <VerticalHeaderMenu />}
          </Box>
        </Box>
      </Container>
    </NavContainer>
  );
}
