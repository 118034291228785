import { ToggleButton, styled } from "@mui/material";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";

const TextPathOptionIcon = styled(ToggleButton)(({ theme }) => ({
  color: Colors.PRIMARY_900,
  padding: theme.spacing(3),
  "&.MuiToggleButtonGroup-grouped": {
    "&:not(:last-of-type), &:last-of-type": {
      margin: theme.spacing(0.5),
      border: `1px solid ${Colors.LIGHT_BORDER}`,
      borderRadius: 3,
    },
  },
  "&:hover, &.Mui-selected, &.Mui-selected:hover": {
    color: theme.palette.primary.main,
    background: Colors.WHITE,
  },
}));

export default TextPathOptionIcon;
