export const FreeFonts = [
  "Arial",
  "Serif",
  "Sans-serif",
  "AbrilFatface-Regular",
  "Cursive",
  "Roboto Condensed",
  "Open Sans",
];

export const PaidFonts = [
  "Monospace",
  "Fantasy",
  "Poppins",
  "Afanan",
  "Airfool",
  "Antigua Presidente",
  "AutumnSpice-Regular",
  "BaksoSapi",
  "Barrio-Regular",
  "Beth-Ellen",
  "BNRomaine",
  "Boheme Floral",
  "BreeSerif-Regular",
  "Caveat-Bold",
  "CaviarDreams-Bold",
  "CaviarDreams",
  "CedarvilleCursive-Regular",
  "Cornish-Pasty",
  "Dolato de Stato",
  "FUTRFW",
  "Gin¢ra Sans Bold",
  "Gin¢ra Sans Regular",
  "Gin¢ra Sans Semi Bold Oblique",
  "HomemadeApple-Regular",
  "Kintsukuroi",
  "Kristi-Regular",
  "Le-Super-Type-SemiBold",
  "Lumiera",
  "LumieraHandwriting",
  "MANIFESTO",
  "Montclar",
  "Montserrat-Bold",
  "Montserrat-Italic",
  "Montserrat-Light",
  "Montserrat-Medium",
  "Montserrat-Regular",
  "Montserrat-SemiBold",
  "Next-Southerland-Serif",
  "Oswald-Bold",
  "Oswald-Demi-BoldItalic",
  "PlayfairDisplay-Regular",
  "PlayfairDisplay-Bold",
  "Quicksand-Bold",
  "QUIRKYSPRING",
  "Roboto-Regular",
  "Roboto-Medium",
  "Roxers Rough",
  "Sunnyside",
  "Vanderful",
  "Ventura Edding",
  "Ventura Edding Bold",
  "Yadon Bold",
  "Yeti Bold",
  "Alvania",
  "Acumin Variable",
  "Acumin",
  "Aileron Black",
  "Andina",
  "Aquarion",
  "Aromabar",
  "Autumn in November",
  "BASKVILL",
  "BDN4",
  "Beau",
  "Bell MT",

  // Other fonts
  "Big Shoulders Display",
  "Mukta",
  "Oswald",
  "Roboto Mono",
  "Yellowtail",
  "Raleway",
  "Ubuntu",
  "PT Sans",
  "Cedarville Cursive",
  "Josefin Sans",
  "Lobster",
  "Arizonia",
  "Rouge Script",
  "Pacifico",
  "Alex Brush",
  "Kaushan Script",
  "Sail",
  "Sacramento",
  "Marck Script",
  "Sonsie One",
  "Monsieur La Doulaise",
  "Rock Salt",
  "Rochester",
  "Rancho",
  "Croissant One",
  "Yesteryear",
  "Courgette",
  "Sofia",
  "Allura",
  "Redressed",
  "Cookie",
  "Amita",
  "Meie Script",
  "Euphoria Script",
  "Tangerine",
  "Sevillana",
  "Lora",
  "Rubik",
  "Recursive",
  "Karla",
  "Abhaya Libre",
  "Merriweather",
  "Alegreya",
  "Aleo",
  "Mulish",
  "Arapey",
  "Nunito",
  "Asap Condensed",
  "Assistant",
  "Barlow",
  "Bitter",
  "Brawler",
  "Caladea",
  "Rokkitt",
  "Carme",
  "Encode Sans Semi Condensed",
  "Enriqueta",
  "Frank Ruhl Libre",
  "Spectral",
  "Work Sans",
  "Gelasio",
  "Headland One",
  "Proza Libre",
  //added 4 march 2022
  "All-Pony",
  "Belgrado",
  "Bangers-Regular",
  "BrittanySignature",
  "BitterSweet",
  "Candy-cane",
  "BukhariScript-Regular",
  "BroadwayEvent",
  "GreatVibes-Regular",
  "CloisterBlack",
  "Kr-cane-letters-Regular",
  "Herr-von-muellerhoff-Regular",
  "Hermaiona",
  "Parisienne-Regular",
  "Nickainley-Normal",
  "Ponsonbynf-Regular",
  "Pentagon",
  "Peaches",
  "Quentincaps-Regular",
  "Romantiques",
  "RioGrande",
  "Rye-Regular",
  "Shintosa",
  "Shrikhand-Regular",
  "Showboat-Regular",
  "Wicked-Seventies-Font",
  "Mokoto-Glitch-Mark-Outline",
  "Vtks-Blacqui-Latter",
  "MonteCarlo",
  "ImperialScript-Regular",
  "PinyonScript-Regular",
  "MountainsofChristmas-Regular",
  "RumRaisin-Regular",
  "WindSong-Medium",
  "CinzelDecorative-Bold",
  "CinzelDecorative-Regular",
  "Quicksand-Regular",
  "LuxuriousScript-Regular",
  "TwinkleStar-Regular",
];
