import { useCallback, useContext } from "react";

import { Box } from "@mui/system";
import { CanvasPageContext } from "@paperdateco/shared-frontend/canvas/provider/CanvasPageProvider";
import CustomDesignDto from "@paperdateco/common/dto/design/custom/CustomDesignDto";
import InviteEnvelopeChooseSection from "./InviteEnvelopeChooseSection";
import { InviteEnvelopeInnerSection } from "@paperdateco/customer/components/common/types/InviteCustomisationSection";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";
import { setInviteEnvelopeCustomiseSubSection } from "@paperdateco/customer/store/inviteCustomise/InviteCustomiseAction";
import { useDispatch } from "react-redux";

interface InviteEnvelopeSectionProps {
  design: CustomDesignDto;
}

export default function InviteEnvelopeSection({
  design,
}: InviteEnvelopeSectionProps) {
  const { instantPreview } = useContext(CanvasPageContext);
  const dispatch = useDispatch();

  const onChooseCoverEnvelope = useCallback(
    (image: LibraryImageDto) => {
      instantPreview?.setCoverEnvelope(image);
      dispatch(
        setInviteEnvelopeCustomiseSubSection(
          InviteEnvelopeInnerSection.COVER_ENVELOPE
        )
      );
    },
    [dispatch, instantPreview]
  );

  const onChooseFrontEnvelope = useCallback(
    (image: LibraryImageDto) => {
      instantPreview?.setFrontEnvelope(image);
      dispatch(
        setInviteEnvelopeCustomiseSubSection(
          InviteEnvelopeInnerSection.FRONT_ENVELOPE
        )
      );
    },
    [dispatch, instantPreview]
  );

  const onChooseInnerEnvelope = useCallback(
    (image: LibraryImageDto) => {
      instantPreview?.setInnerEnvelope(image);
      dispatch(
        setInviteEnvelopeCustomiseSubSection(
          InviteEnvelopeInnerSection.INNER_ENVELOPE
        )
      );
    },
    [dispatch, instantPreview]
  );

  return (
    <Box paddingY={3} maxWidth={300}>
      <InviteEnvelopeChooseSection
        title="Cover Envelope"
        description="Select the cover of your envelope."
        type={LibraryImageType.COVER_ENVELOPE}
        designId={design.design.id}
        onChooseImage={onChooseCoverEnvelope}
        onClose={() => {}}
      />
      <InviteEnvelopeChooseSection
        title="Front Envelope"
        description="Select the front design of your envelope."
        type={LibraryImageType.FRONT_ENVELOPE}
        designId={design.design.id}
        onChooseImage={onChooseFrontEnvelope}
        onClose={() => {}}
      />
      <InviteEnvelopeChooseSection
        title="Inner Envelope"
        type={LibraryImageType.INNER_ENVELOPE}
        designId={design.design.id}
        onChooseImage={onChooseInnerEnvelope}
        onClose={() => {}}
      />
    </Box>
  );
}
