import { ChangeEvent, Dispatch, SetStateAction, useCallback } from "react";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

import CustomDateTimePicker from "@paperdateco/shared-frontend/components/common/form/date/CustomDateTimePicker";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import FormRow from "@paperdateco/shared-frontend/components/common/form/FormRow";
import PrintedInvitationDto from "@paperdateco/common/dto/form/contactUs/printedInvitation/PrintedInvitationDto";
import produce from "immer";

interface PrintedInvitationsFormProps {
  printedInvitationForm: PrintedInvitationDto;
  setPrintedInvitationForm: Dispatch<SetStateAction<PrintedInvitationDto>>;
}
export default function PrintedInvitationsForm({
  printedInvitationForm,
  setPrintedInvitationForm,
}: PrintedInvitationsFormProps) {
  const onChangeQuantity = useCallback(
    (value: string) =>
      setPrintedInvitationForm(
        produce((draft) => {
          draft.quantity = Number(value);
        })
      ),
    [setPrintedInvitationForm]
  );

  const onChangeInserts = useCallback(
    (value: string) =>
      setPrintedInvitationForm(
        produce((draft) => {
          draft.inserts = Number(value);
        })
      ),
    [setPrintedInvitationForm]
  );

  const onChangeFolder = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setPrintedInvitationForm(
        produce((draft) => {
          draft.folder = (event.target as HTMLInputElement).value;
        })
      ),
    [setPrintedInvitationForm]
  );

  const handleDate = useCallback(
    (value?: Date) => {
      if (!value) {
        return;
      }
      setPrintedInvitationForm(
        produce((draft) => {
          draft.deliveryDate = value;
        })
      );
    },
    [setPrintedInvitationForm]
  );

  const onChangeDeliveryLocation = useCallback(
    (value: string) => {
      setPrintedInvitationForm(
        produce((draft) => {
          draft.deliveryLocation = value;
        })
      );
    },
    [setPrintedInvitationForm]
  );

  return (
    <>
      <FormRow>
        <CustomTextField
          label="Quantity"
          type="number"
          required={printedInvitationForm.quantity > 0}
          value={printedInvitationForm.quantity}
          onChange={onChangeQuantity}
        />
      </FormRow>
      <FormRow>
        <CustomTextField
          label="Number of Inserts"
          type="number"
          required={printedInvitationForm.inserts > 0}
          value={printedInvitationForm.inserts}
          onChange={onChangeInserts}
        />
      </FormRow>
      <FormRow>
        <FormLabel>Folder</FormLabel>
        <RadioGroup
          row
          value={printedInvitationForm.folder}
          onChange={onChangeFolder}
        >
          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="No" control={<Radio />} label="No" />
        </RadioGroup>
      </FormRow>
      <CustomDateTimePicker
        label="Delivery Date and Time"
        inputFormat="dd/MM/yyyy h:mm a"
        value={printedInvitationForm.deliveryDate}
        onChange={handleDate}
      />
      <FormRow>
        <CustomTextField
          label="Delivery Location"
          multiline
          rows={4}
          required={false}
          value={printedInvitationForm.deliveryLocation}
          onChange={onChangeDeliveryLocation}
        />
      </FormRow>
    </>
  );
}
