import { Button, FormGroup, FormLabel } from "@mui/material";
import { FormEvent, useEffect, useState } from "react";

import CustomCheckbox from "@paperdateco/shared-frontend/components/common/form/CustomCheckbox";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import DefaultGiftingForm from "./DefaultGiftingForm";
import DefaultPrintedInvitationForm from "./DefaultPrintedInvitationForm";
import FormRow from "@paperdateco/shared-frontend/components/common/form/FormRow";
import GiftingDto from "@paperdateco/common/dto/form/contactUs/gifting/GiftingDto";
import GiftingForm from "./GiftingForm";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import PrintedInvitationDto from "@paperdateco/common/dto/form/contactUs/printedInvitation/PrintedInvitationDto";
import PrintedInvitationsForm from "./PrintedInvitationsForm";
import RequestFormApi from "@paperdateco/common/api/RequestFormApi";

interface ContactUsFormProps {
  printedInvitationForm?: PrintedInvitationDto;
  giftingForm?: GiftingDto;
  updateForm?: boolean;
}

export default function ContactUsForm({
  printedInvitationForm: printedInvitationFormProp,
  giftingForm: giftingFormProp,
  updateForm,
}: ContactUsFormProps) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [digital, setDigital] = useState(false);
  const [printed, setPrinted] = useState(false);
  const [gifting, setGifting] = useState(false);
  const [printedInvitationForm, setPrintedInvitationForm] = useState({
    ...DefaultPrintedInvitationForm,
  });
  const [giftingForm, setGiftingForm] = useState({ ...DefaultGiftingForm });

  useEffect(() => {
    setPrintedInvitationForm(
      printedInvitationFormProp ?? { ...DefaultPrintedInvitationForm }
    );
    setGiftingForm(giftingFormProp ?? { ...DefaultGiftingForm });
  }, [printedInvitationFormProp, giftingFormProp, updateForm]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await RequestFormApi.customerRequest({
        name,
        email: email.toLowerCase(),
        contact: Number(contact),
        message,
        digitalInvitations: digital,
        printedInvitations: printed,
        gifting,
        printedInvitationForm,
        giftingForm,
      });
      NotificationUtils.showSuccess("Contact us Form Submission successful");
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormRow>
        <CustomTextField label="Full Name" value={name} onChange={setName} />
      </FormRow>
      <FormRow>
        <CustomTextField
          label="Email Address"
          type="email"
          value={email}
          onChange={setEmail}
        />
      </FormRow>
      <FormRow>
        <CustomTextField
          label="Contact Number"
          type="tel"
          value={contact}
          onChange={setContact}
        />
      </FormRow>
      <FormRow>
        <CustomTextField
          label="Your Message"
          value={message}
          onChange={setMessage}
        />
      </FormRow>
      <FormLabel>What are you looking for ?</FormLabel>
      <FormGroup>
        <CustomCheckbox
          checked={digital}
          onChange={setDigital}
          name="digital"
          label="Digital invitations"
        />
        <CustomCheckbox
          checked={printed}
          onChange={setPrinted}
          name="printed"
          label="Printed invitations"
        />
        {printed && (
          <PrintedInvitationsForm
            printedInvitationForm={printedInvitationForm}
            setPrintedInvitationForm={setPrintedInvitationForm}
          />
        )}
        <CustomCheckbox
          checked={gifting}
          onChange={setGifting}
          name="gifting"
          label="Gifting"
        />
      </FormGroup>
      {gifting && (
        <GiftingForm
          giftingForm={giftingForm}
          setGiftingForm={setGiftingForm}
        />
      )}

      <FormRow textAlign="center">
        <Button type="submit" variant="contained" size="large">
          SUBMIT
        </Button>
      </FormRow>
    </form>
  );
}
