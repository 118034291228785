import { Box } from "@mui/system";
import CustomDesignDto from "@paperdateco/common/dto/design/custom/CustomDesignDto";
import DownloadUtils from "@paperdateco/common/utils/DownloadUtils";
import InvitePreviewCanvas from "../InvitePreviewCanvas";
import { useCallback } from "react";

interface InvitationAnimationDownloadProps {
  customDesign: CustomDesignDto;
  onComplete: () => void;
}

export default function InvitationAnimationDownload({
  customDesign,
  onComplete,
}: InvitationAnimationDownloadProps) {
  const onRecordComplete = useCallback(
    (url) => {
      DownloadUtils.save(url, "download.webm");
      onComplete();
    },
    [onComplete]
  );

  return (
    <Box display="none">
      <InvitePreviewCanvas
        width={1280}
        height={720}
        customDesign={customDesign}
        shouldRecord={true}
        onRecordComplete={onRecordComplete}
      />
    </Box>
  );
}
