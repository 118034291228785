import CollaborateFormDto from "../dto/form/collaborate/CollaborateFormDto";
import CollaborateRequestDto from "../dto/form/collaborate/CollaborateRequestDto";
import CustomerFormDto from "../dto/form/contactUs/CustomerFormDto";
import CustomerRequestDto from "../dto/form/contactUs/CustomerRequestDto";
import ProductPrintFormDto from "../dto/form/productPrint/ProductPrintFormDto";
import ProductPrintRequestDto from "../dto/form/productPrint/ProductPrintRequestDto";
import StoreInstance from "../store/StoreInstance";
import { handleApiError } from "../utils/ApiUtils";

export default class RequestFormApi {
  public static collaborateRequest = async (
    collaborateFormDto: CollaborateRequestDto
  ) => {
    try {
      const collaborateForm =
        await StoreInstance.api().post<CollaborateFormDto>(
          "/form/collaborate",
          collaborateFormDto
        );
      return collaborateForm.data;
    } catch (e) {
      throw handleApiError(e, "Failed to create Collaborate Request Form");
    }
  };

  public static customerRequest = async (
    customerFormDto: CustomerRequestDto
  ) => {
    try {
      const customerForm = await StoreInstance.api().post<CustomerFormDto>(
        "/form/customer",
        customerFormDto
      );
      return customerForm.data;
    } catch (e) {
      throw handleApiError(e, "Failed to create Customer Request Form");
    }
  };

  public static productPrintRequest = async (
    productPrintFormDto: ProductPrintRequestDto
  ) => {
    try {
      const productPrintForm =
        await StoreInstance.api().post<ProductPrintFormDto>(
          "/form/productPrint",
          productPrintFormDto
        );
      return productPrintForm.data;
    } catch (e) {
      throw handleApiError(e, "Failed to create Product Print Request Form");
    }
  };
}
