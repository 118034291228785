import AnimationValue from "./AnimationValue";
import AnimationValueUtil from "./AnimationValueUtil";
import { fabric } from "fabric";

const scale = AnimationValueUtil.SCALE_FRACTION * 5;
const easing = fabric.util.ease.easeOutQuad;
const duration = 600;

export default function StompAnimation(elem: fabric.Object): AnimationValue[] {
  AnimationValueUtil.setCenteredOriginForZoom(elem);
  const scaleXValue = elem.scaleX ?? 1;
  const scaleYValue = elem.scaleY ?? 1;
  return [
    {
      property: "opacity",
      value: {
        from: 0,
        to: 1,
        easing,
        duration,
      },
    },
    {
      property: "scaleX",
      value: {
        from: scaleXValue * scale,
        to: scaleXValue,
        easing,
        duration,
      },
    },
    {
      property: "scaleY",
      value: {
        from: scaleYValue * scale,
        to: scaleYValue,
        easing,
        duration,
      },
    },
  ];
}
