import AppLogo from "@paperdateco/customer/assets/images/logo/logo.png";
import CanvasBackground from "@paperdateco/customer/assets/images/canvasbg.jpeg";
import CanvasUtils from "@paperdateco/shared-frontend/canvas/CanvasUtils";
import DownloadUtils from "@paperdateco/common/utils/DownloadUtils";

interface PositionType {
  top: number;
  left: number;
  width: number;
}

export default class StaticInvitation {
  constructor(
    private canvas: fabric.Canvas,
    private width: number,
    private height: number
  ) {}

  async addBackgroundImage(background = CanvasBackground) {
    const backgroundImage = await CanvasUtils.loadImage(background);
    const scale = CanvasUtils.scaleImageAsCover(
      backgroundImage,
      this.width,
      this.height
    );

    this.canvas.setBackgroundImage(
      backgroundImage,
      () => CanvasUtils.reRender(this.canvas),
      {
        top: scale.top,
        left: scale.left,
        originX: "left",
        originY: "top",
        scaleX: scale.scaleFactor,
        scaleY: scale.scaleFactor,
      }
    );
  }

  async addImage(image: string, { width, left, top }: PositionType) {
    const imageBlob = await DownloadUtils.get(image);
    const imageInCanvas = await CanvasUtils.loadImage(imageBlob, {
      width,
      left,
      top,
    });
    this.canvas.add(imageInCanvas);
  }

  async addStamp({ width, left, top }: PositionType) {
    const stamp = await CanvasUtils.loadImage(AppLogo, {
      width,
      left,
      top,
    });
    this.canvas.add(stamp);
  }

  async waitForImageLoad(duration = 2000) {
    await new Promise((res) => setTimeout(res, duration));
  }
}
