import { Button, styled } from "@mui/material";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";

const BlackButton = styled(Button)({
  backgroundColor: Colors.BLACK,
  color: Colors.WHITE,
  "&:hover": {
    backgroundColor: Colors.WHITE,
    color: Colors.BLACK,
  },
});

export default BlackButton;
