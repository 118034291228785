import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import CartItemDto from "@paperdateco/common/dto/order/cart/CartItemDto";
import CartItemRow from "./CartItemRow";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";

const ProductContainer = CustomStyled(Box)({
  border: `1px solid ${Colors.LIGHT_BORDER}`,
});

const PriceCell = CustomStyled(TableCell)({
  minWidth: 300,
});

interface CartProductListProps {
  cartItems: CartItemDto[];
  onRemoveItem: (cartItemId: string) => void;
}

export default function CartProductList({
  cartItems,
  onRemoveItem,
}: CartProductListProps) {
  return (
    <ProductContainer marginBottom={2} overflow="auto">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h5">Product</Typography>
            </TableCell>
            <PriceCell>
              <Typography variant="h5">Price</Typography>
            </PriceCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cartItems.map((cartItem, index) => (
            <CartItemRow
              key={index}
              cartItem={cartItem}
              onDelete={onRemoveItem}
            />
          ))}
        </TableBody>
      </Table>
    </ProductContainer>
  );
}
