import { Button, Grid } from "@mui/material";
import { FormEvent, useState } from "react";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import RequestFormApi from "@paperdateco/common/api/RequestFormApi";

const SubmitButton = CustomStyled(Button)({
  background: Colors.ORANGE,
  "&:hover": {
    background: Colors.ORANGE,
  },
});

export default function CollaborateForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [portfolio, setPortfolio] = useState("");

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await RequestFormApi.collaborateRequest({
        name,
        email: email.toLowerCase(),
        contact: contact,
        portfolio,
      });
      NotificationUtils.showSuccess("Request Submission successful");
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} rowSpacing={2} padding={2}>
        <Grid item xs={12} md={6}>
          <CustomTextField label="Full Name" value={name} onChange={setName} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            label="Email"
            type="email"
            value={email}
            onChange={setEmail}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            label="Contact Number"
            type="tel"
            value={contact}
            onChange={setContact}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            label="Portfolio URL"
            type="url"
            value={portfolio}
            onChange={setPortfolio}
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <SubmitButton type="submit" variant="contained" size="medium">
            SUBMIT
          </SubmitButton>
        </Grid>
      </Grid>
    </form>
  );
}
