import { Box } from "@mui/system";
import { Button } from "@mui/material";
import DesignApi from "@paperdateco/common/api/DesignApi";
import DesignImageType from "@paperdateco/common/dto/design/layer/image/DesignImageType";
import InviteInstantPreview from "@paperdateco/shared-frontend/components/invite/InviteInstantPreview";
import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";
import { fabric } from "fabric";
import { useCallback } from "react";
import useFileUpload from "../../common/hooks/useFileUpload";

const DefaultImage: fabric.IImageOptions = {
  top: 30,
  left: 30,
  width: 300,
};
interface DesignEditUploadSectionProps {
  instantPreview?: InviteInstantPreview;
}

export default function DesignEditUploadSection({
  instantPreview,
}: DesignEditUploadSectionProps) {
  const onUpload = useCallback(
    async (file: File) => {
      const isGif = file.type?.toLowerCase().includes("gif");
      const libraryImageType = isGif
        ? LibraryImageType.GIF
        : LibraryImageType.STICKER;
      const image = await DesignApi.uploadLibraryImage(file, libraryImageType);
      const type = isGif ? DesignImageType.GIF : DesignImageType.IMAGE;
      await instantPreview?.addImage({
        ...DefaultImage,
        type: type,
        image,
      });
    },
    [instantPreview]
  );

  const onAddImage = useFileUpload({ onUpload });

  return (
    <Box
      minWidth={300}
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding={3}
    >
      <Button variant="contained" onClick={onAddImage}>
        UPLOAD YOUR MEDIA
      </Button>
    </Box>
  );
}
