import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  WishlistStateDispatchType,
  getAllWishLists,
} from "@paperdateco/customer/store/wishList/WishListAction";
import { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AuthContext } from "@paperdateco/shared-frontend/components/auth/AuthProvider";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import WishListItemRow from "./WishListItemRow";
import { selectWishLists } from "@paperdateco/customer/store/wishList/WishListSelector";

const WishListContainer = CustomStyled(Box)({
  border: `1px solid ${Colors.LIGHT_BORDER}`,
  width: "100%",
});

export default function WishListProductList() {
  const dispatch = useDispatch<WishlistStateDispatchType>();
  const { user } = useContext(AuthContext);
  const wishList = useSelector(selectWishLists) ?? [];

  const getWishList = useCallback(() => {
    if (user) {
      dispatch(getAllWishLists(true));
    }
  }, [dispatch, user]);

  useEffect(() => {
    getWishList();
  }, [getWishList]);

  const products = wishList.map((list) => list.product);

  if (products.length === 0) {
    return <Typography variant="h6">Your WishList is Empty</Typography>;
  }

  return (
    <WishListContainer marginBottom={2} overflow="auto">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h5">Product</Typography>
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <Typography variant="h5">Unit Price</Typography>
            </TableCell>
            <TableCell colSpan={2}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <WishListItemRow
              key={product.id}
              product={product}
              onRemoveProduct={getWishList}
            />
          ))}
        </TableBody>
      </Table>
    </WishListContainer>
  );
}
