import { ToastContainer, toast } from "react-toastify";
import { applyMiddleware, createStore } from "redux";

import Api from "@paperdateco/shared-frontend/utils/Api";
import AppRouter from "./routes/AppRouter";
import CssBaseline from "@mui/material/CssBaseline";
import CustomLocalizationProvider from "@paperdateco/shared-frontend/components/common/CustomLocalizationProvider";
import CustomerAuthProvider from "./components/provider/CustomerAuthProvider";
import DefaultTheme from "./utils/styles/DefaultTheme";
import GtmUtils from "./utils/GtmUtils";
import MessageProvider from "../../shared-frontend/src/components/providers/MessageProvider";
import ModalProvider from "@paperdateco/shared-frontend/components/providers/ModalProvider";
import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";
import StoreInstance from "@paperdateco/common/store/StoreInstance";
import { ThemeProvider } from "@mui/material";
import rootReducer from "./store";

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

StoreInstance.setApi(Api);
StoreInstance.setToast(() => toast);

GtmUtils.init();

function App() {
  return (
    <ThemeProvider theme={DefaultTheme}>
      <CssBaseline />
      <Provider store={store}>
        <CustomerAuthProvider>
          <ModalProvider>
            <MessageProvider>
              <CustomLocalizationProvider>
                <AppRouter />
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar
                  draggable={false}
                  closeOnClick={false}
                />
              </CustomLocalizationProvider>
            </MessageProvider>
          </ModalProvider>
        </CustomerAuthProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
