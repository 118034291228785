import { Grid, GridProps } from "@mui/material";

import CategoryProductItem from "../category/CategoryProductItem";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";

interface ProductListProps {
  products: ProductDto[];
  itemProps?: GridProps;
}

export default function ProductList({ products, itemProps }: ProductListProps) {
  return (
    <Grid
      container
      direction="row"
      alignItems="flex-end"
      justifyContent="flex-start"
      spacing={4}
    >
      {products.map((product) => (
        <Grid item xs={12} sm={6} md={4} xl={3} key={product.id} {...itemProps}>
          <CategoryProductItem product={product} />
        </Grid>
      ))}
    </Grid>
  );
}
