import BreadCrumbLink from "../../common/breadcrumbs/BreadCrumbLink";
import Breadcrumbs from "../../common/breadcrumbs/Breadcrumbs";
import Layout from "../../layout/Layout";
import WishListBody from "./WishListBody";
import routes from "@paperdateco/customer/routes/routes";

const breadcrumbLink: BreadCrumbLink[] = [
  {
    title: "Home",
    link: routes.HOME,
  },
  {
    title: "WishList",
    link: routes.WISHLIST,
  },
];

export default function WishListPage() {
  return (
    <Layout>
      <Breadcrumbs links={breadcrumbLink} />
      <WishListBody />
    </Layout>
  );
}
