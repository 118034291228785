import { Button, Grid, Typography } from "@mui/material";
import { FormEvent, useContext, useEffect, useState } from "react";

import AccountBillingDetails from "./AccountBillingDetails";
import { AuthContext } from "@paperdateco/shared-frontend/components/auth/AuthProvider";
import CustomCheckbox from "@paperdateco/shared-frontend/components/common/form/CustomCheckbox";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import UserApi from "@paperdateco/common/api/UserApi";
import UserUpdateRequestDto from "@paperdateco/common/dto/user/UserUpdateRequestDto";

export default function AccountDetails() {
  const { user, updateAuth } = useContext(AuthContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [updatePassword, setUpdatePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName ?? "");
      setLastName(user.lastName ?? "");
    }
  }, [user]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const data: UserUpdateRequestDto = {
      firstName,
      lastName,
      updatePassword,
      oldPassword,
      newPassword,
    };
    try {
      await UserApi.updateUser(data);
      await updateAuth();
      setOldPassword("");
      setNewPassword("");
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  return (
    <div>
      <Typography variant="h5">Account Details</Typography>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2} marginY={3}>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              label="First Name"
              value={firstName}
              onChange={setFirstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              label="Last Name"
              value={lastName}
              onChange={setLastName}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              disabled
              label="Email Address"
              value={user?.email ?? ""}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomCheckbox
              value={updatePassword}
              onChange={setUpdatePassword}
              label="Update Password"
            />
          </Grid>
          {updatePassword && (
            <>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  required={updatePassword}
                  type="password"
                  label="Old Password"
                  value={oldPassword}
                  onChange={setOldPassword}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  required={updatePassword}
                  type="password"
                  label="New Password"
                  value={newPassword}
                  onChange={setNewPassword}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Button type="submit" variant="contained" size="large">
          {updatePassword ? "Change Password" : "Save Changes"}
        </Button>
      </form>
      <AccountBillingDetails />
    </div>
  );
}
