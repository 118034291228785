import { ButtonBase, styled } from "@mui/material";

import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import HiddenImageFit from "@paperdateco/shared-frontend/components/common/image/HiddenImageFit";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import LibraryImageRequestDto from "@paperdateco/common/dto/design/library/image/LibraryImageRequestDto";
import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";
import PremiumItemIcon from "@paperdateco/shared-frontend/components/common/PremiumItemIcon";
import { useCallback } from "react";

const CustomButton = styled(ButtonBase)({
  position: "relative",
});

interface LibraryImageListItemProps {
  type: LibraryImageType;
  item: LibraryImageDto | LibraryImageRequestDto;
  width?: number;
  aspectRatio: number;
  onChoose?: (type: LibraryImageType, item: LibraryImageDto) => void;
}

export default function LibraryImageListItem({
  type,
  item,
  width = 150,
  aspectRatio,
  onChoose,
}: LibraryImageListItemProps) {
  const handleClick = () => {
    onClick(type, item);
  };

  const onClick = useCallback(
    (type, item) => onChoose?.(type, item),
    [onChoose]
  );

  return (
    <CustomButton onClick={handleClick}>
      <HiddenImage
        src={item.url}
        width={width}
        aspectRatio={aspectRatio}
        fit={HiddenImageFit.CONTAIN}
      />
      {item.price > 0 && <PremiumItemIcon />}
    </CustomButton>
  );
}
