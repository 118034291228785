import { Box, BoxProps } from "@mui/system";

import AlignmentButtonControl from "./AlignmentButtonControl";
import BackwardActiveIcon from "@paperdateco/shared-frontend/assets/images/invite/layer/backwards_active.svg";
import BackwardIcon from "@paperdateco/shared-frontend/assets/images/invite/layer/backwards.svg";
import CanvasElement from "../../objects/CanvasElement";
import ForwardActiveIcon from "@paperdateco/shared-frontend/assets/images/invite/layer/forwards_active.svg";
import ForwardIcon from "@paperdateco/shared-frontend/assets/images/invite/layer/forwards.svg";
import InviteInstantPreview from "@paperdateco/shared-frontend/components/invite/InviteInstantPreview";
import { Stack } from "@mui/material";
import ToBackActiveIcon from "@paperdateco/shared-frontend/assets/images/invite/layer/to_back_active.svg";
import ToBackIcon from "@paperdateco/shared-frontend/assets/images/invite/layer/to_back.svg";
import ToFrontActiveIcon from "@paperdateco/shared-frontend/assets/images/invite/layer/to_front_active.svg";
import ToFrontIcon from "@paperdateco/shared-frontend/assets/images/invite/layer/to_front.svg";
import useInviteLayersChange from "../../hooks/useInviteLayersChange";

const ButtonImageProps: BoxProps = {
  width: 50,
};

interface InviteLayerOrderControlProps<T extends fabric.Object> {
  instantPreview: InviteInstantPreview;
  elements: CanvasElement<T>[];
}

export default function InviteLayerOrderControl<T extends fabric.Object>({
  instantPreview,
  elements,
}: InviteLayerOrderControlProps<T>) {
  const layers = useInviteLayersChange(instantPreview);

  const isTop = () => {
    const count = elements.length;
    return !elements.some(
      (layer) => layers.length - 1 - layers.indexOf(layer) >= count
    );
  };

  const isBottom = () => {
    const count = elements.length;
    return !elements.some((layer) => layers.indexOf(layer) >= count);
  };

  const onMoveForward = () => instantPreview.swapSelectedLayers("forward");

  const onMoveBackwards = () => instantPreview.swapSelectedLayers("backward");

  const onMoveFront = () => instantPreview.swapSelectedLayers("front");

  const onMoveBack = () => instantPreview.swapSelectedLayers("back");

  return (
    <Box margin={2}>
      <Stack spacing={2} direction="row" justifyContent="space-between">
        <AlignmentButtonControl
          name="Forward"
          icon={isTop() ? ForwardActiveIcon : ForwardIcon}
          onClick={onMoveForward}
          imageProps={ButtonImageProps}
        />
        <AlignmentButtonControl
          name="Backward"
          icon={isBottom() ? BackwardActiveIcon : BackwardIcon}
          onClick={onMoveBackwards}
          imageProps={ButtonImageProps}
        />
      </Stack>
      <Stack spacing={2} direction="row" justifyContent="space-between">
        <AlignmentButtonControl
          name="To Front"
          icon={isTop() ? ToFrontActiveIcon : ToFrontIcon}
          onClick={onMoveFront}
          imageProps={ButtonImageProps}
        />
        <AlignmentButtonControl
          name="To Back"
          icon={isBottom() ? ToBackActiveIcon : ToBackIcon}
          onClick={onMoveBack}
          imageProps={ButtonImageProps}
        />
      </Stack>
    </Box>
  );
}
