import FeedItemDto from "../dto/instagramFeed/FeedItemDto";
import StoreInstance from "../store/StoreInstance";
import { handleApiError } from "../utils/ApiUtils";

export default class InstagramFeedApi {
  public static getAllInstagramFeeds = async () => {
    try {
      const instagramFeeds = await StoreInstance.api().get<FeedItemDto[]>(
        "/instagramFeeds"
      );
      return instagramFeeds.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get all instagramFeeds");
    }
  };

  public static updateInstagramFeeds = async () => {
    try {
      const instagramFeeds = await StoreInstance.api().put<FeedItemDto[]>(
        `/instagramFeeds/update`
      );
      return instagramFeeds.data;
    } catch (e) {
      throw handleApiError(e, "Failed to update instagram feeds");
    }
  };
}
