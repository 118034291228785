import EventDto from "@paperdateco/common/dto/order/event/EventDto";

const DefaultEvent: EventDto = {
  eventName: "",
  hostName: "",
  location: "",
  eventDate: new Date(),
  mailerList: [],
  mailerText: "",
  shouldRsvp: true,
  shouldSendReminder: false,
  shouldSendThankYouNote: false,
};

export default DefaultEvent;
