import GiftingDto from "@paperdateco/common/dto/form/contactUs/gifting/GiftingDto";

const DefaultGiftingForm: GiftingDto = {
  giftingOption: "",
  quantity: 0,
  deliveryDate: new Date(),
  deliveryLocation: "",
};

export default DefaultGiftingForm;
