import { fabric } from "fabric";

export default class AnimationValueUtil {
  public static readonly SCALE_FRACTION = 2;

  static setCenteredOriginForZoom(elem: fabric.Object) {
    const center = elem.getCenterPoint();
    elem.originX = "center";
    elem.originY = "center";
    elem.left = center.x;
    elem.top = center.y;
  }

  static easeLinear: fabric.IUtilAminEaseFunction = (t, b, c, d) => {
    return b + (t / d) * c;
  };

  static degreesToRadians(degrees: number) {
    return degrees * (Math.PI / 180);
  }
}
