import { Button, Stack, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import GreenButton from "../../common/ui/GreenButton";
import WarningPopup from "@paperdateco/shared-frontend/components/common/popups/common/WarningPopup";
import routes from "@paperdateco/customer/routes/routes";

interface LoginNeededPopupProps {
  message: string;
  onClose?: () => void;
}

export default function LoginNeededPopup({
  message,
  onClose,
}: LoginNeededPopupProps) {
  const location = useLocation();

  return (
    <WarningPopup>
      <Typography
        variant="body2"
        textAlign="center"
        fontFamily={Fonts.Playfair}
        fontWeight="bold"
      >
        {message}
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        marginTop={1.5}
        fontFamily={Fonts.Roboto}
      >
        <Link to={routes.LOGIN} state={{ from: location.pathname }}>
          <GreenButton
            variant="contained"
            onClick={() => onClose?.()}
            size="small"
          >
            LOGIN
          </GreenButton>
        </Link>
        <Button
          variant="contained"
          onClick={() => onClose?.()}
          size="small"
          color="error"
        >
          CANCEL
        </Button>
      </Stack>
    </WarningPopup>
  );
}
