import CartContentsDto from "../dto/order/cart/CartContentsDto";
import CartItemDto from "../dto/order/cart/CartItemDto";
import CartItemRequestDto from "../dto/order/cart/CartItemRequestDto";
import StoreInstance from "../store/StoreInstance";
import { handleApiError } from "../utils/ApiUtils";

export default class CartApi {
  public static async getCartItems() {
    try {
      const cartItems = await StoreInstance.api().get<CartContentsDto>("/cart");
      return cartItems.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get cart items");
    }
  }

  public static async getCartItem(cartItemId: string) {
    try {
      const cartItem = await StoreInstance.api().get<CartItemDto>(
        `/cart/${cartItemId}`
      );
      return cartItem.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get cart item detail");
    }
  }

  public static async addItemToCart(cartRequestItem: CartItemRequestDto) {
    try {
      const cartItem = await StoreInstance.api().post<CartItemDto>(
        "/cart",
        cartRequestItem
      );
      return cartItem.data;
    } catch (e) {
      throw handleApiError(e, "Failed adding to cart");
    }
  }

  public static async updateItemInCart(
    cartItemId: string,
    cartRequestItem: CartItemRequestDto
  ) {
    try {
      const cartItem = await StoreInstance.api().put<CartItemDto>(
        `/cart/${cartItemId}`,
        cartRequestItem
      );
      return cartItem.data;
    } catch (e) {
      throw handleApiError(e, "Failed updating the cart");
    }
  }

  public static async removeItemInCart(cartItemId: string) {
    try {
      const cartItem = await StoreInstance.api().delete<CartItemDto>(
        `/cart/${cartItemId}`
      );
      return cartItem.data;
    } catch (e) {
      throw handleApiError(e, "Failed removing item from cart");
    }
  }
}
