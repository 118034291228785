import { Box, Button, Stack, Typography } from "@mui/material";
import { PropsWithChildren, useCallback, useContext } from "react";

import AddIcon from "@mui/icons-material/Add";
import { CanvasPageContext } from "../../provider/CanvasPageProvider";
import CanvasPageControl from "./CanvasPageControl";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EnvUtils from "@paperdateco/shared-frontend/utils/EnvUtils";
import PostAddIcon from "@mui/icons-material/PostAdd";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";

export default function CanvasPageControls({
  children,
}: PropsWithChildren<{}>) {
  const {
    pages,
    currentPageIndex,
    onSwapPage,
    onSwitchPage,
    onAddPage,
    onDuplicatePage,
    onDeletePage,
  } = useContext(CanvasPageContext);
  const isFirst = currentPageIndex === 0;
  const isLast = currentPageIndex === pages.length - 1;

  const onSwapPrevious = useCallback(
    () => onSwapPage?.(currentPageIndex, currentPageIndex - 1),
    [onSwapPage, currentPageIndex]
  );

  const onSwapNext = useCallback(
    () => onSwapPage?.(currentPageIndex, currentPageIndex + 1),
    [onSwapPage, currentPageIndex]
  );

  const onSwitchPrevious = useCallback(
    () => onSwitchPage?.(currentPageIndex - 1),
    [onSwitchPage, currentPageIndex]
  );

  const onSwitchNext = useCallback(
    () => onSwitchPage?.(currentPageIndex + 1),
    [onSwitchPage, currentPageIndex]
  );

  if (EnvUtils.isProduction()) {
    return <>{children}</>;
  }

  return (
    <Stack direction="column" width="100%" height="100%">
      <Stack
        direction="row"
        marginX={5}
        maxWidth={800}
        alignItems="center"
        spacing={1}
      >
        <Box flexGrow={1}>
          <Typography variant="body1">
            Page {currentPageIndex + 1}/{pages.length}
          </Typography>
        </Box>
        <CanvasPageControl
          title="Switch Previous"
          icon={SettingsBackupRestoreIcon}
          disabled={isFirst}
          onClick={onSwapPrevious}
        />
        <CanvasPageControl
          title="Switch Next"
          icon={SettingsBackupRestoreIcon}
          iconProps={{ sx: { transform: "scaleX(-1)" } }}
          disabled={isLast}
          onClick={onSwapNext}
        />
        <CanvasPageControl
          title="Duplicate"
          onClick={onDuplicatePage}
          icon={ContentCopyIcon}
        />
        <CanvasPageControl
          title={pages.length <= 1 ? "Need atleast one page" : "Delete"}
          onClick={onDeletePage}
          icon={DeleteIcon}
          disabled={pages.length <= 1}
        />
        <CanvasPageControl
          title="Add Page"
          onClick={onAddPage}
          icon={PostAddIcon}
        />
      </Stack>
      <Stack direction="row" alignItems="center" flexGrow={1} overflow="hidden">
        <CanvasPageControl
          title="Go to Previous"
          disabled={isFirst}
          onClick={onSwitchPrevious}
          icon={ChevronLeftIcon}
        />
        {children}
        <CanvasPageControl
          title="Go to Next"
          disabled={isLast}
          onClick={onSwitchNext}
          icon={ChevronRightIcon}
        />
      </Stack>
      <Box
        width="100%"
        marginTop={1}
        marginX="auto"
        paddingX={3}
        maxWidth={800}
      >
        <Button
          variant="contained"
          fullWidth
          startIcon={<AddIcon />}
          onClick={onAddPage}
        >
          Add Page
        </Button>
      </Box>
    </Stack>
  );
}
