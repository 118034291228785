import { Grid, Typography } from "@mui/material";
import { Link, LinkProps } from "react-router-dom";

import BlackButton from "@paperdateco/shared-frontend/components/common/form/button/BlackButton";
import { Box } from "@mui/system";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import { Fragment } from "react";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import ProductShareIcons from "./ProductShareIcons";
import ProductWishListIcon from "./ProductWishListIcon";
import UrlUtils from "@paperdateco/shared-frontend/utils/UrlUtils";
import routes from "@paperdateco/customer/routes/routes";
import useCustomiseTemplate from "./useCustomiseTemplate";

const OtherDetailsSection = CustomStyled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border.secondary}`,
}));

const searchUrl = (search: string): LinkProps["to"] => ({
  pathname: routes.PRODUCT_SEARCH,
  search: UrlUtils.getQueryString({
    query: search,
  }),
});

interface ProductDescriptionSectionProps {
  product: ProductDto;
}

export default function ProductDescriptionSection({
  product,
}: ProductDescriptionSectionProps) {
  const onCustomiseTemplate = useCustomiseTemplate(product);

  return (
    <Box paddingX={4} paddingY={2}>
      <Typography variant="h4">{product.name}</Typography>
      <Typography variant="body1" marginY={2}>
        {product.designedBy?.name}
      </Typography>
      <Typography variant="h6" fontWeight="bold">
        ₹ {product.price}
      </Typography>
      <Typography variant="body1" marginY={2}>
        {product.description}
      </Typography>

      <Box>
        <ProductWishListIcon product={product} />
        <BlackButton
          variant="contained"
          size="large"
          onClick={onCustomiseTemplate}
        >
          CUSTOMISE TEMPLATE
        </BlackButton>
      </Box>

      <OtherDetailsSection marginTop={3} paddingY={2}>
        <Grid container spacing={2}>
          {product.designedBy && (
            <>
              <Grid item xs={6}>
                <Typography variant="body1">ABOUT DESIGNER</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <Link to={routes.COLLABORATOR(product.designedBy.id)}>
                    {product.designedBy.name}
                  </Link>
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <Typography variant="body1">CATEGORY</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {product.categories.map((category, index) => (
                <Fragment key={category.id}>
                  <Link to={routes.CATEGORY(category.slug)}>
                    {category.name}
                  </Link>
                  {index !== product.categories.length - 1 && ", "}
                </Fragment>
              ))}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">TAGS</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {product.tags.map((tag, index) => (
                <Fragment key={tag}>
                  <Link to={searchUrl(tag)}>{tag}</Link>
                  {index !== product.tags.length - 1 && ", "}
                </Fragment>
              ))}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">SHARE ON</Typography>
          </Grid>
          <Grid item xs={6}>
            <ProductShareIcons />
          </Grid>
        </Grid>
      </OtherDetailsSection>
    </Box>
  );
}
