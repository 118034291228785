import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import InviteInstantPreview from "./InviteInstantPreview";
import InvitePageContents from "./InvitePageContents";
import { styled } from "@mui/system";
import { useCallback } from "react";
import { useFabric } from "@paperdateco/shared-frontend/canvas/useFabric";

const NoFitlerCanvas = styled("canvas")({
  filter: "none",
  border: `1px solid ${Colors.LIGHT_GREY}`,
});

interface InviteInstantPreviewCanvasProps {
  width: number;
  height: number;
  page: InvitePageContents;
  onInit?: (
    page: InvitePageContents,
    instantPreview: InviteInstantPreview
  ) => void;
}

export default function InviteInstantPreviewCanvas({
  width,
  height,
  page,
  onInit,
}: InviteInstantPreviewCanvasProps) {
  const handleCanvas = useCallback(
    async (fabricCanvas: fabric.Canvas, canvas: HTMLCanvasElement) => {
      const instantPreview = new InviteInstantPreview(
        fabricCanvas,
        canvas,
        width,
        height
      );
      await instantPreview.setDefaultPageDesign(page.page);
      onInit?.(page, instantPreview);
    },
    [width, height, page, onInit]
  );

  const ref = useFabric(width, height, handleCanvas);

  if (!(width && height)) {
    return null;
  }

  return <NoFitlerCanvas ref={ref} width={width} height={height} />;
}
