import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import AboutUs from "../components/pages/marketing/AboutUsPage";
import AccountDashboardPage from "../components/pages/account/AccountDashboardPage";
import AccountDetailsPage from "../components/pages/account/AccountDetailsPage";
import AccountDraftsPage from "../components/pages/account/AccountDraftsPage";
import AccountManageInvitesPage from "../components/pages/account/AccountManageInvitesPage";
import AccountOrderPage from "../components/pages/account/AccountOrderPage";
import BlogPage from "../components/pages/marketing/BlogPage";
import CancellationPolicy from "../components/pages/marketing/CancellationPolicy";
import CartCustomisePage from "../components/pages/cart/CartCustomisePage";
import CartPage from "../components/pages/cart/CartPage";
import CartPreviewPage from "../components/pages/cart/CartPreviewPage";
import CategoryPage from "../components/pages/product/CategoryPage";
import CollaboratorPage from "../components/pages/product/CollaboratorPage";
import CollaboratorsPage from "../components/pages/product/CollaboratorsPage";
import ContactUs from "../components/pages/marketing/contactUs/ContactUsPage";
import CustomerRoute from "@paperdateco/customer/routes/guards/CustomerRoute";
import ForgotPasswordPage from "../components/pages/auth/forgotPassword/ForgotPasswordPage";
import GtmUtils from "../utils/GtmUtils";
import HomePage from "../components/pages/home/HomePage";
import InviteCustomisationPage from "../components/pages/invite/customisation/InviteCustomisationPage";
import InvitePreviewPage from "../components/pages/invite/preview/InvitePreviewPage";
import InviteSharePage from "../components/pages/invite/share/InviteSharePage";
import LoginPage from "../components/pages/auth/login/LoginPage";
import LogoutPage from "../components/pages/auth/LogoutPage";
import { ModalContext } from "@paperdateco/shared-frontend/components/providers/ModalProvider";
import PrivacyPolicy from "../components/pages/marketing/PrivacyPolicy";
import ProductDetailPage from "../components/pages/product/ProductDetailPage";
import ProductSearchResultPage from "../components/pages/product/ProductSearchResultPage";
import Refunds from "../components/pages/marketing/Refunds";
import RegisterPage from "../components/pages/auth/register/RegisterPage";
import ResetPasswordPage from "../components/pages/auth/forgotPassword/ResetPasswordPage";
import Returns from "../components/pages/marketing/Returns";
import RsvpPage from "../components/pages/invite/rsvp/RsvpPage";
import ScrollToTop from "@paperdateco/shared-frontend/routes/ScrollToTop";
import TermsAndConditions from "../components/pages/marketing/TermsAndConditions";
import TrackPageView from "@paperdateco/shared-frontend/routes/TrackPageView";
import VerificationEmailPage from "../components/pages/auth/register/VerificationEmailPage";
import WishListPage from "../components/pages/wishlist/WishListPage";
import routes from "./routes";
import { useContext } from "react";

export default function AppRouter() {
  const { popup } = useContext(ModalContext);

  return (
    <Router>
      <ScrollToTop />
      <TrackPageView onPage={GtmUtils.trackPageView} />
      <Routes>
        {/* Marketing Pages */}
        <Route path={routes.HOME} element={<HomePage />} />
        <Route path={routes.ABOUT_US} element={<AboutUs />} />
        <Route path={routes.CONTACT_US} element={<ContactUs />} />
        <Route
          path={routes.CANCELLATION_POLICY}
          element={<CancellationPolicy />}
        />
        <Route path={routes.REFUNDS} element={<Refunds />} />
        <Route path={routes.RETURNS} element={<Returns />} />
        <Route
          path={routes.TERMS_AND_CONDITIONS}
          element={<TermsAndConditions />}
        />
        <Route path={routes.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={routes.BLOG} element={<BlogPage />} />

        {/* Category, Product, Collaborator Pages */}
        <Route path={routes.CATEGORY()} element={<CategoryPage />} />
        <Route path={routes.COLLABORATORS} element={<CollaboratorsPage />} />
        <Route path={routes.COLLABORATOR()} element={<CollaboratorPage />} />
        <Route path={routes.PRODUCT()} element={<ProductDetailPage />} />
        <Route
          path={routes.PRODUCT_SEARCH}
          element={<ProductSearchResultPage />}
        />

        {/* Invite Edit Pages */}
        <Route path={routes.CUSTOM_DESIGNS} element={<CustomerRoute />}>
          <Route
            path={routes.CUSTOM_DESIGN_EDIT()}
            element={<InviteCustomisationPage />}
          />
          <Route
            path={routes.CUSTOM_DESIGN_PREVIEW()}
            element={<InvitePreviewPage />}
          />
        </Route>

        {/* Invite Share Pages */}
        <Route
          path={routes.INVITE_SHARE_LINK()}
          element={<InviteSharePage />}
        />
        <Route path={routes.RSVP()} element={<RsvpPage />} />

        {/* Account Routes */}
        <Route path={routes.ACCOUNT} element={<CustomerRoute />}>
          <Route index element={<Navigate to={routes.ACCOUNT_DASHBOARD} />} />
          <Route
            path={routes.ACCOUNT_DASHBOARD}
            element={<AccountDashboardPage />}
          />
          <Route
            path={routes.ACCOUNT_DETAILS}
            element={<AccountDetailsPage />}
          />
          <Route path={routes.ACCOUNT_DRAFTS} element={<AccountDraftsPage />} />
          <Route
            path={routes.ACCOUNT_MANAGE_INVITES()}
            element={<AccountManageInvitesPage />}
          />
          <Route path={routes.ACCOUNT_ORDERS} element={<AccountOrderPage />} />
        </Route>

        {/* Auth Routes */}
        <Route path={routes.REGISTER} element={<RegisterPage />} />
        <Route path={routes.LOGIN} element={<LoginPage />} />
        <Route path={routes.LOGOUT} element={<LogoutPage />} />
        <Route path={routes.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
        <Route path={routes.RESET_PASSWORD} element={<ResetPasswordPage />} />
        <Route
          path={routes.VERIFICATION_EMAIL}
          element={<VerificationEmailPage />}
        />

        {/* Cart Routes */}
        <Route path={routes.CART} element={<CustomerRoute />}>
          <Route index element={<CartPage />} />
          <Route
            path={routes.CART_ITEM_EDIT()}
            element={<CartCustomisePage />}
          />
          <Route
            path={routes.CART_ITEM_PREVIEW()}
            element={<CartPreviewPage />}
          />
        </Route>

        {/* Wishlish Routes */}
        <Route path={routes.WISHLIST} element={<CustomerRoute />}>
          <Route index element={<WishListPage />} />
        </Route>
      </Routes>
      {popup}
    </Router>
  );
}
