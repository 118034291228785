import {
  Box,
  BoxProps,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import { FormEvent, useCallback, useEffect, useState } from "react";

import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UrlUtils from "@paperdateco/shared-frontend/utils/UrlUtils";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import routes from "@paperdateco/customer/routes/routes";
import { useNavigate } from "react-router-dom";
import useQueryParam from "@paperdateco/customer/utils/useQueryParam";

const SearchTextField = CustomStyled(CustomTextField)({
  color: "inherit",
  "& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused, & .MuiInputAdornment-root, & .MuiInput-root, & .MuiInput-input":
    {
      color: "inherit",
    },
  "& .MuiInput-root:before, & .MuiInput-root:hover:not(.Mui-disabled):before, & .MuiInput-root:after":
    {
      borderBottomColor: "inherit",
    },
});

interface SearchFieldProps {
  category?: string;
}

export default function SearchField({
  category,
  ...props
}: Omit<BoxProps, "onSubmit"> & SearchFieldProps) {
  const pageQuery = useQueryParam("query");
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      navigate({
        pathname: routes.PRODUCT_SEARCH,
        search: UrlUtils.getQueryString({ query, category }),
      });
    },
    [navigate, query, category]
  );

  useEffect(() => setQuery(pageQuery ?? ""), [pageQuery]);

  return (
    <Box
      maxWidth={props.maxWidth}
      display="flex"
      alignItems="flex-start"
      {...props}
      marginBottom={3}
    >
      <form onSubmit={onSubmit}>
        <Stack direction="row" spacing={1}>
          <SearchTextField
            value={query}
            onChange={setQuery}
            variant="standard"
            label="Search"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit" size="small" color="inherit">
                    <FontAwesomeIcon icon={faSearch} color="inherit" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </form>
    </Box>
  );
}
