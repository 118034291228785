import AnimationType from "@paperdateco/common/canvas/animation/AnimationType";
import AnimationValue from "./values/AnimationValue";
import BaselineAnimation from "./values/BaselineAnimation";
import CanvasElement from "../../objects/CanvasElement";
import CanvasUtils from "../../CanvasUtils";
import DriftAnimation from "./values/DriftAnimation";
import FadeInAnimation from "./values/FadeInAnimation";
import FadeOutAnimation from "./values/FadeOutAnimation";
import NeonAnimation from "./values/NeonAnimation";
import PopAnimation from "./values/PopAnimation";
import SlideLeftAnimation from "./values/SlideLeftAnimation";
import SlideRightAnimation from "./values/SlideRightAnimation";
import SlideTopAnimation from "./values/SlideTopAnimation";
import StompAnimation from "./values/StompAnimation";
import TectnoicAnimation from "./values/TectonicAnimation";
import TumbleAnimation from "./values/TumbleAnimation";
import WipeAnimation from "./values/WipeAnimation";
import ZoomInAnimation from "./values/ZoomInAnimation";
import ZoomOutAnimation from "./values/ZoomOutAnimation";
import { fabric } from "fabric";

const animationMap: {
  [name in AnimationType]: (elem: fabric.Object) => AnimationValue[];
} = {
  [AnimationType.DISSOLVE]: FadeOutAnimation,
  [AnimationType.FADE_IN]: FadeInAnimation,
  [AnimationType.FADE_OUT]: FadeOutAnimation,
  [AnimationType.POP]: PopAnimation,
  [AnimationType.SLIDE_HORIZONTAL]: SlideLeftAnimation,
  [AnimationType.SLIDE_LEFT]: SlideLeftAnimation,
  [AnimationType.SLIDE_RIGHT]: SlideRightAnimation,
  [AnimationType.SLIDE_VERTICAL]: SlideTopAnimation,
  [AnimationType.ZOOM_IN]: ZoomInAnimation,
  [AnimationType.ZOOM_OUT]: ZoomOutAnimation,
  [AnimationType.WIPE]: WipeAnimation,
  [AnimationType.BASELINE]: BaselineAnimation,
  [AnimationType.DRIFT]: DriftAnimation,
  [AnimationType.TECTONIC]: TectnoicAnimation,
  [AnimationType.TUMBLE]: TumbleAnimation,
  [AnimationType.NEON]: NeonAnimation,
  [AnimationType.STOMP]: StompAnimation,
};

const IgnoredProperties: (keyof fabric.Object)[] = ["clipPath"];

const restoreElement: {
  [key: string]: ((abort?: boolean) => void) | undefined;
} = {};

export default class CanvasAnimationUtils {
  static async animateElementAndRestoreOriginal<T extends fabric.Object>(
    canvas: fabric.Canvas,
    elem: CanvasElement<T>,
    animation: AnimationType
  ) {
    restoreElement[elem.id]?.(true);
    const [animFn, restoreFn] = CanvasAnimationUtils.animateElement(
      canvas,
      elem.nativeElement,
      animation
    );
    restoreElement[elem.id] = (abort = false) => {
      if (abort) {
        (fabric as any).runningAnimations.cancelAll();
      }
      restoreFn();
      restoreElement[elem.id] = undefined;
    };
    await animFn();
    restoreElement[elem.id]?.();
  }

  static animateElement(
    canvas: fabric.Canvas,
    elem: fabric.Object,
    animation: AnimationType
  ): [() => Promise<void>, () => void] {
    const state = elem.toJSON();
    state.path = (elem as fabric.IText).path;
    state.clipPath = elem.clipPath;
    const animationValues: AnimationValue[] = animationMap[animation](elem);
    CanvasAnimationUtils.setInitialValueOfAnimation(elem, animationValues);

    return [
      () =>
        Promise.all(
          animationValues.map((value) =>
            CanvasUtils.animate(canvas, elem, value.property, value.value)
          )
        ).then(),
      () => {
        elem.setOptions(state);
      },
    ];
  }

  static setInitialValueOfAnimation(
    elem: fabric.Object,
    animationValues: AnimationValue[]
  ) {
    animationValues
      .filter((value) => !IgnoredProperties.includes(value.property))
      .map((value) => elem.set(value.property, value.value.from));
  }
}
