import { Button, Typography } from "@mui/material";
import { FormEvent, useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { AuthContext } from "@paperdateco/shared-frontend/components/auth/AuthProvider";
import AuthForm from "./AuthForm";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import ErrorUtils from "@paperdateco/common/utils/ErrorUtils";
import FormRow from "@paperdateco/shared-frontend/components/common/form/FormRow";
import GoogleSignIn from "@paperdateco/shared-frontend/components/auth/GoogleSignIn";
import LoginNeededPopup from "../popup/login/LoginNeededPopup";
import { ModalContext } from "@paperdateco/shared-frontend/components/providers/ModalProvider";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import UserApi from "@paperdateco/common/api/UserApi";
import UserError from "@paperdateco/common/dto/error/UserError";
import routes from "@paperdateco/customer/routes/routes";

const getLoginDestination = (state?: { from: string }) => {
  let destination = state?.from ?? routes.HOME;
  if (destination === routes.LOGIN) {
    destination = routes.HOME;
  }
  return destination;
};

export default function LoginForm() {
  const navigate = useNavigate();
  const { state }: { state: { from: string } } = useLocation();
  const { updateAuth } = useContext(AuthContext);
  const { open, close } = useContext(ModalContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await UserApi.login({ email: email.toLowerCase(), password });
      await updateAuth();
      navigate(getLoginDestination(state));
    } catch (e) {
      if (ErrorUtils.isError(e, UserError.PASSWORD_NOT_SAVED)) {
        open(
          <LoginNeededPopup
            message="PLEASE USE YOUR GOOGLE ID TO LOGIN OR RESET YOUR PASSWORD"
            onClose={close}
          />
        );
        return;
      }
      NotificationUtils.showGenericError(e);
    }
  };

  return (
    <AuthForm>
      <form onSubmit={handleSubmit}>
        <FormRow>
          <CustomTextField
            variant="standard"
            label="Email Address"
            type="email"
            name="email"
            value={email}
            onChange={setEmail}
          />
        </FormRow>

        <FormRow>
          <CustomTextField
            variant="standard"
            label="Password"
            type="password"
            name="password"
            autoComplete="password"
            value={password}
            onChange={setPassword}
          />
        </FormRow>

        <FormRow textAlign="center">
          <Button variant="contained" type="submit" size="large">
            LOGIN
          </Button>
          <Typography variant="body1" margin={1}>
            Or
          </Typography>
          <GoogleSignIn />
        </FormRow>
      </form>

      <Typography variant="body1" marginY={2}>
        New User?{" "}
        <Link to={routes.REGISTER}>
          <Typography component="span" color={Colors.BLUE}>
            Sign Up
          </Typography>
        </Link>
      </Typography>
      <Typography variant="body2" color="primary.light">
        <Link to={routes.FORGOT_PASSWORD}>Forgot Password</Link>
      </Typography>
    </AuthForm>
  );
}
