import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import EnvUtils from "@paperdateco/shared-frontend/utils/EnvUtils";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import LibraryImageListRow from "./LibraryImageListRow";
import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";
import { SectionLabelType } from "./LibrarySection";

interface LibraryPreviewSectionProps {
  section: SectionLabelType;
  design?: DesignDto;
  onChoose?: (type: LibraryImageType, item: LibraryImageDto) => void;
  onViewMore?: (type: SectionLabelType) => void;
  onAdd?: (type: LibraryImageType) => void;
}

export default function LibraryPreviewSection({
  section,
  design,
  onChoose,
  onViewMore,
  onAdd,
}: LibraryPreviewSectionProps) {
  if (
    design?.designer?.id === "62380fb904e373acf2dc8538" &&
    section !== LibraryImageType.BACKGROUND
  ) {
    return null;
  }
  if (EnvUtils.isProduction()) {
    // Hide sections in production
    switch (section) {
      case LibraryImageType.PHOTO:
        return null;
    }
  }
  return (
    <LibraryImageListRow
      type={section}
      design={design}
      onChoose={onChoose}
      onViewMore={onViewMore}
      onAdd={onAdd}
    />
  );
}
