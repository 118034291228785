import { Box, Typography } from "@mui/material";

import BreadCrumbLink from "./BreadCrumbLink";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import { Link } from "react-router-dom";

const BreadCrumbContainer = CustomStyled(Box)({
  background: Colors.GREY_BACKGROUND,
});

interface BreadcrumbProps {
  link: BreadCrumbLink;
  isLast: boolean;
}

function BreadCrumb({ link, isLast }: BreadcrumbProps) {
  return (
    <>
      <Link to={link.link}>
        <Typography variant="h6" color={isLast ? "primary.600" : "primary"}>
          {link.title}
        </Typography>
      </Link>
      {!isLast && (
        <Typography variant="h6" marginX={2}>
          /
        </Typography>
      )}
    </>
  );
}

interface BreadcrumbsProps {
  links: BreadCrumbLink[];
}

export default function Breadcrumbs({ links }: BreadcrumbsProps) {
  return (
    <BreadCrumbContainer padding={2} display="flex" flexDirection="row">
      {links.map((link, index) => (
        <BreadCrumb
          key={link.title}
          link={link}
          isLast={index === links.length - 1}
        />
      ))}
    </BreadCrumbContainer>
  );
}
