import {
  CartStateDispatchType,
  getAllCartItems,
} from "@paperdateco/customer/store/cart/CartAction";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AuthContext } from "@paperdateco/shared-frontend/components/auth/AuthProvider";
import HeaderIconBadge from "./HeaderIconBadge";
import { IconButton } from "@mui/material";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { selectCartItemsCount } from "@paperdateco/customer/store/cart/CartSelector";

export default function HeaderCartIcon() {
  const cartItemsCount = useSelector(selectCartItemsCount);
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch<CartStateDispatchType>();

  useEffect(() => {
    if (user) {
      dispatch(getAllCartItems());
    }
  }, [dispatch, user]);

  return (
    <IconButton size="small" color="inherit">
      <HeaderIconBadge badgeContent={cartItemsCount} color="primary">
        <ShoppingCartOutlinedIcon color="inherit" />
      </HeaderIconBadge>
    </IconButton>
  );
}
