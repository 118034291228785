import CollaboratorDetailedDto from "@paperdateco/common/dto/product/collaborator/CollaboratorDetailedDto";
import CollaboratorDto from "@paperdateco/common/dto/product/collaborator/CollaboratorDto";
import StoreInstance from "@paperdateco/common/store/StoreInstance";
import { handleApiError } from "@paperdateco/common/utils/ApiUtils";

export default class CollaboratorApi {
  public static async getAllCollaborators() {
    try {
      const collaborators = await StoreInstance.api().get<CollaboratorDto[]>(
        "/collaborators"
      );
      return collaborators.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get all collaborators");
    }
  }

  public static async getCollaboratorInfo(collaboratorId: string) {
    try {
      const collaborator =
        await StoreInstance.api().get<CollaboratorDetailedDto>(
          "/collaborators/" + collaboratorId
        );
      return collaborator.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get collaborator details");
    }
  }
}
