import AspectRatio from "@paperdateco/shared-frontend/utils/styles/AspectRatio";
import BackgroundSelect from "@paperdateco/shared-frontend/components/common/image/BackgroundSelect";
import CategoryDto from "@paperdateco/common/dto/category/CategoryDto";

interface CategoryHeaderProps {
  category: CategoryDto;
}

export default function CategoryHeader({ category }: CategoryHeaderProps) {
  return (
    <BackgroundSelect
      src={category.image}
      aspectRatio={AspectRatio.BANNER}
      type={category.type}
    />
  );
}
