import { fabric } from "fabric";
import { gifToSprite } from "./gifToSprite";

const startTime = performance.now();
/**
 * fabricGif "async"
 * Mainly a wrapper for gifToSprite
 * @param {string|File} gif can be a URL, dataURL or an "input File"
 * @param {number} maxWidth Optional, scale to maximum width
 * @param {number} maxHeight Optional, scale to maximum height
 * @param {number} maxDuration Optional, in milliseconds reduce the gif frames to a maximum duration, ex: 2000 for 2 seconds
 * @returns {*} {error} object if any or a 'fabric.image' instance of the gif with new 'play', 'pause', 'stop' methods
 */
export default class FabricGif {
  static async fromGif(
    gif: string | File,
    { width, height, left, top, ...options }: fabric.IImageOptions = {},
    maxWidth?: number,
    maxHeight?: number,
    maxDuration?: number
  ) {
    const { dataUrl, delay, frameWidth, framesLength } = await gifToSprite(
      gif,
      maxWidth,
      maxHeight,
      maxDuration
    );

    return new Promise<fabric.Image>((resolve) => {
      fabric.Image.fromURL(dataUrl, (img) => {
        const sprite: HTMLImageElement = img.getElement() as HTMLImageElement;

        img.width = width ?? frameWidth;
        img.height = height ?? sprite.naturalHeight;
        img.top = top ?? 0;
        img.left = left ?? 0;
        img.setOptions(options);

        img._render = (ctx) => {
          const now = performance.now();
          const framesIndex = Math.min(
            Math.round(((now - startTime) % (delay * framesLength)) / delay),
            framesLength - 1
          );
          ctx.drawImage(
            sprite,
            frameWidth * framesIndex,
            0,
            frameWidth,
            sprite.height,
            -(img.width ?? 0) / 2,
            -(img.height ?? 0) / 2,
            frameWidth,
            sprite.height
          );
        };
        resolve(img);
      });
    });
  }
}
