import { useCallback, useContext } from "react";

import { CanvasPageContext } from "@paperdateco/shared-frontend/canvas/provider/CanvasPageProvider";
import CanvasUtils from "@paperdateco/shared-frontend/canvas/CanvasUtils";
import CustomDesignApi from "@paperdateco/common/api/CustomDesignApi";
import CustomDesignDto from "@paperdateco/common/dto/design/custom/CustomDesignDto";
import CustomDesignRequestDto from "@paperdateco/common/dto/design/custom/CustomDesignRequestDto";
import DesignEditNextButton from "@paperdateco/shared-frontend/components/design/common/DesignEditNextButton";
import DesignUtils from "@paperdateco/common/utils/DesignUtils";
import ModalContainer from "@paperdateco/shared-frontend/components/layout/ModalContainer";
import { ModalContext } from "@paperdateco/shared-frontend/components/providers/ModalProvider";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import { Typography } from "@mui/material";
import { setInviteCustomiseSection } from "@paperdateco/customer/store/inviteCustomise/InviteCustomiseAction";
import { useDispatch } from "react-redux";

function PreparingPreviewMessage() {
  return (
    <ModalContainer padding={2}>
      <Typography variant="h6" component="h2">
        Preparing preview...
      </Typography>
    </ModalContainer>
  );
}

interface InviteCustomisePreviewProps {
  design: CustomDesignDto;
  onViewPreview?: () => void;
}

export default function InviteCustomisePreviewButton({
  design,
  onViewPreview,
}: InviteCustomisePreviewProps) {
  const { open, close } = useContext(ModalContext);
  const { instantPreview, pages } = useContext(CanvasPageContext);
  const dispatch = useDispatch();

  const savePreviewImage = useCallback(
    (image: string) => CustomDesignApi.uploadInvitationImage(design.id, image),
    [design]
  );

  const onSave = useCallback(async () => {
    if (!instantPreview) {
      return;
    }

    const invitationFrontImage = CanvasUtils.takePicture(
      instantPreview.canvas,
      instantPreview.nativeCanvas
    );
    const preview = await savePreviewImage(invitationFrontImage);

    const pageRequest = await Promise.all(
      pages.map((page) => page.getRequestDto(savePreviewImage))
    );

    const request: CustomDesignRequestDto = {
      design: design.design.id,
      product: design.product.id,
      width: design.width,
      height: design.height,
      pages: pageRequest,
      preview,
      layers: DesignUtils.getRequestLayers(instantPreview.getDesignLayers()),
      components: instantPreview.getComponents(),
    };

    try {
      await CustomDesignApi.updateCustomDesign(design.id, request);
      dispatch(setInviteCustomiseSection());
      onViewPreview?.();
    } catch (e) {
      NotificationUtils.showGenericError(e);
    } finally {
      close();
    }
  }, [
    instantPreview,
    design,
    pages,
    savePreviewImage,
    dispatch,
    onViewPreview,
    close,
  ]);

  const onPreview = () => {
    open(<PreparingPreviewMessage />);
    onSave();
  };

  return (
    <DesignEditNextButton variant="contained" onClick={onPreview}>
      PREVIEW
    </DesignEditNextButton>
  );
}
