import { Box, Stack, styled } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import AnimationIcon from "@mui/icons-material/Animation";
import CanvasElement from "@paperdateco/shared-frontend/canvas/objects/CanvasElement";
import CanvasElementAnimationSection from "@paperdateco/shared-frontend/canvas/controls/animation/CanvasElementAnimationSection";
import CanvasElementOpacityControl from "@paperdateco/shared-frontend/canvas/controls/opacity/CanvasElementOpacityControl";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DesignEditPopoverSection from "@paperdateco/shared-frontend/components/design/common/DesignEditPopoverSection";
import DesignSidebarIcon from "../common/DesignSidebarIcon";
import InviteAlignmentSection from "@paperdateco/shared-frontend/canvas/controls/alignment/InviteAlignmentSection";
import InviteInstantPreview from "@paperdateco/shared-frontend/components/invite/InviteInstantPreview";

const VerticalDarkBox = styled(Box)({
  background: Colors.DARK_BACKGROUND,
});

enum RightSection {
  ALIGNMENT = "ALIGNMENT",
  ANIMATION = "ANIMATION",
}

interface DesignEditRightSidebarProps {
  instantPreview?: InviteInstantPreview;
  selectedLayers: CanvasElement[];
}

export default function DesignEditRightSidebar({
  instantPreview,
  selectedLayers,
}: DesignEditRightSidebarProps) {
  const [choosenSection, setChoosenSection] = useState<RightSection>();

  const closeSection = useCallback(() => setChoosenSection(undefined), []);

  const onChooseAlignmentSection = useCallback(
    () => setChoosenSection(RightSection.ALIGNMENT),
    []
  );

  const onChooseAnimationSection = useCallback(
    () => setChoosenSection(RightSection.ANIMATION),
    []
  );

  const onDuplicateLayer = () => instantPreview?.duplicateLayer(selectedLayers);

  const hasSelectedLayer = selectedLayers.length > 0;
  const iconColor = hasSelectedLayer ? Colors.WHITE : Colors.PRIMARY_LIGHT;

  useEffect(() => {
    if (!hasSelectedLayer && choosenSection !== undefined) {
      closeSection();
    }
  }, [hasSelectedLayer, choosenSection, closeSection]);

  return (
    <VerticalDarkBox display="flex" position="relative">
      <DesignEditPopoverSection
        position="right"
        open={choosenSection !== undefined}
        onClose={closeSection}
      >
        {choosenSection === RightSection.ALIGNMENT && (
          <InviteAlignmentSection
            instantPreview={instantPreview}
            elements={selectedLayers}
            canvasWidth={instantPreview?.width ?? 0}
            canvasHeight={instantPreview?.height ?? 0}
          />
        )}
        {choosenSection === RightSection.ANIMATION && (
          <CanvasElementAnimationSection
            instantPreview={instantPreview}
            elements={selectedLayers}
          />
        )}
      </DesignEditPopoverSection>
      <Stack spacing={5} paddingTop={5}>
        <DesignSidebarIcon
          title="Alignment"
          onClick={onChooseAlignmentSection}
          disabled={!hasSelectedLayer}
        >
          <AlignHorizontalCenterIcon htmlColor={iconColor} />
        </DesignSidebarIcon>
        <DesignSidebarIcon
          title="Animation"
          onClick={onChooseAnimationSection}
          disabled={!hasSelectedLayer}
        >
          <AnimationIcon htmlColor={iconColor} />
        </DesignSidebarIcon>
        <DesignSidebarIcon
          title="Duplicate"
          disabled={!hasSelectedLayer}
          onClick={onDuplicateLayer}
        >
          <ContentCopyIcon htmlColor={iconColor} />
        </DesignSidebarIcon>

        <CanvasElementOpacityControl
          elements={selectedLayers}
          disabled={!hasSelectedLayer}
          iconColor={iconColor}
        />
        <DesignSidebarIcon
          title="Delete"
          onClick={instantPreview?.removeSelectedLayers}
          disabled={!hasSelectedLayer}
        >
          <DeleteOutlineIcon htmlColor={iconColor} />
        </DesignSidebarIcon>
      </Stack>
    </VerticalDarkBox>
  );
}
