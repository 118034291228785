import UploadTypes from "../dto/common/UploadTypes";

// Based on https://stackoverflow.com/a/58026715
// Prevent Safari from garbage collecting the input element and
// the onchange event from not getting called.
let inputElement: HTMLInputElement;

export class FileUtils {
  static ImageExtensions = ".jpg,.jpeg,.gif,.svg,.png,.mp4";

  static openFileDialog = (
    callback: (ev: Event) => any,
    accept: string = ".jpg,.jpeg,.gif,.svg,.png,.pdf,.mp4"
  ) => {
    inputElement = document.createElement("input");
    inputElement.type = "file";
    inputElement.accept = accept; // Note Edge does not support this attribute
    inputElement.addEventListener("change", callback);
    // Based on https://stackoverflow.com/a/39543107
    // Fix to ensure event listener is added before input is clicked
    setTimeout(() => {
      inputElement.click();
    }, 0);
  };

  static getDataUrlFromFile(file: File) {
    return new Promise<string>((res) => {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        if (fileReader.result) {
          res(fileReader.result.toString());
        }
      };
      fileReader.readAsDataURL(file);
    });
  }

  static getDimensionsFromFile(
    file: File,
    dimensions: { width: number; height: number },
    type: string
  ) {
    //Based on https://stackoverflow.com/a/51696339
    const url = URL.createObjectURL(file);
    if (type === "video") {
      const video = document.createElement("video");
      video.src = url;
      video.onloadedmetadata = () => {
        // Revoke when you don't need the url any more to release any reference
        URL.revokeObjectURL(url);
        dimensions.width = video.videoWidth;
        dimensions.height = video.videoHeight;
      };
      video.load(); // fetches metadata
    } else {
      const image = new Image();
      image.src = url;
      image.onload = () => {
        URL.revokeObjectURL(url);
        dimensions.width = image.width;
        dimensions.height = image.height;
      };
    }
  }

  static async getBlobFromDataUri(dataUri: string) {
    const file = await fetch(dataUri);
    return file.blob();
  }

  static getUploadType(type: string) {
    if (type.includes("video")) {
      return UploadTypes.VIDEO;
    }
    return UploadTypes.IMAGE;
  }
}
