import {
  OriginXTypes,
  OriginYTypes,
  getOriginX,
  getOriginY,
} from "@paperdateco/common/dto/design/layer/common/DesignLayerProperties";

import CanvasAnimationUtils from "../controls/animation/CanvasAnimationUtils";
import CustomCanvasObjectProps from "../types/CustomCanvasObjectProps";
import DesignLayerPropertiesDto from "@paperdateco/common/dto/design/layer/common/DesignLayerPropertiesDto";
import { IObjectOptions } from "fabric/fabric-impl";
import LayerOptions from "@paperdateco/common/canvas/objects/LayerOptions";
import LibraryAnimationDto from "@paperdateco/common/dto/design/library/animation/LibraryAnimationDto";
import StringUtils from "@paperdateco/common/utils/StringUtils";
import { fabric } from "fabric";

type OptionChangeListeners = (options: string[]) => void;

export default class CanvasElement<T extends fabric.Object = fabric.Object> {
  id: string;
  optionChangeListeners: OptionChangeListeners[] = [];
  animation?: LibraryAnimationDto;

  constructor(protected canvas: fabric.Canvas, public nativeElement: T) {
    this.id = StringUtils.getRandomId();
    this.addToCanas();
    this.setupListeners();
  }

  select() {
    this.canvas.setActiveObject(this.nativeElement);
  }

  set<K extends keyof T>(
    key: K,
    value: T[K] | ((value: T[K]) => T[K]),
    notify = true
  ) {
    this.nativeElement.set(key, value);
    if (notify) {
      this.notifyOptionsChange([key as string])();
    }
  }

  addChangeListener(listener: OptionChangeListeners) {
    this.optionChangeListeners.push(listener);
  }

  removeChangeListener(listener: OptionChangeListeners) {
    this.optionChangeListeners = this.optionChangeListeners.filter(
      (l) => l !== listener
    );
  }

  setAnimation(animation?: LibraryAnimationDto, animate = false) {
    this.animation = animation;
    if (animation && animate) {
      CanvasAnimationUtils.animateElementAndRestoreOriginal(
        this.canvas,
        this,
        animation.animationType
      );
    }
    this.notifyOptionsChange([CustomCanvasObjectProps.ANIMATION]);
  }

  static convertDesignPropertyToOptions(
    property: DesignLayerPropertiesDto
  ): IObjectOptions & LayerOptions {
    return {
      top: property.top,
      left: property.left,
      angle: property.angle ?? 0,
      originX: property.originX ?? OriginXTypes.LEFT,
      originY: property.originY ?? OriginYTypes.TOP,
      scaleX: property.scaleX ?? 1,
      scaleY: property.scaleY ?? 1,
      opacity: property.opacity ?? 1,
      flipX: property.flipX ?? false,
      flipY: property.flipY ?? false,
      animation: property.animation,
    };
  }

  public getDesignLayerProperties(): DesignLayerPropertiesDto {
    return {
      top: this.nativeElement.top ?? 0,
      left: this.nativeElement.left ?? 0,
      angle: this.nativeElement.angle ?? 0,
      originX: getOriginX(this.nativeElement.originX ?? OriginXTypes.LEFT),
      originY: getOriginY(this.nativeElement.originY ?? OriginYTypes.TOP),
      scaleX: this.nativeElement.scaleX ?? 1,
      scaleY: this.nativeElement.scaleY ?? 1,
      opacity: this.nativeElement.opacity ?? 1,
      flipX: this.nativeElement.flipX,
      flipY: this.nativeElement.flipY,
      animation: this.animation && {
        animation: this.animation,
        animationType: this.animation.animationType,
        price: this.animation.price,
      },
    };
  }

  protected addToCanas() {
    this.canvas.add(this.nativeElement);
  }

  protected setupListeners() {
    this.nativeElement.on(
      "changed",
      this.notifyOptionsChange.bind(this, ["text"])
    );
    this.nativeElement.on(
      "moving",
      this.notifyOptionsChange.bind(this, ["left", "top"])
    );
    this.nativeElement.on(
      "scaling",
      this.notifyOptionsChange.bind(this, ["scaleX", "scaleY"])
    );
    this.nativeElement.on(
      "rotating",
      this.notifyOptionsChange.bind(this, ["angle"])
    );
  }

  protected notifyOptionsChange = (keys: string[]) => {
    this.optionChangeListeners.forEach((listener) => listener(keys));
    return () => {};
  };
}
