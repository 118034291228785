import { Button, Stack, Typography, styled } from "@mui/material";
import { FreeFonts, PaidFonts } from "@paperdateco/common/utils/fonts/Fonts";
import { useCallback, useEffect, useState } from "react";

import { Box } from "@mui/system";
import CanvasElement from "@paperdateco/shared-frontend/canvas/objects/CanvasElement";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import EditableTextOptions from "@paperdateco/common/canvas/objects/EditableTextOptions";
import { FontWeightTypes } from "@paperdateco/common/dto/design/layer/text/DesignTextProperties";
import InviteInstantPreview from "@paperdateco/shared-frontend/components/invite/InviteInstantPreview";
import InviteUtils from "@paperdateco/shared-frontend/utils/InviteUtils";
import TextFontButton from "@paperdateco/shared-frontend/canvas/controls/text/TextFontButton";
import TextPathControl from "./text/TextPathControl";

const FONT_BUTTON_ID = (font: string) => `font-button-id-${font}`;

const WhiteButton = styled(Button)({
  background: Colors.WHITE,
  color: Colors.PRIMARY,
  borderRadius: 0,
  "&:hover": {
    background: Colors.WHITE,
  },
});

const DefaultHeadingText: EditableTextOptions = {
  text: "Heading",
  fontFamily: "Comic Sans",
  fontSize: 32,
  fontWeight: FontWeightTypes.BOLD,
  fontStyle: "normal",
  left: 50,
  top: 50,
};

const DefaultSubHeadingText: EditableTextOptions = {
  text: "Sub Heading",
  fontFamily: "Comic Sans",
  fontSize: 24,
  fontWeight: "normal",
  fontStyle: "normal",
  left: 50,
  top: 50,
};

const DefaultBodyText: EditableTextOptions = {
  text: "Add Text Here",
  fontFamily: "Comic Sans",
  fontSize: 16,
  fontWeight: "normal",
  fontStyle: "normal",
  left: 50,
  top: 50,
};

interface DesignEditTextSectionProps {
  instantPreview?: InviteInstantPreview;
  selectedLayers: CanvasElement[];
}

export default function DesignEditTextSection({
  instantPreview,
  selectedLayers,
}: DesignEditTextSectionProps) {
  const [fontFamily, setFontFamily] = useState<string>();

  const selectedTextLayers = InviteUtils.getTextLayers(selectedLayers);
  const isSelectedTextLayer = selectedTextLayers.length > 0;

  const updateFontFamily = useCallback((value?: string) => {
    setFontFamily(value);
    if (value) {
      document.getElementById(FONT_BUTTON_ID(value))?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, []);

  useEffect(() => {
    if (isSelectedTextLayer) {
      updateFontFamily(selectedTextLayers[0].nativeElement.fontFamily);
    } else {
      updateFontFamily();
    }
  }, [selectedTextLayers, isSelectedTextLayer, updateFontFamily]);

  const onAddHeading = () => instantPreview?.addText({ ...DefaultHeadingText });

  const onAddSubHeading = () =>
    instantPreview?.addText({ ...DefaultSubHeadingText });

  const onAddBodyText = () => instantPreview?.addText({ ...DefaultBodyText });

  const onAddFontFamily = (fontFamily: string) => {
    updateFontFamily(fontFamily);
    if (isSelectedTextLayer) {
      selectedTextLayers.forEach((layer) =>
        layer.nativeElement.set("fontFamily", fontFamily)
      );
      instantPreview?.onPriceUpdate();
    } else {
      instantPreview?.addText({ ...DefaultBodyText, fontFamily });
    }
  };

  return (
    <Box
      minWidth={180}
      maxWidth={350}
      paddingX={2}
      paddingY={5}
      maxHeight="100%"
      overflow="auto"
    >
      <Typography variant="body2">CLICK TEXT TO ADD</Typography>
      <Stack spacing={2} marginY={1}>
        <WhiteButton variant="contained" size="large" onClick={onAddHeading}>
          INSERT HEADING
        </WhiteButton>
        <WhiteButton variant="contained" size="large" onClick={onAddSubHeading}>
          INSERT SUB-HEADING
        </WhiteButton>
        <WhiteButton variant="contained" size="large" onClick={onAddBodyText}>
          INSERT BODY TEXT
        </WhiteButton>
      </Stack>
      <TextPathControl selectedLayers={selectedLayers} />
      <Typography variant="body2">FREE FONTS</Typography>
      <Stack marginY={2} textAlign="center">
        {FreeFonts.sort().map((font) => (
          <TextFontButton
            id={FONT_BUTTON_ID(font)}
            key={font}
            font={font}
            selected={font === fontFamily}
            onClick={onAddFontFamily}
          />
        ))}
      </Stack>
      <Typography variant="body2">PAID FONTS</Typography>
      <Stack marginY={2} textAlign="center">
        {PaidFonts.sort().map((font) => (
          <TextFontButton
            id={FONT_BUTTON_ID(font)}
            key={font}
            font={font}
            selected={font === fontFamily}
            onClick={onAddFontFamily}
          />
        ))}
      </Stack>
    </Box>
  );
}
