import CartItemDto from "../dto/order/cart/CartItemDto";
import CartItemModel from "../dto/order/cart/CartItemModel";
import CustomDesignDto from "@paperdateco/common/dto/design/custom/CustomDesignDto";
import CustomDesignModel from "../dto/design/custom/CustomDesignModel";
import DesignAnimationComponentDto from "../dto/design/components/animation/DesignAnimationComponentDto";
import DesignAnimationComponentRequestDto from "../dto/design/components/animation/DesignAnimationComponentRequestDto";
import DesignImageComponentDto from "../dto/design/components/image/DesignImageComponentDto";
import DesignImageComponentRequestDto from "../dto/design/components/image/DesignImageComponentRequestDto";
import DesignLayerDto from "../dto/design/layer/DesignLayerDto";
import DesignLayerRequestDto from "../dto/design/layer/DesignLayerRequestDto";
import DesignLayerType from "../dto/design/layer/DesignLayerType";
import DesignPageDto from "../dto/design/pages/DesignPageDto";
import { FreeFonts } from "./fonts/Fonts";
import LibraryImageDto from "../dto/design/library/image/LibraryImageDto";
import MapUtils from "./MapUtils";
import NumberUtils from "./NumberUtils";
import OrderItemDto from "@paperdateco/common/dto/order/details/OrderItemDto";
import OrderItemModal from "../dto/order/details/OrderItemModel";
import PriceConstants from "./PriceConstants";
import ProductDto from "../dto/product/ProductDto";
import ProductModel from "../dto/product/ProductModel";

export default class DesignUtils {
  public static getImageFromProduct(product: ProductModel | ProductDto) {
    if (product.useCustomImage) {
      return product.image ?? product.design?.pages?.[0].preview;
    }
    return product.design?.pages?.[0].preview ?? product.image;
  }

  public static getImageFromCustomDesign(
    customDesign: CustomDesignDto | CustomDesignModel
  ) {
    return (
      customDesign.pages[0].preview ??
      DesignUtils.getImageFromProduct(customDesign.product)
    );
  }

  public static getImageFromOrderItem(
    orderItem: OrderItemDto | OrderItemModal
  ) {
    return (
      DesignUtils.getImageFromCustomDesign(orderItem.customDesign) ??
      DesignUtils.getImageFromProduct(orderItem.product)
    );
  }

  public static getImageFromCartItem(cartItem: CartItemDto | CartItemModel) {
    return (
      DesignUtils.getImageFromCustomDesign(cartItem.customDesign) ??
      DesignUtils.getImageFromProduct(cartItem.product)
    );
  }

  public static getRequestLayers(
    layers: DesignLayerDto[]
  ): DesignLayerRequestDto[] {
    return layers.map((layer): DesignLayerRequestDto => {
      const requestLayer: DesignLayerRequestDto = {
        type: layer.type,
      };
      if (layer.textProperties) {
        requestLayer.textProperties = {
          ...layer.textProperties,
          animation: DesignUtils.getAnimationRequest(
            layer.textProperties.animation
          ),
        };
      }
      if (layer.imageProperties) {
        requestLayer.imageProperties = {
          ...layer.imageProperties,
          image: DesignUtils.getImageRequest(layer.imageProperties.image.image),
          animation: DesignUtils.getAnimationRequest(
            layer.imageProperties.animation
          ),
        };
      }
      if (layer.imageMaskProperties) {
        requestLayer.imageMaskProperties = {
          ...layer.imageMaskProperties,
          animation: DesignUtils.getAnimationRequest(
            layer.imageMaskProperties.animation
          ),
          mask: DesignUtils.getImageRequest(
            layer.imageMaskProperties.mask.image
          ),
          maskedImage: layer.imageMaskProperties.maskedImage
            ? {
                ...layer.imageMaskProperties.maskedImage,
                image: DesignUtils.getImageRequest(
                  layer.imageMaskProperties.maskedImage.image.image
                ),
              }
            : undefined,
        };
      }
      return requestLayer;
    });
  }

  public static getAnimationRequest(
    animation?: DesignAnimationComponentDto
  ): DesignAnimationComponentRequestDto | undefined {
    if (!animation) {
      return undefined;
    }
    return {
      animation: animation.animation.id,
      animationType: animation.animation.animationType,
      price: animation.animation.price,
    };
  }

  public static getImageRequest(
    image: LibraryImageDto
  ): DesignImageComponentRequestDto {
    return {
      image: image.id,
      price: image.price,
      url: image.url,
    };
  }

  public static getImageComponent(
    image?: LibraryImageDto
  ): DesignImageComponentDto | undefined {
    if (!image) {
      return undefined;
    }
    return {
      image: image,
      price: image.price,
      url: image.url,
    };
  }

  public static calculatePagePrice(page: DesignPageDto) {
    const textPrice = DesignUtils.calculateTextPrice(page.layers);
    const premiumElementsPrice = DesignUtils.calculatePremiumElementsPrice(
      page.layers,
      page.background
    );

    return [textPrice, premiumElementsPrice];
  }

  public static calculateTextPrice(layers: DesignLayerDto[]) {
    const fonts = layers
      .filter((layer) => layer.type === DesignLayerType.TEXT)
      .map((layer) => layer.textProperties?.fontFamily);

    const fontsMap: { [name: string]: boolean } = {};
    fonts.forEach((font) => {
      if (!font) {
        return;
      }
      fontsMap[font] = true;
    });
    const uniqueFonts = Object.keys(fontsMap);

    const costs = uniqueFonts.map((font) =>
      FreeFonts.includes(font) ? 0 : PriceConstants.PAID_FONTS_PRICE
    );
    return NumberUtils.sum(costs);
  }

  public static calculateEnvelopePrice(design: CustomDesignDto) {
    return design.components.coverEnvelope !== undefined ||
      design.components.frontEnvelope !== undefined ||
      design.components.innerEnvelope !== undefined
      ? PriceConstants.ENVELOPE_PRICE
      : 0;
  }

  public static calculatePremiumElementsPrice(
    layers: DesignLayerDto[],
    background?: DesignImageComponentDto
  ) {
    const images = layers
      .flatMap(DesignUtils.getImages)
      .filter((image): image is LibraryImageDto => image !== undefined);

    const imagesMap = MapUtils.groupBy(images, (image) => image.id);

    const animationPrices = layers
      .map(DesignUtils.getAnimation)
      .map((animation) => animation?.animation.price ?? animation?.price ?? 0);

    const costs = Object.values(imagesMap).map((image) => image.price);
    const cost =
      NumberUtils.sum(costs) +
      DesignUtils.getDesignLibraryPrice(background) +
      NumberUtils.sum(animationPrices);
    return cost;
  }

  public static getDesignLibraryPrice(libraryImage?: DesignImageComponentDto) {
    return libraryImage?.image?.price ?? libraryImage?.price ?? 0;
  }

  public static getAnimation(layer: DesignLayerDto) {
    switch (layer.type) {
      case DesignLayerType.TEXT:
        return layer.textProperties?.animation;
      case DesignLayerType.IMAGE:
        return layer.imageProperties?.animation;
      case DesignLayerType.MASK:
        return layer.imageMaskProperties?.animation;
    }
  }

  public static getImages(layer: DesignLayerDto) {
    switch (layer.type) {
      case DesignLayerType.IMAGE:
        return [layer.imageProperties?.image.image];
      case DesignLayerType.MASK:
        return [
          layer.imageMaskProperties?.mask.image,
          layer.imageMaskProperties?.maskedImage?.image.image,
        ];
      default:
        return [];
    }
  }
}
