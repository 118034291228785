import { useEffect, useState } from "react";

import { Box } from "@mui/system";
import CollaboratorApi from "@paperdateco/common/api/product/CollaboratorApi";
import CollaboratorDto from "@paperdateco/common/dto/product/collaborator/CollaboratorDto";
import CollaboratorItem from "./CollaboratorItem";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";

export default function CollaboratorList() {
  const [collaborators, setCollaborators] = useState<CollaboratorDto[]>([]);

  useEffect(() => {
    CollaboratorApi.getAllCollaborators()
      .then(setCollaborators)
      .catch(NotificationUtils.showGenericError);
  }, []);

  const filteredCollaborators = collaborators.filter(
    (user) => user.profilePicture
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="center"
      marginY={5}
    >
      {filteredCollaborators.map((collaborator) => (
        <CollaboratorItem key={collaborator.id} collaborator={collaborator} />
      ))}
    </Box>
  );
}
