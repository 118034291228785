import CanvasElement from "./CanvasElement";
import CanvasUtils from "../CanvasUtils";
import DesignImagePropertiesDto from "@paperdateco/common/dto/design/layer/image/DesignImagePropertiesDto";
import DesignImageType from "@paperdateco/common/dto/design/layer/image/DesignImageType";
import DownloadUtils from "@paperdateco/common/utils/DownloadUtils";
import FabricGif from "./gif/FabricGif";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import ResizableImageOptions from "@paperdateco/common/canvas/objects/ResizableImageOptions";
import { fabric } from "fabric";

export default class ResizableImageElement extends CanvasElement<fabric.Image> {
  constructor(
    public image: LibraryImageDto,
    public type: DesignImageType,
    canvas: fabric.Canvas,
    canvasImage: fabric.Image
  ) {
    super(canvas, canvasImage);
  }

  static async fromOptions(
    canvas: fabric.Canvas,
    { image, type, animation, ...options }: ResizableImageOptions
  ) {
    const canvasImage = await ResizableImageElement.getImageElement({
      image,
      type,
      ...options,
    });
    const element = new ResizableImageElement(image, type, canvas, canvasImage);
    element.setAnimation(animation?.animation);
    return element;
  }

  static async getImageElement({
    image,
    type,
    ...options
  }: ResizableImageOptions) {
    // We download the image so as to prevent cross origin issues
    // resulting in tainted canvas
    const imageUrl = await DownloadUtils.get(image.url);
    if (type === DesignImageType.IMAGE) {
      return await CanvasUtils.loadImage(imageUrl, options);
    } else {
      const { width, height, ...otherOptions } = options;
      return await FabricGif.fromGif(imageUrl, otherOptions, width, height);
    }
  }

  static convertDesignPropertyToOptions(
    property: DesignImagePropertiesDto
  ): ResizableImageOptions {
    return {
      ...super.convertDesignPropertyToOptions(property),
      image: property.image.image,
      type: property.type,
      width: property.width,
      height: property.height,
    };
  }

  getDesignLayerProperties = (): DesignImagePropertiesDto => {
    const type = this.type;
    return {
      ...super.getDesignLayerProperties(),
      image: {
        image: this.image,
        url: this.image.url,
        price: this.image.price,
      },
      type,
      width: this.nativeElement.width ?? 0,
      height: this.nativeElement.height ?? 0,
    };
  };
}
