import { PropsWithChildren, createContext, useCallback } from "react";

type Callback = (message: any) => void;

let listenerId = 1;

export interface MessageContextProps {
  send: (messageId: string, message: any) => void;
  receive: (messageId: string, callback: Callback) => number;
  stop: (messageId: string, listenerId: number) => void;
}

export const MessageContext = createContext<MessageContextProps>({
  send: () => null,
  receive: () => 0,
  stop: () => null,
});

interface Listener {
  listenerId: number;
  messageId: string;
  callback: Callback;
}

const listeners: { [id: string]: Listener[] } = {};

export default function MessageProvider({ children }: PropsWithChildren<{}>) {
  const send = useCallback((messageId: string, message: any) => {
    listeners[messageId]?.forEach((listener) => listener.callback(message));
  }, []);

  const receive = useCallback((messageId: string, callback: Callback) => {
    listenerId++;

    listeners[messageId]?.push({ listenerId, messageId, callback });
    return listenerId;
  }, []);

  const stop = useCallback((messageId: string, listenerId: number) => {
    listeners[messageId] = (listeners[messageId] ?? []).filter(
      (listener) => listener.listenerId !== listenerId
    );
  }, []);

  return (
    <MessageContext.Provider value={{ send, receive, stop }}>
      {children}
    </MessageContext.Provider>
  );
}
