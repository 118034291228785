import { ColorResult, SketchPicker } from "react-color";

import { Box } from "@mui/system";
import CanvasElement from "@paperdateco/shared-frontend/canvas/objects/CanvasElement";
import ColorPicker from "@paperdateco/shared-frontend/components/common/form/ColorPicker";
import useOptionRerender from "@paperdateco/shared-frontend/canvas/hooks/useOptionRerender";

interface CanvasColorPropControlProps<T extends fabric.Object> {
  elements: CanvasElement<T>[];
  prop: keyof T;
  defaultValue: string;
  presetColor: string[];
}

export default function CanvasColorPropControl<T extends fabric.Object>({
  elements,
  prop,
  defaultValue,
  presetColor,
}: CanvasColorPropControlProps<T>) {
  useOptionRerender(elements, prop);

  const onChangeColorHex = (hexString: string) => {
    elements.forEach((elem) =>
      elem.set(prop, hexString as unknown as T[keyof T])
    );
  };

  const onChangeColor = (newColor: ColorResult) =>
    onChangeColorHex(newColor.hex);

  return (
    <Box
      width="100%"
      padding={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <ColorPicker onChangeColor={onChangeColorHex} />
      <SketchPicker
        width="80%"
        color={
          (elements[0]?.nativeElement[prop] as any)?.toString() ?? defaultValue
        }
        onChange={onChangeColor}
        presetColors={presetColor}
      />
    </Box>
  );
}
