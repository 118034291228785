import BannerReducer from "./banner/BannerReducer";
import CartReducer from "./cart/CartReducer";
import CategoryReducer from "./category/CategoryReducer";
import CustomDesignReducer from "./customDesign/CustomDesignReducer";
import InviteCustomiseReducer from "./inviteCustomise/InviteCustomiseReducer";
import OrderReducer from "./order/OrderReducer";
import WishListReducer from "./wishList/WishListReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  category: CategoryReducer,
  inviteCustomise: InviteCustomiseReducer,
  cart: CartReducer,
  customDesign: CustomDesignReducer,
  wishList: WishListReducer,
  order: OrderReducer,
  banner: BannerReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
