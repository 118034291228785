export default class NumberUtils {
  static round(value: number, digits = 2): number {
    return +NumberUtils.roundString(value, digits);
  }

  static roundString(value: number, digits = 2): string {
    return value.toFixed(digits).replace(/\.0+$/, "");
  }

  static sum(values: number[]) {
    let total = 0;
    values.forEach((val) => (total += val));
    return total;
  }
}
