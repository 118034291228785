import { Box, Typography } from "@mui/material";

import AuthFormContainer from "@paperdateco/customer/components/auth/AuthFormContainer";
import Layout from "../../../layout/Layout";
import LoginForm from "@paperdateco/customer/components/auth/LoginForm";

export default function LoginPage() {
  return (
    <Layout>
      <AuthFormContainer>
        <Box textAlign="center">
          <Typography variant="h5">Login</Typography>
          <Typography variant="body1" marginY={2} color="primary.light">
            Great to have you back!
          </Typography>
        </Box>
        <LoginForm />
      </AuthFormContainer>
    </Layout>
  );
}
