import AnimationValue from "./AnimationValue";
import { fabric } from "fabric";

const duration = 1000;
const easing = fabric.util.ease.easeOutQuad;

export default function TumbleAnimation(elem: fabric.Object): AnimationValue[] {
  return [
    {
      property: "left",
      value: {
        from: (elem.left ?? 0) - 1000,
        to: elem.left ?? 0,
        easing,
        duration,
      },
    },
    {
      property: "top",
      value: {
        from: (elem.top ?? 0) - 1000,
        to: elem.top ?? 0,
        easing,
        duration,
      },
    },
    {
      property: "angle",
      value: {
        from: (elem.angle ?? 0) + 100,
        to: elem.angle ?? 0,
        easing,
        duration,
      },
    },
    {
      property: "opacity",
      value: {
        from: 0,
        to: elem.opacity ?? 1,
        easing,
        duration,
      },
    },
  ];
}
