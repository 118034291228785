import { Box, Button, Stack, Typography } from "@mui/material";
import { FormEvent, useState } from "react";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import CustomDateTimePicker from "@paperdateco/shared-frontend/components/common/form/date/CustomDateTimePicker";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import DesignUtils from "@paperdateco/common/utils/DesignUtils";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import RequestFormApi from "@paperdateco/common/api/RequestFormApi";

const Container = CustomStyled(Box)(({ theme }) => ({
  background: Colors.WHITE,
  maxWidth: 400,
  maxHeight: "90vh",
  width: "90vw",
  minHeight: 600,
  padding: theme.spacing(2),
  border: `1px solid ${Colors.BLACK}`,
  borderRadius: theme.spacing(0.5),
  overflow:"auto"
}));

interface PrintRequestPopupProps {
  product: ProductDto;
  onClose?: () => void;
}

export default function ProductPrintRequestForm({
  product,
  onClose,
}: PrintRequestPopupProps) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [quantity, setQuantity] = useState("");
  const [deliveryDate, setDeliveryDate] = useState<Date>();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await RequestFormApi.productPrintRequest({
        name,
        email: email.toLowerCase(),
        contact: Number(contact),
        quantity: Number(quantity),
        deliveryDate: deliveryDate ? deliveryDate : new Date(),
      });
      onClose?.();
      NotificationUtils.showSuccess(
        "Request for printing Form Submission successful"
      );
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  return (
    <Container display="flex" flexDirection="column" fontFamily={Fonts.Roboto}>
      <Typography variant="h6" textAlign="center" letterSpacing="0.2em">
        PRINT REQUEST SUMMARY
      </Typography>
      <Box margin="auto" padding={2}>
        <HiddenImage
          display="block"
          width="100%"
          minWidth={200}
          aspectRatio={product.design.width / product.design.height}
          src={DesignUtils.getImageFromProduct(product)}
        />
      </Box>
      <form onSubmit={handleSubmit}>
        <Stack direction="column" spacing={2} padding={2}>
          <CustomTextField label="Full Name" value={name} onChange={setName} />
          <CustomTextField
            label="Email Address"
            type="email"
            value={email}
            onChange={setEmail}
          />
          <CustomTextField
            label="Contact Number"
            type="Number"
            value={contact}
            onChange={setContact}
          />
        </Stack>
        <Stack direction="row" spacing={2} padding={2} paddingTop={0}>
          <CustomTextField
            label="Quantity"
            type="Number"
            value={quantity}
            onChange={setQuantity}
          />
          <CustomDateTimePicker
            label="Delivery Date"
            inputFormat="dd/MM/yyyy h:mm a"
            value={deliveryDate}
            onChange={setDeliveryDate}
            PopperProps={{ style: { fontFamily: Fonts.Roboto } }}
          />
        </Stack>

        <Stack direction="row" spacing={2} justifyContent="space-evenly">
          <Button type="submit" variant="contained" size="large">
            SUBMIT
          </Button>

          <Button variant="contained" size="large" onClick={() => onClose?.()}>
            CANCEL
          </Button>
        </Stack>
      </form>
    </Container>
  );
}
