import CustomDesignDto from "@paperdateco/common/dto/design/custom/CustomDesignDto";
import DesignLayerDto from "@paperdateco/common/dto/design/layer/DesignLayerDto";
import DesignLayerType from "@paperdateco/common/dto/design/layer/DesignLayerType";
import DesignImageType from "@paperdateco/common/dto/design/layer/image/DesignImageType";
import DesignPageDto from "@paperdateco/common/dto/design/pages/DesignPageDto";
import DownloadUtils from "@paperdateco/common/utils/DownloadUtils";
import CanvasBackground from "@paperdateco/customer/assets/images/canvasbg.jpeg";
import CanvasUtils from "@paperdateco/shared-frontend/canvas/CanvasUtils";
import CanvasAnimationUtils from "@paperdateco/shared-frontend/canvas/controls/animation/CanvasAnimationUtils";
import EditableTextElement from "@paperdateco/shared-frontend/canvas/objects/EditableTextElement";
import FabricGif from "@paperdateco/shared-frontend/canvas/objects/gif/FabricGif";
import { fabric } from "fabric";

type AnimationFn = () => Promise<void>;

interface LayerObject {
  layer: fabric.Object;
  animations: AnimationFn[];
}

export default class InvitePreview {
  static stampUrl =
    "https://asset.paperdateco.com/cloudinary/utils/pvd2szevvo7wx8mh2xcx.png";

  coverImage?: fabric.Group;
  inviteImages: LayerObject[] = [];
  inviteImagesGroup?: fabric.Group;
  invitationLayers: fabric.Object[] = [];
  inviteBackImage?: fabric.Image;
  inviteGroup?: fabric.Group;
  frontEnvelopeImage?: fabric.Image;
  innerEnvelopeImage?: fabric.Image;
  frontEnvelopeMaskImage?: fabric.Image;

  inviteHeight: number = 0;

  constructor(
    private canvas: fabric.Canvas,
    private width: number,
    private height: number
  ) {
    this.setDefaultConfig();
  }

  async addBackgroundImage() {
    const backgroundImage = await CanvasUtils.loadImage(CanvasBackground);

    const scale = CanvasUtils.scaleImageAsCover(
      backgroundImage,
      this.width,
      this.height
    );

    this.canvas.setBackgroundImage(
      backgroundImage,
      () => CanvasUtils.reRender(this.canvas),
      {
        top: scale.top,
        left: scale.left,
        originX: "left",
        originY: "top",
        scaleX: scale.scaleFactor,
        scaleY: scale.scaleFactor,
      }
    );
  }

  async addCoverEnvelope(image?: string) {
    if (!image) {
      return;
    }
    const imageBlob = await DownloadUtils.get(image);
    const coverEnvelopeWidth = Math.min(this.width + 300, 2500);
    const coverImage = await CanvasUtils.loadImage(imageBlob, {
      width: coverEnvelopeWidth,
    });
    const coverStampImageBlob = await DownloadUtils.get(InvitePreview.stampUrl);
    const coverStampImage = await CanvasUtils.loadImage(coverStampImageBlob, {
      width: 0.1 * coverEnvelopeWidth,
      left: 0.59 * coverEnvelopeWidth,
      top: 0.3 * coverEnvelopeWidth,
    });

    this.coverImage = new fabric.Group([coverImage, coverStampImage]);
    this.coverImage.set({
      originX: "center",
      left: 0.5 * this.width,
      originY: "bottom",
      top: this.height * 2,
      selectable: false,
    });
    this.canvas.add(this.coverImage);
  }

  async animateCoverEnvelope() {
    await CanvasUtils.animate(this.canvas, this.coverImage, "top", {
      to: this.height * 0.9,
    });
    await CanvasUtils.animate(this.canvas, this.coverImage, "left", {
      to: -2 * this.width,
      easing: fabric.util.ease.easeInQuad,
    });
  }

  async addInvitation(customDesign: CustomDesignDto) {
    const frontEnvelope = customDesign.components.frontEnvelope;
    const innerEnvelope = customDesign.components.innerEnvelope;

    this.inviteHeight = Math.min(this.height * 0.6, 1500);
    this.inviteImages = await Promise.all(
      customDesign.pages.map((page) =>
        this.getInvitationPage(customDesign, page, this.inviteHeight * 0.8)
      )
    );
    this.inviteImages[0].layer.opacity = 1;

    this.inviteImagesGroup = new fabric.Group(
      this.inviteImages.map((image) => image.layer)
    );

    if (frontEnvelope && innerEnvelope) {
      const frontEnvelopeBlob = await DownloadUtils.get(frontEnvelope.url);
      this.frontEnvelopeImage = await CanvasUtils.loadImage(frontEnvelopeBlob, {
        height: this.inviteHeight,
      });
      const innerEnvelopeBlob = await DownloadUtils.get(innerEnvelope.url);
      this.innerEnvelopeImage = await CanvasUtils.loadImage(innerEnvelopeBlob, {
        height: this.inviteHeight,
        top: -0.1 * this.inviteHeight,
      });
      this.frontEnvelopeMaskImage = await CanvasUtils.loadImage(
        frontEnvelopeBlob,
        {
          height: this.inviteHeight,
        }
      );
      this.frontEnvelopeMaskImage.set({
        clipPath: new fabric.Rect({
          width: this.frontEnvelopeMaskImage.width,
          height: (this.frontEnvelopeMaskImage.height ?? this.inviteHeight) / 2,
          originY: "top",
          originX: "center",
          left: 0,
        }),
      });
    }

    const images = [
      this.frontEnvelopeImage,
      this.innerEnvelopeImage,
      this.inviteImagesGroup,
      this.frontEnvelopeMaskImage,
    ].filter((item): item is fabric.Image => item !== undefined);

    this.inviteGroup = new fabric.Group(images, {
      originX: "center",
      left: this.width / 2,
      originY: "center",
      top: -this.height / 2,
      width: this.width,
      height: this.height,
      selectable: false,
    });

    // Needs to be set after adding to the group
    this.inviteImagesGroup.set({
      originX: "center",
      originY: "center",
      top: 0,
      left: -5,
    });
    this.inviteImages.forEach(({ layer }) =>
      CanvasUtils.forceRenderElement(layer, this.canvas)
    );
    CanvasUtils.forceRenderElement(this.inviteImagesGroup, this.canvas);
    CanvasUtils.forceRenderElement(this.inviteGroup, this.canvas);
    this.canvas.add(this.inviteGroup);
  }

  async animateInvitation() {
    await CanvasUtils.animate(this.canvas, this.inviteGroup, "top", {
      to: this.height / 2,
    });
    await Promise.all([
      CanvasUtils.animate(this.canvas, this.frontEnvelopeImage, "top", {
        to: this.height,
      }),

      CanvasUtils.animate(this.canvas, this.innerEnvelopeImage, "top", {
        to: this.height - 0.1 * this.inviteHeight,
      }),

      CanvasUtils.animate(this.canvas, this.frontEnvelopeMaskImage, "top", {
        to: this.height,
      }),
      CanvasUtils.animate(this.canvas, this.inviteImagesGroup, "scaleX", {
        to: (this.inviteImagesGroup?.scaleX ?? 1) * 1.3,
      }),

      CanvasUtils.animate(this.canvas, this.inviteImagesGroup, "scaleY", {
        to: (this.inviteImagesGroup?.scaleY ?? 1) * 1.3,
      }),
    ]);
  }

  async animatePages() {
    for (let i = 0; i < this.inviteImages.length; i++) {
      const { layer: inviteImage, animations } = this.inviteImages[i];
      await Promise.all(animations.map((anim) => anim()));
      await CanvasUtils.waitAnimation(3000);
      if (i < this.inviteImages.length - 1) {
        const { layer: nextImage } = this.inviteImages[i + 1];
        CanvasUtils.animate(this.canvas, inviteImage, "opacity", {
          to: 0,
          duration: 500,
        });
        CanvasUtils.animate(this.canvas, nextImage, "opacity", {
          to: 1,
          duration: 500,
        });
      }
    }
  }

  async animateDummy() {
    await CanvasUtils.animate(this.canvas, this.coverImage, "left", {
      to: -3 * this.width,
      duration: 4000,
      easing: fabric.util.ease.easeInQuad,
    });
  }

  async animate(isRecording: boolean) {
    await this.animateCoverEnvelope();
    await this.animateInvitation();
    await this.animatePages();
    if (isRecording) {
      await this.animateDummy();
    }
  }

  private async setDefaultConfig() {
    this.canvas.hoverCursor = "default";
    CanvasUtils.constantlyRerenderCanvas(this.canvas);
    this.listenToFontLoads();
  }

  private listenToFontLoads() {
    document.fonts.ready.then(this.forceRerenderText);
    document.fonts.onloadingdone = this.forceRerenderText;
  }

  forceRerenderText = () => {
    if ((this.invitationLayers?.length ?? 0) === 0) {
      setTimeout(this.forceRerenderText, 500);
      return;
    }
    fabric.util.clearFabricFontCache();
    this.invitationLayers?.forEach((layer) => {
      if (layer instanceof fabric.Text) {
        layer.dirty = true;
        layer.initDimensions();
      }
    });
  };

  private async getInvitationPage(
    customDesign: CustomDesignDto,
    page: DesignPageDto,
    height: number
  ): Promise<LayerObject> {
    const designWidth = customDesign.width;
    const designHeight = customDesign.height;

    const invitationLayers: fabric.Object[] = [];
    const animations: (() => [AnimationFn, () => void])[] = [];
    for (let layer of page.layers) {
      const canvasLayer = await this.getCanvasObjectFromLayer(layer);
      if (canvasLayer) {
        invitationLayers.push(canvasLayer);
        const animation = this.getAnimationFromLayer(layer);
        if (animation) {
          const animFn = CanvasAnimationUtils.animateElement.bind(
            CanvasAnimationUtils,
            this.canvas,
            canvasLayer,
            animation.animationType
          );
          animations.push(animFn);
        }
      }
    }
    const inviteBoundary = this.getInviteBoundary(designWidth, designHeight);
    invitationLayers.push(inviteBoundary);

    if (page.background) {
      const backgroundImage = await this.getScaledBackgroundImage(
        page.background.url,
        designWidth,
        designHeight
      );
      invitationLayers.unshift(backgroundImage);
    }

    const invitationGroup = new fabric.Group(invitationLayers, { opacity: 0 });
    invitationGroup.scaleToHeight(height);
    this.hideInvitationOverflow(
      invitationGroup,
      inviteBoundary,
      designWidth,
      designHeight
    );
    this.invitationLayers.push(...invitationLayers);

    return {
      layer: invitationGroup,
      animations: animations.map((anim) => anim()[0]),
    };
  }

  private getInviteBoundary(width: number, height: number) {
    return new fabric.Rect({
      width,
      height,
      left: 0,
      top: 0,
      strokeWidth: 0,
      fill: "rgba(0,0,0,0)", // Transparant color
    });
  }

  private async getScaledBackgroundImage(
    image: string,
    width: number,
    height: number
  ) {
    const backgroundBlob = await DownloadUtils.get(image);
    const backgroundImage = await CanvasUtils.loadImage(backgroundBlob);
    backgroundImage.scaleX = backgroundImage.width
      ? width / backgroundImage.width
      : 1;
    backgroundImage.scaleY = backgroundImage.height
      ? height / backgroundImage.height
      : 1;
    return backgroundImage;
  }

  private async hideInvitationOverflow(
    invitationGroup: fabric.Group,
    backgroundImage: fabric.Object,
    width: number,
    height: number
  ) {
    const viewportTransform = this.canvas.viewportTransform;
    if (viewportTransform) {
      // Background image is used to align the mask from the center of the group
      // as the group includes all the elements including the ones outside the invitation
      const backgroundPosition = CanvasUtils.computeObjectPosition(
        backgroundImage,
        viewportTransform
      );
      // clip outside the design width and height
      invitationGroup.clipPath = new fabric.Rect({
        width: width,
        height: height,
        left: -width / 2 + backgroundPosition.centerX,
        top: -height / 2 + backgroundPosition.centerY,
      });
    }
  }

  private getAnimationFromLayer(layer: DesignLayerDto) {
    switch (layer.type) {
      case DesignLayerType.TEXT:
        return layer.textProperties?.animation;
      case DesignLayerType.IMAGE:
        return layer.imageProperties?.animation;
      case DesignLayerType.MASK:
        return layer.imageMaskProperties?.animation;
    }
  }

  private async getCanvasObjectFromLayer(
    layer: DesignLayerDto
  ): Promise<fabric.Object | undefined> {
    switch (layer.type) {
      case DesignLayerType.TEXT:
        if (!layer.textProperties) {
          return undefined;
        }
        return new fabric.Text(
          layer.textProperties.text,
          EditableTextElement.convertDesignPropertyToOptions(
            layer.textProperties
          )
        );
      case DesignLayerType.IMAGE:
        if (!layer.imageProperties) {
          return undefined;
        }
        const { image, ...options } = layer.imageProperties;
        const imageUrl = await DownloadUtils.get(image.url);
        switch (layer.imageProperties.type) {
          case DesignImageType.IMAGE:
            return CanvasUtils.loadImage(imageUrl, options);
          case DesignImageType.GIF:
            const { width, height, ...otherOptions } = options;
            return FabricGif.fromGif(imageUrl, otherOptions, width, height);
        }
      // eslint-disable-next-line no-fallthrough
      case DesignLayerType.MASK:
        if (!layer.imageMaskProperties) {
          return undefined;
        }
        const { mask, maskedImage, ...maskOptions } = layer.imageMaskProperties;
        const maskUrl = await DownloadUtils.get(mask.url);
        const group = await CanvasUtils.loadMask(maskUrl, maskOptions);

        if (maskedImage) {
          const imageUrl = await DownloadUtils.get(maskedImage.image.url);
          let canvasImage: fabric.Image;
          switch (maskedImage.type) {
            case DesignImageType.IMAGE:
              canvasImage = await CanvasUtils.loadImage(imageUrl, {
                ...maskedImage,
              });
              break;
            case DesignImageType.GIF:
              canvasImage = await FabricGif.fromGif(imageUrl, {
                ...maskedImage,
              });
          }
          const { patternSourceCanvas } = CanvasUtils.loadMaskImage(
            group,
            canvasImage
          );
          canvasImage.set("scaleX", maskedImage.scaleX);
          canvasImage.set("scaleY", maskedImage.scaleY);
          canvasImage.set("left", maskedImage.offsetX);
          canvasImage.set("top", maskedImage.offsetY);
          CanvasUtils.setPatternCanvasDimension(patternSourceCanvas, group);
        }
        return group;
    }
  }
}
