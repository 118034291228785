import { CircularProgress, Stack, Typography } from "@mui/material";

import ModalContainer from "@paperdateco/shared-frontend/components/layout/ModalContainer";

interface DownloadPreparationMessageProps {
  message: string;
}

export default function DownloadPreparationMessage({
  message,
}: DownloadPreparationMessageProps) {
  return (
    <ModalContainer padding={1.5}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <CircularProgress />
        <Typography variant="body2">{message}</Typography>
      </Stack>
    </ModalContainer>
  );
}
