import { Box, IconButton, Stack, Typography } from "@mui/material";

import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";

export default function ContactUsDetails() {
  return (
    <Box textAlign={"center"}>
      <Stack direction="column" spacing={1} margin={1}>
        <Typography variant="h5">Get a Quote</Typography>
        <Typography variant="h6">
          Fill up the form and our Team will get back to you
        </Typography>
      </Stack>
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems="center"
        justifyContent="center"
        spacing={{ xs: 2, md: 10 }}
        margin={1}
        padding={1}
      >
        <a
          href="https://www.google.co.in/maps/search/Road+No+9,+Jubilee+Hills,+Hyderabad,+Telangana-500033"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Stack direction="column" alignItems="center">
            <IconButton color="inherit">
              <LocationOnIcon />
            </IconButton>
            <Typography variant="body1">Road No 9, Jubilee Hills,</Typography>
            <Typography variant="body1">Hyderabad, Telangana-500033</Typography>
          </Stack>
        </a>
        <a href="tel:+917093300007" target="_blank" rel="noreferrer noopener">
          <Stack direction="column" alignItems="center">
            <IconButton color="inherit">
              <PhoneIcon />
            </IconButton>
            <Typography variant="body1">+91 70933 00007</Typography>
          </Stack>
        </a>
        <a
          href="mailto:admin@paperdateco.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Stack direction="column" alignItems="center">
            <IconButton color="inherit">
              <EmailIcon />
            </IconButton>
            <Typography variant="body1">admin@paperdateco.com</Typography>
          </Stack>
        </a>
      </Stack>
    </Box>
  );
}
