import AnimationValue from "./AnimationValue";
import { fabric } from "fabric";

export default function NeonAnimation(elem: fabric.Object): AnimationValue[] {
  return [
    {
      property: "opacity",
      value: {
        from: 0,
        to: elem.opacity ?? 1,
        duration: 900,
        easing: fabric.util.ease.easeInOutElastic,
      },
    },
  ];
}
