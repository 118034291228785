import { CanvasPageContext } from "@paperdateco/shared-frontend/canvas/provider/CanvasPageProvider";
import CanvasPageControls from "@paperdateco/shared-frontend/canvas/controls/page/CanvasPageControls";
import InviteInstantPreviewCanvas from "@paperdateco/shared-frontend/components/invite/InviteInstantPreviewCanvas";
import ZoomableCanvas from "@paperdateco/shared-frontend/canvas/controls/zoom/ZoomableCanvas";
import { useContext } from "react";

interface InvitePaginatedInstantPreviewProps {
  width: number;
  height: number;
}

export default function InvitePaginatedInstantPreview({
  width,
  height,
}: InvitePaginatedInstantPreviewProps) {
  const { pages, setInstatPreview, currentPageIndex, previewZoom } =
    useContext(CanvasPageContext);

  return (
    <CanvasPageControls>
      {pages.map((page, index) => (
        <ZoomableCanvas
          zoom={previewZoom}
          defaultWidth={width}
          defaultHeight={height}
          key={page.id}
          visible={index === currentPageIndex}
        >
          <InviteInstantPreviewCanvas
            width={width}
            height={height}
            onInit={setInstatPreview}
            page={page}
          />
        </ZoomableCanvas>
      ))}
    </CanvasPageControls>
  );
}
