import { ChangeEvent, useCallback } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

export interface CustomRadioOptionType<T> {
  name: string;
  value: T;
}

interface CustomRadioGroupProps<T> {
  label?: string;
  options: CustomRadioOptionType<T>[];
  value: T;
  onChange: (value: T) => void;
  row?: boolean;
}

export default function CustomRadioGroup<T>({
  label,
  options,
  value,
  onChange,
  row = false,
}: CustomRadioGroupProps<T>) {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value as unknown as T);
    },
    [onChange]
  );

  return (
    <FormControl component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup
        aria-label={label}
        row={row}
        value={value}
        onChange={handleChange}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.name}
            value={option.value}
            control={<Radio />}
            label={option.name}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
