import { Box } from "@mui/system";
import CustomDesignDto from "@paperdateco/common/dto/design/custom/CustomDesignDto";
import InviteDownloadButtons from "./common/InviteDownloadButtons";
import InvitePreviewCanvas from "./InvitePreviewCanvas";
import { useDimensionRef } from "@paperdateco/shared-frontend/canvas/useDimensionRef";

interface InvitePreviewAnimationProps {
  customDesign: CustomDesignDto;
  showDownload?: boolean;
}

export default function InvitePreviewAnimation({
  customDesign,
  showDownload = false,
}: InvitePreviewAnimationProps) {
  const [containerRef, width, height] = useDimensionRef();

  return (
    <Box ref={containerRef} flexGrow={1} overflow="hidden" position="relative">
      <InvitePreviewCanvas
        width={width}
        height={height}
        customDesign={customDesign}
      />
      {showDownload && (
        <Box
          position="fixed"
          bottom={20}
          left={0}
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <InviteDownloadButtons
            customDesign={customDesign}
            direction="row"
            justifyContent="center"
            maxWidth={500}
          />
        </Box>
      )}
    </Box>
  );
}
