import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import SwiperCore, {
  A11y,
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper";

import { ReactNode } from "react";
import { SwiperOptions } from "swiper/types/swiper-options";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]);

const DefaultSlidesPerViewOption: SwiperOptions["slidesPerView"] = 1;
const DefaultLoopOption: SwiperOptions["loop"] = true;
const DefaultAutoPlayOption: SwiperOptions["autoplay"] = {
  delay: 10000,
  pauseOnMouseEnter: true,
  stopOnLastSlide: false,
  waitForTransition: true,
};
const DefaultPaginationOption: SwiperOptions["pagination"] = {
  clickable: true,
};
const DefaultSpeedOption: SwiperOptions["speed"] = 4000;
const DefaultEffectOption: SwiperOptions["effect"] = "fade";

interface CustomSwiperProps {
  slides: ReactNode[];
}

export default function CustomSwiper({
  slides,
  ...props
}: CustomSwiperProps & SwiperProps) {
  return (
    <Swiper
      {...props}
      slidesPerView={props.slidesPerView ?? DefaultSlidesPerViewOption}
      loop={props.loop ?? DefaultLoopOption}
      autoplay={props.autoplay ?? DefaultAutoPlayOption}
      pagination={props.pagination ?? DefaultPaginationOption}
      speed={props.speed ?? DefaultSpeedOption}
      effect={props.effect ?? DefaultEffectOption}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>{slide}</SwiperSlide>
      ))}
      {props.children}
    </Swiper>
  );
}
