import DesignAnimationComponent from "../../components/animation/DesignAnimationComponent";

export enum OriginXTypes {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
}

export enum OriginYTypes {
  TOP = "top",
  BOTTOM = "bottom",
  CENTER = "center",
}

export const getOriginX = (originX?: string): OriginXTypes => {
  switch (originX) {
    case OriginXTypes.CENTER:
      return OriginXTypes.CENTER;
    case OriginXTypes.RIGHT:
      return OriginXTypes.RIGHT;
    case OriginXTypes.LEFT:
    default:
      return OriginXTypes.LEFT;
  }
};

export const getOriginY = (originY?: string): OriginYTypes => {
  switch (originY) {
    case OriginYTypes.BOTTOM:
      return OriginYTypes.BOTTOM;
    case OriginYTypes.CENTER:
      return OriginYTypes.CENTER;
    case OriginYTypes.TOP:
    default:
      return OriginYTypes.TOP;
  }
};

export default interface DesignLayerProperties {
  left: number;
  top: number;
  angle?: number;
  originX?: OriginXTypes;
  originY?: OriginYTypes;
  scaleX?: number;
  scaleY?: number;
  opacity?: number;
  flipX?: boolean;
  flipY?: boolean;
  animation?: DesignAnimationComponent;
}
