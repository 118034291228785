import PrintedInvitationDto from "@paperdateco/common/dto/form/contactUs/printedInvitation/PrintedInvitationDto";

const DefaultPrintedInvitationForm: PrintedInvitationDto = {
  quantity: 0,
  inserts: 0,
  folder: "",
  deliveryDate: new Date(),
  deliveryLocation: "",
};

export default DefaultPrintedInvitationForm;
