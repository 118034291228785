import { Dispatch, SetStateAction, useCallback } from "react";

import CustomDateTimePicker from "@paperdateco/shared-frontend/components/common/form/date/CustomDateTimePicker";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import FormRow from "@paperdateco/shared-frontend/components/common/form/FormRow";
import GiftingDto from "@paperdateco/common/dto/form/contactUs/gifting/GiftingDto";
import produce from "immer";

interface GiftingFormProps {
  giftingForm: GiftingDto;
  setGiftingForm: Dispatch<SetStateAction<GiftingDto>>;
}

export default function GiftingForm({
  giftingForm,
  setGiftingForm,
}: GiftingFormProps) {
  const onChangeGiftingOption = useCallback(
    (value: string) =>
      setGiftingForm(
        produce((draft) => {
          draft.giftingOption = value;
        })
      ),
    [setGiftingForm]
  );

  const onChangeQuantity = useCallback(
    (value: string) =>
      setGiftingForm(
        produce((draft) => {
          draft.quantity = Number(value);
        })
      ),
    [setGiftingForm]
  );

  const handleDate = useCallback(
    (value?: Date) => {
      if (!value) {
        return;
      }
      setGiftingForm(
        produce((draft) => {
          draft.deliveryDate = value;
        })
      );
    },
    [setGiftingForm]
  );

  const onChangeDeliveryLocation = useCallback(
    (value: string) => {
      setGiftingForm(
        produce((draft) => {
          draft.deliveryLocation = value;
        })
      );
    },
    [setGiftingForm]
  );

  return (
    <>
      <FormRow>
        <CustomTextField
          label="Tell us what kind of gifting option you are looking"
          multiline
          rows={4}
          required={false}
          value={giftingForm.giftingOption}
          onChange={onChangeGiftingOption}
        />
      </FormRow>
      <FormRow>
        <CustomTextField
          label="Quantity"
          type="number"
          required={giftingForm.quantity > 0}
          value={giftingForm.quantity}
          onChange={onChangeQuantity}
        />
      </FormRow>
      <CustomDateTimePicker
        label="Delivery Date and Time"
        inputFormat="dd/MM/yyyy h:mm a"
        value={giftingForm.deliveryDate}
        onChange={handleDate}
      />
      <FormRow>
        <CustomTextField
          label="Delivery Location"
          multiline
          rows={4}
          required={false}
          value={giftingForm.deliveryLocation}
          onChange={onChangeDeliveryLocation}
        />
      </FormRow>
    </>
  );
}
