import {
  CartStateDispatchType,
  getAllCartItems,
} from "@paperdateco/customer/store/cart/CartAction";
import { Link, useNavigate } from "react-router-dom";

import CartApi from "@paperdateco/common/api/CartApi";
import DesignEditNextButton from "@paperdateco/shared-frontend/components/design/common/DesignEditNextButton";
import DesignEditPreviousButton from "@paperdateco/shared-frontend/components/design/common/DesignEditPreviousButton";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import { Stack } from "@mui/material";
import routes from "@paperdateco/customer/routes/routes";
import { useDispatch } from "react-redux";
import useSingleInvoke from "@paperdateco/shared-frontend/components/common/hooks/useSingleInvoke";

interface PreviewNavigationButtonProps {
  customDesignId: string;
  cartItemId?: string;
}

export default function PreviewNavigationButton({
  customDesignId,
  cartItemId,
}: PreviewNavigationButtonProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch<CartStateDispatchType>();

  const [onPlaceOrder, blocked] = useSingleInvoke(async () => {
    try {
      await CartApi.addItemToCart({
        customDesign: customDesignId,
      });
      dispatch(getAllCartItems());
      navigate(routes.CART);
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  });

  return (
    <Stack
      direction="row"
      width="100%"
      maxWidth={300}
      justifyContent="space-between"
    >
      <Link
        to={
          cartItemId
            ? routes.CART_ITEM_EDIT(cartItemId)
            : routes.CUSTOM_DESIGN_EDIT(customDesignId)
        }
      >
        <DesignEditPreviousButton variant="contained">
          DESIGN
        </DesignEditPreviousButton>
      </Link>
      {cartItemId ? (
        <Link to={routes.CART}>
          <DesignEditNextButton variant="contained" disabled={blocked}>
            CHECKOUT
          </DesignEditNextButton>
        </Link>
      ) : (
        <DesignEditNextButton
          variant="contained"
          onClick={onPlaceOrder}
          disabled={blocked}
        >
          CHECKOUT
        </DesignEditNextButton>
      )}
    </Stack>
  );
}
