import * as Sentry from "@sentry/react";

import { Integrations } from "@sentry/tracing";
import UserDto from "@paperdateco/common/dto/user/UserDto";

export default class SentryUtils {
  public static init() {
    Sentry.init({
      dsn: process.env.REACT_APP_SENRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      environment: process.env.REACT_APP_ENVIRONMENT,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }

  public static setUser(user?: UserDto) {
    if (user) {
      Sentry.setUser({ email: user.email });
    } else {
      Sentry.configureScope((scope) => scope.setUser(null));
    }
  }
}
