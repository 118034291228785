import { Box, BoxProps } from "@mui/system";

import AddressDto from "@paperdateco/common/dto/user/address/AddressDto";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";

interface AddressInputProps {
  address: AddressDto;
  setAddress?: (address: AddressDto) => void;
}

export default function AddressInput({
  address,
  setAddress,
  ...props
}: AddressInputProps & BoxProps) {
  const onNameChange = (name: string) => setAddress?.({ ...address, name });
  const onAddressChange = (value: string) =>
    setAddress?.({ ...address, address: value });
  const onPhoneNumberChange = (phoneNumber: string) =>
    setAddress?.({ ...address, phoneNumber });

  return (
    <Box {...props}>
      <CustomTextField
        label="Name"
        value={address.name}
        onChange={onNameChange}
      />
      <Box marginY={2}>
        <CustomTextField
          multiline
          label="Address"
          value={address.address}
          onChange={onAddressChange}
        />
      </Box>
      <CustomTextField
        type="tel"
        label="Phone Number"
        value={address.phoneNumber}
        onChange={onPhoneNumberChange}
      />
    </Box>
  );
}
