import { Grid, IconButton, Tooltip } from "@mui/material";

import CustomCheckbox from "@paperdateco/shared-frontend/components/common/form/CustomCheckbox";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MailerDto from "@paperdateco/common/dto/order/event/mailer/MailerDto";
import MailerRsvpStatusIcon from "./MailerRsvpStatusIcon";
import MailerWithChooseDto from "../common/MailerWithChooseDto";
import ShareMessageUtils from "@paperdateco/customer/utils/ShareMessageUtils";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useCallback } from "react";

interface MailerItemProps {
  index: number;
  mailer: MailerWithChooseDto;
  onChange: (index: number, mailer: MailerWithChooseDto) => void;
  onRemove: (index: number) => void;
  orderItemId: string;
}

export default function MailerItem({
  index,
  mailer,
  onChange,
  onRemove,
  orderItemId,
}: MailerItemProps) {
  const onChangeField = useCallback(
    (field: keyof MailerDto) => (value: string) => {
      onChange(index, { ...mailer, [field]: value });
    },
    [mailer, index, onChange]
  );

  const onRemoveMailer = useCallback(() => {
    onRemove(index);
  }, [onRemove, index]);

  const onChangeCheckbox = useCallback(
    (value: boolean) => {
      onChange(index, { ...mailer, choosen: value });
    },
    [mailer, index, onChange]
  );

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      marginBottom={2}
      fontSize={14}
      columns={18}
    >
      <Grid item xs={2} sm={1}>
        <CustomCheckbox
          label=""
          checked={mailer.choosen}
          onChange={onChangeCheckbox}
          size="small"
        />
      </Grid>
      <Grid item xs={3} sm={2}>
        {mailer.mailSent && (
          <IconButton size="small" color="primary">
            <EmailOutlinedIcon fontSize="inherit" />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={7} sm={12}>
        <Grid container spacing={2} width="100%">
          <Grid item md={6} sm={9} xs={18}>
            <CustomTextField
              label="Name"
              value={mailer.name}
              onChange={onChangeField("name")}
              size="small"
            />
          </Grid>
          <Grid item md={6} sm={9} xs={18}>
            <CustomTextField
              type="email"
              label="Email"
              value={mailer.email}
              onChange={onChangeField("email")}
              size="small"
            />
          </Grid>
          <Grid item md={6} sm={9} xs={18}>
            <CustomTextField
              label="Number"
              type="tel"
              name="phoneNumber"
              value={mailer.phoneNumber}
              onChange={onChangeField("phoneNumber")}
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} sm={1}>
        <Tooltip
          title={mailer.rsvpStatus?.toUpperCase() ?? ""}
          placement="top-start"
        >
          <IconButton size="small">
            <MailerRsvpStatusIcon
              status={mailer.rsvpStatus}
              fontSize="inherit"
            />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={2} sm={1}>
        <IconButton
          color="primary"
          href={ShareMessageUtils.whatsppMessage(
            orderItemId,
            mailer.phoneNumber
          )}
          target="_blank"
          rel="noopener"
          size="small"
        >
          <FontAwesomeIcon icon={faWhatsapp} size="sm" />
        </IconButton>
      </Grid>

      <Grid item xs={2} sm={1}>
        <IconButton size="small" color="primary" onClick={onRemoveMailer}>
          <DeleteOutlineIcon fontSize="inherit" />
        </IconButton>
      </Grid>
    </Grid>
  );
}
