import { Box, SliderProps } from "@mui/material";

import CanvasNumberPropControl from "@paperdateco/shared-frontend/canvas/controls/common/CanvasNumberPropControl";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import EditableTextElement from "@paperdateco/shared-frontend/canvas/objects/EditableTextElement";
import FormatLineSpacingIcon from "@mui/icons-material/FormatLineSpacing";
import PopupButton from "@paperdateco/shared-frontend/components/common/form/button/PopupButton";

const LetterSpacingSliderProps: SliderProps = {
  step: 1,
  min: -200,
  max: 800,
};

const LineHeightSliderProps: SliderProps = {
  step: 0.01,
  min: 0.5,
  max: 2.5,
};

interface TextSpacingControlProps {
  textElements: EditableTextElement[];
}

export default function TextSpacingControl({
  textElements,
}: TextSpacingControlProps) {
  return (
    <PopupButton
      button={<FormatLineSpacingIcon htmlColor={Colors.WHITE} />}
      popup={
        <Box width={450} paddingY={2}>
          <CanvasNumberPropControl
            elements={textElements}
            prop="charSpacing"
            defaultValue={0}
            label="Letter Spacing"
            sliderProps={LetterSpacingSliderProps}
          />

          <CanvasNumberPropControl
            elements={textElements}
            prop="lineHeight"
            defaultValue={1}
            label="Line Height"
            sliderProps={LineHeightSliderProps}
          />
        </Box>
      }
    />
  );
}
