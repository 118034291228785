import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";

import BackgroundImage from "@paperdateco/shared-frontend/components/common/image/BackgroundImage";
import BlogItemModel from "./BlogItemModel";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";

const BlogContainer = CustomStyled(Container)(({ theme }) => ({
  padding: theme.spacing(4, 0),
  margin: theme.spacing(3, "auto"),
  fontFamily: Fonts.Carmorant,
}));

const BlogTitle = CustomStyled(Typography)(({ theme }) => ({
  textTransform: "uppercase",
  lineHeight: 1,
  margin: theme.spacing(0, 0, 2, 0),
  fontFamily: Fonts.Futura,
}));

const BlogReadButton = CustomStyled(Button)({
  justifyContent: "space-around",
  fontWeight: 600,
  fontSize: 14,
});

interface BlogContentProps {
  blog: BlogItemModel;
}

const BlogContent = ({ blog }: BlogContentProps) => (
  <Stack direction={"column"} spacing={{ xs: 1, sm: 6 }}>
    <Box paddingX={{ xs: 1, sm: 2 }}>
      <BlogTitle variant="h6">{blog.title}</BlogTitle>
      <Typography variant="body2" marginBottom={2}>
        {blog.desc}
      </Typography>
      <Stack>
        <Typography variant="body3">BY : {blog.by}</Typography>
        <Typography variant="body3">IN : {blog.in}</Typography>
        <Typography variant="body3">{blog.date}</Typography>
      </Stack>
    </Box>
    <Box paddingX={{ xs: 0, sm: 1 }}>
      <BlogReadButton
        variant="text"
        endIcon={<ChevronRightIcon />}
        href={blog.link}
      >
        CONTINUE READING
      </BlogReadButton>
    </Box>
  </Stack>
);

interface BlogLayoutProps {
  BlogArray: BlogItemModel[];
}

export default function BlogLayout({ BlogArray }: BlogLayoutProps) {
  return (
    <BlogContainer maxWidth="lg">
      <Typography
        variant="h3"
        fontWeight={700}
        textAlign={"center"}
        color={Colors.TRANSPARENT_GREY_BACKGROUND}
      >
        <a href="https://paperdateco.com/blog/">LATEST BLOG POSTS</a>
      </Typography>
      {BlogArray.length > 0 &&
        BlogArray.map((blog, i) => (
          <Box
            key={blog.title}
            margin="auto"
            paddingX={2}
            paddingY={{ xs: 2, sm: 4 }}
            width={{ xs: "100%", md: "85%" }}
          >
            <Grid
              container
              spacing={2}
              width="100%"
              flexDirection={i % 2 ? "row-reverse" : "row"}
            >
              <Grid item xs={12} sm={6} minHeight={300}>
                <BackgroundImage
                  image={blog.image}
                  width="100%"
                  height="100%"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <BlogContent blog={blog} />
              </Grid>
            </Grid>
          </Box>
        ))}
      <Box m={1} display="flex" justifyContent="center" alignItems="center">
        <BlogReadButton
          variant="text"
          endIcon={<ChevronRightIcon />}
          href={"https://paperdateco.com/blog/"}
        >
          READ MORE
        </BlogReadButton>
      </Box>
    </BlogContainer>
  );
}
