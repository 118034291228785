import { Box, Link, Typography, styled } from "@mui/material";

import BackgroundImage from "@paperdateco/shared-frontend/components/common/image/BackgroundImage";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import FeedItemDto from "@paperdateco/common/dto/instagramFeed/FeedItemDto";

const CardLink = styled(Link)({
  textDecoration: "none",
});

const BodySection = CustomStyled(Box)(({ theme }) => ({
  borderBottomRightRadius: "8px",
  borderBottomLeftRadius: "8px",
  padding: theme.spacing(1),
}));

interface FooterCardProps {
  card: FeedItemDto;
}

export default function FooterCard({ card }: FooterCardProps) {
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      borderRadius={1}
      overflow="hidden"
      boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
      textAlign="left"
    >
      <CardLink href={card.link} target="_blank" rel="noopener">
        <Box height={150}>
          <BackgroundImage image={card.image} width="100%" height="100%" />
        </Box>
        <BodySection>
          <Typography variant="body3" component="p" lineHeight={1}>
            {card.title}
          </Typography>
          <Typography variant="subtitle2" component="p">
            {card.date}
          </Typography>
          <Typography
            variant="subtitle2"
            component="p"
            marginTop={1}
            lineHeight={1}
            //https://stackoverflow.com/a/71324014
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "3",
              WebkitBoxOrient: "vertical",
            }}
          >
            {card.description}
          </Typography>
        </BodySection>
      </CardLink>
    </Box>
  );
}
