export const ON_LOGIN = "ON_LOGIN";
export const ON_LOGOUT = "ON_LOGOUT";

interface OnLogoutAction {
  type: typeof ON_LOGOUT;
}

interface OnLoginAction {
  type: typeof ON_LOGIN;
}

type CommonActionTypes = OnLogoutAction | OnLoginAction;

export default CommonActionTypes;
