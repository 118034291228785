import { Box, BoxProps } from "@mui/system";
import { Button, Stack, Typography, styled } from "@mui/material";
import { LibrarySectionLabels, SectionLabelType } from "./LibrarySection";
import { useCallback, useEffect, useState } from "react";

import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import DesignLibraryApi from "@paperdateco/common/api/design/DesignLibraryApi";
import LibraryImageAspectRatio from "./LibraryImageAspectRatio";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import LibraryImageListItem from "./LibraryImageListItem";
import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import useListenLibraryUpdate from "./useListenLibraryUpdate";

const AddNewButton = styled(Button)({
  width: 150,
  minHeight: 250,
  flexShrink: 0,
});

interface LibraryImageListRowProps {
  type: LibraryImageType & SectionLabelType;
  design?: DesignDto;
  onChoose?: (type: LibraryImageType, item: LibraryImageDto) => void;
  onViewMore?: (type: SectionLabelType) => void;
  onAdd?: (type: LibraryImageType) => void;
}

export default function LibraryImageListRow({
  type,
  design,
  onChoose,
  onViewMore,
  onAdd,
  ...props
}: LibraryImageListRowProps & BoxProps) {
  const [images, setImages] = useState<LibraryImageDto[]>([]);

  const getImages = useCallback(() => {
    if (design?.designer?.id === "62380fb904e373acf2dc8538" && design?.id) {
      DesignLibraryApi.getDesignImagesByType(type, { design: design.id })
        .then(setImages)
        .catch(NotificationUtils.showGenericError);
    } else {
      DesignLibraryApi.getLibraryImagesByType(type, { design: design?.id })
        .then(setImages)
        .catch(NotificationUtils.showGenericError);
    }
  }, [type, design]);

  const onUpdateLibraryImage = useCallback(
    (image: LibraryImageDto) => {
      if (image.types.includes(type)) {
        getImages();
      }
    },
    [type, getImages]
  );

  useEffect(() => {
    getImages();
  }, [getImages]);

  useListenLibraryUpdate(onUpdateLibraryImage);

  const onClickSeeAll = useCallback(
    () => onViewMore?.(type),
    [onViewMore, type]
  );

  const onClickAdd = useCallback(() => onAdd?.(type), [onAdd, type]);

  return (
    <Box marginY={2} {...props}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body2">{LibrarySectionLabels[type]}</Typography>
        {!(design?.designer?.id === "62380fb904e373acf2dc8538") && (
          <Button variant="text" onClick={onClickSeeAll}>
            <Typography variant="body3">SEE ALL</Typography>
          </Button>
        )}
      </Stack>
      <Box width="100%" overflow="auto">
        <Stack direction="row" spacing={4} paddingY={2}>
          {onAdd && (
            <AddNewButton onClick={onClickAdd}>
              <Box width={150}>Add New</Box>
            </AddNewButton>
          )}
          {images.map((option, index) => (
            <LibraryImageListItem
              key={index}
              type={type}
              item={option}
              aspectRatio={LibraryImageAspectRatio[type]}
              onChoose={onChoose}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
}
