import { Box, Drawer, IconButton, List } from "@mui/material";
import { useCallback, useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import VerticalMenuItem from "./VerticalMenuItem";
import useCategoryMenuItems from "@paperdateco/customer/components/products/category/useCategoryMenuItems";

export default function VerticalHeaderMenu() {
  const [open, setOpen] = useState(false);
  const menuItems = useCategoryMenuItems();

  const openDrawer = useCallback(() => setOpen(true), []);
  const closeDrawer = useCallback(() => setOpen(false), []);

  return (
    <>
      <IconButton color="inherit" onClick={openDrawer}>
        <MenuIcon color="inherit" />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={closeDrawer}>
        <Box role="presentation" onClick={closeDrawer} minWidth={300}>
          <List>
            {menuItems.map((item) => (
              <VerticalMenuItem key={item.id} category={item} />
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
}
