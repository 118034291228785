import { Button } from "@mui/material";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";

const GreenButton = CustomStyled(Button)({
  backgroundColor: Colors.GREEN,
  color: Colors.WHITE,
  "&:hover": {
    backgroundColor: Colors.BLACK,
  },
});

export default GreenButton;
