import { Button, ButtonProps, CircularProgress } from "@mui/material";

import { Box } from "@mui/system";

export default function SubmitButton(props: ButtonProps) {
  return (
    <Button {...props} disabled={props.disabled}>
      {props.disabled ? (
        <>
          <Box component="span" marginRight={2}>
            <CircularProgress size={24} color="primary" />
          </Box>
          Processing...
        </>
      ) : (
        props.children
      )}
    </Button>
  );
}
