import { Box, Grid } from "@mui/material";

import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import Typography from "@mui/material/Typography";
import chooseStep from "../../../assets/images/homepage/steps/choose.png";
import designStep from "../../../assets/images/homepage/steps/design.png";
import inviteStep from "../../../assets/images/homepage/steps/invite.png";

const MainText = CustomStyled(Typography)({
  fontWeight: 800,
  fontFamily: Fonts.Carmorant,
  fontStyle: "italic",
});

interface ProcessItem {
  title: string;
  content: string;
  image: string;
  maxWidth: number;
  aspectRatio: number;
}

const processDetails: ProcessItem[] = [
  {
    title: "CHOOSE",
    content: "Pick your greeting or invite you love!",
    image: chooseStep,
    maxWidth: 101,
    aspectRatio: 1.27,
  },
  {
    title: "DESIGN",
    content: "Personalise it according to your style",
    image: designStep,
    maxWidth: 80,
    aspectRatio: 1,
  },
  {
    title: "INVITE",
    content: "Share your e-invitation with your loved ones",
    image: inviteStep,
    maxWidth: 80,
    aspectRatio: 1,
  },
];

export default function ProcessSection() {
  return (
    <Box>
      <MainText variant="h4" textAlign="center" padding={2}>
        A Simple Guide to Plan Your Perfect Event
      </MainText>
      <Grid
        container
        spacing={4}
        justifyContent="center"
        fontFamily={Fonts.Carmorant}
        textAlign="center"
        padding={1}
      >
        {processDetails.map((process) => (
          <Grid key={process.title} item xs={12} sm={4} md={3} flexGrow={1}>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              height="100%"
            >
              <Box flexGrow={1} width="100%" maxWidth={process.maxWidth}>
                <HiddenImage
                  display="block"
                  width="100%"
                  src={process.image}
                  aspectRatio={process.aspectRatio}
                />
              </Box>
              <Typography
                variant="h5"
                fontWeight="500"
                letterSpacing={2}
                padding={1}
                paddingBottom={0}
              >
                {process.title}
              </Typography>
              <MainText variant="body1" minHeight={50}>
                {process.content}
              </MainText>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
