import { Button, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";

import AuthForm from "./AuthForm";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import UserApi from "@paperdateco/common/api/UserApi";
import routes from "@paperdateco/customer/routes/routes";
import { useNavigate } from "react-router-dom";
import useQueryParam from "@paperdateco/customer/utils/useQueryParam";

export default function VerificationOfEmail() {
  const navigate = useNavigate();

  const verificationCode = useQueryParam("code");

  const verifyEmail = useCallback(async () => {
    if (!verificationCode) {
      NotificationUtils.showError(
        "No verification code received, check the email"
      );
      return;
    }

    try {
      await UserApi.verifyEmail({ verificationCode });
      NotificationUtils.showSuccess("Email Verification successful");
      navigate(routes.LOGIN);
    } catch (error) {
      NotificationUtils.showGenericError(error);
    }
  }, [verificationCode, navigate]);

  useEffect(() => {
    verifyEmail();
  }, [verifyEmail]);

  return (
    <AuthForm>
      <Typography variant="body1" marginY={2}>
        Verifing your email, please click submit to proceed ...
      </Typography>
      <Button variant="contained" onSubmit={verifyEmail}>
        Retry
      </Button>
    </AuthForm>
  );
}
