import {
  Slider,
  SliderProps,
  Stack,
  StackProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

import CustomTextField from "./CustomTextField";
import { useCallback } from "react";

const OutlinedTextBox = styled(CustomTextField)(({ theme }) => ({
  width: 150,
  ".MuiInputBase-root": {
    padding: theme.spacing(0.5),
  },
  ".MuiInputBase-input": {
    textAlign: "center",
  },
  ".MuiOutlinedInput-notchedOutline ": {
    legend: {
      display: "none",
    },
  },
}));

interface CustomSliderInputProps {
  value: number;
  onValueChange: (value: number) => void;
  label: string;
  valueFormat?: (value: number) => number;
  valueLabelFormat?: (value: number) => string;
  scale?: (value?: number) => number | undefined;
  inverseScale?: (value?: number) => number | undefined;
  sliderProps?: SliderProps;
  labelProps?: TypographyProps;
}

export default function CustomSliderInput({
  value,
  onValueChange,
  label,
  scale = (v) => v,
  inverseScale = (v) => v,
  valueFormat = (v) => v,
  valueLabelFormat = (v) => v.toString(),
  sliderProps,
  labelProps,
  ...props
}: CustomSliderInputProps & StackProps) {
  const onCustomValueChange = useCallback(
    (value: number | string) => onValueChange(inverseScale(+value) ?? 0),
    [onValueChange]
  );

  const onSliderValueChange = useCallback(
    (_: any, newValue: number | number[]) => {
      if (Array.isArray(newValue)) {
        if (newValue.length > 0) {
          onCustomValueChange(newValue[0]);
        }
      } else {
        onCustomValueChange(newValue);
      }
    },
    [onCustomValueChange]
  );

  const formattedValue = valueFormat(value);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      {...props}
    >
      <Typography variant="body2" color="primary" minWidth={95} {...labelProps}>
        {label}
      </Typography>
      <Slider
        aria-label={label}
        value={scale(formattedValue)}
        size="small"
        onChange={onSliderValueChange}
        valueLabelFormat={valueLabelFormat}
        getAriaValueText={valueLabelFormat}
        valueLabelDisplay={sliderProps?.valueLabelDisplay ?? "auto"}
        marks={sliderProps?.marks ?? false}
        step={scale(sliderProps?.step ?? 0)}
        min={scale(sliderProps?.min ?? 0)}
        max={scale(sliderProps?.max ?? 0)}
      />
      <OutlinedTextBox
        type="number"
        variant="outlined"
        value={scale(formattedValue)}
        onChange={onCustomValueChange}
      />
    </Stack>
  );
}
