const JRDesigns = [
  {
    NAME: "Zahra",
    SAMPLE: {
      S1: "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/r253dplygbv7pnzsxkr5.webp",
      S2: "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/ryyyj45c7hbvxeqglyxi.webp",
      S3: "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/pdiwzcbju9naysntxu7r.webp",
      S4: "",
    },
    QUANTITY: {
      Q1: 932.2,
      Q2: 672.6,
    },
    DETAILS: {
      ENVELOPE: "170 gsm textured paper",
      FOLDER: "170 gsm textured paper, UV printing on flowers",
      INSERTS: "300 gsm textured paper, UV printing on titles and names",
      DIMENSIONS: "9*7 inches",
    },
  },
  {
    NAME: "Gulazara",
    SAMPLE: {
      S1: "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/sc5qwnnhk0b84s0pzvaq.webp",
      S2: "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/jhajnazh1wmbwocfpsaq.webp",
      S3: "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/htlgyjxf1x7hoowlnpxd.webp",
      S4: "",
    },
    QUANTITY: {
      Q1: 755.2,
      Q2: 571.12,
    },
    DETAILS: {
      ENVELOPE: "170 gsm textured paper",
      FOLDER: "170 gsm textured paper",
      INSERTS: "Thick canvas paper/board",
      DIMENSIONS: "9*7 inches",
    },
  },
  {
    NAME: "Gulshan",
    SAMPLE: {
      S1: "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/lyjfvmueev7kj8l4ttjo.webp",
      S2: "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/krfshkliqsbkoyq4l7fd.webp",
      S3: "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/ezrwplz6i7wlvstenjyr.webp",
      S4: "",
    },
    QUANTITY: {
      Q1: 944,
      Q2: 731.6,
    },
    DETAILS: {
      ENVELOPE: "170 gsm textured paper",
      FOLDER: "170 gsm textured paper",
      INSERTS:
        "300 gsm textured paper, Gold foiling on the bride and groom's name",
      DIMENSIONS: "9*7 inches",
    },
  },
  {
    NAME: "Ayana",
    SAMPLE: {
      S1: "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/ddzs4pwk4kz2uglk1e2b.webp",
      S2: "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/wu5cacvmnbglcihaagpq.webp",
      S3: "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/rr7cyi84rsq8rrilb0al.webp",
      S4: "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/dmdecuetk7cfjsasn5lw.webp",
    },
    QUANTITY: {
      Q1: 944,
      Q2: 731.6,
    },
    DETAILS: {
      ENVELOPE: "170 gsm textured paper",
      FOLDER: "170 gsm textured paper",
      INSERTS:
        "300 gsm textured paper, Gold foiling on the bride and groom's name",
      DIMENSIONS: "8*8 inches",
    },
  },
  {
    NAME: "Keya",
    SAMPLE: {
      S1: "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/kke6futfkue7oct2vjys.webp",
      S2: "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/w4toq3w93wqbvpgmrbeu.webp",
      S3: "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/feum6rguildndsxu3pt3.webp",
      S4: "",
    },
    QUANTITY: {
      Q1: 944,
      Q2: 731.6,
    },
    DETAILS: {
      ENVELOPE: "170 gsm textured paper",
      FOLDER: "170 gsm textured paper",
      INSERTS:
        "300 gsm textured paper, Gold foiling on the bride and groom's name",
      DIMENSIONS: "8*8 inches",
    },
  },
  {
    NAME: "Phulavari",
    SAMPLE: {
      S1: "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/b1sryjwq3padfibduhe9.webp",
      S2: "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/c4fajkzgmrqxul3lxy7n.webp",
      S3: "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/o6len0ue8ippavff3jyu.webp",
      S4: "",
    },
    QUANTITY: {
      Q1: 849.6,
      Q2: 613.6,
    },
    DETAILS: {
      ENVELOPE: "170 gsm velvet lamination ",
      FOLDER: "300 gsm velvet finish",
      INSERTS: "300 gsm velvet finish",
      DIMENSIONS: "8*8 inches",
    },
  },
];

export default JRDesigns;
