import { BoxProps } from "@mui/material";
import { CanvasPageContext } from "@paperdateco/shared-frontend/canvas/provider/CanvasPageProvider";
import CustomDesignDto from "@paperdateco/common/dto/design/custom/CustomDesignDto";
import DesignEditFooterSection from "@paperdateco/shared-frontend/components/design/section/DesignEditFooterSection";
import DesignEditHeaderSection from "@paperdateco/shared-frontend/components/design/section/DesignEditHeaderSection";
import DesignEditLayout from "@paperdateco/shared-frontend/components/design/layout/DesignEditLayout";
import DesignEditRightSidebar from "@paperdateco/shared-frontend/components/design/section/DesignEditRightSidebar";
import InviteCustomisationInstantPreview from "./instantPreview/InviteCustomisationInstantPreview";
import InviteCustomisationLeftSidebar from "./options/InviteCustomisationLeftSidebar";
import InviteCustomiseCostPreview from "./cost/InviteCustomiseCostPreview";
import InviteCustomisePreviewButton from "./options/preview/InviteCustomisePreviewButton";
import { useContext } from "react";
import useInviteSelectedLayers from "@paperdateco/shared-frontend/canvas/hooks/useInviteSelectedLayers";

interface InviteCustomiseLayoutProps {
  design: CustomDesignDto;
  onViewPreview?: () => void;
}

export default function InviteCustomiseLayout({
  design,
  onViewPreview,
  ...props
}: InviteCustomiseLayoutProps & BoxProps) {
  const { pages, instantPreview, setPreviewZoom } =
    useContext(CanvasPageContext);

  const selectedLayers = useInviteSelectedLayers(instantPreview);

  return (
    <DesignEditLayout
      leftSidebar={<InviteCustomisationLeftSidebar design={design} />}
      rightSidebar={
        <DesignEditRightSidebar
          instantPreview={instantPreview}
          selectedLayers={selectedLayers}
        />
      }
      header={
        <DesignEditHeaderSection
          defaultTitle={`You are customizing "${design.product.name}"`}
          selectedLayers={selectedLayers}
        />
      }
      footer={<DesignEditFooterSection onZoom={setPreviewZoom} />}
      canvasHeader={
        <>
          <InviteCustomisePreviewButton
            design={design}
            onViewPreview={onViewPreview}
          />
          <InviteCustomiseCostPreview pages={pages} design={design} />
        </>
      }
      canvas={<InviteCustomisationInstantPreview design={design} />}
      {...props}
    />
  );
}
