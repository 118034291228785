import { useContext, useEffect } from "react";

import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import MessageConstants from "@paperdateco/shared-frontend/utils/MessageConstants";
import { MessageContext } from "@paperdateco/shared-frontend/components/providers/MessageProvider";

export default function useListenLibraryUpdate(
  callback: (image: LibraryImageDto) => void
) {
  const { receive, stop } = useContext(MessageContext);

  useEffect(() => {
    const id = receive(MessageConstants.LIBRARY_IMAGES, callback);
    return () => stop(MessageConstants.LIBRARY_IMAGES, id);
  }, [receive, stop, callback]);
}
