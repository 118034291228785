import { Box, BoxProps } from "@mui/system";
import { useCallback, useEffect, useState } from "react";

import DesignLibraryApi from "@paperdateco/common/api/design/DesignLibraryApi";
import InviteImageOptionsPicker from "../common/InviteImageOptionsPicker";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import { Typography } from "@mui/material";

interface InviteEnvelopeChooseSectionProps {
  title: string;
  description?: string;
  designId: string;
  type: LibraryImageType;
  onChooseImage?: (image: LibraryImageDto) => void;
  onClose: () => void;
}

export default function InviteEnvelopeChooseSection({
  title,
  description,
  designId,
  type,
  onChooseImage,
  onClose,
  ...props
}: InviteEnvelopeChooseSectionProps & BoxProps) {
  const [images, setImages] = useState<LibraryImageDto[]>([]);

  const getImages = useCallback(() => {
    DesignLibraryApi.getLibraryImagesByType(type, { design: designId })
      .then(setImages)
      .catch(NotificationUtils.showGenericError);
  }, [type, designId]);

  useEffect(() => {
    getImages();
  }, [getImages]);

  return (
    <Box paddingX={2} {...props}>
      <Box>
        <Typography variant="h6" fontWeight="bold">
          {title}
        </Typography>
      </Box>
      <InviteImageOptionsPicker
        images={images}
        onPickImage={onChooseImage}
        paddingY={2}
      />
    </Box>
  );
}
