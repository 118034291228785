import { Button, IconButton, Typography } from "@mui/material";
import {
  CartStateDispatchType,
  getAllCartItems,
} from "@paperdateco/customer/store/cart/CartAction";
import { FormEvent, useCallback, useState } from "react";

import { Box } from "@mui/system";
import CartContentsDto from "@paperdateco/common/dto/order/cart/CartContentsDto";
import ClearIcon from "@mui/icons-material/Clear";
import CouponApi from "@paperdateco/common/api/CouponApi";
import CouponError from "@paperdateco/common/dto/error/CouponError";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import ErrorUtils from "@paperdateco/common/utils/ErrorUtils";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import useApiErrorPopup from "../../popup/hooks/useApiErrorPopup";
import { useDispatch } from "react-redux";

interface CartCouponDetailProps {
  cartContents: CartContentsDto;
}

export default function CartCouponDetail({
  cartContents,
}: CartCouponDetailProps) {
  const [coupon, setCoupon] = useState("");
  const showPopup = useApiErrorPopup("THE ENTERED COUPON DOES NOT EXIST");
  const dispatch = useDispatch<CartStateDispatchType>();

  const onRemoveCoupon = useCallback(() => {
    CouponApi.removeCoupon()
      .then(() => {
        dispatch(getAllCartItems(true));
      })
      .catch(NotificationUtils.showGenericError);
  }, [dispatch]);

  const onAddCoupon = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      CouponApi.applyCoupon(coupon)
        .then(() => {
          dispatch(getAllCartItems(true));
        })
        .catch((error) => {
          if (ErrorUtils.isError(error, CouponError.COUPON_NOT_FOUND)) {
            showPopup();
            return;
          }
          NotificationUtils.showGenericError(error);
        });
    },
    [coupon, dispatch, showPopup]
  );

  if (cartContents.appliedCoupon) {
    const coupon = cartContents.appliedCoupon;

    return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">{coupon.name} is applied</Typography>
        <IconButton size="small" color="error" onClick={onRemoveCoupon}>
          <ClearIcon />
        </IconButton>
      </Box>
    );
  }

  return (
    <form onSubmit={onAddCoupon}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CustomTextField
          variant="standard"
          label="Coupon Code"
          value={coupon}
          onChange={setCoupon}
        />
        <Box
          display="flex"
          height="100%"
          alignItems="flex-end"
          justifyContent="flex-end"
          marginLeft={2}
        >
          <Button variant="contained" type="submit">
            REDEEM
          </Button>
        </Box>
      </Box>
    </form>
  );
}
