import { CUSTOM_DESIGN_GET_ITEMS_NAME } from "./CustomDesignTypes";
import CustomDesignDto from "@paperdateco/common/dto/design/custom/CustomDesignDto";
import GetItemsReducer from "@paperdateco/shared-frontend/store/getItems/GetItemsReducer";
import { ON_LOGOUT } from "../common/CommonTypes";

const CustomDesignReducer = GetItemsReducer<CustomDesignDto[]>(
  CUSTOM_DESIGN_GET_ITEMS_NAME,
  [],
  ON_LOGOUT
);

export default CustomDesignReducer;
