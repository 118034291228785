import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { FormEvent, useCallback, useEffect, useState } from "react";

import { Box } from "@mui/system";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import InviteRsvpSaveSuccess from "./InviteRsvpSaveSuccess";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import RsvpApi from "@paperdateco/common/api/RsvpApi";
import RsvpStatus from "@paperdateco/common/dto/order/rsvp/RsvpStatus";
import useGetOrderItemDetails from "../common/useGetOrderItemDetails";

const RsvpOptions = [RsvpStatus.YES, RsvpStatus.NO, RsvpStatus.MAYBE];

const RsvpForm = CustomStyled("form")(({ theme }) => ({
  border: `1px solid ${theme.palette.border.secondary}`,
  borderRadius: theme.spacing(1),
  margin: theme.spacing(5, 0),
  padding: theme.spacing(2),
  textAlign: "center",
  maxWidth: 450,
}));

interface InviteRsvpSaveProps {
  orderItemId: string;
  email: string | null;
  status: RsvpStatus | null;
}

export default function InviteRsvpSave({
  orderItemId,
  email,
  status,
}: InviteRsvpSaveProps) {
  const [emailInput, setEmailInput] = useState(email ?? "");
  const [statusInput, setStatusInput] = useState(status ?? "");
  const [saved, setSaved] = useState(false);
  const orderItem = useGetOrderItemDetails(orderItemId);

  const handleChange = (event: SelectChangeEvent) => {
    setStatusInput(event.target.value);
  };

  const saveRsvpResponse = useCallback(
    async (e?: FormEvent) => {
      e?.preventDefault();
      try {
        await RsvpApi.addRsvp(orderItemId, {
          email: emailInput,
          status: statusInput as RsvpStatus,
        });
        setSaved(true);
      } catch (e) {
        NotificationUtils.showGenericError(e);
      }
    },
    [emailInput, statusInput, orderItemId]
  );

  useEffect(() => {
    if (!email || !status) {
      return;
    }
    // If email and status are present in the URL, then auto sumbit
    saveRsvpResponse();
  }, [email, status, saveRsvpResponse]);

  if (saved) {
    return <InviteRsvpSaveSuccess />;
  }

  if (!orderItem) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <RsvpForm onSubmit={saveRsvpResponse}>
        <Stack spacing={3} alignItems="center">
          <Typography variant="h6">
            You have been invited to "{orderItem.event?.eventName ?? "an event"}
            " by {orderItem.event?.hostName ?? "your friend"}
          </Typography>
          <CustomTextField
            value={emailInput}
            onChange={setEmailInput}
            type="email"
            label="Email"
          />
          <FormControl fullWidth required>
            <InputLabel id="status-label-id">SELECT A RESPONSE</InputLabel>
            <Select
              labelId="status-label-id"
              id="status-select"
              value={statusInput}
              label="SELECT A RESPONSE"
              onChange={handleChange}
            >
              {RsvpOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button type="submit" variant="contained">
            SUBMIT
          </Button>
        </Stack>
      </RsvpForm>
    </Box>
  );
}
