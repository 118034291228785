import RsvpDto from "../dto/order/rsvp/RsvpDto";
import RsvpRequestDto from "../dto/order/rsvp/RsvpRequestDto";
import StoreInstance from "../store/StoreInstance";
import { handleApiError } from "../utils/ApiUtils";

export default class RsvpApi {
  public static async addRsvp(orderItemId: string, rsvpData: RsvpRequestDto) {
    try {
      const rsvp = await StoreInstance.api().post<RsvpDto>(
        `/orders/items/${orderItemId}/rsvp`,
        rsvpData
      );
      return rsvp.data;
    } catch (error) {
      handleApiError(error, "Failed to capture RSVP response");
    }
  }
}
