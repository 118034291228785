import CanvasElement from "../canvas/objects/CanvasElement";
import EditableTextElement from "../canvas/objects/EditableTextElement";

export default class InviteUtils {
  static getTextLayers = (layers: CanvasElement[]) =>
    layers.filter(
      (layer): layer is EditableTextElement =>
        layer instanceof EditableTextElement
    );
}
