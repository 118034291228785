import { Box, BoxProps, styled } from "@mui/material";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import { ReactNode } from "react";

const Container = styled(Box)({
  background: Colors.LIGHT_BACKGROUND,
});

interface DesignEditLayoutProps {
  leftSidebar: ReactNode;
  rightSidebar: ReactNode;
  header: ReactNode;
  footer: ReactNode;
  canvasHeader?: ReactNode;
  canvas: ReactNode;
}

export default function DesignEditLayout({
  leftSidebar,
  rightSidebar,
  header,
  footer,
  canvasHeader,
  canvas,
  ...props
}: DesignEditLayoutProps & BoxProps) {
  return (
    <Container
      display="flex"
      flexDirection="row"
      flexGrow={1}
      overflow="hidden"
      fontFamily={Fonts.Titillium}
      letterSpacing={3}
      {...props}
    >
      {leftSidebar}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        overflow="hidden"
        flexGrow={1}
      >
        {header}
        <Box
          flexGrow={1}
          width="100%"
          overflow="auto"
          display="flex"
          justifyContent="center"
          position="relative"
        >
          {canvasHeader && (
            <Box
              position="absolute"
              zIndex={2}
              top={10}
              left={0}
              width="100%"
              display="flex"
              justifyContent="center"
            >
              {canvasHeader}
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            overflow="auto"
            marginTop={7}
            marginBottom={2}
          >
            {canvas}
          </Box>
        </Box>
        {footer}
      </Box>
      {rightSidebar}
    </Container>
  );
}
