import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  CustomDesignStateDispatchType,
  getAllCustomDesigns,
} from "@paperdateco/customer/store/customDesign/CustomDesignAction";
import { useDispatch, useSelector } from "react-redux";

import DesignUtils from "@paperdateco/common/utils/DesignUtils";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import { Link } from "react-router-dom";
import routes from "@paperdateco/customer/routes/routes";
import { selectAllCustomDesigns } from "@paperdateco/customer/store/customDesign/CustomDesignSelector";
import { useEffect } from "react";

export default function AccountDrafts() {
  const customDesigns = useSelector(selectAllCustomDesigns);
  const dispatch = useDispatch<CustomDesignStateDispatchType>();

  useEffect(() => {
    dispatch(getAllCustomDesigns(true));
  }, [dispatch]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="h5">Name</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5">Image</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5">Action</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {customDesigns.map((customDesign) => (
          <TableRow key={customDesign.id}>
            <TableCell>{customDesign.product.name}</TableCell>
            <TableCell>
              <HiddenImage
                src={DesignUtils.getImageFromCustomDesign(customDesign)}
                width={100}
                aspectRatio={customDesign.width / customDesign.height}
              />
            </TableCell>
            <TableCell>
              <Link to={routes.CUSTOM_DESIGN_EDIT(customDesign.id)}>
                <Button color="secondary">Edit</Button>
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
