import { CATEGORY_GET_ITEMS_NAME } from "./CategoryTypes";
import CategoryDto from "@paperdateco/common/dto/category/CategoryDto";
import GetItemsReducer from "@paperdateco/shared-frontend/store/getItems/GetItemsReducer";

const CategoryReducer = GetItemsReducer<CategoryDto[]>(
  CATEGORY_GET_ITEMS_NAME,
  []
);

export default CategoryReducer;
