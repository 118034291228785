import { Box, BoxProps, Typography } from "@mui/material";

import CanvasElement from "@paperdateco/shared-frontend/canvas/objects/CanvasElement";
import EnvUtils from "@paperdateco/shared-frontend/utils/EnvUtils";
import InviteUtils from "@paperdateco/shared-frontend/utils/InviteUtils";
import TextCurveControl from "@paperdateco/shared-frontend/canvas/controls/text/path/TextCurveControl";
import TextPathOptionsControl from "@paperdateco/shared-frontend/canvas/controls/text/path/TextPathOptionsControl";

interface TextPathControlProps {
  selectedLayers: CanvasElement[];
}

export default function TextPathControl({
  selectedLayers,
  ...props
}: TextPathControlProps & BoxProps) {
  const selectedTextLayers = InviteUtils.getTextLayers(selectedLayers);

  if (selectedTextLayers.length === 0 || EnvUtils.isProduction()) {
    return null;
  }
  return (
    <Box paddingY={2} {...props}>
      <Typography variant="body1">SHAPE</Typography>
      <TextPathOptionsControl textElements={selectedTextLayers} marginY={1.5} />
      <TextCurveControl textElements={selectedTextLayers} />
    </Box>
  );
}
