import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import {
  KeyboardEvent,
  SyntheticEvent,
  useCallback,
  useRef,
  useState,
} from "react";

import CategoryDto from "@paperdateco/common/dto/category/CategoryDto";
import CategoryWithChildren from "@paperdateco/common/dto/category/CategoryWithChildrenDto";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import { Link } from "react-router-dom";
import routes from "@paperdateco/customer/routes/routes";

const CategoryMenuItem = CustomStyled("li")(({ theme }) => ({
  listStyleType: "none",
  padding: theme.spacing(0.5),
  margin: theme.spacing(0, 1.5),
  cursor: "pointer",
  position: "relative",
  textTransform: "uppercase",
  fontFamily: Fonts.Roboto,
  letterSpacing: theme.spacing(0.2),
  fontSize: theme.typography.body3.fontSize,
  ":after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "3px",
    transform: "scaleX(0)",
    transformOrigin: "100% 50%",
    transition:
      "transform .5s cubic-bezier(.8,0,.2,1),-webkit-transform .5s cubic-bezier(.8,0,.2,1)",
    backgroundColor: theme.palette.secondary.main,
  },
  ":hover:after": {
    transform: "scaleX(1)",
    transformOrigin: "0 50%",
  },
}));

const CustomPopper = CustomStyled(Popper)({
  zIndex: 100,
});

const SubMenuContainer = CustomStyled(Paper)({
  backgroundColor: Colors.WHITE,
});

const CategorySubMenuItem = CustomStyled(Typography)(({ theme }) => ({
  letterSpacing: theme.spacing(0.2),
  textTransform: "uppercase",
  fontSize: theme.typography.body3.fontSize,
}));

interface CategoryItemProps {
  category: CategoryDto;
}

const CategoryItem = ({ category }: CategoryItemProps) => {
  return (
    <Link to={routes.CATEGORY(category.slug)}>
      <MenuItem>
        <CategorySubMenuItem variant="body1">
          {category.name}
        </CategorySubMenuItem>
      </MenuItem>
    </Link>
  );
};

interface HorizontalMenuItemProps {
  category: CategoryWithChildren;
}

export default function HorizontalMenuItem({
  category,
}: HorizontalMenuItemProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLLIElement>(null);

  const openMenu = useCallback(() => {
    setOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClose = useCallback((event: Event | SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  }, []);

  const handleListKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }, []);

  return (
    <CategoryMenuItem
      ref={anchorRef}
      id={`${category.id}-menu`}
      onMouseEnter={openMenu}
      onMouseOver={openMenu}
      onMouseLeave={closeMenu}
    >
      <Typography variant="body3" color="inherit">
        <Link to={routes.CATEGORY(category.slug)}>{category.name}</Link>
      </Typography>

      <CustomPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <SubMenuContainer>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id={`${category.id}-menu-list`}
                  aria-labelledby={`${category.id}-menu`}
                  onKeyDown={handleListKeyDown}
                  sx={{ overflowY: "auto", maxHeight: "70vh" }}
                >
                  {category.children.map((childCategory) => (
                    <CategoryItem
                      key={childCategory.id}
                      category={childCategory}
                    />
                  ))}
                </MenuList>
              </ClickAwayListener>
            </SubMenuContainer>
          </Grow>
        )}
      </CustomPopper>
    </CategoryMenuItem>
  );
}
