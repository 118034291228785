import { Button, Typography } from "@mui/material";
import { FormEvent, useContext, useEffect, useState } from "react";

import AddressDto from "@paperdateco/common/dto/user/address/AddressDto";
import AddressInput from "./common/AddressInput";
import { AuthContext } from "@paperdateco/shared-frontend/components/auth/AuthProvider";
import { Box } from "@mui/system";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import UserApi from "@paperdateco/common/api/UserApi";

const DefaultAddress: AddressDto = {
  name: "",
  address: "",
  phoneNumber: "",
};

export default function AccountBillingDetails() {
  const { user, updateAuth } = useContext(AuthContext);
  const [billingAddress, setBillingAddress] = useState(DefaultAddress);
  const [shippingAddress, setShippingAddress] = useState(DefaultAddress);

  useEffect(() => {
    setBillingAddress(user?.billingAddress ?? DefaultAddress);
    setShippingAddress(user?.shippingAddress ?? DefaultAddress);
  }, [user]);

  const onUpdateAddress = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await UserApi.updateUserAddress({ billingAddress, shippingAddress });
      await updateAuth();
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  return (
    <Box marginY={4}>
      <Typography variant="h6">
        The following addresses will be used on the checkout page by default.
      </Typography>

      <form onSubmit={onUpdateAddress}>
        <Typography variant="h6" marginY={2}>
          Billing Address
        </Typography>
        <AddressInput
          address={billingAddress}
          setAddress={setBillingAddress}
          marginBottom={5}
        />
        <Typography variant="h6" marginY={2}>
          Shipping Address
        </Typography>
        <AddressInput
          address={shippingAddress}
          setAddress={setShippingAddress}
          marginBottom={3}
        />

        <Button type="submit" variant="contained" size="large">
          Save Changes
        </Button>
      </form>
    </Box>
  );
}
