import { Badge } from "@mui/material";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";

const HeaderIconBadge = CustomStyled(Badge)({
  ".MuiBadge-standard": {
    background: Colors.BADGE,
  },
});

export default HeaderIconBadge;
