import { Box, BoxProps, Typography } from "@mui/material";

import CanvasChooseAnimationForm from "./section/CanvasChooseAnimationForm";
import CanvasElement from "../../objects/CanvasElement";
import EnvUtils from "@paperdateco/shared-frontend/utils/EnvUtils";
import InviteInstantPreview from "@paperdateco/shared-frontend/components/invite/InviteInstantPreview";

interface CanvasElementAnimationSectionProps<T extends fabric.Object> {
  instantPreview?: InviteInstantPreview;
  elements: CanvasElement<T>[];
}

export default function CanvasElementAnimationSection<T extends fabric.Object>({
  instantPreview,
  elements,
  ...props
}: CanvasElementAnimationSectionProps<T> & BoxProps) {
  const isEnabled = !EnvUtils.isProduction();
  return (
    <Box
      minWidth={270}
      padding={3}
      height="100%"
      {...props}
      sx={{ overflowY: "auto", ...props.sx }}
    >
      <Typography variant="body2" fontWeight="bold">
        ANIMATIONS
      </Typography>

      {isEnabled ? (
        <CanvasChooseAnimationForm
          elements={elements}
          instantPreview={instantPreview}
        />
      ) : (
        <Typography variant="body2" mt={2}>
          Coming Soon...
        </Typography>
      )}
    </Box>
  );
}
