import { Box, Button, Typography } from "@mui/material";
import { FormEvent, useState } from "react";

import { BoxProps } from "@mui/system";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import UserApi from "@paperdateco/common/api/UserApi";

const SubscribeButton = CustomStyled(Button)(({ theme }) => ({
  background: Colors.PRIMARY_500,
  color: Colors.WHITE,
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2, 5),
  borderRadius: 0,
  fontWeight: "bold",
}));

export default function NewsLetterSection(props: BoxProps) {
  const [email, setEmail] = useState("");

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await UserApi.subscribe({ email: email.toLowerCase() });
      NotificationUtils.showSuccess(
        "Subscription successful, Verification mail is sent"
      );
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  return (
    <Box
      marginX="auto"
      padding={2}
      marginBottom={5}
      maxWidth={500}
      fontFamily={Fonts.Carmorant}
      {...props}
    >
      <Typography variant="h5" textAlign="center">
        SIGN UP FOR OUR NEWSLETTER
      </Typography>
      <form onSubmit={onSubmit}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          marginBottom={4}
        >
          <Typography variant="body1" textAlign="center" maxWidth={250}>
            Subscribe to recieve the latest news and exclusive offers every
            week. No spam.
          </Typography>
        </Box>
        <Box marginBottom={3}>
          <CustomTextField
            label="Enter your Email"
            type="email"
            value={email}
            onChange={setEmail}
          />
        </Box>
        <SubscribeButton type="submit" variant="contained" fullWidth>
          SUBSCRIBE
        </SubscribeButton>
      </form>
    </Box>
  );
}
