import { useEffect, useState } from "react";

import CanvasAnimationSections from "./CanvasAnimationSections";
import DesignLibraryAnimationApi from "@paperdateco/common/api/design/DesignLibraryAnimationApi";
import LibraryAnimationDto from "@paperdateco/common/dto/design/library/animation/LibraryAnimationDto";
import MapUtils from "@paperdateco/common/utils/MapUtils";

export interface CanvasAnimationSection {
  name: string;
  options: LibraryAnimationDto[];
}

let cachedAnimations: LibraryAnimationDto[];

const getAnimationFromServer = async () => {
  if (cachedAnimations) {
    return cachedAnimations;
  }
  return DesignLibraryAnimationApi.getLibraryAnimations().then(
    (animations) => (cachedAnimations = animations)
  );
};

const mapToCanvasOption = (
  animations: LibraryAnimationDto[]
): CanvasAnimationSection[] => {
  const animationMap = MapUtils.groupBy(
    animations,
    (animation) => animation.animationType
  );
  return CanvasAnimationSections.map((section) => ({
    name: section.name,
    options: section.options
      .map((option) => animationMap[option] as LibraryAnimationDto | undefined)
      .filter(
        (animation): animation is LibraryAnimationDto => animation !== undefined
      ),
  }));
};

export default function useMappedAnimationOption() {
  const [animationSections, setAnimationSections] = useState<
    CanvasAnimationSection[]
  >([]);
  useEffect(() => {
    getAnimationFromServer().then((animations) =>
      setAnimationSections(mapToCanvasOption(animations))
    );
  }, []);

  return animationSections;
}
