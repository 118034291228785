import { useCallback, useRef } from "react";

import { fabric } from "fabric";

export const useFabric = (
  width: number,
  height: number,
  onChange: (fabricCanvas: fabric.Canvas, canvas: HTMLCanvasElement) => void
) => {
  const fabricRef = useRef<fabric.Canvas>();
  return useCallback(
    (node) => {
      if (node) {
        fabricRef.current = new fabric.Canvas(node, { width, height });
        if (onChange) {
          onChange(fabricRef.current, node);
        }
      } else if (fabricRef.current) {
        fabricRef.current.dispose();
      }
    },
    [width, height, onChange]
  );
};
