import CollaboratorDetail from "../../products/collaborator/CollaboratorDetail";
import { Container } from "@mui/material";
import Layout from "../../layout/Layout";
import { useParams } from "react-router-dom";

export default function CollaboratorPage() {
  const { collaboratorId } = useParams<{ collaboratorId: string }>();

  if (!collaboratorId) {
    return null;
  }

  return (
    <Layout>
      <Container maxWidth="xl" disableGutters>
        <CollaboratorDetail collaboratorId={collaboratorId} />
      </Container>
    </Layout>
  );
}
