import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import DesignPageDto from "@paperdateco/common/dto/design/pages/DesignPageDto";
import InviteInstantPreview from "@paperdateco/shared-frontend/components/invite/InviteInstantPreview";
import InvitePageContents from "@paperdateco/shared-frontend/components/invite/InvitePageContents";

export type CanvasPageContextType = {
  pages: InvitePageContents[];
  instantPreview?: InviteInstantPreview;
  currentPageIndex: number;
  previewZoom: number;
  setPreviewZoom?: (zoom: number) => void;
  setInstatPreview?: (
    page: InvitePageContents,
    instantPreview: InviteInstantPreview
  ) => void;
  onAddPage?: () => void;
  onDuplicatePage?: () => void;
  onDeletePage?: () => void;
  onSwapPage?: (from: number, to: number) => void;
  onSwitchPage?: (index: number) => void;
};

export const CanvasPageContext = createContext<CanvasPageContextType>({
  pages: [],
  currentPageIndex: 0,
  previewZoom: 100,
});

interface CanvasPageProviderProps {
  pages: DesignPageDto[];
}

export default function CanvasPageProvider({
  pages,
  children,
}: PropsWithChildren<CanvasPageProviderProps>) {
  const [invitePages, setInvitePages] = useState<InvitePageContents[]>([]);
  const [activePage, setActivePage] = useState<InvitePageContents>();
  const [zoom, setZoom] = useState(100);

  const currentPageIndex = useMemo(
    () =>
      Math.max(
        invitePages.findIndex((page) => page === activePage),
        0
      ),
    [invitePages, activePage]
  );

  useEffect(() => {
    const result = pages.map((page) => new InvitePageContents(page));
    setInvitePages(result);
    if (result.length > 0) {
      setActivePage(result[0]);
    }
  }, [pages]);

  const setInstatPreview = useCallback(
    (page: InvitePageContents, instantPreview: InviteInstantPreview) => {
      page.setInstantPreview(instantPreview);
      setInvitePages((arr) => [...arr]); // To rerender after attaching preview
    },
    []
  );

  const onAddPage = useCallback(() => {
    const newPages = [...invitePages];
    newPages.splice(
      currentPageIndex + 1,
      0,
      new InvitePageContents({ layers: [] })
    );
    setInvitePages(newPages);
    setActivePage(newPages[currentPageIndex + 1]);
  }, [invitePages, currentPageIndex]);

  const onDuplicatePage = useCallback(async () => {
    const newPages = [...invitePages];
    const currentPage = newPages[currentPageIndex];
    if (!currentPage.instantPreview) {
      return;
    }
    const newPage = new InvitePageContents({
      background: currentPage.instantPreview.getBackground(),
      layers: [...currentPage.instantPreview.getDesignLayers()],
    });
    newPages.splice(currentPageIndex + 1, 0, newPage);
    setInvitePages(newPages);
    setActivePage(newPages[currentPageIndex + 1]);
  }, [invitePages, currentPageIndex]);

  const onDeletePage = useCallback(() => {
    if (invitePages.length <= 1) {
      return;
    }
    const newPages = [...invitePages];
    newPages.splice(currentPageIndex, 1);
    setInvitePages(newPages);
    setActivePage(newPages[Math.min(currentPageIndex, newPages.length - 1)]);
  }, [invitePages, currentPageIndex]);

  const onSwitchPage = useCallback(
    (index: number) => {
      if (index < 0 || index >= invitePages.length) {
        return;
      }
      setActivePage(invitePages[index]);
    },
    [invitePages]
  );

  const onSwapPage = useCallback(
    (from: number, to: number) => {
      if (
        from < 0 ||
        from >= invitePages.length ||
        to < 0 ||
        to >= invitePages.length
      ) {
        return;
      }
      const newPages = [...invitePages];
      const source = newPages[from];
      const dest = newPages[to];
      newPages[from] = dest;
      newPages[to] = source;
      setInvitePages(newPages);
      setActivePage(newPages[to]);
    },
    [invitePages]
  );

  const contextValue = useMemo(
    (): CanvasPageContextType => ({
      pages: invitePages,
      instantPreview: activePage?.instantPreview,
      currentPageIndex,
      previewZoom: zoom,
      setPreviewZoom: setZoom,
      onAddPage,
      onDeletePage,
      onDuplicatePage,
      onSwapPage,
      onSwitchPage,
      setInstatPreview,
    }),
    [
      invitePages,
      activePage,
      currentPageIndex,
      zoom,
      onAddPage,
      onDeletePage,
      onDuplicatePage,
      onSwapPage,
      onSwitchPage,
      setInstatPreview,
    ]
  );
  return (
    <CanvasPageContext.Provider value={contextValue}>
      {children}
    </CanvasPageContext.Provider>
  );
}
