import { createContext, useCallback, useEffect, useState } from "react";

import { RouteProps } from "react-router-dom";
import UserApi from "@paperdateco/common/api/UserApi";
import UserDto from "@paperdateco/common/dto/user/UserDto";

export type AuthType = {
  loaded: boolean;
  user?: UserDto;
};

export type AuthContextType = {
  user?: UserDto;
  updateAuth: () => Promise<void>;
};

export const AuthContext = createContext<AuthContextType>({
  updateAuth: async () => {},
});

interface AuthProviderProps {
  successCallback?: (user: UserDto) => void;
  failureCallback?: () => void;
}

export default function AuthProvider({
  successCallback,
  failureCallback,
  ...props
}: RouteProps & AuthProviderProps) {
  const [auth, setAuth] = useState<AuthType>({
    loaded: false,
  });

  const getLoggedInUser = useCallback(async () => {
    try {
      const user = await UserApi.me();
      successCallback?.(user);
      setAuth({ loaded: true, user });
    } catch (err) {
      console.error((err as Error).message);
      failureCallback?.();
      setAuth({ loaded: true });
    }
  }, [failureCallback, successCallback]);

  // componentDidMount
  useEffect(() => {
    getLoggedInUser();
  }, [getLoggedInUser]);

  return (
    <AuthContext.Provider
      value={{ user: auth.user, updateAuth: getLoggedInUser }}
    >
      {auth.loaded && props.children}
    </AuthContext.Provider>
  );
}
