import {
  WishlistStateDispatchType,
  getAllWishLists,
} from "@paperdateco/customer/store/wishList/WishListAction";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AuthContext } from "@paperdateco/shared-frontend/components/auth/AuthProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeaderIconBadge from "./HeaderIconBadge";
import { IconButton } from "@mui/material";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { selectWishListsCount } from "@paperdateco/customer/store/wishList/WishListSelector";

export default function HeaderWishListIcon() {
  const { user } = useContext(AuthContext);
  const wishListCount = useSelector(selectWishListsCount);
  const dispatch = useDispatch<WishlistStateDispatchType>();

  useEffect(() => {
    if (user) {
      dispatch(getAllWishLists());
    }
  }, [dispatch, user]);

  return (
    <IconButton size="small" color="inherit">
      <HeaderIconBadge badgeContent={wishListCount} color="primary">
        <FontAwesomeIcon icon={faHeart} color="inherit" />
      </HeaderIconBadge>
    </IconButton>
  );
}
