import { LibrarySections, SectionLabelType } from "./LibrarySection";
import { useCallback, useContext, useState } from "react";

import AspectRatio from "@paperdateco/shared-frontend/utils/styles/AspectRatio";
import { Box } from "@mui/system";
import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import DesignImageType from "@paperdateco/common/dto/design/layer/image/DesignImageType";
import DesignPremiumElementPopup from "./DesignPremiumElementPopup";
import InviteInstantPreview from "@paperdateco/shared-frontend/components/invite/InviteInstantPreview";
import LibraryDetailedSection from "./LibrayDetailedSection";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";
import LibraryPreviewSection from "./LibraryPreviewSection";
import { ModalContext } from "@paperdateco/shared-frontend/components/providers/ModalProvider";

interface DesignEditLibrarySectionProps {
  design?: DesignDto;
  instantPreview?: InviteInstantPreview;
  onAdd?: (type: LibraryImageType) => void;
}

export default function DesignEditLibrarySection({
  design,
  instantPreview,
  onAdd,
}: DesignEditLibrarySectionProps) {
  const { open, close } = useContext(ModalContext);
  const [activeSection, setActiveSection] = useState<SectionLabelType>();

  const onChooseItem = (item: LibraryImageDto, action: () => void) => {
    if (item.price > 0 && !instantPreview?.hasImageLayer(item)) {
      open(
        <DesignPremiumElementPopup
          image={item}
          aspectRatio={
            design ? design.width / design.height : AspectRatio.INVITE
          }
          onAdd={() => {
            action();
            close();
          }}
          onCancel={close}
        />
      );
      return;
    }
    action();
  };

  const onAddBackground = (item: LibraryImageDto) => {
    instantPreview?.setBackground(item);
  };

  const onAddImage = (type: DesignImageType, image: LibraryImageDto) => {
    instantPreview?.addImage({
      image,
      type,
      top: 30,
      left: 30,
      width: 300,
    });
  };

  const onAddMask = (mask: LibraryImageDto) => {
    instantPreview?.addMask({
      mask,
      top: 30,
      left: 30,
      width: 300,
    });
  };

  const onChoose = (type: LibraryImageType, item: LibraryImageDto) => {
    switch (type) {
      case LibraryImageType.BACKGROUND:
        onChooseItem(item, () => onAddBackground(item));
        break;
      case LibraryImageType.GIF:
        onChooseItem(item, () => onAddImage(DesignImageType.GIF, item));
        break;
      case LibraryImageType.STICKER:
        onChooseItem(item, () => onAddImage(DesignImageType.IMAGE, item));
        break;
      case LibraryImageType.PHOTO:
        onChooseItem(item, () => onAddImage(DesignImageType.IMAGE, item));
        break;
      case LibraryImageType.MASK:
        onChooseItem(item, () => onAddMask(item));
        break;
    }
  };

  const onViewAll = useCallback(() => setActiveSection(undefined), []);

  const onViewMore = useCallback((type: SectionLabelType) => {
    setActiveSection(type);
  }, []);

  if (activeSection) {
    return (
      <LibraryDetailedSection
        section={activeSection}
        design={design}
        onChoose={onChoose}
        onClose={onViewAll}
      />
    );
  }

  return (
    <Box width={350} padding={2} height="100%" overflow="auto">
      {LibrarySections.map((section) => (
        <LibraryPreviewSection
          key={section}
          section={section}
          design={design}
          onChoose={onChoose}
          onViewMore={onViewMore}
          onAdd={onAdd}
        />
      ))}
    </Box>
  );
}
