import CommonActionTypes, { ON_LOGOUT } from "../common/CommonTypes";
import InviteCustomisationSection, {
  InviteEnvelopeInnerSection,
} from "@paperdateco/customer/components/common/types/InviteCustomisationSection";
import InviteCustomiseActionTypes, {
  SET_CUSTOMISE_SECTION,
  SET_ENVELOPE_CUSTOMISE_SUBSECTION,
} from "./InviteCustomiseTypes";

import { Reducer } from "redux";

export interface InviteCustomiseState {
  section?: InviteCustomisationSection;
  envelopeSubSection?: InviteEnvelopeInnerSection;
}

const initialState: InviteCustomiseState = {};

const InviteCustomiseReducer: Reducer<
  InviteCustomiseState,
  InviteCustomiseActionTypes | CommonActionTypes
> = (state = initialState, action): InviteCustomiseState => {
  switch (action.type) {
    case SET_CUSTOMISE_SECTION:
      return { ...state, section: action.payload };
    case SET_ENVELOPE_CUSTOMISE_SUBSECTION:
      return { ...state, envelopeSubSection: action.payload };
    case ON_LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

export default InviteCustomiseReducer;
