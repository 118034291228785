import { Box } from "@mui/material";
import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import LibraryImageSearchableList from "./LibraryImageSearchableList";
import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";
import { SectionLabelType } from "./LibrarySection";

interface LibraryDetailedSectionProps {
  section: SectionLabelType;
  design?: DesignDto;
  onClose?: () => void;
  onChoose?: (type: LibraryImageType, item: LibraryImageDto) => void;
}

export default function LibraryDetailedSection({
  section,
  design,
  onClose,
  onChoose,
}: LibraryDetailedSectionProps) {
  switch (section) {
    case LibraryImageType.BACKGROUND:
    case LibraryImageType.STICKER:
    case LibraryImageType.MASK:
    case LibraryImageType.GIF:
    case LibraryImageType.PHOTO:
      return (
        <Box width={350} padding={2} height="100%">
          <LibraryImageSearchableList
            type={section}
            design={design}
            onChoose={onChoose}
            onClose={onClose}
          />
        </Box>
      );
    default:
      return null;
  }
}
