import { useDispatch, useSelector } from "react-redux";

import { AuthContext } from "@paperdateco/shared-frontend/components/auth/AuthProvider";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GetItemsActionTypes from "@paperdateco/shared-frontend/store/getItems/GetItemsTypes";
import { IconButton } from "@mui/material";
import LoginNeededPopup from "../../popup/login/LoginNeededPopup";
import { ModalContext } from "@paperdateco/shared-frontend/components/providers/ModalProvider";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import { RootState } from "@paperdateco/customer/store";
import { ThunkDispatch } from "redux-thunk";
import WishListApi from "@paperdateco/common/api/WishListApi";
import WishListDto from "@paperdateco/common/dto/user/wishList/WishListDto";
import WishListUpdateRequestDto from "@paperdateco/common/dto/user/wishList/WishlistUpdateRequestDto";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { getAllWishLists } from "@paperdateco/customer/store/wishList/WishListAction";
import { selectProductWishListStatus } from "@paperdateco/customer/store/wishList/WishListSelector";
import { useContext } from "react";

const Icon = CustomStyled(IconButton)(({ theme }) => ({
  width: 55,
  height: 55,
  padding: theme.spacing(2),
  borderRadius: "50%",
  border: `1px solid ${Colors.BLACK}`,
  marginRight: theme.spacing(2),
  "& svg": {
    fontSize: theme.typography.body1.fontSize,
    color: Colors.WHITE,
  },
}));

const IconDeselected = CustomStyled(Icon)({
  background: Colors.WHITE,
  "& svg": {
    color: Colors.BLACK,
  },
  "&:hover": {
    background: Colors.BLACK,
    "& svg": {
      color: Colors.WHITE,
    },
  },
});

const IconSelected = CustomStyled(Icon)({
  background: Colors.BLACK,
  "& svg": {
    color: Colors.WHITE,
  },
  "&:hover": {
    background: Colors.WHITE,
    "& svg": {
      color: Colors.BLACK,
    },
  },
});

interface ProductWishListIconProps {
  product: ProductDto;
}

export default function ProductWishListIcon({
  product,
}: ProductWishListIconProps) {
  const isProductWishListed = useSelector(
    selectProductWishListStatus(product.id)
  );
  const { user } = useContext(AuthContext);
  const { open, close } = useContext(ModalContext);

  const dispatch =
    useDispatch<
      ThunkDispatch<RootState, unknown, GetItemsActionTypes<WishListDto>>
    >();

  const toggleWishList = async () => {
    if (!user) {
      open(
        <LoginNeededPopup
          message="PLEASE LOGIN TO ADD THIS TO WISHLIST"
          onClose={close}
        />
      );
      return;
    }

    const userWishListData: WishListUpdateRequestDto = {
      productId: product.id,
      add: !isProductWishListed,
    };

    try {
      await WishListApi.updateWishList(userWishListData);
      await dispatch(getAllWishLists(true));
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  if (isProductWishListed) {
    return (
      <IconSelected onClick={toggleWishList}>
        <FontAwesomeIcon icon={faHeart} />
      </IconSelected>
    );
  }

  return (
    <IconDeselected onClick={toggleWishList}>
      <FontAwesomeIcon icon={faHeart} />
    </IconDeselected>
  );
}
