import AccountLayout from "../../account/AccountLayout";
import AccountManageInvites from "../../account/AccountManageInvites";
import useQueryParam from "@paperdateco/customer/utils/useQueryParam";

export default function AccountManageInvitesPage() {
  const orderItemId = useQueryParam("orderItemId");

  return (
    <AccountLayout>
      <AccountManageInvites orderItemId={orderItemId} />
    </AccountLayout>
  );
}
