import { useCallback, useState } from "react";

import useMounted from "./useMounted";

export default function usePersistedState<T>(key: string, defaultValue: T) {
  const mounted = useMounted();
  const [state, setState] = useState<T>(() => {
    const value = window.localStorage.getItem(key);
    if (!value) {
      return defaultValue;
    }
    return JSON.parse(value) || defaultValue;
  });

  const setValue = useCallback(
    (value: T) => {
      window.localStorage.setItem(key, JSON.stringify(value));
      if (mounted.current) {
        setState(value);
      }
    },
    [key, mounted]
  );

  return { state, setState: setValue };
}
