import AccountDetails from "../../account/AccountDetails";
import AccountLayout from "../../account/AccountLayout";

export default function AccountDetailsPage() {
  return (
    <AccountLayout>
      <AccountDetails />
    </AccountLayout>
  );
}
