import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

import { Box } from "@mui/system";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import InviteProductListRow from "./InviteProductListRow";
import useCategoryMenuItems from "@paperdateco/customer/components/products/category/useCategoryMenuItems";
import { useState } from "react";

export default function InviteTemplateSection() {
  const [occasion, setOccasion] = useState<string>("");
  const menuItems = useCategoryMenuItems();

  const handleChange = (event: SelectChangeEvent) => {
    setOccasion(event.target.value);
  };

  const selectedMenu = menuItems.find((item) => item.id === occasion);

  return (
    <Box width={350} padding={2} height="100%" overflow="auto">
      <FormControl fullWidth>
        <InputLabel id="occasion-label-id">SELECT AN OCCASION</InputLabel>
        <Select
          labelId="occasion-label-id"
          id="occasion-select"
          value={occasion}
          label="SELECT AN OCCASION"
          onChange={handleChange}
        >
          {menuItems.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              <Typography fontFamily={Fonts.Titillium}>
                {category.name.toUpperCase()}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedMenu?.children.map((category) => (
        <InviteProductListRow
          category={category}
          key={category.id}
          marginY={2}
          letterSpacing={1}
        />
      ))}
    </Box>
  );
}
