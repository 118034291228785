import Box from "@mui/material/Box";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import Copyright from "./Copyright";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import FooterLinks from "./FooterLinks";
import FooterMessageIcons from "./FooterMessageIcons";

const FooterContainer = CustomStyled(Box)(({ theme }) => ({
  textAlign: "center",
  marginTop: "auto",
  padding: theme.spacing(2),
  paddingTop: theme.spacing(1),
  backgroundColor: Colors.PRIMARY_BACKGROUND_COLOR,
}));

export default function Footer() {
  return (
    <FooterContainer
      component="footer"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <FooterLinks />
      <Copyright />
      <FooterMessageIcons />
    </FooterContainer>
  );
}
