import { Box } from "@mui/system";
import DesignUtils from "@paperdateco/common/utils/DesignUtils";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";

interface ProductImageSectionProps {
  product: ProductDto;
}

export default function ProductImageSection({
  product,
}: ProductImageSectionProps) {
  return (
    <Box width="100%" maxWidth={500} padding={1}>
      <HiddenImage
        src={DesignUtils.getImageFromProduct(product)}
        aspectRatio={product.design.width / product.design.height}
        width="100%"
        display="block"
      />
    </Box>
  );
}
