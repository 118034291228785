import { Box, Grid, Typography, TypographyProps, styled } from "@mui/material";

import CustomCheckbox from "@paperdateco/shared-frontend/components/common/form/CustomCheckbox";

const HeaderText = styled((props: TypographyProps) => (
  <Typography {...props} variant="body2">
    {props.children}
  </Typography>
))({
  fontWeight: "bold",
  fontSize: "0.75rem",
  lineHeight: 1,
});

interface MailerItemHeaderProps {
  allSelected: boolean;
  onSelectAll: (checked: boolean) => void;
}

export default function MailerItemHeader({
  allSelected,
  onSelectAll,
}: MailerItemHeaderProps) {
  return (
    <Grid container columns={18} alignItems="center" textAlign="center">
      <Grid item xs={2} sm={1}>
        <CustomCheckbox
          label=""
          checked={allSelected}
          onChange={onSelectAll}
          size="small"
        />
      </Grid>
      <Grid item xs={3} sm={2}>
        <HeaderText>INVITE STATUS</HeaderText>
      </Grid>
      <Grid item xs={8} sm={12}>
        <Box sx={{ display: { md: "none", xs: "block" } }}>
          <HeaderText>DETAILS</HeaderText>
        </Box>
        <Box sx={{ display: { md: "block", xs: "none" } }}>
          <Grid container>
            <Grid item xs={6}>
              <HeaderText>NAME</HeaderText>
            </Grid>
            <Grid item xs={6}>
              <HeaderText>EMAIL</HeaderText>
            </Grid>
            <Grid item xs={6}>
              <HeaderText>PHONE</HeaderText>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={2} sm={1}>
        <HeaderText>RSVP</HeaderText>
      </Grid>
    </Grid>
  );
}
