import { Container, Typography } from "@mui/material";

import Layout from "../../layout/Layout";

export default function CancellationPolicy() {
  return (
    <Layout>
      <Container maxWidth="lg">
        <Typography variant="h3" margin={2} textAlign={"center"}>
          Cancellation Policy
        </Typography>
        <Typography variant="h6" marginY={10} marginX={2}>
          Prior to 48 hours before the scheduled date of the delivery of the
          Products purchase by you, subject to the products being Returnable
          following applicable Terms and Conditions herein, you may raise a
          request for cancellations to the Mesh Designs. If Mesh Designs agrees
          to the cancellation, any amounts paid by You for the purchase of the
          Products will be refunded to you following the Refund Policy.
        </Typography>
      </Container>
    </Layout>
  );
}
