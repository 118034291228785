import { Box, BoxProps } from "@mui/system";

import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import { PropsWithChildren } from "react";

interface HomePopupLayoutProps {
  image: string;
  alt: string;
  containerProps?: BoxProps;
  buttonContainerProps?: BoxProps;
}

export default function HomePopupLayout({
  image,
  children,
  containerProps,
  buttonContainerProps,
}: PropsWithChildren<HomePopupLayoutProps>) {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...containerProps}
    >
      <Box
        position="relative"
        width="100vw"
        maxWidth={525}
        paddingX={5}
        paddingY={3}
      >
        <HiddenImage
          width="100%"
          src={image}
          display="block"
          aspectRatio={468 / 558}
        />
        <Box
          position="absolute"
          bottom={70}
          left={0}
          right={0}
          margin={3}
          textAlign="center"
          {...buttonContainerProps}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
