import { PropsWithChildren, ReactNode } from "react";

import AppLogo from "../../../assets/images/logo/text_logo.png";
import AspectRatio from "@paperdateco/shared-frontend/utils/styles/AspectRatio";
import { Box } from "@mui/system";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import { Link } from "react-router-dom";
import routes from "@paperdateco/customer/routes/routes";

interface SimpleHeaderProps {
  navSection?: ReactNode;
}

export default function SimpleHeader({
  navSection,
  children,
}: PropsWithChildren<SimpleHeaderProps>) {
  return (
    <Box
      display="flex"
      alignItems="center"
      padding={2}
      borderBottom={`1px solid ${Colors.LIGHT_GREY}`}
    >
      <Link to={routes.HOME}>
        <HiddenImage src={AppLogo} aspectRatio={AspectRatio.TEXT_LOGO} />
      </Link>
      <Box
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        {children}
      </Box>
      {navSection}
    </Box>
  );
}
