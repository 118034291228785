import { Box } from "@mui/material";
import { ReactNode } from "react";

interface EventDetailTabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

export default function EventDetailTabPanel({
  children,
  value,
  index,
  ...other
}: EventDetailTabPanelProps) {
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
