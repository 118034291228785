import { Box } from "@mui/system";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import HomePopupLayout from "./HomePopupLayout";
import HomeWelcomePopupModal from "./HomeWelcomePopupModal";
import PinkButton from "@paperdateco/customer/components/common/ui/PinkButton";
import useOneTimePopup from "@paperdateco/shared-frontend/components/common/hooks/useOneTimePopup";

interface WarningPopupProps {
  onClose?: () => void;
}

function WarningPopup({ onClose }: WarningPopupProps) {
  return (
    <HomePopupLayout
      image="https://asset.paperdateco.com/homepage/popup/warning_V2.svg"
      alt="warning popup"
      buttonContainerProps={{ bottom: "16%" }}
    >
      <Box fontFamily={Fonts.Roboto}>
        <PinkButton variant="contained" size="medium" onClick={onClose}>
          CLOSE
        </PinkButton>
      </Box>
    </HomePopupLayout>
  );
}

export default function HomeWarningPopupModal() {
  const hidePopup = useOneTimePopup("WarningPopup", WarningPopup);

  if (hidePopup) {
    return <HomeWelcomePopupModal />;
  }

  return null;
}
