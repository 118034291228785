import { ComponentType, useCallback, useContext, useEffect } from "react";

import { ModalContext } from "@paperdateco/shared-frontend/components/providers/ModalProvider";
import usePersistedState from "./usePersistedState";

interface PopupComponentProps {
  onClose?: () => void;
}

export default function useOneTimePopup(
  popupName: string,
  Component: ComponentType<PopupComponentProps>,
  hideOnBackdropClick = true
) {
  const { state: hidePopup, setState: setHidePopup } = usePersistedState(
    popupName,
    false
  );

  const { open, close } = useContext(ModalContext);

  const onClose = useCallback(() => {
    close();
    setHidePopup(true);
  }, [close, setHidePopup]);

  useEffect(() => {
    if (!hidePopup) {
      open(<Component onClose={onClose} />, {
        onClose: hideOnBackdropClick ? onClose : undefined,
      });
    }
  }, [hidePopup, open, onClose, Component, hideOnBackdropClick]);

  return hidePopup;
}
