import { BoxProps } from "@mui/system";
import CategoryProductItemWithoutLink from "./CategoryProductItemWithoutLink";
import { Link } from "react-router-dom";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import routes from "@paperdateco/customer/routes/routes";

interface CategoryProductItemProps {
  product: ProductDto;
}

export default function CategoryProductItem({
  product,
  ...props
}: CategoryProductItemProps & BoxProps) {
  return (
    <Link to={routes.PRODUCT(product.slug)}>
      <CategoryProductItemWithoutLink product={product} {...props} />
    </Link>
  );
}
