import { ChangeEvent, useCallback } from "react";
import { Checkbox, CheckboxProps, FormControlLabel } from "@mui/material";

interface CustomCheckboxProps {
  label: string;
  onChange?: (value: boolean) => void;
}
export default function CustomCheckbox({
  label,
  onChange,
  ...props
}: CustomCheckboxProps & Omit<CheckboxProps, "onChange">) {
  const customOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.checked);
    },
    [onChange]
  );

  return (
    <FormControlLabel
      control={<Checkbox onChange={customOnChange} {...props} />}
      label={label}
    />
  );
}
