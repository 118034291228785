import { Box, Grid, Stack } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
  OrderStateDispatchType,
  getAllOrders,
} from "@paperdateco/customer/store/order/OrderAction";
import { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AccountEventDetailsModal from "./eventDetails/AccountEventDetailsModal";
import CustomShadowButton from "./common/CustomShadowButton";
import DesignUtils from "@paperdateco/common/utils/DesignUtils";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import InviteDownloadButtons from "../invite/preview/common/InviteDownloadButtons";
import { ModalContext } from "@paperdateco/shared-frontend/components/providers/ModalProvider";
import OrderItemDto from "@paperdateco/common/dto/order/details/OrderItemDto";
import routes from "@paperdateco/customer/routes/routes";
import { selectOrders } from "@paperdateco/customer/store/order/OrderSelector";

interface AccountCardProps {
  orderId: string;
  orderItem: OrderItemDto;
}

function AccountCard({ orderItem }: AccountCardProps) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-end"
      item
      md={4}
      sm={6}
      xs={12}
    >
      <Box display="flex" flexDirection="column">
        <HiddenImage
          src={DesignUtils.getImageFromOrderItem(orderItem)}
          width="100%"
          aspectRatio={
            orderItem.customDesign.width / orderItem.customDesign.height
          }
        />
        <Stack direction="column" spacing={2} marginY={2}>
          <Link to={routes.INVITE_SHARE_LINK(orderItem.id)} target="_blank">
            <CustomShadowButton fullWidth>View Invite</CustomShadowButton>
          </Link>
          <InviteDownloadButtons customDesign={orderItem.customDesign} />
          <Link to={routes.ACCOUNT_MANAGE_INVITES(orderItem.id)}>
            <CustomShadowButton fullWidth>Manage Event</CustomShadowButton>
          </Link>
        </Stack>
      </Box>
    </Grid>
  );
}

interface AccountManageInvitesProps {
  orderItemId: string | null;
}

export default function AccountManageInvites({
  orderItemId,
}: AccountManageInvitesProps) {
  const orders = useSelector(selectOrders);
  const navigate = useNavigate();
  const { open, close } = useContext(ModalContext);

  const dispatch = useDispatch<OrderStateDispatchType>();

  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);

  const onClosePopup = useCallback(() => {
    navigate(routes.ACCOUNT_MANAGE_INVITES());
  }, [navigate]);

  useEffect(() => {
    if (!orderItemId) {
      close();
      return;
    }

    const order = orders.find((order) =>
      order.items.some((item) => item.id === orderItemId)
    );
    const orderItem = order?.items.find((item) => item.id === orderItemId);
    if (!order || !orderItem) {
      return;
    }
    open(
      <AccountEventDetailsModal orderId={order.id} orderItem={orderItem} />,
      { onClose: onClosePopup }
    );
  }, [orderItemId, orders, open, close, onClosePopup]);

  return (
    <Grid container spacing={2}>
      {orders.flatMap((order) =>
        order.items.map((orderItem) => (
          <AccountCard
            key={orderItem.id}
            orderId={order.id}
            orderItem={orderItem}
          />
        ))
      )}
    </Grid>
  );
}
