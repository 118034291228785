import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import HorizontalMenuItem from "./HorizontalMenuItem";
import useCategoryMenuItems from "@paperdateco/customer/components/products/category/useCategoryMenuItems";

const MenuList = CustomStyled("ul")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexGrow: 1,
  flexWrap: "wrap",
  margin: theme.spacing(0, 2),
  padding: 0,
}));

export default function HorizontalHeaderMenu() {
  const menuItems = useCategoryMenuItems();

  return (
    <MenuList>
      {menuItems.map((category) => (
        <HorizontalMenuItem category={category} key={category.id} />
      ))}
    </MenuList>
  );
}
