import { Box, BoxProps } from "@mui/material";
import { Ref, forwardRef } from "react";

interface BackgroundImageProps {
  image: string;
}

function BackgroundImage(
  { image, children, ...props }: BackgroundImageProps & BoxProps,
  ref?: Ref<unknown>
) {
  return (
    <Box
      {...props}
      sx={{
        backgroundImage: `url(${image})`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        ...props.sx,
      }}
      ref={ref}
    >
      {children}
    </Box>
  );
}

export default forwardRef(BackgroundImage);
