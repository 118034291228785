import { Box, Tab, Tabs, Tooltip, styled } from "@mui/material";
import {
  PropsWithChildren,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import DesignEditLeftSection from "../common/types/DesignEditLeftSection";
import DesignEditPopoverSection from "../common/DesignEditPopoverSection";
import DesignEditTabType from "../common/types/DesignEditTabType";

const VerticalDarkBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  background: Colors.DARK_BACKGROUND,
}));

const CustomTabs = styled(Tabs)(({ theme }) => ({
  paddingTop: theme.spacing(7),
  ".MuiTabs-indicator": {
    width: 0,
  },
  ".Mui-selected": {
    background: Colors.WHITE,
    "& > *": {
      color: Colors.DARK_BACKGROUND,
    },
  },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  maxWidth: 64,
  padding: theme.spacing(3, 2),
  minWidth: "unset",
}));

interface DesignEditLeftSidebarProps {
  section?: DesignEditLeftSection;
  setSection: (section?: DesignEditLeftSection) => void;
  tabs: DesignEditTabType[];
}

export default function DesignEditLeftSidebar({
  section,
  setSection,
  tabs,
  children,
}: PropsWithChildren<DesignEditLeftSidebarProps>) {
  const [value, setValue] = useState(-1);

  const closeSection = useCallback(() => {
    setSection(undefined);
  }, [setSection]);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue >= 0 && newValue < tabs.length) {
      setSection(tabs[newValue].section);
    }
  };

  useEffect(() => {
    // Handle section changes from outside
    setValue(tabs.findIndex((tab) => tab.section === section));
  }, [section, tabs]);

  return (
    <VerticalDarkBox display="flex" position="relative">
      <CustomTabs
        orientation="vertical"
        value={value >= 0 ? value : false}
        onChange={handleChange}
      >
        {tabs.map((tab) => (
          <Tooltip key={tab.name} title={tab.name} placement="right">
            <CustomTab icon={tab.icon} aria-label={tab.name} />
          </Tooltip>
        ))}
      </CustomTabs>
      <DesignEditPopoverSection
        position="left"
        open={section !== undefined}
        onClose={closeSection}
      >
        {children}
      </DesignEditPopoverSection>
    </VerticalDarkBox>
  );
}
