import { Box, Grid, Typography } from "@mui/material";
import { useCallback, useState } from "react";

import CartContentsDto from "@paperdateco/common/dto/order/cart/CartContentsDto";
import CartCouponDetail from "./CartCouponDetail";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import NumberUtils from "@paperdateco/common/utils/NumberUtils";
import OrderApi from "@paperdateco/common/api/OrderApi";
import OrderPaymentInfoDto from "@paperdateco/common/dto/order/OrderPaymentInfoDto";
import RazorPayOrder from "../razorpay/RazorPayOrder";
import SubmitButton from "@paperdateco/shared-frontend/components/common/form/button/SubmitButton";
import useSingleInvoke from "@paperdateco/shared-frontend/components/common/hooks/useSingleInvoke";

const CartTotalContainer = CustomStyled(Box)({
  background: Colors.LIGHT_BACKGROUND,
});

const StrikeThrough = CustomStyled(Typography)({
  textDecoration: "line-through",
});

interface CartSubTotalTextProps {
  cartContents: CartContentsDto;
}

function CartSubTotalText({ cartContents }: CartSubTotalTextProps) {
  const isDiscounted = cartContents.appliedCoupon !== undefined;
  if (isDiscounted) {
    return (
      <Typography component="div" variant="body2" textAlign="right">
        <StrikeThrough variant="body2">
          ₹ {NumberUtils.roundString(cartContents.price.subTotal)}
        </StrikeThrough>
        ₹ {NumberUtils.roundString(cartContents.price.discountedTotal)}
      </Typography>
    );
  }
  return (
    <Typography variant="body2" textAlign="right">
      ₹ {NumberUtils.roundString(cartContents.price.subTotal)}
    </Typography>
  );
}

interface CartTotalProps {
  cartContents: CartContentsDto;
}

export default function CartTotal({ cartContents }: CartTotalProps) {
  const [order, setOrder] = useState<OrderPaymentInfoDto>();

  const onCreateOrder = useCallback(async () => {
    if (order) {
      setOrder({ ...order });
      return;
    }
    try {
      const apiOrder = await OrderApi.createOrder();
      setOrder(apiOrder);
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  }, [order]);

  const [onCheckout, blocked] = useSingleInvoke(onCreateOrder);

  return (
    <CartTotalContainer width={400} padding={{ xs: 2, sm: 5 }}>
      <Typography
        variant="h5"
        fontFamily={Fonts.Marcellus}
        marginBottom={4}
        textAlign="center"
      >
        Cart totals
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6">Subtotal</Typography>
        </Grid>
        <Grid item xs={6}>
          <CartSubTotalText cartContents={cartContents} />
        </Grid>
        <Grid item xs={12}>
          <CartCouponDetail cartContents={cartContents} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">GST</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" textAlign="right">
            ₹ {NumberUtils.roundString(cartContents.price.tax)}
          </Typography>
        </Grid>
        <Grid item xs={6} marginTop={2}>
          <Typography variant="h6">Total</Typography>
        </Grid>
        <Grid item xs={6} marginTop={2}>
          <Typography variant="h5" textAlign="right">
            ₹ {NumberUtils.roundString(cartContents.price.total)}
          </Typography>
        </Grid>
      </Grid>
      <Box marginTop={3} textAlign="center">
        <SubmitButton
          variant="contained"
          size="large"
          onClick={onCheckout}
          disabled={blocked}
        >
          PROCEED TO CHECKOUT
        </SubmitButton>
      </Box>
      {order && <RazorPayOrder order={order} />}
    </CartTotalContainer>
  );
}
