import { Box, Stack, Typography } from "@mui/material";

import CanvasAnimationOptionControl from "../CanvasAnimationOptionControl";
import { CanvasAnimationSection } from "../useMappedAnimationOptions";
import CanvasElement from "@paperdateco/shared-frontend/canvas/objects/CanvasElement";
import InviteInstantPreview from "@paperdateco/shared-frontend/components/invite/InviteInstantPreview";

interface CanvasChooseAnimationSectionProps<T extends fabric.Object> {
  section: CanvasAnimationSection;
  instantPreview?: InviteInstantPreview;
  elements: CanvasElement<T>[];
}

export default function CanvasChooseAnimationSection<T extends fabric.Object>({
  section,
  instantPreview,
  elements,
}: CanvasChooseAnimationSectionProps<T>) {
  return (
    <Stack direction="column" marginY={3} key={section.name} spacing={1}>
      <Typography variant="body2" fontWeight="bold">
        {section.name}
      </Typography>
      <Box display="flex" flexWrap="wrap" alignItems="stretch">
        {section.options.map((option) => (
          <CanvasAnimationOptionControl
            instantPreview={instantPreview}
            elements={elements}
            option={option}
            key={option.animationType}
          />
        ))}
      </Box>
    </Stack>
  );
}
