import CanvasElement from "@paperdateco/shared-frontend/canvas/objects/CanvasElement";
import CanvasUtils from "@paperdateco/shared-frontend/canvas/CanvasUtils";
import DesignComponentsRequestDto from "@paperdateco/common/dto/design/components/DesignComponentsRequestDto";
import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import DesignLayerDto from "@paperdateco/common/dto/design/layer/DesignLayerDto";
import DesignLayerType from "@paperdateco/common/dto/design/layer/DesignLayerType";
import DesignPageDto from "@paperdateco/common/dto/design/pages/DesignPageDto";
import DesignUtils from "@paperdateco/common/utils/DesignUtils";
import DownloadUtils from "@paperdateco/common/utils/DownloadUtils";
import EditableTextElement from "@paperdateco/shared-frontend/canvas/objects/EditableTextElement";
import EditableTextOptions from "@paperdateco/common/canvas/objects/EditableTextOptions";
import Guidelines from "@paperdateco/shared-frontend/canvas/guidelines/Guidelines";
import ImageMaskElement from "@paperdateco/shared-frontend/canvas/objects/ImageMaskElement";
import ImageMaskOptions from "@paperdateco/common/canvas/objects/ImageMaskOptions";
import KeyUtils from "@paperdateco/shared-frontend/utils/KeyUtils";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import ResizableImageElement from "@paperdateco/shared-frontend/canvas/objects/ResizableImageElement";
import ResizableImageOptions from "@paperdateco/common/canvas/objects/ResizableImageOptions";
import { fabric } from "fabric";

export type ChangeListener = () => void;

export default class InviteInstantPreview {
  backgroundTemplate?: LibraryImageDto;
  coverEnvelope?: LibraryImageDto;
  frontEnvelope?: LibraryImageDto;
  innerEnvelope?: LibraryImageDto;
  layers: CanvasElement<any>[] = [];
  selectedLayers: CanvasElement[] = [];

  priceChangeListeners: ChangeListener[] = [];
  layersChangeListeners: ChangeListener[] = [];
  selectionListeners: ChangeListener[] = [];
  envelopeChangeListeners: ChangeListener[] = [];

  constructor(
    public canvas: fabric.Canvas,
    public nativeCanvas: HTMLCanvasElement,
    public width: number,
    public height: number
  ) {
    this.setDefaultConfig();
    this.setupListeners();
    new Guidelines(canvas);
    CanvasUtils.constantlyRerenderCanvas(this.canvas);
  }

  addElement(element: CanvasElement, defaultSelect = true) {
    this.layers.push(element);
    this.onLayersUpdate();
    this.onPriceUpdate();
    if (defaultSelect) {
      element.select();
    }
  }

  addText(options: EditableTextOptions, defaultSelect = true) {
    const element = new EditableTextElement(this.canvas, options);
    this.addElement(element, defaultSelect);
  }

  async addImage(options: ResizableImageOptions, defaultSelect = true) {
    const maskLayer = this.getSelectedMaskLayer();
    if (maskLayer) {
      // If a mask layer is selected, add the image inside the mask.
      await maskLayer.addImage({
        image: options.image,
        type: options.type,
      });
      maskLayer.resetImageOptions();
      this.onPriceUpdate();
      return;
    }
    const element = await ResizableImageElement.fromOptions(
      this.canvas,
      options
    );
    this.addElement(element, defaultSelect);
  }

  async addMask(options: ImageMaskOptions, defaultSelect = true) {
    const element = await ImageMaskElement.fromOptions(this.canvas, options);
    this.addElement(element, defaultSelect);
  }

  setBackground = async (template: LibraryImageDto) => {
    this.backgroundTemplate = template;
    this.onPriceUpdate();
    const templateDataUrl = await DownloadUtils.get(template.url);
    await CanvasUtils.setBackgroundImage(
      this.canvas,
      this.width,
      this.height,
      templateDataUrl
    );
  };

  setZoom = (zoom: number) => {
    this.canvas.setZoom(zoom / 100);
  };

  setCoverEnvelope = (envelope: LibraryImageDto) => {
    this.coverEnvelope = envelope;
    this.onEnvelopeUpdate();
  };

  setFrontEnvelope = (envelope: LibraryImageDto) => {
    this.frontEnvelope = envelope;
    this.onEnvelopeUpdate();
  };

  setInnerEnvelope = (envelope: LibraryImageDto) => {
    this.innerEnvelope = envelope;
    this.onEnvelopeUpdate();
  };

  removeSelectedLayers = () => {
    this.remove(this.selectedLayers);
  };

  moveSelectedLayers = (direction: "left" | "right" | "up" | "down") => {
    this.selectedLayers.forEach((layer) => {
      switch (direction) {
        case "left":
          layer.nativeElement.left = (layer.nativeElement.left ?? 0) - 1;
          break;
        case "right":
          layer.nativeElement.left = (layer.nativeElement.left ?? 0) + 1;
          break;
        case "up":
          layer.nativeElement.top = (layer.nativeElement.top ?? 0) - 1;
          break;
        case "down":
          layer.nativeElement.top = (layer.nativeElement.top ?? 0) + 1;
          break;
      }
    });
    this.canvas.renderAll();
  };

  swapSelectedLayers = (
    direction: "forward" | "backward" | "front" | "back"
  ) => {
    const selectedLayers = this.selectedLayers;
    const index = this.layers.findIndex((layer) =>
      selectedLayers.includes(layer)
    );
    const newLayers = this.layers.filter(
      (layer) => !selectedLayers.includes(layer)
    );

    switch (direction) {
      case "forward":
        newLayers.splice(index + 1, 0, ...selectedLayers);
        break;
      case "backward":
        newLayers.splice(Math.max(index - 1, 0), 0, ...selectedLayers);
        break;
      case "front":
        newLayers.push(...selectedLayers);
        break;
      case "back":
        newLayers.unshift(...selectedLayers);
        break;
    }
    this.layers = newLayers;
    this.layers.forEach((layer, index) =>
      this.canvas.moveTo(layer.nativeElement, index)
    );
    this.onLayersUpdate();
  };

  remove = (layers: CanvasElement[]) => {
    this.layers = this.layers.filter((l) => !layers.includes(l));
    this.onLayersUpdate();
    this.onPriceUpdate();
    this.canvas.remove(...layers.map((layer) => layer.nativeElement));
    this.deselectLayers();
    this.findSelectedLayers();
  };

  translateDesignLayer = (designLayer: DesignLayerDto) => {
    switch (designLayer.type) {
      case DesignLayerType.TEXT:
        if (designLayer.textProperties?.text) {
          designLayer.textProperties = {
            ...designLayer.textProperties,
            left: (designLayer.textProperties?.left ?? 0) + 50,
            top: (designLayer.textProperties?.top ?? 0) + 50,
          };
        }
        return designLayer;
      case DesignLayerType.IMAGE:
        if (designLayer.imageProperties?.image) {
          designLayer.imageProperties = {
            ...designLayer.imageProperties,
            left: (designLayer.imageProperties?.left ?? 0) + 50,
            top: (designLayer.imageProperties?.top ?? 0) + 50,
          };
        }
        return designLayer;
      case DesignLayerType.MASK:
        if (designLayer.imageMaskProperties?.mask) {
          designLayer.imageMaskProperties = {
            ...designLayer.imageMaskProperties,
            left: (designLayer.imageMaskProperties?.left ?? 0) + 50,
            top: (designLayer.imageMaskProperties?.top ?? 0) + 50,
          };
        }
        return designLayer;
    }
  };

  duplicateLayer = (layers: CanvasElement[]) => {
    this.deselectLayers();
    layers.forEach((layer) => {
      const designDto = this.convertCanvasLayerToDesignLayer(layer);
      if (designDto) {
        this.addLayer(this.translateDesignLayer(designDto), false);
      }
    });
  };

  onSelectionChange(listener: ChangeListener) {
    this.selectionListeners.push(listener);
  }

  removeSelectionChangeListener(listener: ChangeListener) {
    this.selectionListeners = this.selectionListeners.filter(
      (l) => l !== listener
    );
  }

  onPriceChange(listener: ChangeListener) {
    this.priceChangeListeners.push(listener);
  }

  removePriceChangeListener(listener: ChangeListener) {
    this.priceChangeListeners = this.priceChangeListeners.filter(
      (l) => l !== listener
    );
  }

  onLayersChange(listener: ChangeListener) {
    this.layersChangeListeners.push(listener);
  }

  removeLayersChangeListener(listener: ChangeListener) {
    this.layersChangeListeners = this.layersChangeListeners.filter(
      (l) => l !== listener
    );
  }

  onEnvelopeChange(listener: ChangeListener) {
    this.envelopeChangeListeners.push(listener);
  }

  removeEnvelopeChangeListener(listener: ChangeListener) {
    this.envelopeChangeListeners = this.envelopeChangeListeners.filter(
      (l) => l !== listener
    );
  }

  getCost() {
    const layers = this.getDesignLayers() ?? [];
    const textPrice = DesignUtils.calculateTextPrice(layers);
    const elementPrice = DesignUtils.calculatePremiumElementsPrice(
      layers,
      DesignUtils.getImageComponent(this.backgroundTemplate)
    );
    return textPrice + elementPrice;
  }

  async setDefaultPageDesign(page: DesignPageDto) {
    if (page.background) {
      await this.setBackground(page.background.image);
    }
    await this.setLayers(page.layers);
  }

  setDefaultEnvelopeDesign = (design: DesignDto) => {
    if (!this.coverEnvelope) {
      this.coverEnvelope = design.components.coverEnvelope?.image;
    }
    if (!this.frontEnvelope) {
      this.frontEnvelope = design.components.frontEnvelope?.image;
    }
    if (!this.innerEnvelope) {
      this.innerEnvelope = design.components.innerEnvelope?.image;
    }
    this.onEnvelopeUpdate();
  };

  getDesignLayers() {
    return this.layers
      .map(this.convertCanvasLayerToDesignLayer)
      .filter((layer): layer is DesignLayerDto => layer !== undefined);
  }

  getBackground() {
    return DesignUtils.getImageComponent(this.backgroundTemplate);
  }

  getComponents(): DesignComponentsRequestDto {
    return {
      background: this.getImageComponentRequest(this.backgroundTemplate),
      coverEnvelope: this.getImageComponentRequest(this.coverEnvelope),
      frontEnvelope: this.getImageComponentRequest(this.frontEnvelope),
      innerEnvelope: this.getImageComponentRequest(this.innerEnvelope),
    };
  }

  hasImageLayer(image: LibraryImageDto) {
    return this.layers.find(
      (layer) =>
        layer instanceof ResizableImageElement && layer.image.id === image.id
    );
  }

  getSelectedMaskLayer(): ImageMaskElement | undefined {
    return this.selectedLayers.find(
      (layer) => layer instanceof ImageMaskElement
    ) as ImageMaskElement | undefined;
  }

  private async setLayers(layers: DesignLayerDto[]) {
    for (let layer of layers) {
      await this.addLayer(layer);
    }
  }

  private setDefaultConfig() {
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.borderColor = "#000";
    fabric.Object.prototype.cornerColor = "#fff";
    fabric.Object.prototype.cornerStrokeColor = "#000";
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerStyle = "circle";
    fabric.Canvas.prototype.preserveObjectStacking = true;
  }

  private setupListeners() {
    this.listenToSelectionEvents();
    this.listenToFontLoads();
    this.listenToKeyboardEvents();
  }

  private listenToFontLoads() {
    document.fonts.ready.then(this.forceRerenderText);
    document.fonts.onloadingdone = this.forceRerenderText;
  }

  private listenToSelectionEvents() {
    this.canvas.on("selection:created", this.findSelectedLayers);
    this.canvas.on("selection:cleared", this.findSelectedLayers);
    this.canvas.on("selection:updated", this.findSelectedLayers);
  }

  private listenToKeyboardEvents() {
    const elem: HTMLElement = (this.canvas as any).wrapperEl;
    elem.tabIndex = 1000;
    elem?.addEventListener("keydown", this.onKeyDown, false);
  }

  private forceRerenderText = () => {
    if (this.layers.length === 0) {
      setTimeout(this.forceRerenderText, 500);
      return;
    }
    fabric.util.clearFabricFontCache();
    this.layers.forEach((layer) => {
      if (layer instanceof EditableTextElement) {
        layer.nativeElement.dirty = true;
        layer.nativeElement.initDimensions();
      }
    });
  };

  private findSelectedLayers = () => {
    const selectedObjects = this.canvas.getActiveObjects();
    this.setSelectedLayers(
      this.layers.filter((layer) =>
        selectedObjects.find((item) => item === layer.nativeElement)
      )
    );
  };

  private setSelectedLayers(value: CanvasElement[]) {
    this.selectedLayers = value;
    this.selectionListeners.forEach((listener) => listener());
  }

  private selectAllLayers() {
    this.deselectLayers();
    const elements = this.layers.map((layer) => layer.nativeElement);
    var sel = new fabric.ActiveSelection(elements, { canvas: this.canvas });
    this.canvas.setActiveObject(sel);
    this.findSelectedLayers();
  }

  private deselectLayers() {
    this.canvas.discardActiveObject().renderAll();
  }

  private onEnvelopeUpdate() {
    this.envelopeChangeListeners.forEach((listener) => listener());
  }

  public onPriceUpdate() {
    this.priceChangeListeners.forEach((listener) => listener());
  }

  public onLayersUpdate() {
    this.layersChangeListeners.forEach((listener) => listener());
  }

  private convertCanvasLayerToDesignLayer = (
    layer: CanvasElement
  ): DesignLayerDto | undefined => {
    if (layer instanceof EditableTextElement) {
      const textProperties = layer.getDesignLayerProperties();
      if (textProperties.text === "") {
        return undefined;
      }
      return {
        type: DesignLayerType.TEXT,
        textProperties,
      };
    }
    if (layer instanceof ResizableImageElement) {
      return {
        type: DesignLayerType.IMAGE,
        imageProperties: layer.getDesignLayerProperties(),
      };
    }
    if (layer instanceof ImageMaskElement) {
      return {
        type: DesignLayerType.MASK,
        imageMaskProperties: layer.getDesignLayerProperties(),
      };
    }
    return undefined;
  };

  private addLayer = async (layer: DesignLayerDto, defaultSelect = false) => {
    switch (layer.type) {
      case DesignLayerType.TEXT:
        if (layer.textProperties) {
          this.addText(
            EditableTextElement.convertDesignPropertyToOptions(
              layer.textProperties
            ),
            defaultSelect
          );
        }
        return;
      case DesignLayerType.IMAGE:
        if (layer.imageProperties) {
          await this.addImage(
            ResizableImageElement.convertDesignPropertyToOptions(
              layer.imageProperties
            ),
            defaultSelect
          );
        }
        return;
      case DesignLayerType.MASK:
        if (layer.imageMaskProperties) {
          await this.addMask(
            ImageMaskElement.convertDesignPropertyToOptions(
              layer.imageMaskProperties
            ),
            defaultSelect
          );
        }
    }
  };

  private onKeyDown = (e: KeyboardEvent) => {
    if (KeyUtils.isSelectAll(e)) {
      this.selectAllLayers();
    } else if (KeyUtils.isEscape(e)) {
      this.deselectLayers();
    } else if (KeyUtils.isDelete(e)) {
      this.removeSelectedLayers();
    } else if (KeyUtils.isLeft(e)) {
      this.moveSelectedLayers("left");
    } else if (KeyUtils.isRight(e)) {
      this.moveSelectedLayers("right");
    } else if (KeyUtils.isUp(e)) {
      this.moveSelectedLayers("up");
    } else if (KeyUtils.isDown(e)) {
      this.moveSelectedLayers("down");
    } else {
      return;
    }
    e.preventDefault();
  };

  private getImageComponentRequest(image?: LibraryImageDto) {
    return image && DesignUtils.getImageRequest(image);
  }
}
