import { TimePicker, TimePickerProps } from "@mui/x-date-pickers";

import { TextField } from "@mui/material";
import { useCallback } from "react";

interface CustomTimePickerProps {
  value?: Date;
  onChange: (value?: Date) => void;
}

export default function CustomTimePicker({
  value,
  onChange,
  ...props
}: CustomTimePickerProps &
  Omit<
    TimePickerProps<Date | null, Date | null>,
    "value" | "onChange" | "renderInput"
  >) {
  const onChangeDate = useCallback((value: Date | null) => {
    if (!value) {
      onChange(undefined);
      return;
    }
    onChange(value);
  }, []);

  return (
    <TimePicker
      {...props}
      value={value ?? null}
      onChange={onChangeDate}
      renderInput={(params) => <TextField fullWidth required {...params} />}
    />
  );
}
