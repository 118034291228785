import { Box, Stack, Typography } from "@mui/material";
import StringUtils, {
  StringDateFormat,
} from "@paperdateco/common/utils/StringUtils";

import EventDto from "@paperdateco/common/dto/order/event/EventDto";

interface EventDetailsContentProps {
  event: EventDto;
}

export default function EventDetailsContent({
  event,
}: EventDetailsContentProps) {
  return (
    <Box>
      <Typography variant="body1" fontWeight={600}>
        EVENT DETAILS
      </Typography>
      <Typography component="div" variant="body1" color="primary.800">
        <Stack direction="column" spacing={1} marginY={2}>
          <span>Host Name: {event.hostName}</span>
          <span>Event Name: {event.eventName}</span>
          <span>
            Event Date:{" "}
            {StringUtils.formatDate(
              event.eventDate,
              StringDateFormat.ONLY_DATE
            )}
          </span>
          <span>
            Event Time:{" "}
            {StringUtils.formatDate(
              event.eventDate,
              StringDateFormat.ONLY_TIME
            )}
          </span>
          <span>Location: {event.location}</span>
        </Stack>
      </Typography>
    </Box>
  );
}
