import AnimationValue from "./AnimationValue";
import AnimationValueUtil from "./AnimationValueUtil";
import { fabric } from "fabric";

export default function WipeAnimation(elem: fabric.Object): AnimationValue[] {
  const clipPath = new fabric.Rect({
    width: elem.width ?? 0,
    height: elem.height ?? 0,
    top: 0,
    left: 0,
    originX: "center",
    originY: "center",
    inverted: true,
  });
  elem.clipPath = clipPath;
  return [
    {
      property: "clipPath",
      value: {
        from: 0,
        to: elem.width,
        duration: 1000,
        easing: AnimationValueUtil.easeLinear,
        onChange: (value) => {
          clipPath.set("left", value);
          elem.dirty = true;
        },
        onComplete: () => {
          elem.clipPath = undefined;
        },
      },
    },
  ];
}
