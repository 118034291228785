import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import RsvpStatus from "@paperdateco/common/dto/order/rsvp/RsvpStatus";
import { SvgIconProps } from "@mui/material";
interface MailerRsvpStatusIconProps {
  status?: RsvpStatus;
}

export default function MailerRsvpStatusIcon({
  status,
  ...props
}: MailerRsvpStatusIconProps & SvgIconProps) {
  switch (status) {
    case RsvpStatus.YES:
      return <CheckIcon {...props} sx={{ ...props.sx, color: Colors.GREEN }} />;
    case RsvpStatus.NO:
      return <CloseIcon {...props} sx={{ ...props.sx, color: Colors.RED }} />;
    case RsvpStatus.MAYBE:
      return (
        <QuestionMarkIcon {...props} sx={{ ...props.sx, color: Colors.BLUE }} />
      );
    default:
      return null;
  }
}
