import CanvasObjectPosition from "./CanvasObjectPosition";
import { fabric } from "fabric";

export interface AnimateOptions {
  from?: number;
  to?: number;
  easing?: Function;
  duration?: number;
  delay?: number;
  onChange?: (value: number) => void;
  onComplete?: () => void;
}

interface RecordOptions {
  frameRate?: number;
  duration?: number;
  onComplete?: (videoUrl: string) => void;
}

export default class CanvasUtils {
  static computeObjectPosition = (
    object: fabric.Object,
    viewportTransform: number[]
  ): CanvasObjectPosition => {
    const objectCenter = object.getCenterPoint();
    const objectBoundingRect = object.getBoundingRect();
    return {
      centerX: objectCenter.x,
      centerY: objectCenter.y,
      width: objectBoundingRect.width / viewportTransform[0],
      height: objectBoundingRect.height / viewportTransform[3],
    };
  };

  static reRender = (canvas: fabric.Canvas | fabric.StaticCanvas) => {
    if (canvas.getContext()) {
      canvas.renderAll();
      return true;
    } else {
      return false;
    }
  };

  /**
   * For Gifs to animate, we need to constantly re render canvas
   *
   * Because of this, we no longer update the canvas when we do other
   * updates like alignment, opacity, etc.
   *
   */
  static constantlyRerenderCanvas = (
    canvas: fabric.Canvas | fabric.StaticCanvas
  ) => {
    (function render() {
      const updated = CanvasUtils.reRender(canvas);
      if (updated) {
        fabric.util.requestAnimFrame(render);
      }
    })();
  };

  static forceRenderElement = (
    element: fabric.Object,
    canvas: fabric.Canvas | fabric.StaticCanvas
  ) => {
    (function render() {
      element.dirty = true;
      if (canvas.getContext()) {
        fabric.util.requestAnimFrame(render);
      }
    })();
  };

  static loadImage = (
    url: string,
    {
      width,
      height,
      left,
      top,
      scaleX,
      scaleY,
      ...options
    }: fabric.IImageOptions = {}
  ) => {
    return new Promise<fabric.Image>((res) => {
      fabric.Image.fromURL(
        url,
        (image) => {
          CanvasUtils.scaleDimensions(image, width, height, scaleX, scaleY);
          image.set({
            left: left ?? 0,
            top: top ?? 0,
          });
          res(image);
        },
        options
      );
    });
  };

  static loadMask = (
    maskUrl: string,
    { width, height, scaleX, scaleY, ...options }: fabric.IGroupOptions = {}
  ) => {
    return new Promise<fabric.Group>((resolve) => {
      fabric.loadSVGFromURL(maskUrl, (res) => {
        var mask = new fabric.Group(res, { ...options, objectCaching: false });
        mask.getObjects().forEach((o) => (o.objectCaching = false));
        CanvasUtils.scaleDimensions(mask, width, height, scaleX, scaleY);
        resolve(mask);
      });
    });
  };

  static loadMaskImage = (mask: fabric.Group, image: fabric.Image) => {
    const { patternSourceCanvas, source } =
      CanvasUtils.getHtmlElementFromFabricImage(image, mask);
    const pattern = new fabric.Pattern({
      source,
      repeat: "no-repeat",
    });
    mask.getObjects().forEach((o) => o.set("fill", pattern));
    return { pattern, patternSourceCanvas };
  };

  static setPatternCanvasDimension(
    canvas: fabric.StaticCanvas,
    mask: fabric.Group
  ) {
    canvas?.setDimensions({
      width: mask.width ?? 0,
      height: mask.height ?? 0,
    });
  }

  static getHtmlElementFromFabricImage = (
    image: fabric.Image,
    mask: fabric.Group
  ) => {
    // Scale the image by 2 since the `patternSourceCanvas` scales the output by 2.
    image.scaleToWidth(image.getScaledWidth() / 2);
    var patternSourceCanvas = new fabric.StaticCanvas(null);
    CanvasUtils.setPatternCanvasDimension(patternSourceCanvas, mask);
    patternSourceCanvas.add(image);
    CanvasUtils.constantlyRerenderCanvas(patternSourceCanvas);
    return {
      patternSourceCanvas,
      source: patternSourceCanvas.getElement() as unknown as HTMLImageElement,
    };
  };

  static async setBackgroundImage(
    canvas: fabric.Canvas,
    width: number,
    height: number,
    imageUrl: string
  ) {
    const backgroundImage = await CanvasUtils.loadImage(imageUrl);

    const imageWidth = backgroundImage.width;
    const imageHeight = backgroundImage.height;

    if (!imageWidth || !imageHeight) {
      return;
    }

    canvas.setBackgroundImage(
      backgroundImage,
      () => {
        if (canvas.getContext()) {
          canvas.renderAll();
        }
      },
      {
        top: 0,
        left: 0,
        originX: "left",
        originY: "top",
        scaleX: width / imageWidth,
        scaleY: height / imageHeight,
      }
    );
  }

  static scaleImageAsCover(image: fabric.Image, width: number, height: number) {
    const imageWidth = image.width;
    const imageHeight = image.height;

    if (!imageWidth || !imageHeight) {
      return { top: 0, left: 0, scaleFactor: 0 };
    }
    // Calcuation to make the background image as cover
    const canvasAspect = width / height;
    const imgAspect = imageWidth / imageHeight;
    var left: number, top: number, scaleFactor: number;

    if (canvasAspect >= imgAspect) {
      scaleFactor = width / imageWidth;
      left = 0;
      top = -(imageHeight * scaleFactor - height) / 2;
    } else {
      scaleFactor = height / imageHeight;
      top = 0;
      left = -(imageWidth * scaleFactor - width) / 2;
    }

    return { left, top, scaleFactor };
  }

  static scaleDimensions(
    obj: fabric.Object,
    width?: number,
    height?: number,
    scaleX?: number,
    scaleY?: number
  ) {
    if (scaleX) {
      obj.scaleX = scaleX;
    } else if (width) {
      obj.scaleToWidth(width);
    }

    if (scaleY) {
      obj.scaleY = scaleY;
    } else if (height) {
      obj.scaleToHeight(height);
    }
  }

  static animate(
    canvas: fabric.Canvas,
    obj: fabric.Object | undefined,
    property: keyof fabric.Object,
    {
      from,
      to,
      easing,
      duration,
      delay = 0,
      onChange,
      onComplete,
    }: AnimateOptions = {}
  ) {
    return new Promise<void>((res) => {
      if (!obj) {
        res();
        return;
      }
      setTimeout(
        () =>
          fabric.util.animate({
            startValue: from ?? obj[property],
            endValue: to,
            duration: duration ?? 2000,
            easing: easing ?? fabric.util.ease.easeOutQuad,
            onChange: (value) => {
              if (onChange) {
                onChange(value);
              } else {
                obj.set(property, value);
              }
              obj.setCoords();
              if (canvas.getContext()) {
                canvas.renderAll();
              }
            },
            onComplete: () => {
              onComplete?.();
              res();
            },
          }),
        delay
      );
    });
  }

  static waitAnimation = (timeInMs = 5000) => {
    return new Promise((res) => setTimeout(res, timeInMs));
  };

  static record(
    canvas: HTMLCanvasElement,
    { frameRate = 30, onComplete }: RecordOptions = {}
  ) {
    return new Promise<MediaRecorder>((res) => {
      const videoStream = canvas.captureStream(frameRate);
      const mediaRecorder = new MediaRecorder(videoStream);

      let chunks: Blob[] = [];
      mediaRecorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: "video/webm" });
        chunks = [];
        const videoURL = URL.createObjectURL(blob);
        onComplete?.(videoURL);
      };

      mediaRecorder.start(100);
      res(mediaRecorder);
    });
  }

  static takePicture(fabricCanvas: fabric.Canvas, canvas: HTMLCanvasElement) {
    if (fabricCanvas.getActiveObjects().length > 0) {
      fabricCanvas.discardActiveObject().renderAll();
    }
    return canvas.toDataURL();
  }
}
