import { Button, Typography } from "@mui/material";
import { FormEvent, useState } from "react";

import AuthForm from "./AuthForm";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import FormRow from "@paperdateco/shared-frontend/components/common/form/FormRow";
import GoogleSignIn from "@paperdateco/shared-frontend/components/auth/GoogleSignIn";
import LoginLink from "./LoginLink";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import UserApi from "@paperdateco/common/api/UserApi";
import routes from "@paperdateco/customer/routes/routes";
import { useNavigate } from "react-router";

export default function RegisterForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      NotificationUtils.showError("Please check if the password match");
      return;
    }

    try {
      await UserApi.register({ email: email.toLowerCase(), password });
      NotificationUtils.showSuccess(
        "Registration successful, Verification mail is sent"
      );
      navigate(routes.LOGIN);
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  return (
    <AuthForm>
      <form onSubmit={handleSubmit}>
        <FormRow>
          <CustomTextField
            variant="standard"
            type="email"
            name="email"
            label="Email Address"
            autoComplete="email"
            value={email}
            onChange={setEmail}
          />
        </FormRow>
        <FormRow>
          <CustomTextField
            variant="standard"
            label="Password"
            type="password"
            name="password"
            autoComplete="new-password"
            value={password}
            onChange={setPassword}
          />
        </FormRow>
        <FormRow>
          <CustomTextField
            variant="standard"
            label="Confirm Password"
            type="password"
            autoComplete="new-password"
            value={confirmPassword}
            onChange={setConfirmPassword}
          />
        </FormRow>
        <FormRow textAlign="center">
          <Button variant="contained" type="submit" size="large">
            REGISTER
          </Button>
          <Typography variant="body1" margin={1}>
            Or
          </Typography>
          <GoogleSignIn />
        </FormRow>
      </form>
      <LoginLink />
    </AuthForm>
  );
}
