import { Box, Button } from "@mui/material";
import {
  CartStateDispatchType,
  getAllCartItems,
} from "@paperdateco/customer/store/cart/CartAction";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import CartApi from "@paperdateco/common/api/CartApi";
import CartProductList from "./CartProductList";
import CartTotal from "./CartTotal";
import { Link } from "react-router-dom";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import routes from "@paperdateco/customer/routes/routes";
import { selectCartContents } from "@paperdateco/customer/store/cart/CartSelector";

export default function CartBody() {
  const cartContents = useSelector(selectCartContents);
  const dispatch = useDispatch<CartStateDispatchType>();

  const getCartItems = useCallback(() => {
    dispatch(getAllCartItems(true));
  }, [dispatch]);

  useEffect(() => {
    getCartItems();
  }, [getCartItems]);

  const removeProductItem = useCallback(
    (cartItemId: string) => {
      CartApi.removeItemInCart(cartItemId)
        .then(getCartItems)
        .catch(NotificationUtils.showGenericError);
    },
    [getCartItems]
  );

  const cartItems = cartContents?.items ?? [];

  return (
    <Box padding={{ xs: 2, sm: 4 }}>
      {cartItems.length > 0 && (
        <CartProductList
          onRemoveItem={removeProductItem}
          cartItems={cartItems}
        />
      )}
      <Link to={routes.HOME}>
        <Button color="primary" variant="contained" size="large">
          CONTINUE SHOPPING
        </Button>
      </Link>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        marginTop={2}
      >
        {cartContents && <CartTotal cartContents={cartContents} />}
      </Box>
    </Box>
  );
}
