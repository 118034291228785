import { Box } from "@mui/system";
import { PropsWithChildren } from "react";

interface ZoomableCanvasProps {
  zoom: number;
  defaultWidth: number;
  defaultHeight: number;
  visible?: boolean;
}

export default function ZoomableCanvas({
  zoom,
  defaultWidth,
  defaultHeight,
  children,
  visible = true,
}: PropsWithChildren<ZoomableCanvasProps>) {
  const zoomRatio = zoom / 100;
  const width = defaultWidth * zoomRatio;
  const height = defaultHeight * zoomRatio;

  return (
    <Box
      display={visible ? "block" : "none"}
      width="100%"
      height="100%"
      overflow="auto"
      flexGrow={1}
      padding={1}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={width}
        height={height}
      >
        <Box sx={{ transform: `scale(${zoomRatio})` }}>{children}</Box>
      </Box>
    </Box>
  );
}
