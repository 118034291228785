import { CSSProperties, PropsWithChildren } from "react";

import BackgroundSelect from "@paperdateco/shared-frontend/components/common/image/BackgroundSelect";
import { Box } from "@mui/system";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import HiddenImageFit from "@paperdateco/shared-frontend/components/common/image/HiddenImageFit";
import classNames from "classnames";

const SLIDE_ACTIVE_CLASS = "slide-active";
const IMAGE_CONTAINER_CLASS = "image-container";
const TEXT_CONTAINER_CLASS = "text-container";

const Container = CustomStyled(Box)({
  position: "relative",
  ["." + IMAGE_CONTAINER_CLASS]: {
    overflow: "hidden",
    "& > *": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      transform: "translate(100%, 0)",
      opacity: 0,
      transition: "all 1s",
    },
  },
  ["." + TEXT_CONTAINER_CLASS]: {
    transform: "translate(0, 15%)",
    opacity: 0,
    transition: "all 1s",
  },
  ["&." + SLIDE_ACTIVE_CLASS]: {
    ["." + IMAGE_CONTAINER_CLASS]: {
      "& > *": {
        transform: "translate(0, 0)",
        opacity: 1,
        transitionDelay: "1s",
      },
    },
    ["." + TEXT_CONTAINER_CLASS]: {
      transform: "translate(0, 0)",
      opacity: 1,
      transitionDelay: "2s",
    },
  },
});

const TextContent = CustomStyled(Box)({
  position: "absolute",
  right: 0,
  top: 0,
  zIndex: 2,
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

interface BannerLayoutProps {
  src?: string;
  type: string;
  width: number;
  height: number;
  isActive: boolean;
  imageStyle?: CSSProperties;
}

export default function BannerLayout({
  src,
  type,
  width,
  height,
  isActive,
  imageStyle,
  children,
}: PropsWithChildren<BannerLayoutProps>) {
  return (
    <Container
      className={classNames({
        [SLIDE_ACTIVE_CLASS]: isActive,
      })}
    >
      <Box width="100%" className={IMAGE_CONTAINER_CLASS}>
        <BackgroundSelect
          src={src}
          style={imageStyle}
          fit={HiddenImageFit.COVER}
          aspectRatio={width / height}
          type={type}
        />
      </Box>

      {children && (
        <TextContent className={TEXT_CONTAINER_CLASS}>{children}</TextContent>
      )}
    </Container>
  );
}
