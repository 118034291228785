import { Box, BoxProps } from "@mui/system";
import { Button, ButtonGroup } from "@mui/material";
import { CSSProperties, Ref, forwardRef } from "react";
import {
  IconDefinition,
  faDownload,
  faHome,
  faListAlt,
  faMapMarkerAlt,
  faSignOutAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import routes from "@paperdateco/customer/routes/routes";

interface NavOption {
  name: string;
  icon: IconDefinition;
  route: string;
}

const NavOptions: NavOption[] = [
  { name: "Dashboard", icon: faHome, route: routes.ACCOUNT_DASHBOARD },
  { name: "Orders", icon: faListAlt, route: routes.ACCOUNT_ORDERS },
  { name: "Drafts", icon: faDownload, route: routes.ACCOUNT_DRAFTS },
  {
    name: "Manage/Download Invites",
    icon: faMapMarkerAlt,
    route: routes.ACCOUNT_MANAGE_INVITES(),
  },
  {
    name: "Account Details",
    icon: faUser,
    route: routes.ACCOUNT_DETAILS,
  },
  { name: "Logout", icon: faSignOutAlt, route: routes.LOGOUT },
];

const ButtonStyle: CSSProperties = {
  justifyContent: "space-between",
  padding: "15px 40px",
};

export default function AccountSectionsNav(props: BoxProps) {
  return (
    <Box maxWidth={300} width="100%" margin="auto" {...props}>
      <ButtonGroup orientation="vertical" fullWidth>
        {NavOptions.map((nav) => (
          <Button
            key={nav.name}
            color="primary"
            component={forwardRef((props, ref: Ref<any>) => (
              <Link ref={ref} to={nav.route} style={ButtonStyle} {...props}>
                {props.children}
              </Link>
            ))}
            endIcon={<FontAwesomeIcon icon={nav.icon} width="100%" />}
          >
            {nav.name}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
}
