import axios from "axios";
const Buffer = require("buffer/").Buffer;

export default class DownloadUtils {
  static async get(url: string) {
    if (url.startsWith("data")) {
      return url;
    }
    const response = await axios.get(url, {
      responseType: "arraybuffer",
    });
    return (
      "data:image/jpeg;base64, " +
      Buffer.from(response.data as any, "binary").toString("base64")
    );
  }

  static async save(url: string, fileName: string) {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
