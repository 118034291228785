import AdminBannerUpdateRequestDto from "../dto/banner/AdminBannerUpdateRequest";
import BannerDto from "../dto/banner/BannerDto";
import { FileUtils } from "../utils/FileUtils";
import PresignedRequestDto from "../dto/common/PresignedRequestDto";
import StoreInstance from "../store/StoreInstance";
import UploadApi from "./UploadApi";
import UploadTypes from "../dto/common/UploadTypes";
import { handleApiError } from "../utils/ApiUtils";

export default class BannerApi {
  public static getAll = async () => {
    try {
      const banners = await StoreInstance.api().get<BannerDto[]>("/banners");
      return banners.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get all banners");
    }
  };

  public static getAllAdminBanners = async () => {
    try {
      const banners = await StoreInstance.api().get<BannerDto[]>(
        "/banners/all"
      );
      return banners.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get all banners at admin");
    }
  };

  public static getBanner = async (bannerId: string) => {
    try {
      const banner = await StoreInstance.api().get<BannerDto>(
        `/banners/${bannerId}`
      );
      return banner.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get Banner");
    }
  };

  public static updateBanner = async (
    bannerId: string,
    data: AdminBannerUpdateRequestDto
  ) => {
    try {
      const banner = await StoreInstance.api().put<BannerDto>(
        `/banners/${bannerId}`,
        data
      );
      return banner.data;
    } catch (e) {
      throw handleApiError(e, "Failed to update banner detail");
    }
  };

  public static addBanner = async (data: AdminBannerUpdateRequestDto) => {
    try {
      const banner = await StoreInstance.api().post<BannerDto>(
        `/banners`,
        data
      );
      return banner.data;
    } catch (e) {
      throw handleApiError(e, "Failed to add banner detail");
    }
  };

  public static hideBanner = async (bannerId: string) => {
    try {
      const banner = await StoreInstance.api().delete<BannerDto>(
        `/banners/${bannerId}`
      );
      return banner.data;
    } catch (e) {
      throw handleApiError(e, "Failed to hide banner");
    }
  };

  public static async uploadBannerMedia(file: File) {
    let dimensions = {
      width: 0,
      height: 0,
    };
    const uploadType = FileUtils.getUploadType(file.type);
    FileUtils.getDimensionsFromFile(file, dimensions, uploadType);
    const presignedRequest = await this.getPresignedUrlForNewMedia(
      file.name,
      uploadType
    );
    const url = await UploadApi.uploadFile(presignedRequest, file);

    return {
      url,
      type: uploadType,
      width: dimensions.width,
      height: dimensions.height,
    };
  }

  private static async getPresignedUrlForNewMedia(
    fileName: string,
    type: UploadTypes
  ) {
    try {
      const products = await StoreInstance.api().get<PresignedRequestDto>(
        `/banners/media/presigned-url?name=${fileName}&type=${type}`
      );
      return products.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get valid url for uploading file");
    }
  }
}
