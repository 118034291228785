import { Ref, forwardRef } from "react";

import BackgroundVideo from "./BackgroundVideo";
import { BoxProps } from "@mui/system";

interface HiddenVideoProps {
  src?: string;
}

function HiddenVideo(
  { src, ...props }: HiddenVideoProps & BoxProps,
  ref?: Ref<unknown>
) {
  if (!src) {
    return null;
  }
  return <BackgroundVideo video={src} {...props} ref={ref} />;
}

export default forwardRef(HiddenVideo);
