import { useCallback, useEffect, useState } from "react";

import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import OrderApi from "@paperdateco/common/api/OrderApi";
import OrderItemDto from "@paperdateco/common/dto/order/details/OrderItemDto";

export default function useGetOrderItemDetails(orderItemId?: string) {
  const [orderItem, setOrderItem] = useState<OrderItemDto>();

  const getOrderItemDetails = useCallback(() => {
    if (!orderItemId) {
      return;
    }
    OrderApi.getOrderItemDetails(orderItemId)
      .then(setOrderItem)
      .catch(NotificationUtils.showGenericError);
  }, [orderItemId]);

  useEffect(() => getOrderItemDetails(), [getOrderItemDetails]);

  return orderItem;
}
