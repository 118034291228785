import AnimationValue from "./AnimationValue";

export default function FadeOutAnimation(
  elem: fabric.Object
): AnimationValue[] {
  return [
    {
      property: "opacity",
      value: {
        from: elem.opacity ?? 1,
        to: 0,
      },
    },
  ];
}
