enum AnimationType {
  SLIDE_HORIZONTAL = "SLIDE_HORIZONTAL",
  SLIDE_VERTICAL = "SLIDE_VERTICAL",
  SLIDE_LEFT = "SLIDE_LEFT",
  SLIDE_RIGHT = "SLIDE_RIGHT",
  DISSOLVE = "DISSOLVE",
  ZOOM_IN = "ZOOM_IN",
  ZOOM_OUT = "ZOOM_OUT",
  POP = "POP",
  FADE_IN = "FADE_IN",
  FADE_OUT = "FADE_OUT",
  WIPE = "WIPE",
  BASELINE = "BASELINE",
  DRIFT = "DRIFT",
  TECTONIC = "TECTONIC",
  TUMBLE = "TUMBLE",
  NEON = "NEON",
  STOMP = "STOMP",
}

export default AnimationType;
