import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import routes from "@paperdateco/customer/routes/routes";

export default function LoginLink() {
  return (
    <Typography variant="body2" marginTop={4}>
      Already registered?
      <Link to={routes.LOGIN}>
        {" "}
        <Typography component="span" color={Colors.BLUE}>
          Please Login
        </Typography>
      </Link>
    </Typography>
  );
}
