import { useCallback, useState } from "react";

import useMounted from "./useMounted";

/**
 *
 * @param fn Function that has to be executed only once at a time
 * @returns [fn, blocked]
 */
export default function useSingleInvoke<A extends any[], B>(
  fn?: (...args: A) => Promise<B>
): [(...args: A) => Promise<B | undefined>, boolean] {
  const mounted = useMounted();

  const [running, setRunning] = useState(false);

  const callback = useCallback(
    async (...args: A) => {
      if (running) {
        console.error("Blocked call as previous call is not completed");
        return;
      }
      setRunning(true);
      try {
        return await fn?.(...args);
      } finally {
        if (mounted.current) {
          setRunning(false);
        }
      }
    },
    [fn, running, mounted]
  );

  return [callback, running];
}
