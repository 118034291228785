import { Box } from "@mui/system";
import { Button } from "@mui/material";
import CategoryDto from "@paperdateco/common/dto/category/CategoryDto";
import { Link } from "react-router-dom";
import SearchField from "../../common/search/SearchField";
import routes from "@paperdateco/customer/routes/routes";
import { selectAllCategoriesWithParent } from "@paperdateco/customer/store/category/CategorySelector";
import { useSelector } from "react-redux";

interface CategorySiblingListProps {
  category: CategoryDto;
}

export default function CategorySiblingList({
  category,
}: CategorySiblingListProps) {
  const parent = category.parent ?? category.id;
  const categories = useSelector(selectAllCategoriesWithParent(parent));

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      margin={2}
    >
      <SearchField category={category.slug} marginBottom={2} />
      {categories.map((category) => (
        <Link to={routes.CATEGORY(category.slug)} key={category.id}>
          <Button key={category.id}>{category.name}</Button>
        </Link>
      ))}
    </Box>
  );
}
