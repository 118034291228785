import { Box } from "@mui/system";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { styled } from "@mui/material";

const PopupContainer = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.LIGHT_YELLOW,
  border: `0.25px solid ${Colors.BLACK}`,
  boxShadow: `4px 4px 0px ${Colors.LIGHT_SHADOW}`,
  borderRadius: theme.spacing(1),
}));

export default PopupContainer;
