import StringUtils, {
  StringDateFormat,
} from "@paperdateco/common/utils/StringUtils";
import { useEffect, useState } from "react";

import BlogItemModel from "./BlogItemModel";
import BlogLayout from "./BlogLayout";
import axios from "axios";
import htmlToText from "@paperdateco/customer/utils/htmlToTextUtils";

export default function BlogSection() {
  const [blogArray, setBlogArray] = useState<BlogItemModel[]>([]);

  useEffect(() => {
    axios
      .get("https://paperdateco.com/blog/wp-json/wp/v2/posts?tags=92")
      .then((response) => {
        const blogs: any = response.data;
        blogs.forEach((blog: any) => {
          const blogItem = {
            image: blog.featured_image_url,
            by: "PAPER DATE CO",
            in: "POPULAR",
            date: StringUtils.formatDate(
              blog.date + "Z",
              StringDateFormat.ONLY_DATE
            ),
            title: blog.title.rendered,
            desc: htmlToText(blog.excerpt.rendered),
            link: blog.link,
          };
          setBlogArray((blogArray) => [...blogArray, blogItem]);
        });
      });
  }, []);

  return <BlogLayout BlogArray={blogArray} />;
}
