import GetItemsReducer from "@paperdateco/shared-frontend/store/getItems/GetItemsReducer";
import { ON_LOGOUT } from "../common/CommonTypes";
import { WISHLIST_GET_ITEMS_NAME } from "./WishListTypes";
import WishListDto from "@paperdateco/common/dto/user/wishList/WishListDto";

const WishlistReducer = GetItemsReducer<WishListDto[]>(
  WISHLIST_GET_ITEMS_NAME,
  [],
  ON_LOGOUT
);

export default WishlistReducer;
