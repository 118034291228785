import { Box, BoxProps } from "@mui/system";

import CanvasElement from "../../objects/CanvasElement";
import InviteElementLocationControl from "./InviteElementLocationControl";
import InviteInstantPreview from "@paperdateco/shared-frontend/components/invite/InviteInstantPreview";
import InviteLayerOrderControl from "./InviteLayerOrderControl";
import { Typography } from "@mui/material";

interface InviteElementLocationControlProps<T extends fabric.Object> {
  instantPreview?: InviteInstantPreview;
  elements: CanvasElement<T>[];
  canvasWidth: number;
  canvasHeight: number;
}

export default function InviteAlignmentSection<T extends fabric.Object>({
  instantPreview,
  elements,
  canvasWidth,
  canvasHeight,
  ...props
}: InviteElementLocationControlProps<T> & BoxProps) {
  if (elements.length === 0 || !instantPreview) {
    return null;
  }
  return (
    <Box
      minWidth={270}
      padding={3}
      height="100%"
      display="flex"
      flexDirection="column"
      overflow="auto"
      {...props}
    >
      <Box marginY="auto">
        <InviteLayerOrderControl
          instantPreview={instantPreview}
          elements={elements}
        />
        <Typography fontWeight="bold" variant="body3">
          ALIGN TO PAGE
        </Typography>
        <InviteElementLocationControl
          instantPreview={instantPreview}
          elements={elements}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
        />
      </Box>
    </Box>
  );
}
