import HomePopupLayout from "./HomePopupLayout";
import { Link } from "react-router-dom";
import routes from "@paperdateco/customer/routes/routes";
import useOneTimePopup from "@paperdateco/shared-frontend/components/common/hooks/useOneTimePopup";

interface WelcomePopupProps {
  onClose?: () => void;
}

function WelcomePopup({ onClose }: WelcomePopupProps) {
  return (
    <Link to={routes.REGISTER} onClick={onClose}>
      <HomePopupLayout
        image="https://asset.paperdateco.com/banner/media/618a948c7065bf4d93a5292b/cd3a068c-179f-4b5f-96ba-be73487ad0cf-pop-up-design.svg"
        alt="Welcome Popup"
      >
        {/* <Stack
        spacing={2}
        justifyContent="center"
        marginBottom={2}
        fontFamily={Fonts.Carmorant}
        alignItems="center"
      >
        <SignupButton variant="contained" size="large">
          <Link to={routes.REGISTER} onClick={onClose}>
            <Typography variant="body1" fontWeight="600">
              SIGN UP
            </Typography>
          </Link>
        </SignupButton>
        <LaterButton variant="contained" onClick={onClose}>
          <Typography variant="body3" fontWeight="600">
            I&rsquo;LL DO THIS LATER
          </Typography>
        </LaterButton>
      </Stack> */}
      </HomePopupLayout>
    </Link>
  );
}

export default function HomeWelcomePopupModal() {
  useOneTimePopup("WelcomePopup", WelcomePopup);
  return null;
}
