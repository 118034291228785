import { Button, Stack, Typography, styled } from "@mui/material";

import { Box } from "@mui/system";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";

const InfoContainer = styled(Box)(({ theme }) => ({
  background: Colors.DARK_BLUE_BACKGROUND,
  color: Colors.WHITE,
  padding: theme.spacing(2),
  boxShadow: `3px 3px 1px ${Colors.LIGHT_SHADOW}`,
  borderRadius: 4,
}));

const DescriptionContainer = styled(Box)(({ theme }) => ({
  borderLeft: `1px solid ${Colors.WHITE}`,
  paddingLeft: theme.spacing(1),
}));

interface DesignPremiumElementPopupProps {
  image: LibraryImageDto;
  aspectRatio?: number;
  onAdd?: () => void;
  onCancel?: () => void;
}

export default function DesignPremiumElementPopup({
  image,
  aspectRatio = 1,
  onAdd,
  onCancel,
}: DesignPremiumElementPopupProps) {
  return (
    <Box fontFamily={Fonts.Roboto}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <HiddenImage src={image.url} width={365} height={365 / aspectRatio} />
        <InfoContainer maxWidth={350}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              flex={2.5}
            >
              <Typography variant="body1" fontWeight="bold">
                THIS IS A PREMIUM ELEMENT!
              </Typography>
              <Typography variant="body3" component="p" marginY={2}>
                Cost Per Design - INR{" "}
                <Typography component="span" fontWeight="bold">
                  {image.price}
                </Typography>
              </Typography>
              <Stack direction="row" spacing={2}>
                <Button variant="contained" size="small" onClick={onAdd}>
                  ADD TO DESIGN
                </Button>
                <Button
                  variant="text"
                  size="small"
                  color="secondary"
                  onClick={onCancel}
                >
                  remove
                </Button>
              </Stack>
            </Box>
            <DescriptionContainer flex={1}>
              <Typography variant="subtitle2" component="p">
                This is a premium element and can be used multiple times in the
                same design.
                <br />
                <br />
                To use it in a new design, you will have to buy it again.
              </Typography>
            </DescriptionContainer>
          </Stack>
        </InfoContainer>
      </Stack>
    </Box>
  );
}
