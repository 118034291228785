import CustomRadioGroup, {
  CustomRadioOptionType,
} from "@paperdateco/shared-frontend/components/common/form/CustomRadioGroup";
import { FormEvent, useEffect, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";

import { Box } from "@mui/system";
import CustomCheckbox from "@paperdateco/shared-frontend/components/common/form/CustomCheckbox";
import CustomDateTimePicker from "@paperdateco/shared-frontend/components/common/form/date/CustomDateTimePicker";
import CustomShadowButton from "../common/CustomShadowButton";
import DefaultEvent from "../common/DefaultEvent";
import DoneIcon from "@mui/icons-material/Done";
import EventDetailImage from "./EventDetailImage";
import EventDetailTabContent from "./common/EventDetailTabContent";
import EventDetailsContent from "./EventDetailsContent";
import EventDto from "@paperdateco/common/dto/order/event/EventDto";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import OrderItemDto from "@paperdateco/common/dto/order/details/OrderItemDto";
import ReminderModel from "@paperdateco/common/dto/order/event/reminder/ReminderModel";
import ReminderType from "@paperdateco/common/dto/order/event/reminder/ReminderType";

const ReminderTypeOptions: CustomRadioOptionType<ReminderType>[] = [
  { name: "DAILY", value: ReminderType.DAILY },
  { name: "WEEKLY", value: ReminderType.WEEKLY },
  { name: "CUSTOM", value: ReminderType.CUSTOM },
  { name: "NONE", value: ReminderType.NONE },
];

interface AccountEventRemindersProps {
  orderItem: OrderItemDto;
  onUpdateEvent?: (event: EventDto) => void;
}

export default function AccountEventReminders({
  orderItem,
  onUpdateEvent,
}: AccountEventRemindersProps) {
  const event = orderItem.event ?? DefaultEvent;
  const [reminderType, setReminderType] = useState(ReminderType.NONE);
  const [date, setDate] = useState<Date>();
  const [repeat, setRepeat] = useState<boolean>();

  useEffect(() => {
    setReminderType(event.reminder?.type ?? ReminderType.NONE);
    setDate(event.reminder?.date);
    setRepeat(event.reminder?.repeat);
  }, [event]);

  const onSet = (e: FormEvent) => {
    e.preventDefault();
    const reminder: ReminderModel | undefined =
      reminderType !== ReminderType.NONE
        ? {
            type: reminderType,
            date: date ?? new Date(),
            repeat,
          }
        : undefined;
    onUpdateEvent?.({ ...event, reminder });
  };

  return (
    <EventDetailTabContent>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <EventDetailImage orderItem={orderItem} />
        </Grid>
        <Grid item xs={12} md={8} textAlign="left">
          <EventDetailsContent event={event} />
          <Typography variant="body1" fontWeight={600}>
            SET A REMINDER
          </Typography>

          <form onSubmit={onSet}>
            <Stack direction="column" spacing={1.5}>
              <CustomRadioGroup
                row
                options={ReminderTypeOptions}
                value={reminderType}
                onChange={setReminderType}
              />

              {reminderType !== ReminderType.NONE && (
                <CustomDateTimePicker
                  label="Reminder Date"
                  inputFormat="dd/MM/yyyy h:mm a"
                  value={date}
                  onChange={setDate}
                  PopperProps={{ style: { fontFamily: Fonts.Roboto } }}
                />
              )}
              {reminderType === ReminderType.CUSTOM && (
                <CustomCheckbox
                  label="Repeating"
                  checked={repeat ?? false}
                  onChange={setRepeat}
                />
              )}
              <Box textAlign="center">
                <CustomShadowButton endIcon={<DoneIcon />} type="submit">
                  SET
                </CustomShadowButton>
              </Box>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </EventDetailTabContent>
  );
}
