import { Box, Typography } from "@mui/material";

import AnimatedImageSection from "../AnimatedImageSection";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import Transition from "@paperdateco/shared-frontend/utils/styles/Transition";

const CategoryImageText = CustomStyled(Typography)(({ theme }) => ({
  margin: theme.spacing(1, 2),
  textAlign: "center",
  opacity: 0,
  transition: Transition.Default,
  fontFamily: Fonts.Marcellus,
  letterSpacing: 0.5,
}));

interface CategoryCardProps {
  image: string;
  imageText: string;
}

export default function CategoryCard({ image, imageText }: CategoryCardProps) {
  return (
    <AnimatedImageSection src={image} name={imageText}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="absolute"
        width="100%"
        height="100%"
        left={0}
        top={0}
      >
        <CategoryImageText className="overlay-text" variant="h5">
          <span>{imageText}</span>
        </CategoryImageText>
      </Box>
    </AnimatedImageSection>
  );
}
