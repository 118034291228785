import { BoxProps } from "@mui/system";
import Footer from "./Footer/Footer";
import HomepageFooterMenu from "./Footer/homepage/HomepageFooterMenu";
import { ReactNode } from "react";
import SimpleLayout from "./SimpleLayout";

interface SimpleLayoutWithFooterProps {
  title: ReactNode;
  headerNav?: ReactNode;
}

export default function SimpleLayoutWithFooter({
  title,
  headerNav,
  children,
  ...props
}: SimpleLayoutWithFooterProps & Omit<BoxProps, "title">) {
  return (
    <SimpleLayout title={title} headerNav={headerNav} {...props}>
      <>
        {children} <HomepageFooterMenu /><Footer />
      </>
    </SimpleLayout>
  );
}
