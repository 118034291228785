import CanvasObjectPosition from "../CanvasObjectPosition";
import CanvasUtils from "../CanvasUtils";
import DesignConstants from "@paperdateco/common/utils/constants/DesignConstants";
import { fabric } from "fabric";
// ==========================================
// CANVAS CENTER SNAPPING & ALIGNMENT GUIDELINES
// ==========================================

// ORIGINAL:
// https://github.com/fabricjs/fabric.js/blob/master/lib/centering_guidelines.js

// Changes done
// 1. Convert to typescript
// 2. Refactored the code
// 3. Bug fixes
// 4. Design changes to the guidelines

interface HorizontalLine {
  x1: number;
  x2: number;
  y: number;
}

interface VerticalLine {
  x: number;
  y1: number;
  y2: number;
}

export default class Guidelines {
  private aligningLineOffset = 5;
  private aligningLineMargin = 5;
  private aligningLineWidth = 1;
  private aligningLineColor = "rgb(0,0,0)";
  private verticalLines: VerticalLine[] = [];
  private horizontalLines: HorizontalLine[] = [];

  constructor(private canvas: fabric.Canvas) {
    this.setupListeners();
  }

  private setupListeners() {
    this.canvas.on("object:moving", this.handleObjectMoving);
    this.canvas.on("before:render", this.clearContext);
    this.canvas.on("after:render", this.renderLines);
    this.canvas.on("mouse:up", this.clearLines);
  }

  private handleObjectMoving = (e: fabric.IEvent<Event>) => {
    const activeObject = e.target;
    const viewportTransform = this.canvas.viewportTransform;
    if (!activeObject || !viewportTransform) {
      return;
    }
    this.restrictToWithinCanvas(activeObject, viewportTransform);
    this.computeLines(activeObject, viewportTransform);
  };

  private restrictToWithinCanvas = (
    object: fabric.Object,
    viewportTransform: number[]
  ) => {
    if (!(object instanceof fabric.Text)) {
      return;
    }
    const position = CanvasUtils.computeObjectPosition(
      object,
      viewportTransform
    );
    const canvasWidth = this.canvas.width ?? DesignConstants.DEFAULT_WIDTH;
    const canvasHeight = this.canvas.height ?? DesignConstants.DEFAULT_HEIGHT;

    let snappedCenterX = position.centerX;
    let snappedCenterY = position.centerY;

    if (position.centerX - position.width / 2 < 0) {
      snappedCenterX = position.width / 2;
    }
    if (position.centerX + position.width / 2 > canvasWidth) {
      snappedCenterX = canvasWidth - position.width / 2;
    }
    if (position.centerY - position.height / 2 < 0) {
      snappedCenterY = position.height / 2;
    }
    if (position.centerY + position.height / 2 > canvasHeight) {
      snappedCenterY = canvasHeight - position.height / 2;
    }

    object.setPositionByOrigin(
      new fabric.Point(snappedCenterX, snappedCenterY),
      "center",
      "center"
    );
  };

  private drawLine(x1: number, y1: number, x2: number, y2: number) {
    const ctx = this.canvas.getSelectionContext();
    const viewportTransform = this.canvas.viewportTransform;
    const zoom = this.canvas.getZoom();
    if (!viewportTransform) {
      return;
    }
    ctx.save();
    ctx.lineWidth = this.aligningLineWidth;
    ctx.strokeStyle = this.aligningLineColor;
    ctx.beginPath();
    ctx.moveTo(
      (x1 + viewportTransform[4]) * zoom,
      (y1 + viewportTransform[5]) * zoom
    );
    ctx.lineTo(
      (x2 + viewportTransform[4]) * zoom,
      (y2 + viewportTransform[5]) * zoom
    );
    ctx.stroke();
    ctx.restore();
  }

  private drawVerticalLine(coords: VerticalLine) {
    this.drawLine(
      coords.x + 0.5,
      coords.y1 > coords.y2 ? coords.y2 : coords.y1,
      coords.x + 0.5,
      coords.y2 > coords.y1 ? coords.y2 : coords.y1
    );
  }

  private drawHorizontalLine(coords: HorizontalLine) {
    this.drawLine(
      coords.x1 > coords.x2 ? coords.x2 : coords.x1,
      coords.y + 0.5,
      coords.x2 > coords.x1 ? coords.x2 : coords.x1,
      coords.y + 0.5
    );
  }

  private isInRange(value1: number, value2: number) {
    value1 = Math.round(value1);
    value2 = Math.round(value2);
    return Math.abs(value1 - value2) <= this.aligningLineMargin;
  }

  private clearContext = () => {
    this.canvas.clearContext((this.canvas as any).contextTop);
  };

  private renderLines = () => {
    for (let i = this.verticalLines.length; i--; ) {
      this.drawVerticalLine(this.verticalLines[i]);
    }
    for (let i = this.horizontalLines.length; i--; ) {
      this.drawHorizontalLine(this.horizontalLines[i]);
    }
  };

  private clearLines = () => {
    this.verticalLines.length = this.horizontalLines.length = 0;
    this.canvas.renderAll();
  };

  private createVerticalLine(
    x: number,
    o1: CanvasObjectPosition,
    o2: CanvasObjectPosition
  ) {
    const start =
      Math.min(o1.centerY - o1.height / 2, o2.centerY - o2.height / 2) -
      this.aligningLineOffset;
    const end =
      Math.max(o1.centerY + o1.height / 2, o2.centerY + o2.height / 2) +
      this.aligningLineOffset;
    return {
      x,
      y1: start,
      y2: end,
    };
  }

  private createHorizontalLine(
    y: number,
    o1: CanvasObjectPosition,
    o2: CanvasObjectPosition
  ) {
    const start = Math.min(
      o1.centerX - o1.width / 2,
      o2.centerX - o2.width / 2
    );
    const end = Math.max(o1.centerX + o1.width / 2, o2.centerX + o2.width / 2);
    return {
      y,
      x1: start,
      x2: end,
    };
  }

  private computeVerticalLine = (
    object: CanvasObjectPosition,
    activeObject: CanvasObjectPosition
  ) => {
    const horizontalPoi = [
      activeObject.centerX,
      activeObject.centerX - activeObject.width / 2,
      activeObject.centerX + activeObject.width / 2,
    ];

    const objectHorizontalPoi = [
      object.centerX,
      object.centerX - object.width / 2,
      object.centerX + object.width / 2,
    ];

    for (let j = 0; j < horizontalPoi.length; j++) {
      for (let k = 0; k < objectHorizontalPoi.length; k++) {
        if (this.isInRange(horizontalPoi[j], objectHorizontalPoi[k])) {
          const line = this.createVerticalLine(
            objectHorizontalPoi[k],
            object,
            activeObject
          );
          this.verticalLines.push(line);
          return objectHorizontalPoi[k] - horizontalPoi[j];
        }
      }
    }
  };

  private computeHorizontalLine = (
    object: CanvasObjectPosition,
    activeObject: CanvasObjectPosition
  ) => {
    const verticalPoi = [
      activeObject.centerY,
      activeObject.centerY - activeObject.height / 2,
      activeObject.centerY + activeObject.height / 2,
    ];

    const objectVerticalPoi = [
      object.centerY,
      object.centerY - object.height / 2,
      object.centerY + object.height / 2,
    ];

    for (let j = 0; j < verticalPoi.length; j++) {
      for (let k = 0; k < objectVerticalPoi.length; k++) {
        if (this.isInRange(verticalPoi[j], objectVerticalPoi[k])) {
          const line = this.createHorizontalLine(
            objectVerticalPoi[k],
            object,
            activeObject
          );
          this.horizontalLines.push(line);
          return objectVerticalPoi[k] - verticalPoi[j];
        }
      }
    }
  };

  private computeLines = (
    activeObject: fabric.Object,
    viewportTransform: number[]
  ) => {
    this.horizontalLines.length = this.verticalLines.length = 0;

    const activeObjectPosition = CanvasUtils.computeObjectPosition(
      activeObject,
      viewportTransform
    );

    const canvasObjects = this.canvas.getObjects();
    const horizontalGaps: number[] = [];
    const verticalGaps: number[] = [];

    for (let i = canvasObjects.length; i--; ) {
      if (canvasObjects[i] === activeObject) continue;

      const objectPosition = CanvasUtils.computeObjectPosition(
        canvasObjects[i],
        viewportTransform
      );
      const verticalGap = this.computeVerticalLine(
        objectPosition,
        activeObjectPosition
      );
      if (verticalGap) {
        verticalGaps.push(verticalGap);
      }

      const horizontalGap = this.computeHorizontalLine(
        objectPosition,
        activeObjectPosition
      );
      if (horizontalGap) {
        horizontalGaps.push(horizontalGap);
      }
    }

    activeObject.setPositionByOrigin(
      new fabric.Point(
        activeObjectPosition.centerX + this.findMin(verticalGaps),
        activeObjectPosition.centerY + this.findMin(horizontalGaps)
      ),
      "center",
      "center"
    );
  };

  private findMin = (array: number[]) => {
    return array.length > 0 ? Math.min(...array) : 0;
  };
}
