import routes from "../routes/routes";

export default class ShareMessageUtils {
  private static WHATSAPP_TEXT =
    "I have a little something for you, beautifully curated on PaperDateCo. Go ahead and open it up! ";

  public static whatsppMessage = (orderItemId: string, phoneNumber?: string) =>
    `https://wa.me/${
      phoneNumber
        ? (phoneNumber.startsWith("+") ? "" : "+91") + phoneNumber
        : ""
    }?text=${ShareMessageUtils.encodeUrlString(
      ShareMessageUtils.WHATSAPP_TEXT
    )}${process.env.REACT_APP_CUSTOMER_URL}${routes.INVITE_SHARE_LINK(
      orderItemId
    )}`;

  public static encodeUrlString = (message: string) => {
    const response = encodeURIComponent(message).replace(" ", "%20");
    return response;
  };
}
