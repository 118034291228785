import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons";

import { Box } from "@mui/system";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

const IconContainer = CustomStyled(Box)(({ theme }) => ({
  "& > *": {
    padding: theme.spacing(1),
  },
}));

export default function ProductShareIcons() {
  const currentUrl = window.location.href;

  return (
    <IconContainer>
      <a
        target="_blank"
        href={`https://www.facebook.com/sharer/sharer.php?u=#${currentUrl}`}
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faFacebookF} />
      </a>
      <a
        target="_blank"
        href={`https://twitter.com/intent/tweet?url=${currentUrl}`}
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faTwitter} />
      </a>
      <a
        target="_blank"
        href={`mailto:?subject=I%20wanted%20you%20to%20see%20this%20amazing%20product&amp;body=Check%20out%20this%20product%20on%20${currentUrl}.`}
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </a>
    </IconContainer>
  );
}
