export default class KeyUtils {
  public static isEscape(e: KeyboardEvent) {
    return e.code === "Escape";
  }

  public static isDelete(e: KeyboardEvent) {
    return e.code === "Delete" || e.code === "Backspace";
  }

  public static isLeft(e: KeyboardEvent) {
    return e.code === "ArrowLeft" || e.code === "KeyA";
  }

  public static isRight(e: KeyboardEvent) {
    return e.code === "ArrowRight" || e.code === "KeyD";
  }

  public static isUp(e: KeyboardEvent) {
    return e.code === "ArrowUp" || e.code === "KeyW";
  }

  public static isDown(e: KeyboardEvent) {
    return e.code === "ArrowDown" || e.code === "KeyS";
  }

  public static isSelectAll(e: KeyboardEvent) {
    return e.code === "KeyA" && (e.metaKey || e.ctrlKey);
  }
}
