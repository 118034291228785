import { Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { Box } from "@mui/system";
import CollaboratorApi from "@paperdateco/common/api/product/CollaboratorApi";
import CollaboratorDetailedDto from "@paperdateco/common/dto/product/collaborator/CollaboratorDetailedDto";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import AspectRatio from "@paperdateco/shared-frontend/utils/styles/AspectRatio";
import ProductList from "../detail/ProductList";

interface CollaboratorDetailProps {
  collaboratorId: string;
}

export default function CollaboratorDetail({
  collaboratorId,
}: CollaboratorDetailProps) {
  const [collaborator, setCollaborator] = useState<CollaboratorDetailedDto>();
  const [print, setprint] = useState(false);

  useEffect(() => {
    CollaboratorApi.getCollaboratorInfo(collaboratorId)
      .then(setCollaborator)
      .catch(NotificationUtils.showGenericError);
    if (collaboratorId === "62380fb904e373acf2dc8538") {
      setprint(true);
    }
  }, [collaboratorId]);

  if (!collaborator) {
    return null;
  }

  return (
    <Box>
      {print ? (
        <>
          <HiddenImage
            src={collaborator.bannerImage}
            width="100%"
            display="block"
            aspectRatio={1337 / 600}
          />
          <Grid container justifyContent="space-evenly" marginTop={4}>
            <Grid item xs={12} md={5} display="flex" padding={2}>
              <Box width="100%" border="3px solid #a67430" padding={3}>
                <HiddenImage
                  src={
                    "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/selected%20pictures/jihb63iijbwtlztbkmzz.webp"
                  }
                  width="100%"
                  height="100%"
                  aspectRatio={1}
                  display="block"
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign={"justify"}
              padding={2}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Typography variant="h6">
                  In a truly exciting couture and stationery collaboration,
                  <Typography component={"span"} variant="h6" fontWeight="bold">
                    {" "}
                    Jayanti Reddy
                  </Typography>{" "}
                  has merged her design sensibilities with Paper Date Co.’s
                  artistic vision to curate a line of limited edition wedding
                  cards and collectible stationery for that special day.
                </Typography>
                <Typography variant="h6">
                  The range of products are inspired by Art Deco architecture,
                  dramatic arches, and ornate blooms, key Jayanti Reddy
                  insignias that add to the grandeur of the designs. Her
                  signature botanicals and rich colour palette adorn beautifully
                  textured paper wrapped up in buttery envelopes that are
                  timelessly elegant.
                </Typography>
                <Typography variant="h6">
                  The luxe collection of digital invites can also be printed out
                  for a more traditional wedding card moment. Furthermore, they
                  can also be customized in terms of colour, font, and envelopes
                  giving them a look that is personal and exclusive!
                </Typography>
                <Box width={"20%"} paddingTop={2}>
                  <HiddenImage
                    src={
                      "https://asset.paperdateco.com/cloudinary/Jayanthi%20Reddy%20/fyeap2m6zr3dilrqmwpq.png"
                    }
                    width="100%"
                    aspectRatio={100 / 26}
                    display="block"
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </>
      ) : (
        <HiddenImage
          src={collaborator.bannerImage}
          width="100%"
          aspectRatio={AspectRatio.BANNER}
          display="block"
        />
      )}
      <Box margin={4} padding={4}>
        <ProductList
          products={collaborator.products}
          itemProps={{ xs: 12, sm: 4, md: 3, lg: 2 }}
        />
      </Box>
    </Box>
  );
}
