import { Box, Button, Grid, Typography } from "@mui/material";

import { BoxProps } from "@mui/system";
import routes from "@paperdateco/customer/routes/routes";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import { Link } from "react-router-dom";
import CollaborateForm from "./form/CollaborateForm";

const StylisedText = CustomStyled(Typography)({
  fontFamily: Fonts.Carmorant,
});

const CollaborateFormContainer = CustomStyled(Box)({
  border: `1px solid ${Colors.BLACK}`,
});

const CollaborateFormHeader = CustomStyled(Box)({
  background: Colors.ORANGE,
});

const CollaborateImage =
  "https://asset.paperdateco.com/cloudinary/Home%20Page%20Banners-20220110T114935Z-001/Home%20Page%20Banners/2.%20Collaborate%20with%20us%20Banner/wd0inac8bgnwicro81nj.webp";

export default function CollaborateSection(props: BoxProps) {
  return (
    <Box padding={2} {...props}>
      <Grid container>
        <Grid item xs={12} md={7} display="flex" paddingRight={1}>
          <HiddenImage
            src={CollaborateImage}
            width="100%"
            height="100%"
            display="block"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          display="flex"
          flexDirection="column"
          alignItems="center"
          padding={2}
        >
          <StylisedText
            variant="h5"
            fontWeight="300"
            maxWidth={400}
            textAlign="center"
          >
            WANT TO JOIN OUR DESIGN COMMUNITY OF INDEPENDENT <br />
            <Typography variant="inherit" component="span" fontWeight="600">
              ARTIST &amp; CREATORS ?
            </Typography>
          </StylisedText>

          <Box
            margin={1}
            marginX={{ xs: 0, md: 3 }}
            textAlign="center"
            maxWidth={600}
          >
            <CollaborateFormContainer marginBottom={1} position="relative">
              <CollaborateFormHeader
                display="flex"
                justifyContent="center"
                alignItems="center"
                paddingX={5}
                paddingY={1}
              >
                <Typography variant="body1" component="h5">
                  GET IN TOUCH WITH US
                </Typography>
              </CollaborateFormHeader>
              <CollaborateForm />
            </CollaborateFormContainer>
            <Link to={routes.COLLABORATORS}>
              <Button variant="contained" size="medium">
                LEARN MORE
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
