import { CART_GET_ITEMS_NAME } from "./CartTypes";
import CartApi from "@paperdateco/common/api/CartApi";
import CartContentsDto from "@paperdateco/common/dto/order/cart/CartContentsDto";
import GetItemsAction from "@paperdateco/shared-frontend/store/getItems/GetItemsAction";
import GetItemsActionTypes from "@paperdateco/shared-frontend/store/getItems/GetItemsTypes";
import { RootState } from "..";
import { ThunkDispatch } from "redux-thunk";

export const getAllCartItems = GetItemsAction(
  CART_GET_ITEMS_NAME,
  CartApi.getCartItems,
  (state: RootState) => state.cart
);

export type CartStateDispatchType = ThunkDispatch<
  RootState,
  unknown,
  GetItemsActionTypes<CartContentsDto>
>;
