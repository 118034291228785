import CanvasUtils from "@paperdateco/shared-frontend/canvas/CanvasUtils";
import CustomDesignDto from "@paperdateco/common/dto/design/custom/CustomDesignDto";
import InvitePreview from "./InvitePreview";
import { useCallback } from "react";
import { useFabric } from "@paperdateco/shared-frontend/canvas/useFabric";

interface InvitePreviewCanvasProps {
  width: number;
  height: number;
  customDesign: CustomDesignDto;
  shouldRecord?: boolean;
  onRecordComplete?: (url: string) => void;
}

export default function InvitePreviewCanvas({
  width,
  height,
  customDesign,
  shouldRecord = false,
  onRecordComplete,
}: InvitePreviewCanvasProps) {
  const recordCanvas = useCallback(
    async (canvas, animate) => {
      let mediaRecorder: MediaRecorder | undefined;
      if (shouldRecord) {
        mediaRecorder = await CanvasUtils.record(canvas, {
          onComplete: (url) => onRecordComplete?.(url),
        });
      }

      await animate(shouldRecord);

      if (shouldRecord && mediaRecorder) {
        // Add 5 minutes at the end of the recording
        await new Promise((res) => setTimeout(res, 5000));
        mediaRecorder.stop();
      }
    },
    [onRecordComplete, shouldRecord]
  );

  const handleCanvas = useCallback(
    async (fabricCanvas, canvas) => {
      recordCanvas(canvas, async (isRecording: boolean) => {
        const invite = new InvitePreview(fabricCanvas, width, height);
        await invite.addBackgroundImage();
        await invite.addCoverEnvelope(
          customDesign.components.coverEnvelope?.url
        );
        await invite.addInvitation(customDesign);
        invite.forceRerenderText();
        await invite.animate(isRecording);
      });
    },
    [width, height, customDesign, recordCanvas]
  );

  const ref = useFabric(width, height, handleCanvas);

  if (!(width && height)) {
    return null;
  }

  return <canvas ref={ref} width={width} height={height} />;
}
