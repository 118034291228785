import { Box, Grid, Typography } from "@mui/material";

import AppLogo from "@paperdateco/shared-frontend/assets/images/logo/small_logo.png";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import FooterCardSection from "./FooterCardSection";
import FooterSocial from "./FooterSocial";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import { PropsWithChildren } from "react";
import { TypographyProps } from "@mui/system";

const DescriptionBox = CustomStyled(Box)({
  background: Colors.PRIMARY_A100,
});

const AboutText = (props: PropsWithChildren<TypographyProps>) => (
  <Typography
    variant="body3"
    lineHeight={1}
    fontWeight="bold"
    fontStyle="italic"
    component="p"
    margin={2}
    {...props}
  >
    {props.children}
  </Typography>
);

export default function FooterCompanyDetails() {
  return (
    <Grid marginY={3} container spacing={2}>
      <Grid item xs={12} md={2}>
        <Box width="100%" margin="auto">
          <HiddenImage width={150} src={AppLogo} aspectRatio={1.5} />
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <DescriptionBox paddingY={1}>
          <AboutText>
            In a time when the world has gone virtual, Paper Date Co. presents a
            range of online invitations and greetings with the same old-world
            charm and nostalgia you experience from a hand written invite,
            postmarked with love{" "}
          </AboutText>
          <AboutText>
            Customise, create and plan for a special event with invitations and
            greetings from a design marketplace of independent artists and
            graphic designers.
          </AboutText>{" "}
          <AboutText>
            There's something special about receiving a letter with your name on
            it. Every Paper Date Co. card you send captures that emotion.
          </AboutText>
        </DescriptionBox>
        <FooterSocial />
      </Grid>
      <Grid item xs={12} md={6} position="relative">
        <FooterCardSection />
      </Grid>
    </Grid>
  );
}
