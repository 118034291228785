import { Box, BoxProps } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import CategoryDto from "@paperdateco/common/dto/category/CategoryDto";
import InviteProductListItem from "./InviteProductListItem";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import ProductApi from "@paperdateco/common/api/product/ProductApi";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";

interface InviteProductListRowProps {
  category: CategoryDto;
}

export default function InviteProductListRow({
  category,
  ...props
}: InviteProductListRowProps & BoxProps) {
  const [products, setProducts] = useState<ProductDto[]>([]);

  useEffect(() => {
    ProductApi.getByCategory(category.slug)
      .then((data) => setProducts(data))
      .catch((e) => NotificationUtils.showGenericError(e));
  }, [category]);

  if (products.length === 0) {
    return null;
  }

  return (
    <Box {...props}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body3">{category.name.toUpperCase()}</Typography>
      </Stack>
      <Box width="100%" overflow="auto">
        <Stack direction="row">
          {products.map((product) => (
            <InviteProductListItem
              key={product.id}
              product={product}
              width={150}
              marginX={2}
              marginY={1}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
}
