import { Button, styled } from "@mui/material";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";

const WhiteButton = styled(Button)({
  backgroundColor: Colors.WHITE,
  color: Colors.BLACK,
  "&:hover": {
    color: Colors.WHITE,
  },
});

export default WhiteButton;
