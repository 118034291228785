import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";

export const GIPHY_SECTION = "GiphySection";

export const LibrarySections = [
  LibraryImageType.BACKGROUND,
  LibraryImageType.STICKER,
  LibraryImageType.GIF,
  LibraryImageType.MASK,
  LibraryImageType.PHOTO,
] as const;

export type SectionLabelType = typeof LibrarySections[number];

export const LibrarySectionLabels: { [key in SectionLabelType]: string } = {
  [LibraryImageType.BACKGROUND]: "Background",
  [LibraryImageType.STICKER]: "Stickers",
  [LibraryImageType.GIF]: "Gif",
  [LibraryImageType.MASK]: "Masks",
  [LibraryImageType.PHOTO]: "Photos",
};
