import { GiphyFetch, MediaType } from "@giphy/js-fetch-api";

import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";

export default class GiphyImagePlugin {
  public static webKey = process.env.REACT_APP_GIPHY_KEY;
  private static giphyFetch = GiphyImagePlugin.webKey
    ? new GiphyFetch(GiphyImagePlugin.webKey)
    : undefined;

  public static fetch(query: string, queryType?: LibraryImageType, limit = 25) {
    switch (queryType) {
      case LibraryImageType.STICKER:
        return GiphyImagePlugin.fetchByType(query, "stickers", limit);
      case LibraryImageType.GIF:
        return GiphyImagePlugin.fetchByType(query, "gifs", limit);
      default:
        return GiphyImagePlugin.fetchByType(query, undefined, limit);
    }
  }

  static fetchByType(query: string, type?: MediaType, limit = 25) {
    const fetchApi = GiphyImagePlugin.giphyFetch;
    if (!fetchApi) {
      return undefined;
    }
    if (query === "") {
      return (offset: number) =>
        fetchApi.trending({
          type,
          offset,
          limit,
        });
    } else {
      return (offset: number) =>
        fetchApi.search(query, {
          type,
          offset,
          limit,
        });
    }
  }
}
