import { AuthContext } from "@paperdateco/shared-frontend/components/auth/AuthProvider";
import CustomDesignAddRequestDto from "@paperdateco/common/dto/design/custom/CustomDesignAddRequestDto";
import CustomDesignApi from "@paperdateco/common/api/CustomDesignApi";
import LoginNeededPopup from "../../popup/login/LoginNeededPopup";
import { ModalContext } from "@paperdateco/shared-frontend/components/providers/ModalProvider";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import ProductApi from "@paperdateco/common/api/product/ProductApi";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import routes from "@paperdateco/customer/routes/routes";
import { setInviteCustomiseSection } from "@paperdateco/customer/store/inviteCustomise/InviteCustomiseAction";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

export default function useCustomiseTemplate(product: ProductDto) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { open, close } = useContext(ModalContext);
  const { user } = useContext(AuthContext);

  const onCustomiseTemplate = () => {
    if (!user) {
      open(
        <LoginNeededPopup
          message="PICK LOGIN TO CUSTOMISE YOUR TEMPLATE"
          onClose={close}
        />
      );
      return;
    }
    ProductApi.findOneBySlug(product.slug)
      .then((productWithDesign) => {
        const request: CustomDesignAddRequestDto = {
          design: productWithDesign.design.id,
          product: productWithDesign.id,
        };

        CustomDesignApi.createNewCustomDesign(request)
          .then((customDesign) => {
            dispatch(setInviteCustomiseSection());
            navigate(routes.CUSTOM_DESIGN_EDIT(customDesign.id));
          })
          .catch(NotificationUtils.showGenericError);
      })
      .catch(NotificationUtils.showGenericError);
  };

  return onCustomiseTemplate;
}
