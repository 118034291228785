import { ChangeEvent, useRef } from "react";

import { Box } from "@mui/system";
import ColorizeIcon from "@mui/icons-material/Colorize";
import { IconButton } from "@mui/material";

interface EyeDropperIconProps {
  onClick: () => void;
}

function EyeDropperIcon({ onClick }: EyeDropperIconProps) {
  return (
    <IconButton onClick={onClick}>
      <ColorizeIcon />
    </IconButton>
  );
}

interface ColorPickerProps {
  onChangeColor: (hex: string) => void;
}

export default function ColorPicker({ onChangeColor }: ColorPickerProps) {
  const hasNativeSupport = (window as any).EyeDropper !== undefined;
  const ref = useRef<HTMLInputElement>(null);

  const openNativePicker = () => {
    if (!hasNativeSupport) {
      return;
    }
    let eyeDropper = new (window as any).EyeDropper();

    eyeDropper
      .open()
      .then((colorSelectionResult: any) => {
        onChangeColor(colorSelectionResult.sRGBHex);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const clickBrowserInput = () => {
    ref.current?.focus();
    ref.current?.click();
  };

  const onBrowserInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeColor(e.target.value);
  };

  if (hasNativeSupport) {
    return <EyeDropperIcon onClick={openNativePicker} />;
  }

  return (
    <Box position="relative">
      <input
        ref={ref}
        type="color"
        onChange={onBrowserInputChange}
        style={{ opacity: 0, position: "absolute", left: 0, top: 0 }}
      />
      <EyeDropperIcon onClick={clickBrowserInput} />
    </Box>
  );
}
