import PresignedRequestDto from "../dto/common/PresignedRequestDto";
import axios from "axios";

export default class UploadApi {
  public static async uploadFile(
    presignedRequest: PresignedRequestDto,
    file: File | Blob
  ) {
    const formData = new FormData();
    Object.keys(presignedRequest.body).forEach((key) => {
      formData.append(key, presignedRequest.body[key]);
    });
    formData.append("Content-Type", file.type);
    formData.append("file", file);
    await axios.post(presignedRequest.uploadUrl, formData);
    return presignedRequest.publicUrl;
  }
}
