import { SliderProps, TypographyProps } from "@mui/material";

import CustomCanvasObjectProps from "../../../types/CustomCanvasObjectProps";
import CustomSliderInput from "@paperdateco/shared-frontend/components/common/form/CustomSliderInput";
import EditableTextElement from "../../../objects/EditableTextElement";
import useOptionRerender from "../../../hooks/useOptionRerender";

const CurveSliderProps: SliderProps = {
  step: 1,
  min: -100,
  max: 100,
  valueLabelDisplay: "off",
};

const LabelProps: TypographyProps = {
  minWidth: 40,
};

interface TextCurveControlProps {
  textElements: EditableTextElement[];
}

export default function TextCurveControl({
  textElements,
}: TextCurveControlProps) {
  useOptionRerender(textElements, CustomCanvasObjectProps.CURVE);

  const textPath = textElements[0]?.textPath;
  const textPathValue = textElements[0]?.textPathParams[0];

  const setValue = (value: number) =>
    textElements.forEach((element) => element.setPathParams([value]));

  if (!textPath) {
    return null;
  }

  return (
    <CustomSliderInput
      value={textPathValue}
      onValueChange={setValue}
      label="Curve"
      sliderProps={CurveSliderProps}
      labelProps={LabelProps}
    />
  );
}
