import { CSSProperties } from "react";
import HiddenImage from "./HiddenImage";
import HiddenImageFit from "./HiddenImageFit";
import HiddenVideo from "./HiddenVideo";

interface BackgroundSelectProps {
  src?: string;
  type: string;
  style?: CSSProperties;
  fit?: HiddenImageFit;
  aspectRatio?: number;
}

export default function BackgroundSelect({
  src,
  type,
  style,
  fit,
  aspectRatio,
}: BackgroundSelectProps) {
  if (!type) {
    type = "image";
  }
  return (
    <>
      {type === "image" ? (
        <HiddenImage
          src={src}
          width="100%"
          height="100%"
          display="block"
          style={style}
          fit={fit}
          aspectRatio={aspectRatio}
        />
      ) : (
        <HiddenVideo src={src} />
      )}
    </>
  );
}
