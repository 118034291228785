import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import LibraryImageQueryParam from "@paperdateco/common/dto/design/library/image/LibraryImageQueryParam";
import LibraryImageRequestDto from "@paperdateco/common/dto/design/library/image/LibraryImageRequestDto";
import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";
import LibraryUserImageRequestDto from "@paperdateco/common/dto/design/library/image/LibraryUserImageRequestDto";
import StoreInstance from "@paperdateco/common/store/StoreInstance";
import { handleApiError } from "@paperdateco/common/utils/ApiUtils";

export default class DesignLibraryApi {
  public static async getLibraryImages() {
    try {
      const images = await StoreInstance.api().get<LibraryImageDto[]>(
        "/design-library/images"
      );
      return images.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get library images");
    }
  }

  public static async getMyLibraryImages() {
    try {
      const images = await StoreInstance.api().get<LibraryImageDto[]>(
        "/design-library/images/me"
      );
      return images.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get my library images");
    }
  }

  public static async addNewLibraryImage(data: LibraryImageRequestDto) {
    try {
      const image = await StoreInstance.api().post<LibraryImageDto>(
        "/design-library/images",
        data
      );
      return image.data;
    } catch (e) {
      throw handleApiError(e, "Failed to add new library image");
    }
  }

  public static async addUserImage(data: LibraryUserImageRequestDto) {
    try {
      const image = await StoreInstance.api().post<LibraryImageDto>(
        "/design-library/images/me",
        data
      );
      return image.data;
    } catch (e) {
      throw handleApiError(e, "Failed to add new library image");
    }
  }

  public static async getLibraryImage(imageId: string) {
    try {
      const image = await StoreInstance.api().get<LibraryImageDto>(
        `/design-library/images/${imageId}`
      );
      return image.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get library image");
    }
  }

  public static async updateLibraryImage(
    imageId: string,
    data: LibraryImageRequestDto
  ) {
    try {
      const image = await StoreInstance.api().put<LibraryImageDto>(
        `/design-library/images/${imageId}`,
        data
      );
      return image.data;
    } catch (e) {
      throw handleApiError(e, "Failed to update library image");
    }
  }

  public static async hideLibraryImage(imageId: string) {
    try {
      const image = await StoreInstance.api().delete<LibraryImageDto>(
        `/design-library/images/${imageId}`
      );
      return image.data;
    } catch (e) {
      throw handleApiError(e, "Failed to hide library image");
    }
  }

  // User Apis

  public static async getLibraryImagesByType(
    type: LibraryImageType,
    query: LibraryImageQueryParam = {}
  ) {
    try {
      const images = await StoreInstance.api().get<LibraryImageDto[]>(
        `/design-library/images/type/${type}?${this.getQueryString(query)}`
      );
      return images.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get library images");
    }
  }

  public static async getDesignImagesByType(
    type: LibraryImageType,
    query: LibraryImageQueryParam = {}
  ) {
    try {
      const images = await StoreInstance.api().get<LibraryImageDto[]>(
        `/design-library/design-images/type/${type}?${this.getQueryString(
          query
        )}`
      );
      return images.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get library images");
    }
  }

  public static async searchLibraryImages(
    type: LibraryImageType,
    query: LibraryImageQueryParam = {}
  ) {
    try {
      const images = await StoreInstance.api().get<LibraryImageDto[]>(
        `/design-library/images/type/${type}/search?${this.getQueryString(
          query
        )}`
      );
      return images.data;
    } catch (e) {
      throw handleApiError(e, "Failed to search library images");
    }
  }

  private static getQueryString(query: LibraryImageQueryParam) {
    const params = new URLSearchParams("");
    if (query.query) {
      params.append("query", query.query);
    }
    if (query.user) {
      params.append("user", query.user);
    }
    if (query.limit) {
      params.append("limit", query.limit.toString());
    }
    if (query.design) {
      params.append("design", query.design);
    }
    return params.toString();
  }
}
