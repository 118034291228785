import { Box, BoxProps } from "@mui/system";
import { Stack, styled } from "@mui/material";

import AlignmentButtonControl from "./AlignmentButtonControl";
import BottomActiveIcon from "@paperdateco/shared-frontend/assets/images/invite/alignment/bottom-active.svg";
import BottomIcon from "@paperdateco/shared-frontend/assets/images/invite/alignment/bottom.svg";
import CanvasElement from "@paperdateco/shared-frontend/canvas/objects/CanvasElement";
import CenterActiveIcon from "@paperdateco/shared-frontend/assets/images/invite/alignment/center-active.svg";
import CenterIcon from "@paperdateco/shared-frontend/assets/images/invite/alignment/center.svg";
import InviteInstantPreview from "@paperdateco/shared-frontend/components/invite/InviteInstantPreview";
import LeftActiveIcon from "@paperdateco/shared-frontend/assets/images/invite/alignment/left-active.svg";
import LeftIcon from "@paperdateco/shared-frontend/assets/images/invite/alignment/left.svg";
import MiddleActiveIcon from "@paperdateco/shared-frontend/assets/images/invite/alignment/middle-active.svg";
import MiddleIcon from "@paperdateco/shared-frontend/assets/images/invite/alignment/middle.svg";
import RightActiveIcon from "@paperdateco/shared-frontend/assets/images/invite/alignment/right-active.svg";
import RightIcon from "@paperdateco/shared-frontend/assets/images/invite/alignment/right.svg";
import TopActiveIcon from "@paperdateco/shared-frontend/assets/images/invite/alignment/top-active.svg";
import TopIcon from "@paperdateco/shared-frontend/assets/images/invite/alignment/top.svg";
import { useReducer } from "react";

const Container = styled(Box)(({ theme }) => ({
  "& > *": {
    margin: theme.spacing(2, 0),
  },
}));

interface InviteElementLocationControlProps<T extends fabric.Object> {
  instantPreview: InviteInstantPreview;
  elements: CanvasElement<T>[];
  canvasWidth: number;
  canvasHeight: number;
}

export default function InviteElementLocationControl<T extends fabric.Object>({
  instantPreview,
  elements,
  canvasWidth,
  canvasHeight,
  ...props
}: InviteElementLocationControlProps<T> & BoxProps) {
  const [, rerender] = useReducer((x) => x + 1, 0);

  const element = instantPreview.canvas.getActiveObject();

  const onMoveLeft = () => {
    element?.set("originX", "left");
    element?.set("left", 0);
    rerender();
  };

  const isLeft = () => element?.originX === "left" && element?.left === 0;

  const onMoveRight = () => {
    element?.set("originX", "right");
    element?.set("left", canvasWidth);
    rerender();
  };

  const isRight = () =>
    element?.originX === "right" && element?.left === canvasWidth;

  const onMoveCenter = () => {
    element?.set("originX", "center");
    element?.set("left", canvasWidth / 2);
    rerender();
  };

  const isCenter = () =>
    element?.originX === "center" && element?.left === canvasWidth / 2;

  const onMoveTop = () => {
    element?.set("originY", "top");
    element?.set("top", 0);
    rerender();
  };

  const isTop = () => element?.originY === "top" && element?.top === 0;

  const onMoveBottom = () => {
    element?.set("originY", "bottom");
    element?.set("top", canvasHeight);
    rerender();
  };

  const isBottom = () =>
    element?.originY === "bottom" && element?.top === canvasHeight;

  const onMoveMiddle = () => {
    element?.set("originY", "center");
    element?.set("top", canvasHeight / 2);
    rerender();
  };

  const isMiddle = () =>
    element?.originY === "center" && element?.top === canvasHeight / 2;

  return (
    <Container paddingX={2} width="100%" {...props}>
      <Stack
        direction="row"
        spacing={1}
        width="100%"
        textAlign="center"
        justifyContent="space-between"
      >
        <Stack spacing={2}>
          <AlignmentButtonControl
            name="Top"
            icon={isTop() ? TopActiveIcon : TopIcon}
            onClick={onMoveTop}
          />
          <AlignmentButtonControl
            name="Middle"
            icon={isMiddle() ? MiddleActiveIcon : MiddleIcon}
            onClick={onMoveMiddle}
          />
          <AlignmentButtonControl
            name="Bottom"
            icon={isBottom() ? BottomActiveIcon : BottomIcon}
            onClick={onMoveBottom}
          />
        </Stack>
        <Stack spacing={2}>
          <AlignmentButtonControl
            name="Left"
            icon={isLeft() ? LeftActiveIcon : LeftIcon}
            onClick={onMoveLeft}
          />
          <AlignmentButtonControl
            name="Center"
            icon={isCenter() ? CenterActiveIcon : CenterIcon}
            onClick={onMoveCenter}
          />
          <AlignmentButtonControl
            name="Right"
            icon={isRight() ? RightActiveIcon : RightIcon}
            onClick={onMoveRight}
          />
        </Stack>
      </Stack>
    </Container>
  );
}
