import AccountLayout from "../../account/AccountLayout";
import AccountOrders from "../../account/AccountOrders";

export default function AccountOrderPage() {
  return (
    <AccountLayout>
      <AccountOrders />
    </AccountLayout>
  );
}
