import CanvasElement from "./CanvasElement";
import CanvasUtils from "../CanvasUtils";
import DesignImageMaskPropertiesDto from "@paperdateco/common/dto/design/layer/mask/DesignImageMaskPropertiesDto";
import DesignImageType from "@paperdateco/common/dto/design/layer/image/DesignImageType";
import DownloadUtils from "@paperdateco/common/utils/DownloadUtils";
import ImageMaskOptions from "@paperdateco/common/canvas/objects/ImageMaskOptions";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import ResizableImageElement from "./ResizableImageElement";
import ResizableImageOptions from "@paperdateco/common/canvas/objects/ResizableImageOptions";
import { fabric } from "fabric";

export default class ImageMaskElement extends CanvasElement<fabric.Group> {
  public image?: LibraryImageDto;
  public imageType: DesignImageType;
  private imageElement?: fabric.Image;

  constructor(
    public mask: LibraryImageDto,
    canvas: fabric.Canvas,
    maskGroup: fabric.Group
  ) {
    super(canvas, maskGroup);
    this.nativeElement.lockScalingFlip = true;
    this.nativeElement.lockRotation = true;
    this.listenForMaskUpdate();
  }

  static async fromOptions(
    canvas: fabric.Canvas,
    { mask, maskedImage, animation, ...options }: ImageMaskOptions
  ) {
    const maskUrl = await DownloadUtils.get(mask.url);
    const maskGroup = await CanvasUtils.loadMask(maskUrl, options);
    maskGroup.setControlVisible("mtr", false);
    const element = new ImageMaskElement(mask, canvas, maskGroup);
    element.setAnimation(animation?.animation);
    if (maskedImage) {
      await element.addImage(
        maskedImage.image && maskedImage.type
          ? {
              image: maskedImage.image.image,
              type: maskedImage.type,
              scaleX: maskedImage.scaleX,
              scaleY: maskedImage.scaleY,
            }
          : undefined
      );
      element.setOffsetX(maskedImage.offsetX ?? 0);
      element.setOffsetY(maskedImage.offsetY ?? 0);
      element.setImageScaleX(maskedImage.scaleX ?? 1);
      element.setImageScaleY(maskedImage.scaleY ?? 1);
    }
    return element;
  }

  static convertDesignPropertyToOptions(
    property: DesignImageMaskPropertiesDto
  ): ImageMaskOptions {
    return {
      ...super.convertDesignPropertyToOptions(property),
      mask: property.mask.image,
      maskedImage: property.maskedImage,
    };
  }

  getDesignLayerProperties = (): DesignImageMaskPropertiesDto => {
    return {
      ...super.getDesignLayerProperties(),
      mask: {
        image: this.mask,
        url: this.mask.url,
        price: this.mask.price,
      },
      maskWidth: this.nativeElement.width ?? 0,
      maskHeight: this.nativeElement.height ?? 0,
      maskedImage: this.image && {
        image: {
          image: this.image,
          url: this.image.url,
          price: this.image.price,
        },
        type: this.imageType,
        offsetX: this.imageElement?.left,
        offsetY: this.imageElement?.top,
        scaleX: this.imageElement?.scaleX ?? 1,
        scaleY: this.imageElement?.scaleY ?? 1,
      },
    };
  };

  addImage = async (options?: ResizableImageOptions) => {
    if (!options) {
      return;
    }
    this.image = options.image;
    this.imageType = options.type;
    this.nativeElement.absolutePositioned = true;
    this.imageElement = await ResizableImageElement.getImageElement({
      image: options.image,
      type: options.type,
      top: 0,
      left: 0,
      scaleX: options.scaleX,
      scaleY: options.scaleY,
      angle: options.angle,
      flipX: options.flipX,
      flipY: options.flipY,
    });

    CanvasUtils.loadMaskImage(this.nativeElement, this.imageElement);
    this.canvas.renderAll();
  };

  resetImageOptions = () => {
    this.setOffsetX(0);
    this.setOffsetY(0);
    const scaledToMask =
      (this.nativeElement.width ?? 0) / (this.imageElement?.width ?? 1);
    this.setImageScale(scaledToMask / 2);
  };

  setOffsetX = (value: number) => {
    this.imageElement?.set("left", value);
  };

  setOffsetY = (value: number) => {
    this.imageElement?.set("top", value);
  };

  setImageScale = (value: number) => {
    if (value <= 0 || !this.imageElement) {
      return;
    }
    this.imageElement.scale(value);
  };

  setImageScaleX = (value: number) => {
    if (value <= 0 || !this.imageElement) {
      return;
    }
    this.imageElement.set("scaleX", value);
  };

  setImageScaleY = (value: number) => {
    if (value <= 0 || !this.imageElement) {
      return;
    }
    this.imageElement.set("scaleY", value);
  };

  private nativeCenterX() {
    const width =
      (this.nativeElement.width ?? 0) * (this.nativeElement.scaleX ?? 1);
    switch (this.nativeElement.originX) {
      case "center":
        return width / 2;
      case "right":
        return width;
      default:
        return 0;
    }
  }

  private nativeCenterY() {
    const height =
      (this.nativeElement.height ?? 0) * (this.nativeElement.scaleY ?? 1);
    switch (this.nativeElement.originY) {
      case "center":
        return height / 2;
      case "bottom":
        return height;
      default:
        return 0;
    }
  }

  private listenForMaskUpdate() {
    this.canvas.on("mouse:dblclick", async (e) => {
      if (e.target !== this.nativeElement || !this.image) {
        return;
      }
      const maskScaleX = 2 * (this.nativeElement.scaleX ?? 1);
      const maskScaleY = 2 * (this.nativeElement.scaleY ?? 1);
      const newImageOpposite = await ResizableImageElement.getImageElement({
        image: this.image,
        type: this.imageType,
        left:
          (this.nativeElement.left ?? 0) -
          this.nativeCenterX() +
          (this.imageElement?.left ?? 0) * maskScaleX,
        top:
          (this.nativeElement.top ?? 0) -
          this.nativeCenterY() +
          (this.imageElement?.top ?? 0) * maskScaleY,
        opacity: 0.3,
        scaleX: (this.imageElement?.scaleX ?? 1) * maskScaleX,
        scaleY: (this.imageElement?.scaleY ?? 1) * maskScaleY,
        angle: this.nativeElement?.angle ?? 0,
        flipX: this.imageElement?.flipX,
        flipY: this.imageElement?.flipY,
        lockRotation: true,
        lockScalingFlip: true,
      });
      newImageOpposite.setControlVisible("mtr", false);
      const updateOffset = () => {
        this.setOffsetX(
          ((newImageOpposite.left ?? 0) +
            this.nativeCenterX() -
            (this.nativeElement.left ?? 0)) /
            maskScaleX
        );
        this.setOffsetY(
          ((newImageOpposite.top ?? 0) +
            this.nativeCenterY() -
            (this.nativeElement.top ?? 0)) /
            maskScaleY
        );
      };
      const updateScale = () => {
        this.setImageScaleX((newImageOpposite.scaleX ?? 1) / maskScaleX);
        this.setImageScaleY((newImageOpposite.scaleY ?? 1) / maskScaleY);
        updateOffset();
      };
      newImageOpposite.on("moving", updateOffset);
      newImageOpposite.on("scaling", updateScale);
      newImageOpposite.onDeselect = () => {
        this.canvas.remove(newImageOpposite);
        return false;
      };
      this.canvas.add(newImageOpposite);
      this.canvas.setActiveObject(newImageOpposite);
    });
  }
}
