import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";

export default class FullScreenUtils {
  public static toggle() {
    if (document.fullscreenElement === null) {
      document.body.requestFullscreen().catch((err) => {
        NotificationUtils.showError(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        );
      });
    } else {
      document.exitFullscreen();
    }
  }
}
