import { useCallback, useEffect, useRef, useState } from "react";

import debounce from "lodash.debounce";

export const useDimensionRef = (): [
  (node: HTMLCanvasElement) => void,
  number,
  number
] => {
  const fabricRef = useRef<HTMLCanvasElement>();
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const setDimension = useCallback(() => {
    setWidth(fabricRef.current?.clientWidth ?? 0);
    setHeight(fabricRef.current?.clientHeight ?? 0);
  }, []);

  useEffect(() => {
    setDimension();
    const debouncedSetDimension = debounce(setDimension, 1000);
    window.addEventListener("resize", debouncedSetDimension);
    return () => {
      window.removeEventListener("resize", debouncedSetDimension);
    };
  }, [setDimension]);

  return [
    useCallback((node: HTMLCanvasElement) => {
      if (node) {
        fabricRef.current = node;
      }
    }, []),
    width,
    height,
  ];
};
