import GetItemsAction from "@paperdateco/shared-frontend/store/getItems/GetItemsAction";
import GetItemsActionTypes from "@paperdateco/shared-frontend/store/getItems/GetItemsTypes";
import { ORDER_GET_ITEMS_NAME } from "./OrderTypes";
import OrderApi from "@paperdateco/common/api/OrderApi";
import OrderDetailedDto from "@paperdateco/common/dto/order/OrderDetailedDto";
import { RootState } from "..";
import { ThunkDispatch } from "redux-thunk";

export const getAllOrders = GetItemsAction(
  ORDER_GET_ITEMS_NAME,
  OrderApi.getAllUserOrders,
  (state: RootState) => state.order
);

export type OrderStateDispatchType = ThunkDispatch<
  RootState,
  unknown,
  GetItemsActionTypes<OrderDetailedDto>
>;
