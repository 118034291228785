import {
  Button,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import DesignUtils from "@paperdateco/common/utils/DesignUtils";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import HiddenImageFit from "@paperdateco/shared-frontend/components/common/image/HiddenImageFit";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import WishListApi from "@paperdateco/common/api/WishListApi";
import { useCallback } from "react";
import useCustomiseTemplate from "../../products/detail/useCustomiseTemplate";

interface WishListItemRowProps {
  product: ProductDto;
  onRemoveProduct?: () => void;
}
export default function WishListItemRow({
  product,
  onRemoveProduct,
}: WishListItemRowProps) {
  const onCustomiseTemplate = useCustomiseTemplate(product);

  const removeProduct = useCallback(async () => {
    try {
      await WishListApi.updateWishList({
        productId: product.id,
        add: false,
      });
      onRemoveProduct?.();
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  }, [onRemoveProduct, product]);

  return (
    <TableRow>
      <TableCell>
        <HiddenImage
          src={DesignUtils.getImageFromProduct(product)}
          height={125}
          width={125}
          fit={HiddenImageFit.COVER}
        />
      </TableCell>
      <TableCell>
        <Typography variant="h6">{product.name}</Typography>
      </TableCell>
      <TableCell align="right">₹ {product.price}</TableCell>
      <TableCell>
        <Button
          variant="contained"
          color="primary"
          onClick={onCustomiseTemplate}
        >
          Customise Template
        </Button>
      </TableCell>
      <TableCell>
        <IconButton onClick={removeProduct}>
          <CloseIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
