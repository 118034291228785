import { RootState } from "..";

export const selectAllCategories = (state: RootState) => state.category.items;

export const selectCategoryBySlug =
  (categorySlug: string) => (state: RootState) =>
    selectAllCategories(state).find(
      (category) => category.slug === categorySlug
    );

export const selectAllCategoriesWithParent =
  (parentId: string) => (state: RootState) =>
    selectAllCategories(state).filter(
      (category) => category.parent === parentId
    );
