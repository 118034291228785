import { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface TrackPageViewProps {
  onPage: () => void;
}

export default function TrackPageView({ onPage }: TrackPageViewProps) {
  const location = useLocation();

  useEffect(() => {
    onPage(); // To track the subsequent pageviews
  }, [location, onPage]);

  return null;
}
