import { Box } from "@mui/system";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import { PropsWithChildren } from "react";
import Transition from "@paperdateco/shared-frontend/utils/styles/Transition";

const OverlayContainer = CustomStyled(Box)({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  color: Colors.WHITE,
  transition: Transition.Default,
});

const Container = CustomStyled(Box)({
  position: "relative",
  overflow: "hidden",
  ".image": {
    transition: Transition.Default,
  },
  "& .overlay-text": {
    position: "relative",
    span: {
      backgroundImage: `linear-gradient(transparent, ${Colors.WHITE})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "0% 4px",
      backgroundPosition: "50% 100%",
      transition: Transition.Default,
    },
  },
  "&:hover": {
    ".image": {
      transform: "scale(1.1)",
    },
    "& .overlay": {
      background: Colors.TRANSPARENT_GREY_BACKGROUND,
    },
    "& .overlay-text": {
      opacity: 1,
      span: {
        backgroundSize: "100% 4px",
      },
    },
  },
});

interface AnimatedImageSectionProps {
  src: string;
  name: string;
}

export default function AnimatedImageSection({
  src,
  children,
}: PropsWithChildren<AnimatedImageSectionProps>) {
  return (
    <Container>
      <HiddenImage
        className="image"
        width="100%"
        display="block"
        src={src}
        aspectRatio={1}
      />
      <OverlayContainer className="overlay">{children}</OverlayContainer>
    </Container>
  );
}
