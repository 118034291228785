import Layout from "../../layout/Layout";
import ProductSearchList from "../../products/productSearch/ProductSearchList";
import useQueryParam from "@paperdateco/customer/utils/useQueryParam";

export default function ProductSearchResultPage() {
  const query = useQueryParam("query");
  const category = useQueryParam("category");

  return (
    <Layout>
      <ProductSearchList query={query} category={category} />
    </Layout>
  );
}
