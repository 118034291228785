import { Box, Button, Grid, Stack, Typography } from "@mui/material";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import Fonts from "@paperdateco/shared-frontend/utils/styles/Fonts";
import ReactImageMagnify from "react-image-magnify";

const Container = CustomStyled(Box)(({ theme }) => ({
  background: Colors.WHITE,
  width: "90vw",
  padding: theme.spacing(2),
  border: `1px solid ${Colors.BLACK}`,
  borderRadius: theme.spacing(0.8),
  margin: "auto",
}));

interface PrintRequestPopupProps {
  sample: string;
  onClose?: () => void;
}

export default function ProductSamplePreview({
  sample,
  onClose,
}: PrintRequestPopupProps) {
  return (
    <Container fontFamily={Fonts.Roboto}>
      <Grid container spacing={2} justifyContent="flex-start">
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <ReactImageMagnify
              {...{
                smallImage: {
                  isFluidWidth: true,
                  src: sample,
                },
                largeImage: {
                  src: sample,
                  width: 1200,
                  height: 800,
                },
                isHintEnabled: true,
                shouldHideHintAfterFirstActivation: false,
              }}
            />
            <Button
              variant="contained"
              size="large"
              onClick={() => onClose?.()}
            >
              CLOSE
            </Button>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          alignItems="center"
          textAlign="justify"
          justifyContent="center"
        >
          <Box>
            <Typography variant="h4">Image Zoom Options</Typography>
            <br />
            <Typography variant="h5">Touch Screen</Typography>
            <Typography variant="body1">
              Press (long touch) image to magnify. Pan (drag) to traverse image.
            </Typography>
            <br />
            <Typography variant="h5">Using Mouse</Typography>
            <Typography variant="body1">
              Hover over image to magnify.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
