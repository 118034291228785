import StoreInstance from "../store/StoreInstance";
import WishListDto from "../dto/user/wishList/WishListDto";
import WishListUpdateRequestDto from "../dto/user/wishList/WishlistUpdateRequestDto";
import { handleApiError } from "../utils/ApiUtils";

export default class WishListApi {
  public static getAll = async () => {
    try {
      const wishLists = await StoreInstance.api().get<WishListDto[]>(
        "/wishlist/me"
      );
      return wishLists.data;
    } catch (error) {
      throw handleApiError(error, "Failed to get all wishLists");
    }
  };

  public static updateWishList = async (
    wishListData: WishListUpdateRequestDto
  ) => {
    try {
      const wishList = await StoreInstance.api().post<WishListDto>(
        "/wishlist",
        wishListData
      );

      return wishList.data;
    } catch (error) {
      throw handleApiError(error, "Failed to add wishList");
    }
  };
}
