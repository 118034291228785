interface TextColorIconProps {
  color: string;
}

export default function TextColorIcon({ color }: TextColorIconProps) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 24 26"
      enableBackground="new 0 0 24 26"
      width="100%"
    >
      <defs id="defs17" />
      <g id="g10">
        <path
          d="M16.8,16.9L16,14.8H9.2L8.4,17c-0.3,0.8-0.6,1.4-0.8,1.7C7.3,19,7,19.1,6.5,19.1c-0.4,0-0.8-0.2-1.1-0.5   C5,18.3,4.9,18,4.9,17.6c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.6,0.4-1L9.6,4.9c0.1-0.3,0.3-0.7,0.4-1.1s0.4-0.8,0.6-1.1   c0.2-0.3,0.5-0.5,0.8-0.7s0.7-0.3,1.2-0.3c0.5,0,0.9,0.1,1.2,0.3c0.3,0.2,0.6,0.4,0.8,0.7C14.7,3,14.9,3.3,15,3.6s0.3,0.8,0.5,1.3   l4.4,10.8c0.3,0.8,0.5,1.4,0.5,1.8c0,0.4-0.2,0.7-0.5,1.1c-0.3,0.3-0.7,0.5-1.2,0.5c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.5-0.4   c-0.1-0.2-0.3-0.4-0.4-0.8S16.9,17.2,16.8,16.9z M10.1,12.3h5l-2.5-6.9L10.1,12.3z"
          id="path8"
          fill="#fff"
        />
      </g>
      <rect
        x="4.9"
        y="21.5"
        fill={color}
        width="15.8"
        height="4"
        id="rect12"
        stroke="#fff"
        strokeWidth="0.3"
      />
    </svg>
  );
}
