import { Box, BoxProps } from "@mui/system";

import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import DesignUtils from "@paperdateco/common/utils/DesignUtils";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import ProductDto from "@paperdateco/common/dto/product/ProductDto";
import { Typography } from "@mui/material";

const ProductContainer = CustomStyled(Box)({
  "& .product-image": {
    transition: "all 0.3s",
  },
  "&:hover .product-image": {
    transform: "scale(1.1)",
  },
});

interface CategoryProductItemWithoutLinkProps {
  product: ProductDto;
  showName?: boolean;
}

export default function CategoryProductItemWithoutLink({
  product,
  showName = true,
  ...props
}: CategoryProductItemWithoutLinkProps & BoxProps) {
  return (
    <ProductContainer width="100%" {...props}>
      <Box overflow="hidden" marginBottom={1}>
        <HiddenImage
          className="product-image"
          src={DesignUtils.getImageFromProduct(product)}
          aspectRatio={product.design.width / product.design.height}
          width="100%"
          display="block"
        />
      </Box>
      {showName && (
        <Typography variant="body1" textAlign="center">
          {product.name}
        </Typography>
      )}
    </ProductContainer>
  );
}
