import { Box } from "@mui/system";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import Footer from "./Footer/Footer";
import FullWidthPage from "@paperdateco/shared-frontend/components/layout/FullWidthPage";
import Header from "./Header/Header";
import HomepageFooterMenu from "./Footer/homepage/HomepageFooterMenu";
import { PropsWithChildren } from "react";

export default function Layout({ children }: PropsWithChildren<{}>) {
  return (
    <FullWidthPage>
      <Header
        // backgroundImage={NavbarBackground}
        color={Colors.PRIMARY_LIGHT}
        position="fixed"
        top={0}
        zIndex={15}
        width="100%"
      />
      <Box height={150} width="100%" /> {/* Container to offset fixed header */}
      {children}
      <HomepageFooterMenu />
      <Footer />
    </FullWidthPage>
  );
}
