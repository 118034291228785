import { CART_GET_ITEMS_NAME } from "./CartTypes";
import CartContentsDto from "@paperdateco/common/dto/order/cart/CartContentsDto";
import GetItemsReducer from "@paperdateco/shared-frontend/store/getItems/GetItemsReducer";
import { ON_LOGOUT } from "../common/CommonTypes";

const CartReducer = GetItemsReducer<CartContentsDto | undefined>(
  CART_GET_ITEMS_NAME,
  undefined,
  ON_LOGOUT
);

export default CartReducer;
