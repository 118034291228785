import AccountDashboard from "../../account/AccountDashboard";
import AccountLayout from "../../account/AccountLayout";

export default function AccountDashboardPage() {
  return (
    <AccountLayout>
      <AccountDashboard />
    </AccountLayout>
  );
}
