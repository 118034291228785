import { Link, styled } from "@mui/material";

import GoogleButton from "../../assets/images/btn_google_signin.png";

const GoogleBtn = styled("img")({
  maxWidth: 250,
});

export default function GoogleSignIn() {
  return (
    <Link href={process.env.REACT_APP_API_URL + "auth/google"}>
      <GoogleBtn src={GoogleButton} alt="Google Sign In Button" />
    </Link>
  );
}
