import { Box, Stack, Typography, styled } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import CustomDesignDto from "@paperdateco/common/dto/design/custom/CustomDesignDto";
import InvitePageContents from "@paperdateco/shared-frontend/components/invite/InvitePageContents";

const Container = styled(Box)(({ theme }) => ({
  maxWidth: 250,
  background: Colors.BRIGHT_RED,
  color: Colors.WHITE,
  filter: `drop-shadow(3px 3px 0px ${Colors.LIGHT_SHADOW})`,
  position: "absolute",
  zIndex: 3,
  right: 30,
  padding: theme.spacing(1),
}));

const Label = styled(Box)(({ theme }) => ({
  borderRight: `1px solid ${Colors.WHITE}`,
  marginRight: theme.spacing(1),
}));

interface InviteCustomiseCostPreviewProps {
  pages: InvitePageContents[];
  design: CustomDesignDto;
}

export default function InviteCustomiseCostPreview({
  pages,
  design,
}: InviteCustomiseCostPreviewProps) {
  const [price, setPrice] = useState(0);

  const computeCost = useCallback(() => {
    const productCost = design.product.price;
    const totalCost = pages.reduce(
      (ans, page) => ans + (page.instantPreview?.getCost() ?? 0),
      0
    );
    setPrice(Math.round(productCost + totalCost));
  }, [pages, design]);

  useEffect(() => {
    computeCost();
    pages.map((page) => page.instantPreview?.onPriceChange(computeCost));
    return () =>
      pages.forEach((page) =>
        page.instantPreview?.removePriceChangeListener(computeCost)
      );
  }, [pages, computeCost]);

  return (
    <Container>
      <Stack direction="row" alignItems="center">
        <Label>
          <Typography variant="body2" fontWeight="bold">
            YOUR UNBILLED AMOUNT{" "}
            <Typography component="span" variant="body3">
              (APPROX)
            </Typography>
          </Typography>
        </Label>
        <Typography variant="body1" fontWeight="bold">
          INR
          <Typography component="span" fontWeight="normal">
            {price}
          </Typography>
        </Typography>
      </Stack>
    </Container>
  );
}
