import AspectRatio from "@paperdateco/shared-frontend/utils/styles/AspectRatio";
import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";

const LibraryImageAspectRatio: { [key: string]: number } = {
  [LibraryImageType.BACKGROUND]: AspectRatio.INVITE,
  [LibraryImageType.STICKER]: 1,
  [LibraryImageType.GIF]: 1,
  [LibraryImageType.MASK]: 1,
  [LibraryImageType.PHOTO]: 1,
};

export default LibraryImageAspectRatio;
