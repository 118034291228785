import { Button, Typography } from "@mui/material";

import { Box } from "@mui/system";
import CollaboratorDto from "@paperdateco/common/dto/product/collaborator/CollaboratorDto";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import { Link } from "react-router-dom";
import routes from "@paperdateco/customer/routes/routes";

interface CollaboratorItemProps {
  collaborator: CollaboratorDto;
}

export default function CollaboratorItem({
  collaborator,
}: CollaboratorItemProps) {
  return (
    <Box paddingX={1} paddingY={2}>
      <Link to={routes.COLLABORATOR(collaborator.id)}>
        <Button variant="text">
          {collaborator.profilePicture ? (
            <HiddenImage
              src={collaborator.profilePicture}
              aspectRatio={1}
              width={200}
            />
          ) : (
            <Box
              width={200}
              height={200}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="body1" padding={2}>
                {collaborator.name}
              </Typography>
            </Box>
          )}
        </Button>
      </Link>
    </Box>
  );
}
