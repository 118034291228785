import {
  CartStateDispatchType,
  getAllCartItems,
} from "@paperdateco/customer/store/cart/CartAction";
import { useCallback, useContext, useEffect } from "react";

import { AuthContext } from "@paperdateco/shared-frontend/components/auth/AuthProvider";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import OrderApi from "../../../../../common/src/api/OrderApi";
import OrderPaymentInfoDto from "@paperdateco/common/dto/order/OrderPaymentInfoDto";
import RazorPayUtils from "./RazorPayUtils";
import { getAllOrders } from "@paperdateco/customer/store/order/OrderAction";
import routes from "@paperdateco/customer/routes/routes";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

interface RazorPayOrderProps {
  order: OrderPaymentInfoDto;
}

export default function RazorPayOrder({ order }: RazorPayOrderProps) {
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch<CartStateDispatchType>();
  const navigate = useNavigate();

  const onSuccessfulOrder = useCallback(() => {
    dispatch(getAllCartItems(true));
    dispatch(getAllOrders(true));
    navigate(routes.ACCOUNT_MANAGE_INVITES());
  }, [dispatch, navigate]);

  useEffect(() => {
    if (!user) {
      return;
    }
    if (order.amount <= 0) {
      onSuccessfulOrder();
      return;
    }
    const options = RazorPayUtils.createOrderOptions(order, user, (res) => {
      OrderApi.veriryOrder(order.orderId, {
        razorPayOrderId: res.razorpay_order_id,
        razorPayPaymentId: res.razorpay_payment_id,
        razorPaySignature: res.razorpay_signature,
      })
        .then(onSuccessfulOrder)
        .catch(NotificationUtils.showGenericError);
    });
    const razorPayInstance = RazorPayUtils.createOrder(options, (error) => {
      console.error(error);
      NotificationUtils.showError("Failed to receive payment");
    });
    razorPayInstance.open();
  }, [order, user, onSuccessfulOrder]);

  return null;
}
