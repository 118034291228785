import {
  Tab,
  Tabs,
  TabsProps,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/customer/utils/styles/DefaultTheme";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { useCallback } from "react";

const EventTabs = styled((props: TabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  minHeight: "unset",
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 45,
    width: "100%",
    backgroundColor: Colors.GREEN,
  },
});

const EventTab = CustomStyled(Tab)(({ theme }) => ({
  minHeight: "unset",
  fontSize: theme.typography.body2.fontSize,
  lineHeight: theme.typography.body2.lineHeight,
  padding: theme.spacing(0.5, 2),
  "&:not(:last-child)": {
    borderRight: `1px solid ${Colors.BLACK}`,
  },
}));

interface EventDetailTabsProps {
  value: number;
  setValue: (value: number) => void;
}

export default function EventDetailTabs({
  value,
  setValue,
}: EventDetailTabsProps) {
  const handleChange = useCallback(
    (_, newValue: number) => {
      setValue(newValue);
    },
    [setValue]
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const iconPosition = matches ? "start" : "top";

  return (
    <EventTabs value={value} onChange={handleChange} centered>
      <EventTab
        icon={<EventAvailableOutlinedIcon />}
        iconPosition={iconPosition}
        label="EVENT DETAILS"
      />
      <EventTab
        icon={<GroupsOutlinedIcon />}
        iconPosition={iconPosition}
        label="GUESTS"
      />
      <EventTab
        icon={<NotificationsActiveOutlinedIcon />}
        iconPosition={iconPosition}
        label="REMINDERS"
      />
    </EventTabs>
  );
}
