import { Box, Stack, StackProps } from "@mui/material";

import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import DesignLibraryApi from "@paperdateco/common/api/design/DesignLibraryApi";
import { GifsResult } from "@giphy/js-fetch-api";
import GiphyImagePlugin from "./GiphyImagePlugin";
import { Grid } from "@giphy/react-components";
import HiddenImage from "@paperdateco/shared-frontend/components/common/image/HiddenImage";
import HiddenImageFit from "@paperdateco/shared-frontend/components/common/image/HiddenImageFit";
import { IGif } from "@giphy/js-types";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";
import giphyLogo from "@paperdateco/shared-frontend/assets/images/logo/PoweredBy_640_Horizontal_Light-Backgrounds_With_Logo.gif";
import { useCallback } from "react";

interface GiphyImagesProps {
  query: string;
  type?: LibraryImageType;
  design?: DesignDto;
  fetchGifs?: (offset: number) => Promise<GifsResult>;
  onChoose?: (type: LibraryImageType, item: LibraryImageDto) => void;
}

export default function GiphyImages({
  query,
  type,
  design,
  fetchGifs = GiphyImagePlugin.fetch(query, type),
  onChoose,
  ...props
}: GiphyImagesProps & StackProps) {
  const onChooseGif = useCallback(
    async (gif: IGif) => {
      const libraryImage = await DesignLibraryApi.addUserImage({
        types: [LibraryImageType.GIF], // Giphy always returns a Gif, even if it is a sticker
        url: gif.images.original.url,
        design: design?.id,
      });
      onChoose?.(LibraryImageType.GIF, libraryImage);
    },
    [onChoose]
  );

  if (!fetchGifs || type !== LibraryImageType.GIF) {
    return null;
  }

  return (
    <Stack direction="column" width="100%" paddingX={2} {...props}>
      <HiddenImage
        src={giphyLogo}
        width={"100%"}
        aspectRatio={4.77}
        fit={HiddenImageFit.CONTAIN}
        marginBottom={1.5}
      />
      <Box height="100%" maxHeight={600} overflow="auto">
        <Grid
          key={query}
          width={285}
          columns={2}
          noLink
          fetchGifs={fetchGifs}
          onGifClick={onChooseGif}
        />
      </Box>
    </Stack>
  );
}
